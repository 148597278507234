import { createSelector } from "reselect";

const selectRestaurant = (state) => state.vendors;

export const selectRestaurantData = createSelector(
  [selectRestaurant],
  (vendors) => vendors.vendor
);

export const selectVendorsList = createSelector(
  [selectRestaurant],
  (vendors) => vendors.vendors
);

export const selectFavoriteRestaurants = createSelector(
  [selectRestaurant],
  (vendors) => vendors.favoriteVendors
);

export const selectPopularNearRestaurants = createSelector(
  [selectRestaurant],
  (vendors) => vendors.popularNearVendors
);

export const selectVendorFilters = createSelector(
  [selectRestaurant],
  (vendors) => vendors.filters
);

export const selectListVendorsLoadingLoadMore = createSelector(
  [selectRestaurant],
  (vendors) => vendors.loadingLoadMore
);

export const selectListVendorsLoading = createSelector(
  [selectRestaurant],
  (vendors) => vendors.loading
);

export const selectTotalPages = createSelector(
  [selectRestaurant],
  (vendors) => vendors.total_pages
);

export const selectHasMoreVendors = createSelector(
  [selectRestaurant],
  (vendors) => vendors.hasMore
);

export const selectVendorReviews = createSelector(
  [selectRestaurant],
  (vendors) => vendors.reviews
);

export const selectIsReviewsLoading = createSelector(
  [selectRestaurant],
  (vendors) => vendors.isReviewLoading
);

export const selectTopOffersVendors = createSelector(
  [selectRestaurant],
  (vendors) => vendors.topOffersVendors
);
