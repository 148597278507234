import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getPayload } from "utils";

import { selectCurrentUser } from "Redux/user";
import {
  deleteUserProfileStart,
  updateUserProfileStart,
} from "Redux/user/actions/user.actions";

import { CustomButton, Typography, FormInput, Spinner } from "components/atoms";
import { Footer, NavBar } from "components/molecules";
import {
  ChangeMobileModal,
  ChangePasswordModal,
  DeleteAccountModal,
} from "components/organisms";
import { ValidationsWrapper } from "components/Containers";

import styles from "./Profile.module.css";
import {
  selectIsProfileLoading,
  selectIsProfileUpdated,
} from "Redux/user/selectors/user.selectors";
import Layout from "components/molecules/Layout";

const ProfileTemplate = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const isProfileUpdated = useSelector(selectIsProfileUpdated);
  const isProfileLoading = useSelector(selectIsProfileLoading);

  const [profileSaved, setProfileSaved] = useState(false);

  const SaveProfile = (userData) => dispatch(updateUserProfileStart(userData));
  const SaveProfileHandler = (e, userData) => {
    e.preventDefault();
    SaveProfile(userData);
    setProfileSaved(true);
  };

  useEffect(() => {
    if (isProfileUpdated && profileSaved) {
      // window.location.reload();
    }
  }, [isProfileUpdated]);

  const [userData, setUserData] = useState({
    first_name: currentUser?.first_name,
    last_name: currentUser?.last_name,
    phone: currentUser?.mobile_number,
    email: currentUser?.email,
    date_of_birth: currentUser?.date_of_birth,
    receive_offers_discounts: currentUser?.receive_offers_discounts,
    subscribe_news_letter: currentUser?.subscribe_news_letter,
    gender: parseInt(Object.keys(currentUser?.gender)[0]),
  });

  // modals
  const [isChangeMobile, setIsChangeMobile] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);

  return (
    <Layout>
      {isProfileLoading ? (
        <Spinner color="#fff" loading={isProfileLoading} />
      ) : (
        <div className={styles.main}>
          <ValidationsWrapper
            initialValues={{
              first_name: userData.first_name,
              last_name: userData.last_name,
              phone: userData.phone,
              email: userData.email,
              date_of_birth: userData.date_of_birth,
              receive_offers_discounts: userData.receive_offers_discounts,
              subscribe_news_letter: userData.subscribe_news_letter,
              gender: userData.gender,
            }}
            rules={["first_name", "last_name", "email"]}
          >
            {({ FormikHandler }) => {
              return (
                <div className={styles.container}>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[220px]"
                      text={t("First Name")}
                    />
                    <FormInput
                      id="First Name"
                      key="First Name"
                      value={FormikHandler.values.first_name}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass="AddressInputColor"
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "first_name",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "first_name",
                              value: e.target.value,
                            },
                          },
                          userData,
                          setUserData
                        );
                      }}
                      error={FormikHandler.errors.first_name}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[220px]"
                      text={t("Last Name")}
                    />
                    <FormInput
                      id={"Last Name"}
                      key={"Last Name"}
                      value={FormikHandler.values.last_name}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "last_name",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "last_name",
                              value: e.target.value,
                            },
                          },
                          userData,
                          setUserData
                        );
                      }}
                      error={FormikHandler.errors.last_name}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[220px]"
                      text={t("Phone")}
                    />
                    <FormInput
                      id={"Phone"}
                      key={"Phone"}
                      value={FormikHandler.values.phone}
                      readOnly={true}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[220px]"
                      text={t("Email (Optional)")}
                    />
                    <FormInput
                      id={"Email (Optional)"}
                      key={"Email (Optional)"}
                      value={FormikHandler.values.email}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue("email", e.target.value);
                        getPayload(
                          {
                            target: {
                              name: "email",
                              value: e.target.value,
                            },
                          },
                          userData,
                          setUserData
                        );
                      }}
                      error={FormikHandler.errors.email}
                    />
                  </div>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[200px]"
                      text={t("Gender (optional)")}
                    />
                    <div className="flex w-full items-center mt-2">
                      <FormInput
                        id="male"
                        key="male"
                        name="Gender"
                        type="radio"
                        fullWidth={false}
                        width="w-5"
                        height="h-5"
                        value={0}
                        checked={FormikHandler.values.gender == 0}
                        required
                        onValueChange={(e) => {
                          FormikHandler.setFieldValue(
                            "gender",
                            parseInt(e.target.value)
                          );
                          getPayload(
                            {
                              target: {
                                name: "gender",
                                value: parseInt(e.target.value),
                              },
                            },
                            userData,
                            setUserData
                          );
                        }}
                        containerClasses="selectInputs"
                        label={t("Male")}
                        labelSize="md"
                        textColor="text-text_777777"
                      />
                      <FormInput
                        id="Female"
                        key="Female"
                        name="Gender"
                        type="radio"
                        fullWidth={false}
                        width="w-5"
                        height="h-5"
                        value={1}
                        checked={FormikHandler.values.gender == 1}
                        required
                        onValueChange={(e) => {
                          FormikHandler.setFieldValue(
                            "gender",
                            parseInt(e.target.value)
                          );
                          getPayload(
                            {
                              target: {
                                name: "gender",
                                value: parseInt(e.target.value),
                              },
                            },
                            userData,
                            setUserData
                          );
                        }}
                        containerClasses="selectInputs"
                        label={t("Female")}
                        labelSize="md"
                        textColor="text-text_777777"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row md:items-center max-w-[750px] py-2">
                    <Typography
                      as="h2"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4 md:w-[220px]"
                      text={t("Date of birth (optional)")}
                    />
                    <FormInput
                      id={"Date of birth (optional)"}
                      key={"Date of birth (optional)"}
                      value={FormikHandler.values.date_of_birth}
                      type="date"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "date_of_birth",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "date_of_birth",
                              value: e.target.value,
                            },
                          },
                          userData,
                          setUserData
                        );
                      }}
                      error={
                        Date.now() - Date.parse(userData.date_of_birth) >=
                        378432000000
                          ? ""
                          : "bigger than years"
                      }
                    />
                  </div>
                  <div className="w-full flex flex-col max-w-[750px] py-2">
                    <CustomButton
                      height="h-9 3xl:h-12"
                      buttonTypeClass="outlined"
                      borderColor="border-transparent"
                      textColor="text-primary-900"
                      classes="font-bold py-1"
                      label={t("Change mobile number")}
                      labelContainerClassName="!justify-start"
                      action={() => setIsChangeMobile(true)}
                    />
                    <CustomButton
                      height="h-9 3xl:h-12"
                      buttonTypeClass="outlined"
                      borderColor="border-transparent"
                      textColor="text-primary-900"
                      classes="font-bold py-1"
                      label={t("Change Password")}
                      labelContainerClassName="!justify-start"
                      action={() => setIsChangePassword(true)}
                    />
                    <CustomButton
                      height="h-9 3xl:h-12"
                      buttonTypeClass="outlined"
                      borderColor="border-transparent"
                      textColor="text-primary-900"
                      classes="font-bold py-1"
                      label={t("Delete account")}
                      labelContainerClassName="!justify-start"
                      action={() => setIsDeleteAccount(true)}
                    />
                    <div className="mt-4">
                      <FormInput
                        id="receive_offers_discounts"
                        name="receive_offers_discounts"
                        value="receive_offers_discounts"
                        type="checkbox"
                        fullWidth={false}
                        checked={FormikHandler.values.receive_offers_discounts}
                        width="w-5"
                        height="h-5"
                        containerClasses="selectInputs"
                        inputTypeClass="solidCheckbox"
                        label={t("Receive offers and discounts")}
                        onValueChange={(e) => {
                          FormikHandler.setFieldValue(
                            "receive_offers_discounts",
                            !FormikHandler.values.receive_offers_discounts
                          );
                          getPayload(
                            {
                              target: {
                                name: "receive_offers_discounts",
                                value: !userData.receive_offers_discounts,
                              },
                            },
                            userData,
                            setUserData
                          );
                        }}
                        labelSize="xl"
                        textColor="text-text_777777"
                      />
                      <FormInput
                        id="subscribe_news_letter"
                        name="subscribe_news_letter"
                        value="subscribe_news_letter"
                        type="checkbox"
                        fullWidth={false}
                        checked={FormikHandler.values.subscribe_news_letter}
                        width="w-5"
                        height="h-5"
                        containerClasses="selectInputs"
                        inputTypeClass="solidCheckbox"
                        label={t("Subscribe to newsletter")}
                        onValueChange={(e) => {
                          FormikHandler.setFieldValue(
                            "subscribe_news_letter",
                            !FormikHandler.values.subscribe_news_letter
                          );
                          getPayload(
                            {
                              target: {
                                name: "subscribe_news_letter",
                                value: !userData.subscribe_news_letter,
                              },
                            },
                            userData,
                            setUserData
                          );
                        }}
                        labelSize="xl"
                        textColor="text-text_777777"
                      />
                    </div>
                    <CustomButton
                      width="w-full"
                      height="h-[46px]"
                      borderRadius="8px"
                      classes="mt-4"
                      label={t("Save")}
                      disabled={
                        !(
                          FormikHandler.isValid &&
                          Date.now() - Date.parse(userData.date_of_birth) >=
                            378432000000
                        )
                      }
                      action={(e) => SaveProfileHandler(e, userData)}
                    />
                  </div>
                </div>
              );
            }}
          </ValidationsWrapper>
        </div>
      )}

      <ChangeMobileModal
        isOpen={isChangeMobile}
        ToggleModalVisibility={() => setIsChangeMobile(!isChangeMobile)}
      />
      <ChangePasswordModal
        isOpen={isChangePassword}
        ToggleModalVisibility={() => setIsChangePassword(!isChangePassword)}
      />
      <DeleteAccountModal
        isOpen={isDeleteAccount}
        ToggleModalVisibility={() => setIsDeleteAccount(!isDeleteAccount)}
      />
    </Layout>
  );
};

export default ProfileTemplate;
