import { useMemo } from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router";

import {
  Account,
  LogoutIcon,
  MyOrdersIcon,
  Location,
} from "utils/helpers/SVGsSrc";

import { signoutStart } from "Redux/user/actions/user.actions";

import { Typography } from "components/atoms";
import { PopOver } from "components/molecules";

const AccountPopoverItem = ({ action, title, icon }) => {
  return (
    <div
      onClick={action}
      className="flex w-[285px] py-[20px] px-[16px] cursor-pointer "
    >
      <div className="ltr:mr-[19px] rtl:ml-[19px] w-[26px] h-[26px] ">
        {icon}
      </div>
      <Typography as="p" text={title} />
    </div>
  );
};

const AccountPopOver = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const accountItems = useMemo(
    () => [
      {
        title: t("My Orders"),
        icon: <MyOrdersIcon width="100%" height="100%" title="My Orders" />,
        action: () => navigate("/Orders"),
      },
      {
        title: t("Account Info"),
        icon: <Account width="100%" height="100%" title="My Orders" />,
        action: () => navigate("/Account_Info"),
      },
      {
        title: t("Saved Adresses"),
        icon: <Location width="100%" height="100%" title="My Orders" />,
        action: () => navigate("/Addresses"),
      },
      {
        title: t("Logout"),
        icon: <LogoutIcon width="100%" height="100%" title="logout" />,
        action: () => {
          navigate("/");
          dispatch(signoutStart());
        },
      },
    ],
    [t, navigate, dispatch]
  );

  return (
    <PopOver
      panelClasses="-translate-x-full rtl:translate-x-0 left-1/2"
      label={<Account width="100%" height="100%" title="user account" />}
    >
      {accountItems.map((accountItem, idx) => (
        <AccountPopoverItem
          key={idx}
          action={accountItem.action}
          title={accountItem.title}
          icon={accountItem.icon}
          path={accountItem.path}
        />
      ))}
    </PopOver>
  );
};

AccountPopoverItem.propTypes = {
  action: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.node,
};

export default AccountPopOver;
