import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getPayload } from "utils";

import { useCountDown } from "Hooks";

import {
  resendVerificationMessageStart,
  setMobileStart,
  verifyOTPStart,
} from "Redux/user/actions/user.actions";
import {
  selectCurrentUser,
  selectUnverifiedMobile,
} from "Redux/user/selectors/user.selectors";

import { CustomButton, FormInput, Typography } from "components/atoms";
import { ValidationsWrapper } from "components/Containers";

import styles from "./SetMobileNumberForm.module.css";

const SetMobileNumberForm = () => {
  const { t } = useTranslation();

  const [mobileNumber, setMobileNumber] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);
  const un_verified_mobile = useSelector(selectUnverifiedMobile);

  const SetMobileHandler = () => {
    const action = () => {
      navigate("/Verify_mobile_number", {
        state: {
          scope: "verification",
          mobile: un_verified_mobile,
          OTPSent: true,
        },
      });
    };

    dispatch(setMobileStart({ mobileNumber, action }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    SetMobileHandler();
  };

  // useEffect(() => {
  //   if (un_verified_mobile) {
  //     navigate("/Verify_mobile_number", {
  //       state: {
  //         scope: "verification",
  //         mobile: un_verified_mobile,
  //         OTPSent: true,
  //       },
  //     });
  //   }
  // }, [navigate, currentUser]);

  return (
    <ValidationsWrapper
      className="h-full"
      initialValues={{ phone: "" }}
      rules={["phone"]}
    >
      {({ FormikHandler }) => {
        return (
          <div className={styles.form}>
            <div className={styles.formcontainer}>
              <div className={styles.formHeader}>
                <Typography
                  as="h1"
                  size="9xl"
                  color="text-text_white"
                  text={t("Set mobile number")}
                />

                <Typography
                  as="p"
                  size="xl"
                  color="text-text_grey"
                  fontWeight="font-normal"
                  lineHeight="leading-[1.23]"
                  classes="mt-2 lg:mt-4 3xl:mt-8 w-full"
                  text={t("Please enter your mobile number")}
                  style={{ textAlign: "start" }}
                />

                <FormInput
                  containerHeight={"mt-4"}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8 focus:border-bg_e8e8e8 !border-r-0 !border-l-[1px] rtl:!border-l-0 rtl:!border-r-[1px] focus:border-r-0 active:border-r-0 shadow-0"
                  width="w-full"
                  height="h-[35px]"
                  placeholder={"mobile number"}
                  value={FormikHandler.values.phone}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("phone", e.target.value);
                    setMobileNumber(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5 lg:mt-8 3xl:mt-10 ">
                <CustomButton
                  width="w-56"
                  height="h-10"
                  label={t("Continue")}
                  disabled={!FormikHandler.isValid}
                  action={handleSubmit}
                />
              </div>
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default SetMobileNumberForm;
