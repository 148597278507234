import VendorsActionTypes from "../types/vendors.types";

import { createAction } from "utils";

export const ListRestaurantDataStart = (id) =>
  createAction(VendorsActionTypes.RESTAURANT_DATA_START, id);

export const ListRestaurantDataSuccess = (data) =>
  createAction(VendorsActionTypes.RESTAURANT_DATA_SUCCESS, data);

export const ListRestaurantDataFailure = (error) =>
  createAction(VendorsActionTypes.RESTAURANT_DATA_FAILURE, error);

export const ListFavoriteRestaurantsStart = (payload) =>
  createAction(VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_START, payload);

export const ListFavoriteRestaurantsSuccess = (favoriteRestaurants) =>
  createAction(
    VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_SUCCESS,
    favoriteRestaurants
  );

export const ListFavoriteRestaurantsFailure = (error) =>
  createAction(VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_FAILURE, error);

export const loadMoreFavoriteVendorsStart = (payload) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_START,
    payload
  );

export const loadMoreFavoriteVendorsSucess = (vendors) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_SUCCESS,
    vendors
  );

export const loadMoreFavoriteVendorsFailure = (error) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_FAILURE,
    error
  );

export const ListPopularNearRestaurantsStart = (payload) =>
  createAction(VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_START, payload);

export const ListPopularNearRestaurantsSuccess = (popularNearRestaurants) =>
  createAction(
    VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_SUCCESS,
    popularNearRestaurants
  );

export const ListPopularNearRestaurantsFailure = (error) =>
  createAction(VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_FAILURE, error);

export const loadMorePopularNearVendorsStart = (payload) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_START,
    payload
  );

export const loadMorePopularNearVendorsSucess = (vendors) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_SUCCESS,
    vendors
  );

export const loadMorePopularNearVendorsFailure = (error) =>
  createAction(
    VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_FAILURE,
    error
  );

export const getAllVendorsStart = (filters) =>
  createAction(VendorsActionTypes.GET_ALL_VENDORS_START, filters);

export const getAllVendorsSuccess = (vendors) =>
  createAction(VendorsActionTypes.GET_ALL_VENDORS_SUCCESS, vendors);

export const getAllVendorsFailure = (error) =>
  createAction(VendorsActionTypes.GET_ALL_VENDORS_FAILURE, error);

export const addFavoriteVendorStart = (id) =>
  createAction(VendorsActionTypes.ADD_FAVORITE_VENDOR_START, id);

export const addFavoriteVendorSuccess = (vendors) =>
  createAction(VendorsActionTypes.ADD_FAVORITE_VENDOR_SUCCESS, vendors);

export const addFavoriteVendorFailure = (error) =>
  createAction(VendorsActionTypes.ADD_FAVORITE_VENDOR_FAILURE, error);

export const removeFavoriteVendorStart = (id) =>
  createAction(VendorsActionTypes.REMOVE_FAVORITE_VENDOR_START, id);

export const removeFavoriteVendorSuccess = (vendors) =>
  createAction(VendorsActionTypes.REMOVE_FAVORITE_VENDOR_SUCCESS, vendors);

export const removeFavoriteVendorFailure = (error) =>
  createAction(VendorsActionTypes.REMOVE_FAVORITE_VENDOR_FAILURE, error);

export const getVendorFiltersStart = () =>
  createAction(VendorsActionTypes.GET_VENDOR_FILTERS_START);

export const getVendorFiltersSuccess = (filters) =>
  createAction(VendorsActionTypes.GET_VENDOR_FILTERS_SUCCESS, filters);

export const getVendorFiltersFailure = (error) =>
  createAction(VendorsActionTypes.GET_VENDOR_FILTERS_FAILURE, error);

export const loadMoreVendorsStart = (payload) =>
  createAction(VendorsActionTypes.LOAD_MORE_VENDORS_START, payload);

export const loadMoreVendorsSucess = (vendors) =>
  createAction(VendorsActionTypes.LOAD_MORE_VENDORS_SUCCESS, vendors);

export const loadMoreVendorsFailure = (error) =>
  createAction(VendorsActionTypes.LOAD_MORE_VENDORS_FAILURE, error);

export const getVendorReviewsStart = (id) =>
  createAction(VendorsActionTypes.GET_VENDOR_REVIEWS_START, id);

export const getVendorReviewsSuccess = (reviews) =>
  createAction(VendorsActionTypes.GET_VENDOR_REVIEWS_SUCCESS, reviews);

export const getVendorReviewsFailure = (error) =>
  createAction(VendorsActionTypes.GET_VENDOR_REVIEWS_FAILURE, error);

export const getTopOffersVendorsStart = () =>
  createAction(VendorsActionTypes.GET_TOP_OFFERS_VENDORS_START);

export const getTopOffersVendorsSuccess = (topOffersVendors) =>
  createAction(
    VendorsActionTypes.GET_TOP_OFFERS_VENDORS_SUCCESS,
    topOffersVendors
  );

export const getTopOffersVendorsFailure = (error) =>
  createAction(VendorsActionTypes.GET_TOP_OFFERS_VENDORS_FAILURE, error);
