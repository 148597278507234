import classNames from "classnames";
import { Typography } from "components/atoms";
import { CustomSlider, ReOrderCard } from "components/molecules";
import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "templates/App/Home/Home.module.css";

const OrderAgain = ({ t, ordersList, ordersChunkSize, width }) => {
  return (
    <section className={styles.orderAgain}>
      <div className={styles.orderAgainHeader}>
        <Typography
          as="h2"
          size="7xl"
          color="text-text_464646"
          classes="mb-6"
          text={t("Order again")}
        />
        <NavLink to="/Orders" state={{ status: "completed" }}>
          <Typography
            as="span"
            size="5xl"
            color="text-text_464646"
            classes="mb-6"
            text={t("View all")}
          />
        </NavLink>
      </div>
      <CustomSlider ArrowsPosition={width < 600 ? "bottom" : "baseline"}>
        {ordersList?.map((ordersArray, idx) => (
          <div
            key={idx}
            className={classNames(
              "flex  justify-evenly w-11/12 2xl:w-full mx-auto",
              {
                "lg:justify-start": ordersArray?.length < ordersChunkSize,
              },
              {
                "2xl:justify-around": !(ordersArray?.length < ordersChunkSize),
              }
            )}
          >
            {ordersArray?.map((order) => (
              <ReOrderCard
                key={order.id}
                orderId={order.id}
                restaurantName={order?.cart?.vendor_name}
                quantity={order?.cart?.cart_items[0]?.quantity}
                productName={order?.cart?.cart_items[0]?.product_name}
                isReviewed={order?.is_reviewed}
                cardClasses={`${
                  ordersArray?.length < ordersChunkSize
                    ? "lg:mx-4 2xl:mx-14"
                    : ""
                }`}
              />
            ))}
          </div>
        ))}
      </CustomSlider>
    </section>
  );
};

export default withTranslation()(OrderAgain);
