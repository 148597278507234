import { ImagesSrc } from "utils";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import styles from "./FullScreenBgLayout.module.css";

const FullScreenBgLayout = ({
  children,
  backgroundImage,
  glassContainerWidth,
  glassContainerHight,
  glassContainerStyles,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${ImagesSrc[backgroundImage]})` }}
      className={styles.container}
    >
      <div className={styles.innerContainer}>
        <div
          className={classnames(
            glassContainerWidth,
            glassContainerHight,
            styles.glassContainer,
            glassContainerStyles
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

FullScreenBgLayout.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  glassContainerWidth: PropTypes.string,
  glassContainerHight: PropTypes.string,
};

export default FullScreenBgLayout;
