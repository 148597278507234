import { PropTypes } from "prop-types";

import { ImagesSrc } from "utils";

import { Img, Typography } from "components/atoms";

import styles from "./RiderInfo.module.css";

const RiderInfo = ({ riderInfo }) => {
  return (
    <div className={styles.container}>
      <div className={styles.riderImage}>
        <Img
          fileName={riderInfo?.image ?? ImagesSrc.Cookdoor}
          containerClasses="w-16 h-14 lg:w-[115px] lg:h-[100px] 2xl:w-[153px] 2xl:h-[136px] "
          altText={riderInfo?.full_name}
          picClasses="rounded-[8px]"
        />
      </div>
      <div className="ltr:ml-4 rtl:mr-4">
        <Typography
          as="p"
          size="3xl"
          color="text-text_464646"
          fontWeight="font-bold"
          lineHeight="leading-[1.21]"
          text={riderInfo?.full_name}
        />
        <Typography
          as="span"
          size="2xl"
          color="text-warm_grey"
          lineHeight="leading-[1.18]"
          classes="font-SFProText"
          text={riderInfo?.mobile_number}
        />
      </div>
    </div>
  );
};

RiderInfo.propTypes = {
  riderInfo: PropTypes.instanceOf(Object),
};

export default RiderInfo;
