import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import { GetFAQStart } from "Redux/user/actions/user.actions";
import {
  selectFAQs,
  selectIsFAQLoading,
} from "Redux/user/selectors/user.selectors";

import { FAQTemplate } from "templates";

const FAQPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLng = i18n?.language;
  const FAQs = useSelector(selectFAQs);
  const isFAQsLoading = useSelector(selectIsFAQLoading);
  useEffect(() => {
    dispatch(GetFAQStart());
  }, [dispatch, currentLng]);
  return <FAQTemplate FAQs={FAQs} isFAQsLoading={isFAQsLoading} />;
};

export default FAQPage;
