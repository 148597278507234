import React from "react";
import { ImagesSrc } from "utils";
import styles from "templates/App/Home/Home.module.css";
import { CustomButton, Img, Typography } from "components/atoms";
import { withTranslation } from "react-i18next";

const DownloadApp = ({ t }) => {
  return (
    <section className={styles.downloadApps}>
      <div className={styles.downloadAppsTextContainer}>
        <Typography
          as="h2"
          size="7xl"
          color="text-warm_grey"
          text={t("Download the app")}
        />
        <Typography
          as="h1"
          size="10xl"
          fontWeight="leading-[1.34]"
          color="text-primary-900"
          className=""
          classes="w-full md:w-[52%] lg:w-4/5 xl:w-3/5  my-5 xl:my-10"
          text={t("Get Started With Abla Nazira Today !")}
        />
        <Typography
          as="p"
          size="xl"
          fontWeight="leading-[1.5]"
          color="text-warm_grey"
          className="w-2/3"
          classes="w-full md:w-[86%]  xl:w-[70%] 2xl:w-2/3"
          text={t("Home Page Description")}
        />
        <div className={styles.DownloadButtons}>
          <a
            href={
              "https://play.google.com/store/apps/details?id=com.inova.ablanazira"
            }
            target="_blank"
          >
            <CustomButton
              bgImage={ImagesSrc.GooglePlay}
              borderRadius="8px"
              width="w-36 3xl:w-[153px]"
              height="h-12"
            />
          </a>

          <a
            href={"https://apps.apple.com/in/app/ablanazira/id1614639645?uo=2"}
            target="_blank"
          >
            <CustomButton
              bgImage={ImagesSrc.AppStore}
              borderRadius="8px"
              width="w-36 3xl:w-[153px]"
              height="h-12"
            />
          </a>
        </div>
      </div>
      <div>
        <Img
          fileName={ImagesSrc.MobileApp}
          fileName2x={ImagesSrc.MobileApp2x}
          fileName3x={ImagesSrc.MobileApp3x}
          altText="mobile app"
          className="w-96"
          containerClasses="w-full md:w-96 xl:w-[31rem] lg:h-auto relative"
        />
      </div>
    </section>
  );
};

export default withTranslation()(DownloadApp);
