import { showToast, toastTypes } from "utils/helpers/Toast";

import { StorageUtils } from "utils";
import axios from "axios";
import i18n from "i18n";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_MODE
    : window.location.origin === "https://webstaging.ablanazira.com"
    ? process.env.REACT_APP_DEV_MODE
    : process.env.REACT_APP_PRO_MODE;

axios.defaults.baseURL = BASE_URL;
//axios.defaults.baseURL = "https://apistaging.ablanazira.com/api/v1";
// axios.defaults.baseURL = "https://api.ablanazira.com/api/v1";

const token = StorageUtils.getItems(localStorage, ["access_token"])[
  "access_token"
];

axios.defaults.headers.common["Authorization"] = token
  ? `Bearer ${token}`
  : null;

const CommonHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};
//console.log(i18n, "i18n");
const getAxios = () => {
  axios.defaults.headers.common["Accept-Language"] =
    localStorage.getItem("i18nextLng") == "ar" ? "ar" : "en";
  const setHeaders = (header, value) => {
    axios.defaults.headers.common[header] = value;
  };

  const call = async (method = "get", url = "", data = {}, headers = {}) => {
    //console.log(localStorage.getItem("i18nextLng"), "test");
    try {
      const response = await axios[method](url, data, {
        ...CommonHeaders,
        headers: {
          ...headers,
          // ["Accept-Language"]:
          //   localStorage.getItem("i18nextLng") == "ar" ? "ar" : "en",
        },
      });

      return response;
    } catch (error) {
      if (url.includes("/send_verification_message")) {
      }
      if (
        !(
          method === "get" &&
          url.includes("/cart/cart") &&
          url.includes("/notifications") &&
          url.includes("/notifications_count")
        )
      )
        showToast(toastTypes.ERR, error?.response?.data?.message);
      //console.log({ error });
      if (error?.response?.status === 403 || error?.response?.status == 401) {
        axios.defaults.headers.common["Authorization"] = null;

        if (!url.includes("/login")) {
          if (!url.includes("/google_login")) {
            if (!url.includes("/facebook_login")) {
              if (!url.includes("/apple_login")) {
                if (!url.includes("/send_verification_message")) {
                  if (!url.includes("/verify_otp")) {
                    localStorage.clear();
                    window.location.reload("/");
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return {
    setHeaders,
    call,
  };
};

export default getAxios;
