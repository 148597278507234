import { useTranslation } from "react-i18next";

import { FullScreenBgLayout } from "components/Layouts";
import { /* CustomButton, */ Typography } from "components/atoms";

const PrivacyPolicyTemplate = () => {
  const { t } = useTranslation();

  return (
    <FullScreenBgLayout
      backgroundImage="PrivacyPolicyBg"
      glassContainerWidth="md:w-4/5 xl:w-7/12"
      glassContainerHight="md:h-max"
    >
      <main className="h-full">
        <div className="h-full flex flex-col justify-between">
          <Typography
            as="h1"
            size="9xl"
            color="text-text_white"
            classes="text-center"
            text={t("Privacy policy")}
          />
          <div className="h-96 w-full whiteScroll overflow-y-scroll my-4 lg:my-8 3xl:my-16">
            <div className=" px-5 md:px-8 lg:px-14 3xl:px-20 ">
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تريد منصة ابلة نظيرة مساعدتك على فهم أفضل لكيفية جمع بياناتك الشخصية واستخدامها وحمايتها ومشاركتها. تم تصميم سياسة الخصوصية هذه لمساعدتك على فهم خيارات الخصوصية الخاصة بك عند زيارة موقعنا 
نحن نشجعك على قراءة سياسة الخصوصية هذه بعناية وخذ الوقت الكافي للتعرف على ممارساتنا. إذا كانت لديك أسئلة حول سياسة الخصوصية هذه أو ممارسات الخصوصية لدينا ، فيرجى الاتصال بنا على البريد الإلكتروني الاتي :-  
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`ماده 1 :- التعاريف.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`"معلومات الحساب" تعني بيانات حول كيفية ووقت الوصول إلى منصة ابلة نظيرة  والميزات المستخدمة ، بما في ذلك معلومات المتجر.
تعني "معلومات المتصفح" التي يوفرها المستعرض ، بما في ذلك عنوان IP ، وموقع الويب الذي تمت زيارته ، واتصال الشبكة ، ومعلومات الجهاز ، والبيانات الأخرى ، بما في ذلك ملفات تعريف الارتباط.
"معلومات الاتصال" تعني المعلومات الشخصية والتجارية الأساسية ، مثل الاسم الأول واسم العائلة واسم الشركة وعنوان البريد الإلكتروني والعنوان البريدي ورقم الهاتف ، وقد تتضمن معلومات حساب وسائل التواصل الاجتماعي.
"معلومات الجهاز" تعني معلومات حول جهازك ، مثل رقم معرف الجهاز ، والطراز ، والشركة المصنعة ، وإصدار نظام التشغيل والمنطقة الجغرافية ، التي يتم جمعها من أي أجهزة عند الوصول إلى موقعنا على الويب ، أو استخدام تطبيق الهاتف المحمول ، أو أي من خدماتنا.
"معلومات الدفع" تعني ، على سبيل المثال ، بطاقة الائتمان أو معلومات الدفع الأخرى.
"معلومات الأمان" تعني معرف المستخدم وكلمة المرور ومعلومات الأمان الأخرى المستخدمة للمصادقة والوصول إلى الحساب.
"معلومات المتجر" تعني معلومات حول متجرك ومنتجاته وبنيته.
تتضمن "معلومات الدعم" معلومات حول أجهزتك وبرامجك ، و محتويات جلسة الدردشة ، و تقارير الأخطاء ، و بيانات الأداء ، و غيرها من الاتصالات أو المعلومات الفنية ، و قد تتضمن ، بإذن صريح ، الوصول عن بُعد لتسهيل استكشاف الأخطاء وإصلاحها.
"معلومات الصفقة " تعني البيانات المتعلقة بالمعاملات و الصفقات التي تحدث على منصتنا ، بما في ذلك المنتج والطلب ومعلومات الشحن ومعلومات الاتصال ومعلومات الدفع.
"معلومات الاستخدام" تعني المعلومات التي يتم جمعها عند تفاعلك مع موقع منصة ابلة نظيرة  أو أي من خدماتنا ، بما في ذلك الوظائف التي تم الوصول إليها والصفحات التي تمت زيارتها وبيانات التفاعل الأخرى.
. "اتخاذ القرار الآلي" يعني القرار الذي يتم اتخاذه بالوسائل الآلية فقط دون تدخل بشري.
. "المتحكم" يعني الكيان الذي يحدد أغراض ووسائل معالجة البيانات الشخصية.
. "ملف تعريف الارتباط" ملف صغير موجود على محرك الأقراص الثابتة بجهاز الكمبيوتر الخاص بك والذي يحتوي غالبًا على معرف فريد مجهول يمكن الوصول إليه بواسطة موقع الويب الذي وضعه هناك ، ولكن لا يمكن الوصول إليه من قبل المواقع الأخرى.
"التاجر" يعني الكيان الذي استخدم أو يستخدم الخدمات في التجارة الإلكترونية.
"البيانات الشخصية" أو "المعلومات الشخصية" تعني المعلومات التي (1) تتعلق بشخص طبيعي محدد أو يمكن التعرف عليه ، أو (2) يحدد أو يرتبط أو يصف أو يمكن أن يرتبط به أو يمكن ربطه بشكل معقول ، بشكل مباشر أو غير مباشر ، مع مستهلك أو أسرة معينة.
. "المعالجة" تعني أي عملية أو مجموعة من العمليات التي يتم إجراؤها على البيانات الشخصية ، سواء بالوسائل الآلية أم لا ، بما في ذلك ، على سبيل المثال لا الحصر ، التغيير والجمع والتنظيم والتسجيل والاسترجاع والتخزين والنقل والاستخدام.
. "البيانات الشخصية الحساسة" تعني أي بيانات تكشف عن الأصل العرقي ، أو الآراء السياسية ، أو المعتقدات الدينية أو الفلسفية ، أو العضوية النقابية ، أو البيانات المتعلقة بالصحة أو الحياة الجنسية للشخص الطبيعي.
. "المتسوق" يعني كيانًا أو شخصًا طبيعيًا يتفاعل مع عرض التجارة الإلكترونية للتاجر من خلال منصة ابلة نظيرة  
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`ماده 2 :- المعلومات المجمعة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتفاعل التاجر مع موقعنا ، على سبيل المثال ، عن طريق التسجيل للحصول على نسخة تجريبية أو اشتراك أو رسالة إخبارية أو محتوى آخر ، أو إجراء معاملات ، قد تجمع منصتنا وتتحكم في المعلومات مثل معلومات الحساب ، معلومات المتصفح ، معلومات الاتصال ، الدفع المعلومات ومعلومات الدعم ومعلومات الجهاز ومعلومات الأمان ومعلومات المعاملات ومعلومات الاستخدام وتعيين ملف تعريف الارتباط.
استخدام المعلومات. نحن نستخدم هذه المعلومات كمراقب لتزويد التجار بخدماتنا ، وتأكيد الهويات ، وتقديم الدعم مثل تصحيح الأخطاء ، واستكشاف الأخطاء وإصلاحها ، واتخاذ القرار الآلي مثل اكتشاف إنشاء حساب احتيالي عند الاشتراك في خدمتنا ، للإعلان والتسويق ، والفواتير ، لحل الحوادث المتعلقة باستخدام موقعنا وخدماتنا ، لتحسين خدماتنا و إضفاء الطابع الشخصي عليها ، مثل الإشعارات الفورية المتعلقة بأنشطة متجرك ، والامتثال للمتطلبات القانونية. 
قد نكشف عن بعض المعلومات ، بما في ذلك معلومات الحساب ومعلومات الاتصال ومعلومات الدعم ومعلومات المعاملات إلى الشركاء الخاضعين لالتزامات السرية التي تحيل التجار إلينا أو يشاركون من قبل التاجر لتقديم الخدمات أو المنتجات المتعلقة بمتجر أو استخدام موقعنا وخدماتنا ، أو لتأكيد الهويات وتحسين وتخصيص تفاعلاتنا وخدماتنا. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها التاجر قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 3:-  الشركاء`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يسجل أحد الشركاء للحصول على حساب شريك أو يحيل تاجرًا إلينا ، يجوز لـمنصتنا جمع معلومات والتحكم فيها مثل معلومات الحساب ومعلومات المتصفح ومعلومات الاتصال ومعلومات الدفع ومعلومات الدعم ومعلومات الاستخدام.
استخدام المعلومات. نحن نستخدم هذه المعلومات كمراقب لتزويد الشركاء بخدماتنا ، وتأكيد الهويات ، وتقديم الدعم ، للإعلان والتسويق ، وإعداد الفواتير ، لحل الحوادث المتعلقة باستخدام موقعنا وخدماتنا ، لتحسين خدماتنا وإضفاء الطابع الشخصي عليها ، والامتثال مع المتطلبات القانونية. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها الشريك قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 4:- الزوار`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتصفح الزائرون موقعنا الإلكتروني ، أو ينخرطون في اتصالات معنا عبر الإنترنت أو دون الاتصال بالإنترنت ، فقد نجمع ونتحكم ، حسب الاقتضاء ، في معلومات المتصفح ، ومعلومات الدعم ، ومعلومات الاتصال ، ومعلومات الاستخدام المقدمة أو المرسلة إلينا.
استخدام المعلومات. نستخدم هذه المعلومات كمراقب لتقديم خدماتنا ، وتحسين وتخصيص الاتصالات والتفاعلات وخدماتنا وتقديم الدعم إذا لزم الأمر. قد نستخدم هذه المعلومات في حالات أخرى يكون فيها الزائر قد أعطى موافقة صريحة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 5:- المتسوقون`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`عندما يتفاعل المتسوقون مع عرض التجارة الإلكترونية للتاجر من خلال منصة ابلة نظيرة  ، يجوز لنا جمع ومعالجة معلومات المتصفح ومعلومات المعاملات للمتسوق نيابة عن التاجر.
استخدام المعلومات. نستخدم هذه المعلومات بصفتنا معالجًا لتقديم خدماتنا إلى التجار ودعم ومعالجة الطلبات وإدارة المخاطر والاحتيال.
التاجر هو المتحكم في هذه المعلومات والمتسوقون الذين لديهم أسئلة حول استخدامنا لهذه المعلومات يجب عليهم الاتصال بالتاجر. يجوز لنا أيضًا استخدام معلومات معينة كمراقب لتحسين خدماتنا وتخصيصها وإدارة المخاطر والاحتيال.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 6:- الاتصالات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`قد نرسل اتصالات ترويجية إلى التجار والشركاء والزوار الحاليين والمحتملين عن طريق البريد الإلكتروني والهاتف والقنوات الأخرى ، مثل لينكد ان. على سبيل المثال ، يجوز لنا إخطار التاجر عند انتهاء الاشتراك ، أو دعوة المستلم للمشاركة في استطلاع. يمكنك إلغاء الاشتراك في تلقي الاتصالات الترويجية منا في أي وقت.  
الاتصالات المطلوبة  بخصوص بعض البيانات المطلوبة ، مثل إشعارات الحساب أو المعلومات ، إلى مستخدمي خدماتنا. لا يجوز لك إلغاء الاشتراك في تلقي هذه الاتصالات إذا كانت لديك واجهة متجر نشطة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 7:- تبادل المعلومات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`في بعض الأحيان يكون من الضروري مشاركة بيانات التاجر أو الشريك أو المتسوق الشخصية معهم لدعم خدماتنا. يجوز لنا الوصول إلى تلك البيانات الشخصية ونقلها و / أو الكشف عنها و / أو الاحتفاظ بها بموافقة أو في الظروف التالية.
الامتثال للأنظمة المعمول بها :- إذا كان لدينا اعتقاد حسن النية أن القيام بذلك ضروري من أجل: (1) الامتثال للأنظمة المعمول بها  أو الاستجابة للإجراءات القانونية الصالحة ، بما في ذلك من جهات إنفاذ القانون أو الوكالات الحكومية الأخرى ؛ أو (2) حماية حقوق أو ممتلكات منصة ابلة نظيرة  ، بما في ذلك فرض الشروط التي تحكم استخدام الخدمات.
توفير الحماية. إذا كان لدينا اعتقاد حسن النية أن القيام بذلك ضروري من أجل: (1) حماية التجار أو الشركاء أو المتسوقين أو الزوار ؛ على سبيل المثال ، لمنع البريد العشوائي أو محاولات الاحتيال علينا أو لمستخدمي خدماتنا ، أو ردًا على تهديدات سلامة أي شخص ؛ أو (2) تشغيل وصيانة أمان منتجاتنا ، بما في ذلك منع أو وقف هجوم على أنظمة الكمبيوتر أو الشبكات الخاصة بنا.
مجموعات تطوير البرمجيات وواجهات برمجة التطبيقات:- قد تتضمن منصة ابلة نظيرة  مكتبات جهات خارجية مثل مجموعات تطوير البرامج  للأغراض الموضحة في سياسة الخصوصية هذه ، بما في ذلك ما يلي:
أطقم تطوير برامج التحليلات: هذه مكتبات معالجات فرعية خارجية تابعة لجهات خارجية نستخدمها في جمع بيانات الجهاز والاستخدام لمراقبة أداء التطبيق.
معالجة الدفع. نشارك بيانات الدفع مع البنوك والكيانات الأخرى التي تعالج معاملات الدفع أو تقدم خدمات مالية أخرى لمنع الاحتيال والحد من مخاطر الائتمان.
الاندماج. أو البيع . قد نكشف أيضًا عن البيانات الشخصية كجزء من معاملة الشركة مثل الدمج أو بيع الأصول.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 8:-  آلية صنع القرار`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`يمكن استخدام بعض البيانات الشخصية في آلية اتخاذ القرار لمساعدتنا في فحص الحسابات بحثًا عن مخاوف تتعلق بالمخاطر أو الاحتيال أو إساءة الاستخدام. مع مراعاة القانون المعمول به ، يمكننا تزويدك بالتفاصيل الكامنة وراء المراجعة الآلية لصنع القرار وتصحيح أي أخطاء.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 9:- ملفات تعريف الارتباط`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تستخدم منصتنا ومقدمو خدمات الطرف الثالث ملفات تعريف الارتباط وإشارات الويب وتقنيات التتبع المماثلة للتعرف عليك عند زيارة موقعنا على الويب وتذكر تفضيلاتك ومنحك تجربة مخصصة.
عندما تزور مواقعنا الإلكترونية ، يجوز لنا ، أو لطرف ثالث مرخص له ، وضع ملف تعريف ارتباط على جهازك يجمع المعلومات ، بما في ذلك البيانات الشخصية ، حول أنشطتك عبر الإنترنت بمرور الوقت وعبر مواقع مختلفة. تتيح لنا ملفات تعريف الارتباط تتبع الاستخدام واستنتاج تفضيلات التصفح وتحسين تجربة التصفح الخاصة بك وتخصيصها.
تظل ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الخاص بك عندما تكون غير متصل بالإنترنت ، بينما يتم حذف ملفات تعريف الارتباط بمجرد إغلاق متصفح الويب الخاص بك.
قد يقوم موقع ويب بتعيين ملف تعريف ارتباط إذا كانت تفضيلات المتصفح تسمح بذلك. يسمح المستعرض لموقع الويب فقط بالوصول إلى ملفات تعريف الارتباط التي قام بتعيينها ، وليس تلك التي حددتها مواقع الويب الأخرى.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`الأنواع.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أ- أساسى:- ملفات تعريف الارتباط هذه ضرورية لموقعنا الإلكتروني للعمل على النحو المنشود.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ب- وظيفي:- تعمل ملفات تعريف الارتباط هذه على تمكين وظائف محسنة ، مثل مقاطع الفيديو والدردشة الحية. و بدون ملفات تعريف الارتباط هذه ، قد تصبح بعض الوظائف غير متاحة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ج. التحليل . تُستخدم ملفات تعريف الارتباط هذه لإنشاء ملفات تعريف أو تخصيص محتوى لتحسين تجربتك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`د-  الاستهداف والإعلان. تُستخدم ملفات تعريف الارتباط هذه لإنشاء ملفات تعريف أو تخصيص محتوى لتحسين تجربتك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ه-  التحكم. من الممكن تعطيل ملفات تعريف الارتباط من خلال جهازك أو إعدادات المتصفح ، ولكن القيام بذلك قد يؤثر على قدرتك على استخدام موقعنا. على سبيل المثال ، قد لا نتمكن من التعرف على جهاز الكمبيوتر أو الجهاز المحمول الخاص بك وقد تحتاج إلى تسجيل الدخول في كل مرة تزور فيها موقعنا على الويب. قد تختلف طريقة تعطيل ملفات تعريف الارتباط حسب الجهاز والمتصفح ، ولكن يمكن العثور عليها عادةً في التفضيلات أو إعدادات الأمان. الرجاء استخدام الروابط التالية لمزيد من التعليمات:`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أ-  . جوجل كروم`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ب أبل سفاري`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`جــــ موزيلا فايرفوكس`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`د. متصفح الانترنت الخاص بمايكروسفت`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ه- متاجر تطبيقات الطرف الثالث: قد تقوم متاجر التطبيقات التابعة لجهات خارجية ، مثل متجر تطبيقات Apple أو Google Play من Google ، بجمع معلومات إضافية ومشاركتها معنا. يرجى الرجوع إلى سياسة الخصوصية لمتجر التطبيقات لفهم كيفية معالجة أي من المعلومات الشخصية التي يجمعونها ومشاركتها مع ناشري التطبيقات أمثالنا بشكل أفضل.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 10:- حماية المعلومات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`نحافظ على تدابير أمنية إدارية وفنية ومادية مصممة لتوفير حماية معقولة للبيانات الشخصية ضد الوصول غير المصرح به والكشف والتعديل والفقدان وسوء الاستخدام. 
لسوء الحظ ، لا توجد طريقة آمنة تمامًا لاستخدام الإنترنت أو نقل البيانات أو التخزين الإلكتروني ، لذلك لا يمكننا ضمان الأمان المطلق للبيانات الشخصية.
بينما نحن ملتزمون بتأمين موقعنا وخدماتنا ، فأنت مسؤول عن تأمين والحفاظ على خصوصية كلمات المرور ومعلومات الحساب الخاصة بك. نحن لسنا مسؤولين عن حماية البيانات الشخصية التي تتم مشاركتها مع طرف ثالث بناءً على اتصال الحساب الذي صرحت به.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 11:-  المساءلة والضمانات للتحويل الداخلي`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={` نحن نقدم خدمات في جميع أنحاء العالم لذا قد يكون من الضروري نقل البيانات الشخصية خارج الدولة التي تم تلقي البيانات فيها. نحن نخضع للأنظمة المعمول بها في المملكة العربية السعودية
معالجات البيانات. لن نشارك البيانات الشخصية أو نكشف عنها إلا للمعالجين المحددين هنا ، والملتزمين تعاقديًا بتوفير نفس مستوى حماية الخصوصية على الأقل الذي تتطلبه المبادئ الأساسية للخصوصية. علاوة على ذلك ، سنلزم أي معالج بالأغراض المحددة والصريحة والشرعية المتوافقة مع موافقتك.
التدابير العلاجية. إذا علمنا أن البيانات الشخصية ليست محمية وفقًا لعقدنا أو تتم معالجتها بما يتجاوز موافقتك ، فسنتخذ خطوات معقولة لحماية معلوماتك و / أو التوقف عن معالجتها غير المشروعة.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 12:-. حقوق صاحب البيانات`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`. بشكل عام. يمكنك ممارسة الحقوق على بياناتك الشخصية. نقدم خطوات معقولة للسماح لك بالوصول إلى بياناتك الشخصية أو تصحيحها أو محوها أو نقلها أو تقييد استخدامها. ويحق لك الاعتراض على استخدام بياناتك الشخصية في أي وقت ، وفقًا للأنظمة  المعمول بها.  
التجار والشركاء والزوار:-. التجار والشركاء قادرون على تحديث العديد من أنواع البيانات الشخصية المجمعة مباشرة داخل حساباتهم. يرجى الاتصال بنا إذا كنت زائرًا أو غير قادر على الوصول إلى بياناتك الشخصية أو تغييرها بطريقة أخرى داخل حسابك.
`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 13:- معلومات للتواصل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={` امتثالاً لمبادئ الخصوصية ، تلتزم منصتنا بحل الشكاوى المتعلقة بجمعنا أو استخدامنا لمعلوماتك الشخصية للاستفسارات أو الشكاوى المتعلقة بسياسة الخصوصية الخاصة بنا ، يجب الاتصال بمنصتنا على:`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`مادة 14:- التحديثات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`. قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر استجابة للتطورات القانونية أو الفنية أو التجارية المتغيرة. عندما نقوم بتحديث سياسة الخصوصية الخاصة بنا ، لن نبلغك بذلك.
يمكنك معرفة تاريخ آخر تحديث لسياسة الخصوصية هذه عن طريق التحقق من تاريخ "تاريخ آخر مراجعة" المعروض في الجزء العلوي من سياسة الخصوصية هذه.
 
`}
              />
            </div>
          </div>
          {/* 
          <div className=" mx-auto w-full lg:w-9/12 flex justify-evenly">
            <CustomButton
              label={t("Decline")}
              buttonTypeClass="outlined"
              width="w-40 lg:w-[215px]"
              height="h-12"
            />

            <CustomButton
              label={t("Accept")}
              width="w-40 lg:w-[215px]"
              height="h-12"
            />
          </div> */}
        </div>
      </main>
    </FullScreenBgLayout>
  );
};

export default PrivacyPolicyTemplate;
