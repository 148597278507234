import OffersActionTypes from "../types/offers.types";

const INITIAL_STATE = {
  offers: null,
  error: null,
  loading: false,
  total_pages: 0,
  hasMore: true,
};

const offersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OffersActionTypes.LIST_OFFERS_START:
      return {
        ...state,
        loading: true,
      };
    case OffersActionTypes.LOAD_MORE_OFFERS_START:
      return {
        ...state,
        loading: true,
        hasMore: true,
      };
    case OffersActionTypes.LIST_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers,
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case OffersActionTypes.LOAD_MORE_OFFERS_SUCCESS:
      const newOffersList = action.payload.offers;
      const { offers } = state;
      return {
        offers: [...offers, ...newOffersList],
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case OffersActionTypes.LOAD_MORE_OFFERS_FAILURE:
    case OffersActionTypes.LIST_OFFERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default offersReducer;
