import CartActionTypes from "../types/cart.types";

export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    (item) => item.id === cartItemToAdd.id
  );
  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.id === cartItemToAdd.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }
  return [...cartItems, { cartItemToAdd, quantity: 1 }];
};

const INITIAL_STATE = {
  cart: null,
  error: null,
  loading: false,
  PromoError: null,
  OrderTime: sessionStorage.getItem("OrderTime"),
  deliveryTimeRanges: [],
};

const CartReducer = (state = INITIAL_STATE, action) => {
  let Payload = action.payload;

  switch (action.type) {
    case CartActionTypes.CART_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.CART_DATA_SUCCESS:
      return {
        ...state,
        cart: Payload,
        loading: false,
      };
    case CartActionTypes.CART_DATA_FAILURE:
      return {
        cart: null,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.UPDATE_CART_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.UPDATE_CART_DATA_SUCCESS:
      return {
        ...state,
        cart: Payload,
        loading: false,
      };
    case CartActionTypes.DELETE_CART_ITEM_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        cart: Payload.cart,
        loading: false,
      };
    case CartActionTypes.UPDATE_CART_DATA_FAILURE:
      return {
        ...state,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.DELETE_CART_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.DELETE_CART_DATA_SUCCESS:
      return {
        cart: null,
        loading: false,
      };
    case CartActionTypes.DELETE_CART_DATA_FAILURE:
      return {
        cart: null,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.UPDATE_CART_ITEM_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.UPDATE_CART_ITEM_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: [
            ...state.cart.cart_items
              .map((item) => (item.id === Payload.id ? Payload : item))
              .filter((item) => item.quantity !== 0),
          ],
        },
        loading: false,
      };
    case CartActionTypes.UPDATE_CART_ITEM_FAILURE:
      return {
        ...state,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.ADD_CART_ITEM_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.ADD_CART_ITEM_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: [...state.cart.cart_items, Payload],
        },
        loading: false,
      };
    case CartActionTypes.ADD_CART_ITEM_FAILURE:
      return {
        ...state,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: [Payload],
        },
        loading: false,
      };
    case CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_FAILURE:
      return {
        ...state,
        error: Payload,
        loading: false,
      };
    case CartActionTypes.APPLY_PROMO_CODE_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        loading: false,
      };
    case CartActionTypes.APPLY_PROMO_CODE_FAILURE:
      return {
        ...state,
        PromoError: action.payload,
        loading: false,
      };
    case CartActionTypes.REMOVE_PROMO_CODE_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.REMOVE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        cart: action.payload,
      };
    case CartActionTypes.REMOVE_PROMO_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CartActionTypes.CREATE_CART_START:
      return {
        ...state,
        loading: true,
      };

    case CartActionTypes.CREATE_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        cart: action.payload,
      };

    case CartActionTypes.CREATE_CART_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CartActionTypes.UPDATE_ORDER_TIME:
      return {
        ...state,
        OrderTime: action.payload,
      };

    case CartActionTypes.DELIVERY_TIME_RANGE_START:
      return {
        ...state,
        loading: true,
      };
    case CartActionTypes.DELIVERY_TIME_RANGE_SUCCESS:
      return {
        ...state,
        deliveryTimeRanges: Payload?.data,
        loading: false,
      };
    case CartActionTypes.DELIVERY_TIME_RANGE_FAILURE:
      return {
        cart: null,
        error: Payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default CartReducer;
