import { useTranslation } from "react-i18next";

import { CustomButton, FormInput } from "components/atoms";

import styles from "./NewsLetterForm.module.css";

const NewsLetterForm = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.newsLetterFormContainer}>
      <form className={styles.newsLetterForm}>
        <FormInput
          bgColor="bg-bg_e8e8e8"
          borderColor="border-text_bfbfbf"
          borderRadius="4px"
          height="h-10 md:h-12"
          width="w-full md:w-[440px] lg:w-[658px]"
          placeholder={t("Enter your email")}
        />
        <CustomButton
          label={t("Subscribe")}
          borderRadius="4px"
          width="w-40 md:w-52"
          height="h-10 md:h-12"
          classes="mt-4 md:mt-0"
        />
      </form>
    </div>
  );
};

export default NewsLetterForm;
