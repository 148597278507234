import { NowLaterModal } from "components/organisms";
import { Bicycle, Check, Like, Money } from "utils/helpers/SVGsSrc";
import { CustomButton, Img, Spinner, Typography } from "components/atoms";
import { CustomSlider, Footer, NavBar } from "components/molecules";
import { ImagesSrc, StorageUtils, chunkArray } from "utils";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { selectCurrentUser } from "Redux/user";
import styles from "./Home.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "Hooks";
import MapWithASearchBox from "components/organisms/MapSearchBox";
import DownloadApp from "components/organisms/Home/DownloadApp";
import PopularNearYou from "components/organisms/Home/PopularNearYou";
import OrderAgain from "components/organisms/Home/OrderAgain";
import PopularResturant from "components/organisms/Home/PopularResturant";
import dayjs from "dayjs";
import Favourite from "components/organisms/Home/Favourite";
import Categories from "components/organisms/Home/Categories";
import Layout from "components/molecules/Layout";
const HomeTemplate = ({
  categories,
  offers,
  orders,
  favoriteRestaurants,
  popularNearRestaurants,
  detectUserCoords,
  selectedAddress,
  UserCoords,
  setUserCoords,
  topRatedVendors,
  currentLng,
  CartData,
  loading,
}) => {
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );

  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const currentLang = i18n.language;
  const currentUser = useSelector(selectCurrentUser);
  const token = localStorage.getItem("access_token");
  const address = StorageUtils.getItems(sessionStorage, ["address"])["address"];

  const services = useMemo(
    () => [
      {
        icon: (
          <Check
            className={classnames(styles.icon, "w-10 h-8", "lg:w-10 lg:h-7")}
          />
        ),
        heading: t("Easy to order"),
        description: t("Order food in just few steps"),
      },
      {
        icon: (
          <Bicycle
            className={classnames(styles.icon, "w-16 h-9", "lg:w-12 lg:h-7")}
          />
        ),
        heading: t("Fastest delivery"),
        description: t("Delivery that is always on time and even faster"),
      },
      {
        icon: (
          <Money
            className={classnames(styles.icon, "w-5 h-9", "lg:w-4 lg:h-7")}
          />
        ),
        heading: t("Easy payments"),
        description: t("Very secure payment protocol."),
      },
      {
        icon: (
          <Like
            className={classnames(styles.icon, "w-9 h-8", "lg:w-7 lg:h-7")}
          />
        ),
        heading: t("Best quality"),
        description: t("For us quality comes first"),
      },
    ],
    [t]
  );

  const [categoriesChunckSize, setCategoriesChunckSize] = useState();
  const [offersChunckSize, setOffersChunckSize] = useState();
  const [ordersChunkSize, setOrdersChunkSize] = useState();
  const [offersList, setOffersList] = useState([]);
  const [favoriteRestaurantsList, setFavoriteRestaurantsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [defaultTimeRange, setDefaultTimeRange] = useState(null);

  const [popularNearRestaurantsList, setPopularNearRestaurantsList] = useState(
    []
  );
  const [isLaterOrder, setIsLaterOrder] = useState(false);
  const [isOrderTimeSelected, setIsOrderTimeSelected] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const handelTimeLocal = (date, type) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const local = currentLang == "ar" ? "ar-EG" : "en";
    if (type == "date") return new Date(date)?.toLocaleDateString(local);
    if (type == "time") {
      let val = new Date(date)?.toLocaleDateString(local, options);
      let valArr = val?.split(" ");
      //console.log(valArr);
      return `${valArr?.[1]} ${valArr?.[2]}`;
    }
  };
  useEffect(() => {
    if (CartData?.delivery_type == "later") {
      const date =
        currentLang != "ar"
          ? dayjs(CartData?.delivery_date_time).format("DD/MM/YYYY")
          : handelTimeLocal(CartData?.delivery_date_time, "date");
      const from =
        currentLang != "ar"
          ? dayjs(CartData?.delivery_time_range.from).format("hh:mm A")
          : handelTimeLocal(CartData?.delivery_time_range.from, "time");

      const to =
        currentLang != "ar"
          ? dayjs(CartData?.delivery_time_range.to).format("hh:mm A")
          : handelTimeLocal(CartData?.delivery_time_range.to, "time");
      setDefaultTimeRange({
        date,
        from,
        to,
      });
    } else {
      const date =
        currentLang != "ar"
          ? dayjs(CartData?.delivery_date_time).format("DD/MM/YYYY")
          : handelTimeLocal(CartData?.delivery_date_time, "date");

      const from = handelTimeLocal(CartData?.delivery_date_time, "time");

      setDefaultTimeRange({
        date,
        from,
      });
    }
  }, [CartData]);

  useEffect(() => {
    // Categories, popular near you, Favorite
    if (width >= 1600) {
      setCategoriesChunckSize(5);
      setOrdersChunkSize(3);
    } else if (width < 1600 && width > 1400) {
      setCategoriesChunckSize(5);
      setOrdersChunkSize(3);
    } else if (width <= 1400 && width >= 1080) {
      setCategoriesChunckSize(4);
      setOrdersChunkSize(2);
    } else if (width < 1080 && width >= 750) {
      setCategoriesChunckSize(3);
      setOrdersChunkSize(2);
    } else {
      setCategoriesChunckSize(1);
      setOrdersChunkSize(1);
    }

    // offers
    if (width >= 800) setOffersChunckSize(2);
    else setOffersChunckSize(1);
  }, [width]);

  useEffect(() => {
    const chunkedArray = chunkArray(categoriesChunckSize, categories);
    setCategoriesList(chunkedArray);
  }, [categoriesChunckSize, categories]);

  useEffect(() => {
    const chunkedArray = chunkArray(offersChunckSize, offers);
    setOffersList(chunkedArray);
  }, [offersChunckSize, offers]);

  useEffect(() => {
    const chunkedArray = chunkArray(ordersChunkSize, orders);
    setOrdersList(chunkedArray);
  }, [ordersChunkSize, orders]);

  useEffect(() => {
    const chunkedArray = chunkArray(categoriesChunckSize, favoriteRestaurants);
    setFavoriteRestaurantsList(chunkedArray);
  }, [categoriesChunckSize, favoriteRestaurants]);

  useEffect(() => {
    setPopularNearRestaurantsList(popularNearRestaurants);
  }, [categoriesChunckSize, popularNearRestaurants]);
  return (
    <main className={styles.main}>
      <Layout setUserCoords={setUserCoords}>
        <header className={styles.header}>
          {currentUser !== null ? (
            <NowLaterModal
              isOpen={isOrderTimeSelected}
              ToggleModalVisibility={setIsOrderTimeSelected}
            />
          ) : (
            <></>
          )}

          <div className={styles.hero}>
            <Img
              fileName={ImagesSrc.HomeHeaderMobile}
              fileName2x={ImagesSrc.HomeHeader2x}
              fileName3x={ImagesSrc.HomeHeader3x}
              altText="Kebab"
              picClasses="object-cover"
              containerClasses="w-full h-[270px] md:h-60 lg:h-auto relative"
            />
            <div
              className={`absolute w-full ${
                !sessionLocation || address ? "top-1/3" : "top-[23%]"
              } lg:top-1/3  ltr:xl:top-[30%] rtl:xl:top-1/3 right-1/2 translate-x-1/2`}
            >
              <div className="w-full text-center">
                <Typography
                  as="h1"
                  size="10xl"
                  lineHeight="leading-[1.18]"
                  color="text-text_white"
                  text={t("HUNGRY ?")}
                />
                <Typography
                  as="h2"
                  size="8xl"
                  color="text-text_f8f8fe"
                  classes="mt-2 lg:mt-4 3xl:mt-8"
                  text={t("Delicious Food Is Waiting For You")}
                />
              </div>
              <div className="mt-8 xl:mt-10 3xl:mt-12 flex justify-center ">
                <MapWithASearchBox
                  selectedAddress={selectedAddress}
                  detectUserCoords={detectUserCoords}
                  source="home"
                  UserCoords={UserCoords}
                  setUserCoords={setUserCoords}
                />
              </div>
            </div>
          </div>
        </header>

        {/* time range msg */}

        {currentUser !== null ? (
          <div
            className={styles.location}
            style={{
              background: "#ffffffbd",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#707070",
              fontWeight: "700",
            }}
          >
            {CartData?.delivery_type == "later" ? (
              <div className="w-[100%] md:w-[fit-content]">
                {t("We will deliver your meal On")}{" "}
                <span style={{ fontWeight: "800", color: "#3C3C3C" }}>
                  {" "}
                  {t("Date")} :{" "}
                </span>{" "}
                {defaultTimeRange?.date},{" "}
                <span style={{ fontWeight: "800", color: "#3C3C3C" }}>
                  {t("Time range")}
                </span>{" "}
                {t("from")} {defaultTimeRange?.from} {t("to")}{" "}
                {defaultTimeRange?.to}{" "}
              </div>
            ) : (
              <div className="w-[100%] md:w-[fit-content]">
                {t("We will deliver your meal On")}
                <span style={{ fontWeight: "800", color: "#3C3C3C" }}>
                  {" "}
                  {t("Date")} :{" "}
                </span>
                {defaultTimeRange?.date} ,{" "}
                <span style={{ fontWeight: "800", color: "#3C3C3C" }}>
                  {t("Time")}
                </span>
                : {defaultTimeRange?.from}
              </div>
            )}

            <div
              onClick={() => setIsOrderTimeSelected(!isOrderTimeSelected)}
              style={{
                color: "#BF0022",
                marginInline: "5px",
                cursor: "pointer",
              }}
            >
              {" "}
              {t("Change date and time")}
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* Our Services */}
        <section className={styles.services}>
          <div className="mb-8 lg:mb-10 3xl:mb-16">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="mt-2 lg:mt-4 3xl:mt-8"
              text={t("Our Awesome Services")}
            />
          </div>
          <div className={styles.servicesContainer}>
            {services.map((service, idx) => (
              <div key={idx} className={styles.serviceContainer}>
                <div className={styles.serviceHeader}>
                  <div>{service.icon}</div>
                  <Typography
                    as="h3"
                    size="4xl"
                    color="text-primary-900"
                    text={service.heading}
                  />
                </div>

                <Typography
                  as="p"
                  size="xl"
                  color="text-warm_grey"
                  fontWeight="font-semibold"
                  lineHeight="leading-[1.35]"
                  className="h-11"
                  classes="mt-6 lg:mt-8 min-h-11 h-11"
                  text={service.description}
                />
              </div>
            ))}
          </div>
        </section>

        {/* After detecting location and login */}
        {/* Offers */}
        {offers && offersList?.length !== 0 && (
          <section className={styles.Offers}>
            <CustomSlider>
              {offersList?.map((offersArray, idx) => (
                <div
                  key={idx}
                  className="flex justify-center lg:justify-between w-full mx-auto"
                >
                  {offersArray?.map((offer) => (
                    <NavLink
                      style={{ width: "48%" }}
                      key={offer?.id}
                      to={{
                        pathname: `/restaurant/${offer.vendor_id}`,
                        state: { offerId: offer?.id },
                      }}
                    >
                      <Img
                        containerClasses="cursor-pointer w-[100%] max-h-[324px]"
                        picClasses="max-h-[324px] object-fill"
                        fileName={offer?.image}
                        altText={offer?.description}
                      />
                    </NavLink>
                  ))}
                </div>
              ))}
            </CustomSlider>
          </section>
        )}

        {/* Categories */}
        {categories && categoriesList?.length !== 0 && (
          <Categories
            categoriesChunckSize={categoriesChunckSize}
            categoriesList={categoriesList}
          />
        )}

        {/* Favourite */}
        {favoriteRestaurants && favoriteRestaurantsList?.length !== 0 && (
          <section className={styles.Favourite}>
            <Favourite
              favoriteRestaurantsList={favoriteRestaurantsList}
              width={width}
              categoriesChunckSize={categoriesChunckSize}
              currentLng={currentLng}
              UserCoords={UserCoords}
              cartData={CartData}
            />
          </section>
        )}

        {/* Order again */}
        {orders && ordersList?.length !== 0 && (
          <OrderAgain
            ordersList={ordersList}
            ordersChunkSize={ordersChunkSize}
            width={width}
          />
        )}

        {/*  Popular near you */}

        {loading ? (
          <Spinner loading={loading} classes={"py-0"} />
        ) : token && sessionLocation ? (
          popularNearRestaurants &&
          popularNearRestaurantsList?.length !== 0 && (
            <PopularNearYou
              popularNearRestaurantsList={
                sessionLocation ? popularNearRestaurantsList : topRatedVendors
              }
              categoriesChunckSize={categoriesChunckSize}
              width={width}
              UserCoords={sessionLocation}
              cartData={CartData}
              selectedAddress={selectedAddress}
            />
          )
        ) : (
          // <section className={styles.Popular_res}>
          <PopularResturant
            topRatedVendors={
              sessionLocation ? popularNearRestaurantsList : topRatedVendors
            }
            width={width}
            categoriesChunckSize={categoriesChunckSize}
            currentLng={currentLng}
            UserCoords={sessionLocation}
            cartData={CartData}
          />
          // </section>
        )}

        {/* Download Apps */}
        <DownloadApp />
      </Layout>
    </main>
  );
};

HomeTemplate.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  offers: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  orders: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  favoriteRestaurants: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  popularNearRestaurants: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default HomeTemplate;
