import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import classNames from "classnames";
import axios from "axios";

import { getCurrentLng } from "utils";
import {
  Account,
  LanguageIcon,
  Location,
  LogoutIcon,
  MyOrdersIcon,
  NotificationIcon,
} from "utils/helpers/SVGsSrc";

import { signoutStart } from "Redux/user/actions/user.actions";
import { selectCurrentUser } from "Redux/user";

import { Typography } from "components/atoms";

import styles from "./SideBar.module.css";

const SideBar = ({ SideOpen, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const i18nDir = i18n.dir();
  const currentUser = useSelector(selectCurrentUser);
  //console.log(i18n);
  useEffect(() => {
    document.body.dir = i18nDir;
    document.body.lang = i18n.language;
  }, [i18nDir, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const UserMenuItems = useMemo(
    () => [
      {
        title: t("My Orders"),
        icon: <MyOrdersIcon width="24px" height="24px" title="My Orders" />,
        action: () => {
          setOpen(false);
          navigate(currentUser ? "/Orders" : "/login");
        },
        path: "/Orders",
      },
      {
        title: t("Account Info"),
        icon: <Account width="24px" height="24px" title="My Orders" />,
        action: () => {
          setOpen(false);
          navigate(currentUser ? "/Account_Info" : "/login");
        },
        path: "/Account_Info",
      },
      {
        title: t("Saved Adresses"),
        icon: <Location width="24px" height="24px" title="My Orders" />,
        action: () => {
          setOpen(false);
          navigate(currentUser ? "/Addresses" : "/login");
        },
        path: "/Addresses",
      },
      {
        title: t("Notifications"),
        icon: <NotificationIcon width="24px" height="24px" title="My Orders" />,
        action: () => {
          setOpen(false);
          navigate(currentUser ? "/Notifications" : "/login");
        },
        path: "/Notifications",
      },
      {
        title: t("Language"),
        icon: <LanguageIcon width="24px" height="24px" title="logout" />,
        action: () => {
          changeLanguage(getCurrentLng() === "ar" ? "en" : "ar");
        },
      },
      {
        title: t("Logout"),
        icon: <LogoutIcon width="24px" height="24px" title="logout" />,
        action: () => {
          setOpen(false);
          navigate("/");
          dispatch(signoutStart());
        },
      },
    ],
    [t, navigate, dispatch]
  );

  return (
    <>
      <div
        className={classNames(styles.overlay, { "hidden left-0": !SideOpen })}
        onClick={() => setOpen(!SideOpen)}
      ></div>
      <div
        className={classNames(
          styles.SideBarContainer,
          { "ltr:left-0 rtl:right-0": SideOpen },
          { "ltr:-left-[280px] rtl:-right-[280px]": !SideOpen }
        )}
      >
        {UserMenuItems.map((item, i) => (
          <div className={styles.SideItem} onClick={item.action} key={i}>
            {item.icon}
            <Typography
              as="h3"
              lineHeight="leading-[1.6]"
              fontWeight="font-semibold"
              color={`${
                pathname == item.path ? "text-primary-900" : "text-sideText "
              } focus:text-primary-900`}
              classes={`text-[15px] ltr:pl-4 rtl:pr-4`}
              text={item.title}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SideBar;
