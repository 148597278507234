import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { Link, NavLink, useNavigate } from "react-router-dom";

import {
  AblaNaziraLogo,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
} from "utils/helpers/SVGsSrc";

import { Img, Typography } from "components/atoms";
import NewsLetterForm from "../../NewsLetterForm";

import styles from "./Footer.module.css";
import { ImagesSrc } from "utils";
import { selectSystemConfiguration } from "Redux/user/selectors/user.selectors";
import { useSelector } from "react-redux";

const SocialMediaLink = ({ icon, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.socialMedialogo}
    >
      {icon}
    </a>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const SystemConfiguration = useSelector(selectSystemConfiguration);

  const footerNavItems = useMemo(
    () => [
      {
        element: (idx) => (
          <NavLink
            className={({ isActive }) =>
              ` ${isActive ? "text-primary-900" : "text-warm_grey"}` +
              (isActive ? " text-primary-900" : " hover:text-primary-900")
            }
            key={idx}
            to="/Offers"
          >
            <Typography
              size="2xl"
              as="span"
              lineHeight="leading-[1.18]"
              fontWeight="font-semibold"
              classes="cursor-pointer"
              text={t("Offers")}
            />
          </NavLink>
        ),
      },
      {
        element: (idx) => (
          <Typography
            key={idx}
            size="2xl"
            as="a"
            href="https://vendor.ablanazira.com"
            target="_blank"
            color="text-warm_grey hover:text-primary-900"
            lineHeight="leading-[1.18]"
            fontWeight="font-semibold"
            classes="cursor-pointer"
            text={t("Become a partner")}
          />
        ),
      },
      {
        element: (idx) => (
          <NavLink
            className={({ isActive }) =>
              ` ${isActive ? "text-primary-900" : "text-warm_grey"}` +
              (isActive ? " text-primary-900" : " hover:text-primary-900")
            }
            key={idx}
            to="/About_Us"
          >
            <Typography
              size="2xl"
              as="span"
              lineHeight="leading-[1.18]"
              fontWeight="font-semibold"
              classes="cursor-pointer"
              text={t("About Us")}
            />
          </NavLink>
        ),
      },
      {
        element: (idx) => (
          <NavLink
            className={({ isActive }) =>
              ` ${isActive ? "text-primary-900" : "text-warm_grey"}` +
              (isActive ? " text-primary-900" : " hover:text-primary-900")
            }
            key={idx}
            to="/Contact_Us"
          >
            <Typography
              size="2xl"
              as="span"
              lineHeight="leading-[1.18]"
              fontWeight="font-semibold"
              classes="cursor-pointer"
              text={t("Contact Us")}
            />
          </NavLink>
        ),
      },
    ],
    [t]
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.footerMainContainer}>
        <div className={styles.newsLetter}>
          <div className={styles.newsLetterHeading}>
            <NavLink to="/">
              <div className={styles.logo}>
                <AblaNaziraLogo
                  width="100%"
                  height="100%"
                  title="Abla Nazira"
                />
              </div>
            </NavLink>

            <Typography
              as="p"
              size="xl"
              color="text-warm_grey"
              lineHeight="leading-[1.5]"
              text={t(
                "Subscribe to our newsletter and receive exclusive offers every week"
              )}
            />
          </div>
          <NewsLetterForm />
        </div>
        <div className={styles.footerNavlinksContiner}>
          {footerNavItems.map((navItem, idx) => navItem.element(idx))}
        </div>
        <div className={styles.socialMediaLinksContainer}>
          <SocialMediaLink
            icon={
              <FacebookLogo
                width="100%"
                height="100%"
                title="Abla Nazira Facebook"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "facebook_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <TwitterLogo
                width="100%"
                height="100%"
                title="Abla Nazira twitter"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "twitter_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <LinkedinLogo
                width="100%"
                height="100%"
                title="Abla Nazira Linkedin"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "linkedin_link")
                ?.value
            }
          />

          <SocialMediaLink
            icon={
              <InstagramLogo
                width="100%"
                height="100%"
                title="Abla Nazira Instagram"
              />
            }
            href={
              SystemConfiguration?.find((item) => item.name == "instagram_link")
                ?.value
            }
          />
        </div>
        <div className="text-center mt-8 lg:mt-10 xl:mt-16">
          <>
            {/* <Link to="/terms_of_use" target="_blank"> */}
            <Typography
              as="span"
              color="text-warm_grey"
              action={() => navigate("/terms_of_use")}
              classes="underline underline-offset-2 cursor-pointer"
              text={t("terms of use")}
            />
            {/* </Link> */}
            <Typography color="text-warm_grey" as="span" text={t("and")} />
            {/* <Link to="/privacy_policy" target="_blank"> */}
            <Typography
              as="span"
              color="text-warm_grey"
              action={() => navigate("/privacy_policy")}
              classes="underline underline-offset-2 cursor-pointer"
              text={t("privacy policy")}
            />
            {/* </Link> */}
          </>
        </div>
      </div>
      <div className={styles.copyRights}>
        <Typography
          as="a"
          href="https://inovaeg.com/"
          target="_blank"
          color="text-warm_grey"
          fontWeight="font-semibold"
          lineHeight="leading-[1]"
          classes={"text-[15px] cursor-pointer"}
          text={t("By Inova LLC - © 2022 ALL RIGHTS RESERVED")}
        />
        <a href="https://inovaeg.com/" target="_blank">
          <Img
            containerClasses="w-[75px] h-fit px-2 cursor-pointer"
            fileName={ImagesSrc.InovaLogo}
            altText="Inova"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;

SocialMediaLink.propTypes = {
  icon: PropTypes.node,
  href: PropTypes.string,
};
