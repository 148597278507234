import classnames from "classnames";
import { PropTypes } from "prop-types";

import styles from "./CustomButton.module.css";

const CustomButton = ({
  form,
  label,
  name,
  value,
  AfterIcon,
  BeforeIcon,
  buttonTypeClass = "primary",
  bgImage,
  width,
  height,
  action,
  disabled = false,
  type,
  borderRadius,
  borderColor = "border-text_white",
  textColor = "text-text_white ",
  classes,
  boxShadow,
  backdropFilter,
  labelContainerClassName,
}) => {
  return (
    <>
      {/* <div className="w-full flex flex-col items-start"> */}
      <button
        form={form}
        name={name}
        value={value}
        type={type}
        disabled={disabled}
        onClick={action}
        style={{
          // width: "100%",
          backgroundImage: bgImage ? `url(${bgImage})` : "none",
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: borderRadius ? borderRadius : "",
          boxShadow: boxShadow,
          backdropFilter: backdropFilter,
        }}
        className={classnames(
          styles.button,
          width,
          height,
          classes,
          styles[buttonTypeClass],
          {
            [styles.disabled]: disabled,
          },
          { [borderColor]: buttonTypeClass === "outlined" },
          { [textColor]: buttonTypeClass === "outlined" }
        )}
      >
        <div
          className={classnames(
            labelContainerClassName,
            "flex items-center justify-center  "
          )}
        >
          {BeforeIcon ?? ""}
          {label ?? ""}
          {AfterIcon ?? ""}
        </div>
      </button>
      {/* </div> */}
    </>
  );
};

CustomButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonTypeClass: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  backdropFilter: PropTypes.string,
};

export default CustomButton;
