import {
  Container
} from "@mui/material";
import React from "react";
import TextFieldInput from "../TextField/TextFieldInput";
import "./PhoneInput.css";

function PhoneInput({
  setPhone,
  clickEnter,
  placeholder,
  id,
  name,
  inputValue,
  error,
  helperText,
}) {
  
  return (
    <Container style={{ display: "flex" }} disableGutters>
      <TextFieldInput
        error={error}
        helperText={helperText}
        name={name}
        id={id}
        value={inputValue}
        placeholder={placeholder}
        onchange={setPhone}
        className={"mb-20px"}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter();
        }}
      />
    </Container>
  );
}

export default PhoneInput;
