export const formatAMPM = (TimeString) => {
  
  const date = new Date(TimeString);
  let hours = date.getHours();
  let mins = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 ? hours % 12 : 12;
  mins = mins < 10 ? "0" + mins : mins;
  let postTime = hours + ":" + mins + " " + ampm;

  return postTime;
};
