import { PropTypes } from "prop-types";

import { CloseIcon, ConfirmedIcon } from "utils/helpers/SVGsSrc";

import { CustomButton, Img, Typography } from "components/atoms";
import { Modal } from "components/molecules";
import { ImagesSrc, StorageUtils } from "utils";
import { useTranslation } from "react-i18next";
import LaterOrderModal from "../LaterOrder";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateCartData } from "Redux/Cart/sagas/cart.sagas";
import {
  UpdateCartDataStart,
  UpdateOrderTime,
} from "Redux/Cart/actions/cart.actions";

const NowLaterModal = ({
  isOpen,
  ToggleModalVisibility,
  zIndex = "z-30",
  onClosAction,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLaterOrder, setIsLaterOrder] = useState(false);
  const date = new Date();
  return (
    <Modal
      isOpen={isOpen}
      ToggleModalVisibility={() => {
        ToggleModalVisibility(true);
      }}
      zIndex={zIndex}
    >
      <LaterOrderModal
        isOpen={isLaterOrder}
        ToggleModalVisibility={setIsLaterOrder}
        ToggleNowLater={() => {
          ToggleModalVisibility(false);
        }}
        zIndex={"z-40"}
      />
      <div className="bg-white w-[600px] 2xl:w-[700px] p-4 3xl:p-10 py-10">
        <Img
          containerClasses="w-fit h-[220px] mx-auto"
          picClasses=""
          fileName={ImagesSrc.NowLater}
        />
        <div className="w-full flex flex-col items-center mt-9 3xl:mt-12 ">
          <Typography
            as="h3"
            size="3xl"
            fontWeight="font-bold"
            lineHeight="leading-[1.06]"
            color="text-text_3b3b3b"
            classes="w-full max-w-[450px]"
            text={t("When do you want to receive your order ?")}
          />
          <CustomButton
            width="w-full max-w-[450px]"
            height="h-10 3xl:h-12"
            borderRadius="8px"
            boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
            backdropFilter="blur(30)"
            label={t("Now")}
            classes={"mt-4"}
            action={() => {
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "OrderTime",
                  data: "now",
                },
              ]);
              dispatch(UpdateOrderTime("now"));
              // save datetime
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_datetime",
                  data: `${date.getHours()}:${date.getMinutes()} - ${date.getDate()}-${
                    date.getMonth() + 1
                  }-${date.getFullYear()}`,
                },
              ]);
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_date",
                  data: `${date.getDate()}-${
                    date.getMonth() + 1
                  }-${date.getFullYear()}`,
                },
              ]);
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_time",
                  data: `${date.getHours()}:${date.getMinutes()}`,
                },
              ]);
              dispatch(
                UpdateCartDataStart({
                  cart: {
                    delivery_type: "now",
                    delivery_time: `${date.getHours()}:${date.getMinutes()}`,
                    delivery_date: `${date.getDate()}-${
                      date.getMonth() + 1
                    }-${date.getFullYear()}`,
                  },
                })
              );
              ToggleModalVisibility(false);
            }}
          />
          <CustomButton
            width="w-full max-w-[450px]"
            height="h-10 3xl:h-12"
            borderRadius="8px"
            boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
            borderColor={"border-primary-900"}
            textColor={"text-primary-900"}
            buttonTypeClass="outlined"
            backdropFilter="blur(30)"
            label={t("Later")}
            classes={"mt-4"}
            action={() => setIsLaterOrder(true)}
          />
        </div>
      </div>
    </Modal>
  );
};

NowLaterModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  heading: PropTypes.string,
};

export default NowLaterModal;
