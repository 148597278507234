import AddressesActionsTypes from "../types/addresses.types";

import { createAction } from "utils";

export const listSavedAddressesStart = (payload) =>
  createAction(AddressesActionsTypes.LIST_SAVED_ADDRESSES_START, payload);

export const listSavedAddressesSuccess = (savedAddresses) =>
  createAction(
    AddressesActionsTypes.LIST_SAVED_ADDRESSES_SUCCESS,
    savedAddresses
  );

export const listSavedAddressesFailure = (error) =>
  createAction(AddressesActionsTypes.LIST_SAVED_ADDRESSES_FAILURE, error);

export const loadMoreAddressesStart = (payload) =>
  createAction(AddressesActionsTypes.LOAD_MORE_ADDRESS_START, payload);

export const loadMoreAddressesSuccess = (savedAddresses) =>
  createAction(AddressesActionsTypes.LOAD_MORE_ADDRESS_SUCCESS, savedAddresses);

export const SaveAddressStart = (id) =>
  createAction(AddressesActionsTypes.SAVE_ADDRESS_START, id);

export const SaveAddressSuccess = (savedAddress) =>
  createAction(AddressesActionsTypes.SAVE_ADDRESS_SUCCESS, savedAddress);

export const SaveAddressFailure = (error) =>
  createAction(AddressesActionsTypes.SAVE_ADDRESS_FAILURE, error);

export const DeleteAddressStart = (id) =>
  createAction(AddressesActionsTypes.DELETE_ADDRESS_START, id);

export const DeleteAddressSuccess = (Address) =>
  createAction(AddressesActionsTypes.DELETE_ADDRESS_SUCCESS, Address);

export const DeleteAddressFailure = (error) =>
  createAction(AddressesActionsTypes.DELETE_ADDRESS_FAILURE, error);

export const DeleteAddressInformed = () =>
  createAction(AddressesActionsTypes.DELETE_ADDRESS_INFORMED);

export const UpdateAddressStart = (payload) =>
  createAction(AddressesActionsTypes.UPDATE_ADDRESS_START, payload);

export const UpdateAddressSuccess = (Address) =>
  createAction(AddressesActionsTypes.UPDATE_ADDRESS_SUCCESS, Address);

export const UpdateAddressFailure = (error) =>
  createAction(AddressesActionsTypes.UPDATE_ADDRESS_FAILURE, error);

export const setSelectedAddressStart = (selectAddress) =>
  createAction(AddressesActionsTypes.SET_SELECTED_ADDRESS_START, selectAddress);

export const setSelectedAddressSuccess = (payload) =>
  createAction(AddressesActionsTypes.SET_SELECTED_ADDRESS_SUCCESS, payload);

export const setSelectedAddressFailure = (error) =>
  createAction(AddressesActionsTypes.SET_SELECTED_ADDRESS_FAILURE, error);

export const getSelectedAddressStart = (save) =>
  createAction(AddressesActionsTypes.GET_SELECTED_ADDRESS_START, save);

export const getSelectedAddressSuccess = (payload) =>
  createAction(AddressesActionsTypes.GET_SELECTED_ADDRESS_SUCCESS, payload);

export const getSelectedAddressFailure = (error) =>
  createAction(AddressesActionsTypes.GET_SELECTED_ADDRESS_FAILURE, error);

export const listCitiesStart = () =>
  createAction(AddressesActionsTypes.LIST_CITIES_START);

export const listCitiesSuccess = (cities) =>
  createAction(AddressesActionsTypes.LIST_CITIES_SUCCESS, cities);

export const listCitiesFailure = (error) =>
  createAction(AddressesActionsTypes.LIST_CITIES_FAILURE, error);

export const listAreasByCityStart = (payload) =>
  createAction(AddressesActionsTypes.LIST_AREAS_BY_CITY_START, payload);

export const listAreasByCitySuccess = (areas) =>
  createAction(AddressesActionsTypes.LIST_AREAS_BY_CITY_SUCCESS, areas);

export const listAreasByCityFailure = (error) =>
  createAction(AddressesActionsTypes.LIST_AREAS_BY_CITY_FAILURE, error);

export const changeSelectedArea = (area) =>
  createAction(AddressesActionsTypes.CHANGE_SELECTED_AREA, area);

export const changeCord = (details) =>
  createAction(AddressesActionsTypes.CHANGE_CORD, details);
