import React, { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { StorageUtils } from "utils";

const Layout = ({ children, setUserCoords }) => {
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const address = StorageUtils.getItems(sessionStorage, ["address"])["address"];

  //console.log(address, "address");
  const [locationState, setLocationState] = useState();
  return (
    <>
      <NavBar
        setLocationState={setLocationState}
        locationState={locationState}
        setUserCoords={setUserCoords}
      />
      <div
        className={` ${
          !sessionLocation
            ? "mt-[195px] md:mt-[145px]"
            : address
            ? "mt-[170px] md:mt-[120px]"
            : "mt-[114px] md:mt-[60px]"
        }`}
      >
        {children}
      </div>

      <Footer />
    </>
  );
};

export default Layout;
