import VendorsActionTypes from "../types/vendors.types";

const INITIAL_STATE = {
  vendors: null,
  vendor: null,
  filters: null,
  favoriteVendors: [],
  popularNearVendors: null,
  topOffersVendors: null,
  error: null,
  loading: false,
  loadingLoadMore: false,
  isReviewLoading: false,
  total_pages: 0,
  hasMore: true,
  reviews: null,
};

const vendorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VendorsActionTypes.GET_ALL_VENDORS_START:
    case VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_START:
    case VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_START:
    case VendorsActionTypes.GET_TOP_OFFERS_VENDORS_START:
      return {
        ...state,
        loading: true,
      };
    case VendorsActionTypes.GET_TOP_OFFERS_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        topOffersVendors: action.payload,
      };
    case VendorsActionTypes.LOAD_MORE_VENDORS_START:
    case VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_START:
    case VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_START:
      return {
        ...state,
        loadingLoadMore: true,
        hasMore: true,
      };
    case VendorsActionTypes.GET_ALL_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload.vendors,
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore: true,
      };
    case VendorsActionTypes.LOAD_MORE_VENDORS_SUCCESS:
      const newVendorsList = action.payload.vendors;
      const { vendors } = state;
      return {
        ...state,
        vendors: [...vendors, ...newVendorsList],
        total_pages: action.payload.total_pages,
        loading: false,
        loadingLoadMore: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_SUCCESS:
      const newfavoriteVendorsList = action.payload.favoriteVendors;
      const { favoriteVendors } = state;
      return {
        ...state,
        favoriteVendors: [...favoriteVendors, ...newfavoriteVendorsList],
        total_pages: action.payload.total_pages,
        loading: false,
        loadingLoadMore: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };

    case VendorsActionTypes.RESTAURANT_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case VendorsActionTypes.RESTAURANT_DATA_SUCCESS:
      return {
        ...state,
        vendor: action.payload,
        loading: false,
      };
    case VendorsActionTypes.RESTAURANT_DATA_FAILURE:
      return {
        ...state,
      };

    case VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_SUCCESS:
      return {
        ...state,
        favoriteVendors: action.payload.favoriteVendors,
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore: true,
      };
    case VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_SUCCESS:
      return {
        ...state,
        popularNearVendors: action.payload.popularNearVendors,
        loading: false,
      };
    case VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_SUCCESS:
      const newpopularNearVendorsList = action.payload.popularNearVendors;
      const { popularNearVendors } = state;

      return {
        ...state,
        popularNearVendors: [
          ...popularNearVendors,
          ...newpopularNearVendorsList,
        ],
        total_pages: action.payload.total_pages,
        loading: false,
        loadingLoadMore: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case VendorsActionTypes.GET_VENDOR_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload,
      };
    case VendorsActionTypes.ADD_FAVORITE_VENDOR_SUCCESS:
      return {
        ...state,
        vendor: { ...state.vendor, is_favorite: action.payload.is_favorite },
        favoriteVendors: [...state.favoriteVendors, action.payload],
      };
    case VendorsActionTypes.REMOVE_FAVORITE_VENDOR_SUCCESS:
      return {
        ...state,
        favoriteVendors: [
          ...state.favoriteVendors.filter(
            (item) => item?.id !== action?.payload?.id
          ),
        ],
        vendor: { ...state.vendor, is_favorite: action.payload.is_favorite },
      };
    case VendorsActionTypes.GET_VENDOR_REVIEWS_START:
      return {
        ...state,
        isReviewLoading: true,
      };
    case VendorsActionTypes.GET_VENDOR_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        isReviewLoading: false,
      };
    case VendorsActionTypes.GET_ALL_VENDORS_FAILURE:
    case VendorsActionTypes.RESTAURANT_DATA_FAILURE:
    case VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_FAILURE:
    case VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_FAILURE:
    case VendorsActionTypes.GET_VENDOR_FILTERS_FAILURE:
    case VendorsActionTypes.LOAD_MORE_VENDORS_FAILURE:
    case VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_FAILURE:
    case VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_FAILURE:
    case VendorsActionTypes.GET_VENDOR_REVIEWS_FAILURE:
    case VendorsActionTypes.REMOVE_FAVORITE_VENDOR_FAILURE:
    case VendorsActionTypes.ADD_FAVORITE_VENDOR_FAILURE:
    case VendorsActionTypes.GET_TOP_OFFERS_VENDORS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loadingLoadMore: false,
      };
    default:
      return state;
  }
};

export default vendorsReducer;
