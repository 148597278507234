import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ListPopularNearRestaurantsStart,
  loadMorePopularNearVendorsStart,
} from "Redux/vendors/actions/vendors.actions";

import {
  selectListVendorsLoading,
  selectHasMoreVendors,
  selectPopularNearRestaurants,
  selectListVendorsLoadingLoadMore,
} from "Redux/vendors/selectors/vendors.selectors";

// import { WithSpinner } from "Hocs";

import { getPayload } from "utils";

import { GridListWithSearchTemplate } from "templates";
import {
  selectCartData,
  selectOrderTime,
} from "Redux/Cart/selectors/cart.selectors";
import { useLocation } from "react-router";

const PopularNearYouPage = () => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const dispatch = useDispatch();
  const location = useLocation();

  const popularNearVendors = useSelector(selectPopularNearRestaurants);
  const loadingLoadMore = useSelector(selectListVendorsLoadingLoadMore);
  const loading = useSelector(selectListVendorsLoading);
  const hasMore = useSelector(selectHasMoreVendors);
  const OrderTime = useSelector(selectOrderTime);
  const cartData = useSelector(selectCartData);

  const [queryParams, setQueryParams] = useState({
    search_key: "",
    page_number: 1,
    page_size: 8,
  });
  const getQueryParams = (e) => getPayload(e, queryParams, setQueryParams);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (popularNearVendors) setPage((prev) => prev + 1);
  }, [popularNearVendors]);

  useEffect(() => {
    setPage(1);
  }, [queryParams, OrderTime]);

  useEffect(() => {
    if (currentLng) {
      dispatch(ListPopularNearRestaurantsStart(queryParams));
    }
  }, [currentLng, queryParams, OrderTime]);

  const loadMoreVendors = () => {
    if (page != 1)
      dispatch(
        loadMorePopularNearVendorsStart({
          ...queryParams,
          page_number: page,
        })
      );
  };

  return (
    <GridListWithSearchTemplate
      getQueryParams={getQueryParams}
      queryParams={queryParams}
      vendors={popularNearVendors}
      hasMore={hasMore}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
      loadMoreVendors={loadMoreVendors}
      heading="Popular near you"
      cartData={cartData}
    />
  );
};

export default PopularNearYouPage;
