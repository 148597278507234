import { DeliveryAddressCard, OrderDetails } from "components/molecules";

import { OrderSummary } from "components/organisms";
import { PropTypes } from "prop-types";
import { SplitScreen } from "components/Layouts";
import styles from "./OrderDetails.module.css";
import { useTranslation } from "react-i18next";

const OrderDetailsTemplate = ({ order, currentUser }) => {
  const { t } = useTranslation();
  return (
    <>
      <main className={styles.main}>
        <SplitScreen
          containerClasses="flex flex-col lg:flex-row lg:mb-8"
          leftPaneClasses="mb-8 lg:mb-0 lg:w-[70%] bg-primary-50 "
          rightPaneClasses="mb-8 lg:mb-0"
        >
          <OrderDetails
            restaurantId={order?.cart?.vendor_id}
            restaurantName={order?.cart?.vendor_name}
            orderDate={order?.created_at}
            orderHash={order?.hash_id}
            RestaurantLogo={order?.vendor?.logo}
            clickable={false}
          />

          <DeliveryAddressCard
            cardHeading={t("Delivery details")}
            canChangeAddress={false}
            hasBicycleIcon={false}
            borderRadius="0px"
            height="h-full"
            addressDescriptionClasses="ellipseText line-clamp-2 h-12 max-h-12 w-[277px] max-w-[277px] 2xl:w-[369px] 2xl:max-w-[369px] "
            hasMarginBottom={false}
            address={{ ...order?.customer_address, isUserSavedAddress: true }}
            currentUser={currentUser}
          />
        </SplitScreen>
        <OrderSummary
          restaurantName={order?.cart?.vendor_name}
          cartItems={order?.cart?.cart_items}
          invoiceDetails={order?.invoice_details}
          specialInstruction={order?.cart?.special_instructions}
          paymentType={order && Object.entries(order?.payment_type)[0][1]}
          // paymentType={order?.invoice_details?.status}
        />
      </main>
    </>
  );
};

OrderDetailsTemplate.propTypes = {
  order: PropTypes.instanceOf(Object),
};

export default OrderDetailsTemplate;
