import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { WithSpinner } from "Hocs";
import { getOrderStart } from "Redux/orders/actions/orders.actions";
import {
  selectOrder,
  selectOrdersLoading,
} from "Redux/orders/selectors/orders.selectors";
import { OrderDetailsTemplate } from "templates";
import { selectCurrentUser } from "Redux/user";
import Layout from "components/molecules/Layout";

const OrderDetailsPage = () => {
  const { id } = useParams();

  const { i18n } = useTranslation();
  const currentLng = i18n?.language;

  const dispatch = useDispatch();

  const order = useSelector(selectOrder);
  const loading = useSelector(selectOrdersLoading);
  const currentUser = useSelector(selectCurrentUser);

  const OrderDetailsTemplateContainer = WithSpinner(OrderDetailsTemplate);

  useEffect(() => {
    if (currentLng && id) dispatch(getOrderStart({ orderId: id }));
  }, [id, currentLng, dispatch]);

  return (
    <Layout>
      <OrderDetailsTemplateContainer
        order={order}
        isLoading={loading}
        page={true}
        currentUser={currentUser}
      />
    </Layout>
  );
};

export default OrderDetailsPage;
