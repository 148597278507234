import React, { useEffect } from "react";
import { useState } from "react";
import { BlackBicycle, Pickup } from "utils/helpers/SVGsSrc";
import styles from "../../molecules/Cards/DeliveryAddressCard/DeliveryAddressCard.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import { Typography } from "components/atoms";
import { StorageUtils } from "utils";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";
import { useSelector } from "react-redux";

const SelectDileveryOption = ({
  t,
  setDeliveryOption,
  restaurantData,
  isDetails = false,
  handelChangeDeliveryOption,
  CartData,
  deliveryOption,
}) => {
  const { i18n } = useTranslation();

  const [selectedOption, setSelectedOption] = useState();
  const currentLang = i18n.language;
  const selectedAddress = useSelector(selectSelectedAddress);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setDeliveryOption(event.target.value);
    handelChangeDeliveryOption &&
      handelChangeDeliveryOption(event.target.value);
  };
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  useEffect(() => {
    setSelectedOption(deliveryOption);
  }, [CartData]);

  const pickupDistnation =
    parseFloat(restaurantData?.travel_distance).toFixed(2) > 1
      ? parseFloat(restaurantData?.travel_distance).toFixed(2)
      : parseFloat(restaurantData?.travel_distance).toFixed(2) * 1000;
  const pickupDistnationUnit =
    Math.ceil(parseFloat(restaurantData?.travel_distance).toFixed(2)) > 1
      ? `${t("km")}`
      : `${t("m")}`;
  return (
    <div
      style={{ borderRadius: "8px" }}
      className="bg-bg_white  px-6 py-3 w-full  mb-4"
    >
      <div className="flex flex-col gap-2 ">
        {restaurantData?.is_delivery_available && (
          <label key={"delivery"} className="flex items-center gap-1 ">
            <input
              type="radio"
              value="delivery"
              checked={selectedOption === "delivery"}
              onChange={handleOptionChange}
              className="mr-2"
            />

            <div className="flex flex-row items-center">
              <div className={styles.headerIcon}>
                <BlackBicycle
                  width="100%"
                  height="100%"
                  title="go to addresses"
                />
              </div>
              {sessionLocation && isDetails ? (
                <div className="flex gap-[10px] ">
                  <Typography
                    as="span"
                    size="sm"
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#3B3B3B",
                    }}
                    lineHeight="leading-[1.19]"
                    text={t(`delivery`)}
                  />
                  <Typography
                    as="span"
                    size="sm"
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#3B3B3B",
                    }}
                    lineHeight="leading-[1.19]"
                    text={
                      typeof restaurantData?.delivery_details?.delivery_fee ==
                      "string"
                        ? restaurantData?.delivery_details?.delivery_fee
                        : `( ${parseFloat(
                            restaurantData?.delivery_details?.delivery_fee
                          )?.toFixed(2)} Egp )`
                    }
                  />
                </div>
              ) : (
                <Typography
                  as="span"
                  size="sm"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#3B3B3B",
                  }}
                  lineHeight="leading-[1.19]"
                  text={t(`delivery`)}
                />
              )}
            </div>
          </label>
        )}
        {/* pickup */}

        {restaurantData?.pickup_details?.is_pickup_available && (
          <label key="pickup" className="flex items-center gap-1 items-center">
            <input
              type="radio"
              value="pickup"
              checked={selectedOption === "pickup"}
              onChange={handleOptionChange}
              className="mr-2"
            />
            <div className="flex flex-row items-center">
              <div className={`${styles.headerIcon} `}>
                <Pickup width="100%" height="100%" title="go to addresses" />
              </div>
              {sessionLocation && isDetails ? (
                <div className="flex gap-[10px] ">
                  <Typography
                    as="span"
                    size="sm"
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#3B3B3B",
                    }}
                    lineHeight="leading-[1.19]"
                    text={t(`pickup`)}
                  />
                  <div>
                    <Typography
                      as="span"
                      size="sm"
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#3B3B3B",
                      }}
                      lineHeight="leading-[1.19]"
                      text={`(${
                        currentLang?.includes("ar")
                          ? `${t(
                              `awayar`
                            )} ${pickupDistnation} ${pickupDistnationUnit}  ${t(
                              "approx"
                            )}`
                          : ""
                      }   ${
                        currentLang?.includes("en")
                          ? `${t(
                              "approx"
                            )} ${pickupDistnation} ${pickupDistnationUnit}  ${t(
                              `away`
                            )}  `
                          : ""
                      })`}
                    />
                  </div>
                </div>
              ) : (
                <Typography
                  as="span"
                  size="sm"
                  lineHeight="leading-[1.19]"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#3B3B3B",
                  }}
                  text={t(`pickup`)}
                />
              )}
            </div>
          </label>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SelectDileveryOption);
