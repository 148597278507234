import Google from "assets/Icons/Google/google.png";

import Apple from "assets/Icons/Apple/apple.png";

import Facebook from "assets/Icons/Facebook/facebook.png";

import Search from "assets/Icons/Search/search.webp";

import Pickup from "assets/Icons/pickup.svg";
import Delivery from "assets/Icons/delivery.svg";

const IconsSrc = {
  Google,
  Apple,
  Facebook,
  Search,
  Pickup,
  Delivery,
};

export default IconsSrc;
