import Logo from "./Images/AblaNaziraLogo/logo.svg";
import DeleteAccount from "./Icons/DeleteAccount.png";
import Exclamationmark from "./Icons/Exclamationmark.svg";
import Success from "./Icons/Success.svg";
import NotFound from "./Icons/NotFound.svg";

export const dummy = {};

export const images = {
  Logo,
};

export const icons = {
  DeleteAccount,
  Exclamationmark,
  Success,
  NotFound,
};
