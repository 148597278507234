import AddressesActionsTypes from "../types/addresses.types";

const INITIAL_STATE = {
  savedAddresses: null,
  error: null,
  isLoading: false,
  total_pages: 0,
  hasMore: true,
  selectedAddress: null,
  isAddressDeleted: false,
  cities: [],
  areas: [],
  isAreasLoading: false,
  selectedArea: null,
  distance_cords: null,
};

const addressesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressesActionsTypes.LIST_SAVED_ADDRESSES_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.LIST_SAVED_ADDRESSES_SUCCESS:
      return {
        ...state,
        savedAddresses: action.payload.savedAddresses,
        total_pages: action.payload.total_pages,
        isLoading: false,
        hasMore: true,
      };
    case AddressesActionsTypes.LIST_SAVED_ADDRESSES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.LOAD_MORE_ADDRESS_START:
      return {
        ...state,
        loading: true,
        hasMore: true,
      };
    case AddressesActionsTypes.LOAD_MORE_ADDRESS_SUCCESS:
      const newAddressesList = action.payload.savedAddresses;
      const { savedAddresses } = state;
      return {
        ...state,
        savedAddresses: [...savedAddresses, ...newAddressesList],
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case AddressesActionsTypes.SAVE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        selectedAddress: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.SAVE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.DELETE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        savedAddresses: [
          ...state.savedAddresses.filter(
            (address) => +address.id !== +action.payload.id
          ),
        ],
        isAddressDeleted: true,
        selectedAddress: action.payload.is_selected
          ? null
          : state.selectedAddress,
        isLoading: false,
      };
    case AddressesActionsTypes.DELETE_ADDRESS_INFORMED:
      return {
        ...state,
        isAddressDeleted: false,
        isLoading: false,
      };
    case AddressesActionsTypes.DELETE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.UPDATE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        savedAddresses: [
          action.payload,
          ...state.savedAddresses.filter(
            (address) => +address.id !== +action.payload.id
          ),
        ],
        selectedAddress: action.payload.is_selected
          ? action.payload
          : state.selectedAddress,
        isLoading: false,
      };
    case AddressesActionsTypes.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.SET_SELECTED_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.SET_SELECTED_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedAddress: action.payload,
      };
    case AddressesActionsTypes.SET_SELECTED_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AddressesActionsTypes.GET_SELECTED_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case AddressesActionsTypes.GET_SELECTED_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedAddress: action.payload,
      };
    case AddressesActionsTypes.SET_IS_SELECTED_ADDRESS_CHANGED:
      return {
        ...state,
      };
    case AddressesActionsTypes.GET_SELECTED_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    // case AddressesActionsTypes.LIST_CITIES_START:
    //   return {
    //     ...state,
    //   };
    case AddressesActionsTypes.LIST_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload.cities,
      };
    case AddressesActionsTypes.LIST_CITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case AddressesActionsTypes.LIST_AREAS_BY_CITY_START:
      return {
        ...state,
        isAreasLoading: true,
      };
    case AddressesActionsTypes.LIST_AREAS_BY_CITY_SUCCESS:
      return {
        ...state,
        areas: action.payload.areas.areas,
        isAreasLoading: false,
      };
    case AddressesActionsTypes.LIST_AREAS_BY_CITY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isAreasLoading: false,
      };
    case AddressesActionsTypes.CHANGE_SELECTED_AREA:
      return {
        ...state,
        selectedArea: action.payload,
      };
    case AddressesActionsTypes.CHANGE_CORD:
      return {
        ...state,
        distance_cords: action.payload,
      };
    default:
      return state;
  }
};

export default addressesReducer;
