import React, { useEffect, useState } from "react";

// import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { selectCurrentUser } from "Redux/user";

import { Img, Typography } from "components/atoms";
import { NewItemModal } from "components/organisms";
import SplitScreen from "components/Layouts/SplitScreen";

import { Plus } from "utils/helpers/SVGsSrc";

import styles from "./RestaurantMenuCard.module.css";

import { ImagesSrc } from "utils";

const RestaurantMenuCard = ({ ProductData, deliveryOption }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [now, setNow] = useState(ProductData?.tags ? ProductData?.tags[0] : "");

  const Handle = () => {
    setNow(
      ProductData?.tags[Math.floor(Math.random() * ProductData?.tags?.length)]
    );
  };

  const [open, setOpen] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const Toggle = () => {
    if (currentUser) {
      setOpen(!open);
    } else {
      navigate(`/login`, {
        state: {
          returnUrl: `/restaurant/${ProductData.vendor_id}`,
        },
      });
    }
  };

  useEffect(() => {
    const intervalID = now ? setInterval(Handle, 2000) : "";
    return () => clearInterval(intervalID);
  }, [Handle]);

  return (
    <>
      <SplitScreen
        containerClasses={
          "w-full items-start flex-row-reverse  md:flex-row my-[32px] px-5 md:px-0"
        }
        leftPaneClasses="w-min sm:hidden"
        middlePaneClasses="w-full md:w-full md:w-[65%]"
        rightPaneClasses="w-auto md:w-[30%]"
      >
        <span>
          {ProductData?.is_available && (
            <Plus
              width={"25px"}
              height={"25px"}
              className={"cursor-pointer"}
              onClick={Toggle}
            />
          )}
        </span>
        <div className={`${styles.RMenuCard} ${styles.RMenuCardMobile} md:m-0`}>
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b font-semibold text-[14px] md:text-lg cursor-pointer"
            classes={"pb-1 sm:pb-3"}
            text={ProductData?.name}
            action={Toggle}
          />
          <Typography
            as="p"
            color="text-warm_grey"
            classes={"sm:pb-3 text-[12px] md:text-[16px]"}
            text={ProductData?.description}
          />
          <div className={"flex w-full justify-between items-end"}>
            <span className="flex items-center sm:mb-2">
              {ProductData?.price?.base_price === 0 ? (
                <Typography
                  as="span"
                  color="text-text_464646"
                  classes={" font-SFProText text-[12px] md:text-lg"}
                  text={t("Price on selection")}
                />
              ) : (
                <>
                  <Typography
                    as="span"
                    color="text-text_464646"
                    classes={
                      ProductData?.price?.price_after_discount === 0 ||
                      ProductData?.price?.price_after_discount ===
                        ProductData?.price?.base_price
                        ? "pr-3 pl-0 rtl:pl-3 rtl:pr-0 text-[12px] md:text-lg font-SFProText"
                        : "pr-3 pl-0 rtl:pl-3 rtl:pr-0 text-[12px] md:text-lg line-through font-SFProText"
                    }
                    text={`${ProductData?.price?.base_price} EGP`}
                  />
                  {ProductData?.price?.price_after_discount &&
                  ProductData?.price?.price_after_discount !==
                    ProductData?.price?.base_price ? (
                    <Typography
                      as="span"
                      color="text-text_464646"
                      classes={
                        "pr-3 pl-0 rtl:pl-3 rtl:pr-0 text-[12px] md:text-lg font-SFProText"
                      }
                      text={`${ProductData?.price?.price_after_discount} EGP`}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </span>
            <span>
              {ProductData?.is_available && (
                <Plus
                  width={"58px"}
                  height={"52px"}
                  className={"cursor-pointer hidden sm:block"}
                  onClick={Toggle}
                />
              )}
            </span>
          </div>
        </div>
        <div className={styles.RMenuImage}>
          <div style={{ position: "relative" }}>
            {!ProductData?.is_available && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                  left: "0",
                  backgroundColor: "rgba(18, 18, 18, 0.38)",
                  backdropFilter: "blur(5.3px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  maxWidth: "218px",
                }}
              >
                <Typography
                  as="h3"
                  size="lg"
                  color="text-white"
                  lineHeight="leading-[1.45]"
                  clsasses={"text-center"}
                  text={"Unavailable"}
                />
              </div>
            )}
            {ProductData?.image ? (
              <Img
                containerClasses="max-w-none md:w-full min-w-[100px] max-w-[218px] w-[100%] mt-2 md:mt-0 cursor-pointer"
                picClasses="rounded-[6px] max-h-[101px] object-cover"
                fileName={ProductData?.image}
                // fileName2x={imageFile2x}
                // fileName3x={imageFile3x}
                altText={"OfferImg"}
                action={Toggle}
              />
            ) : (
              <Img
                fileName={ImagesSrc.MenuCardPlaceHolder}
                fileName2x={ImagesSrc.MenuCardPlaceHolder2x}
                fileName3x={ImagesSrc.MenuCardPlaceHolder3x}
                altText={"OfferImg"}
                containerClasses="max-w-none md:w-full min-w-[100px] max-w-[218px] w-[100%] mt-2 md:mt-0 cursor-pointer"
                picClasses="rounded-[6px] max-h-[101px] object-cover"
                action={Toggle}
              />
            )}
          </div>
          {now && (
            <div
              className={`${styles.overlay} cursor-pointer`}
              onClick={Toggle}
            >
              <div className={styles.OverlayText}>{now.name}</div>
            </div>
          )}
        </div>
      </SplitScreen>
      <hr className={"w-full h-[1px]  bg-text_bfbfbf"} />
      <NewItemModal
        Open={open}
        ToggleModal={Toggle}
        ItemData={ProductData}
        deliveryOption={deliveryOption}
      />
    </>
  );
};

// RestaurantMenuCard.PropTypes = {
//   title: PropTypes.string,
//   desc: PropTypes.string,
//   price: PropTypes.number,
//   oldPrice: PropTypes.number,
//   overlay: PropTypes.array,
// };

export default RestaurantMenuCard;
