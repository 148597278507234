import { PropTypes } from "prop-types";

import { MapMarkerPin } from "utils/helpers/SVGsSrc";

import styles from "./MapMarker.module.css";

const MapMarker = ({ text, onClick }) => {
  return (
    <div alt={text} onClick={onClick} className={styles.wrapper}>
      <div>
        <MapMarkerPin width="100%" height="100%" title="map pin" />
      </div>
    </div>
  );
};

export default MapMarker;

MapMarker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};
