import React from "react";
import { VerifyMobileForEditForm } from "templates/Auth/AuthComponents";
import Layout from "components/molecules/Layout";

const VerifyMobileForEditTemplate = () => {
  return (
    <Layout>
      <VerifyMobileForEditForm process={"change"} />
    </Layout>
  );
};

export default VerifyMobileForEditTemplate;
