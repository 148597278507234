import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TimeAgo from "react-timeago";
import classNames from "classnames";
import englishStrings from "react-timeago/lib/language-strings/en";
import arabicStrings from "react-timeago/lib/language-strings/ar";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { useNavigate } from "react-router";

import { NotificationIcon } from "utils/helpers/SVGsSrc";

import { PopOver } from "components/molecules";
import { Typography } from "components/atoms";

const NotificationItem = ({ data, setNotificationsRead }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const formatter = i18n?.language?.includes("ar")
    ? buildFormatter(arabicStrings)
    : buildFormatter(englishStrings);

  return (
    <div
      className={classNames(
        { "bg-bg_e8e8e8/50": !data?.is_seen },
        "flex justify-between py-3 lg:py-4 px-2 lg:px-4 cursor-pointer hover:bg-bg_e8e8e8 border-t-[1px] border-warm_grey/23"
      )}
      onClick={() => {
        setNotificationsRead(data?.id);
        if (
          (data?.notification_type == "order_deliver_nt" ||
            data?.notification_type == "order_accepted_nt") &&
          !data?.order_status == "rejected"
        ) {
          navigate("/Order_tracking", {
            state: {
              orderId: data?.data_id,
            },
          });
        } else if (
          data?.notification_type == "order_rate_nt" &&
          data?.need_action
        ) {
          navigate(`/Order_rate/${data?.data_id}`);
        } else if (
          data?.notification_type == "order_rate_nt" &&
          !data?.need_action
        ) {
          navigate(`/Order_details/${data?.data_id}`);
        } else if (data?.notification_type == "new_offer_nt") {
          navigate(`/restaurant/${data?.data_id}`);
        } else if (data?.notification_type == "order_request_change_nt") {
          navigate(`/Notifications`);
        } else if (data?.order_status == "rejected") {
          navigate(`/Order_details/${data?.data_id}`);
        }
      }}
    >
      <Typography
        as="p"
        size="sm"
        color="text-warm_grey"
        classes="w-[210px] max-w-[210px] ellipseText line-clamp-1 "
        text={i18n.language === "ar" ? data?.ar_title : data?.title}
      />

      <Typography
        as="span"
        size="sm"
        color="text-warm_grey"
        text={<TimeAgo date={data?.sent_at} formatter={formatter} />}
      />
    </div>
  );
};

const NotificationsPopOver = ({
  currentUser,
  NotificationsCount,
  Notifications,
  setNotificationsRead,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <PopOver
      panelClasses={`translate-x-1/2 translate-x-0 md:-translate-x-full rtl:-translate-x-full md:rtl:translate-x-0 left-1/2`}
      label={
        <>
          {NotificationsCount &&
          NotificationsCount?.notifications?.unseen &&
          currentUser ? (
            <span className="absolute right-[-3px] top-[-5px] bg-primary-900 rounded-full h-[18px] w-[18px] text-text_white font-SFProText flex justify-center items-center">
              {NotificationsCount?.notifications?.unseen}
            </span>
          ) : (
            ""
          )}
          <NotificationIcon width="100%" height="100%" title="notification" />
        </>
      }
      onClick={onClick}
    >
      <div
        className={`w-[90vw] md:w-[400px] max-h-[85vh] h-full overflow-auto`}
      >
        <NavLink to={`${currentUser ? "/Notifications" : "/login"}`}>
          <Typography
            as="h1"
            size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-bold"
            classes="px-6 py-4 text-center cursor-pointer "
            text={t("Notifications")}
          />
        </NavLink>

        <div className=" h-full max-h-[400px]">
          {currentUser && Notifications
            ? [
                ...Notifications?.today,
                ...Notifications?.yesterday,
                ...Notifications?.later,
              ]
                .slice(0, 4)
                ?.map((item) => (
                  <NotificationItem
                    data={item?.attributes}
                    setNotificationsRead={setNotificationsRead}
                  />
                ))
            : ""}
          <NavLink to={`${currentUser ? "/Notifications" : "/login"}`}>
            <Typography
              as="h1"
              size="xl"
              color="text-primary-900"
              fontWeight="font-bold"
              classes="px-6 py-4 text-center cursor-pointer border-t-[1px] border-bg_e8e8e8"
              text={t("Show all notification")}
            />
          </NavLink>
        </div>
      </div>
    </PopOver>
  );
};

export default NotificationsPopOver;
