// Utilies to deal with localStorage, sessionStorage

const StorageUtils = {
  saveItems: (StorageType, items) => {
    items.map((item) => StorageType?.setItem(item.name, item.data));
  },

  getItems: (StorageType, items) => {
    return items.reduce(
      (acc, cur) => ({ ...acc, [cur]: StorageType?.getItem(cur) }),
      {}
    );
  },

  removeItems: (StorageType, items) => {
    items.map((item) => StorageType?.removeItem(item));
  },

  clear: (StorageType) => {
    StorageType?.clear();
  },
};

export default StorageUtils;
