import { PropTypes } from "prop-types";
import classnames from "classnames";

import styles from "./SplitScreen.module.css";

const SplitScreen = ({
  children,
  leftPaneClasses = "",
  middlePaneClasses = "",
  rightPaneClasses = "",
  containerClasses = "",
}) => {
  const [left, middle, right] = children;
  return (
    <div className={classnames(styles.container, containerClasses)}>
      <div className={classnames(leftPaneClasses, styles.left)}>{left}</div>
      {right ? (
        <>
          <div className={classnames(middlePaneClasses, styles.middle)}>
            {middle}
          </div>
          <div className={classnames(rightPaneClasses, styles.right)}>
            {right}
          </div>
        </>
      ) : (
        <div className={classnames(rightPaneClasses, styles.right)}>
          {middle}
        </div>
      )}
    </div>
  );
};

SplitScreen.propTypes = {
  children: PropTypes.node,
  containerClasses: PropTypes.string,
  leftPaneClasses: PropTypes.string,
  middlePaneClasses: PropTypes.string,
  rightPaneClasses: PropTypes.string,
};

export default SplitScreen;
