import React from "react";

import { Spinner } from "components/atoms";

const WithSpinner =
  (WrappedComponent) =>
  ({ isLoading, page = false, ...otherProps }) => {
    return isLoading ? (
      <Spinner classes={page ? "pt-32" : ""} loading={isLoading} />
    ) : (
      <WrappedComponent {...otherProps} />
    );
  };

export default WithSpinner;
