import { MinusSign, PlusSign } from "utils/helpers/SVGsSrc";

import { Typography } from "components/atoms";

import styles from "./Counter.module.css";

const Counter = ({
  ItemData,
  UpdateCartItemHandleClick,
  ItemInstruction = "",
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <MinusSign
          width="10px"
          height="100%"
          // width={"10px"}
          className={"cursor-pointer"}
          onClick={(e) => {
            UpdateCartItemHandleClick(e, "Subtract", ItemData, ItemInstruction);
          }}
        />
      </div>

      <Typography
        as="span"
        // size="2xl"
        color="text-text_3b3b3b"
        lineHeight="leading-none"
        classes="px-2 font-SFProText text-[15px]"
        text={ItemData?.quantity}
      />

      <div className={styles.icon}>
        <PlusSign
          width="10px"
          height="100%"
          // width={"12px"}
          className="cursor-pointer"
          onClick={(e) =>
            UpdateCartItemHandleClick(e, "Add", ItemData, ItemInstruction)
          }
        />
      </div>
    </div>
  );
};

export default Counter;
