import { useEffect, useState } from "react";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import firebase from "firebase/compat/app";

import { IconsSrc } from "utils";
import { signInWithApplePopup } from "utils/firebase/firebase.utils";

import {
  appleSignInStart,
  facebookSignInStart,
  googleSignInStart,
} from "Redux/user/actions/user.actions";

import { CustomButton } from "components/atoms";

const SocialAuth = () => {
  const dispatch = useDispatch();

  const [googlePayload, setGooglePayload] = useState();

  const responseGoogle = (res) => {
    setGooglePayload({
      gm_token: res.tokenId,
      gm_id: res.googleId,
    });
  };
  console.log(googlePayload, "googlePayload");
  const [facebookPayload, setFacebookPayload] = useState();

  const responseFacebook = (res) => {
    if (res?.accessToken)
      setFacebookPayload({
        fb_token: res?.accessToken,
        fb_id: res?.id,
      });
  };

  const [applePayload, setApplePayload] = useState();

  const triggerAppleLoginPopup = async () => {
    // if (firebase?.messaging?.isSupported()) {
    // FCM is supported in this environment
    console.log("Firebase Cloud Messaging is supported.");
    const { accessToken } = await signInWithApplePopup();
    if (accessToken) {
      setApplePayload({
        apple_token: accessToken,
      });
    }
    //   // Further code to handle notifications can be added here
    // } else {
    //   // FCM is not supported in this environment
    //   console.warn("Firebase Cloud Messaging is not supported.");

    //   // You might want to provide alternative handling or notify the user
    // }
  };

  useEffect(() => {
    if (googlePayload) dispatch(googleSignInStart(googlePayload));
  }, [dispatch, googlePayload]);

  useEffect(() => {
    if (facebookPayload) dispatch(facebookSignInStart(facebookPayload));
  }, [dispatch, facebookPayload]);

  useEffect(() => {
    if (applePayload) dispatch(appleSignInStart(applePayload));
  }, [dispatch, applePayload]);

  return (
    <div className="flex w-3/4 lg:w-2/3 items-center justify-between">
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <CustomButton
            width="w-14"
            height="h-14"
            buttonTypeClass="circular"
            bgImage={IconsSrc.Google}
            label=" "
            action={renderProps.onClick}
            disabled={renderProps.disabled}
          />
        )}
        buttonText=""
        onSuccess={responseGoogle}
        cookiePolicy={"single_host_origin"}
        redirectUri=""
      />

      <CustomButton
        width="w-14"
        height="h-14"
        buttonTypeClass="circular"
        bgImage={IconsSrc.Apple}
        label=" "
        action={triggerAppleLoginPopup}
      />

      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        callback={responseFacebook}
        disableMobileRedirect={true}
        isMobile={false}
        render={(renderProps) => (
          <CustomButton
            width="w-14"
            height="h-14"
            buttonTypeClass="circular"
            label=" "
            bgImage={IconsSrc.Facebook}
            action={renderProps.onClick}
          />
        )}
      />
    </div>
  );
};

export default SocialAuth;
