import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";

import { CustomButton } from "components/atoms";
import { Modal, ModalHeader } from "components/molecules";

const FiltersModal = ({ isOpen, ToggleModalVisibility, heading, children }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white w-80 min-w-80">
        <ModalHeader
          ToggleModalVisibility={ToggleModalVisibility}
          heading={heading}
        />
        <div className="w-full h-[310px]  max-h-[310px] greyScroll overflow-y-scroll p-6">
          {children}
        </div>
        <div className="px-6 pb-6">
          <CustomButton
            boxShadowClass="shadow-[0_3px_6px_0_rgb(0,0,0,0.16)]"
            borderRadius="4px"
            height="h-10"
            width="w-full"
            label={t("Apply")}
            textColor="text-primary-50"
            action={ToggleModalVisibility}
          />
        </div>
      </div>
    </Modal>
  );
};

FiltersModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default FiltersModal;
