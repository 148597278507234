import { PropTypes } from "prop-types";

const Img = ({
  fileName,
  fileName3x,
  fileName2x,
  altText,
  action,
  containerClasses = "",
  picClasses = "",
  onError,
  Style,
}) => {
  return (
    <div onClick={action} className={containerClasses}>
      <picture className={` ${picClasses} w-full h-full`}>
        <source
          onError={onError}
          srcSet={fileName3x}
          media="(min-width: 1200px)"
        />
        <source
          onError={onError}
          srcSet={fileName2x}
          media="(min-width: 700px)"
        />
        <img
          onError={onError}
          className={` ${picClasses} w-full h-full`}
          srcSet={fileName}
          alt={altText}
          style={Style}
        />
      </picture>
    </div>
  );
};

Img.propTypes = {
  fileName: PropTypes.string,
  fileName3x: PropTypes.string,
  fileName2x: PropTypes.string,
  altText: PropTypes.string,
  action: PropTypes.func,
  containerClasses: PropTypes.string,
  picClasses: PropTypes.string,
};

export default Img;
