import { createSelector } from "reselect";

const selectAddresses = (state) => state.addresses;

export const selectSavedAddresses = createSelector(
  [selectAddresses],
  (addresses) => addresses.savedAddresses
);

export const selectSelectedAddress = createSelector(
  [selectAddresses],
  (addresses) => addresses.selectedAddress
);

export const selectAddressesLoading = createSelector(
  [selectAddresses],
  (addresses) => addresses.isLoading
);

export const selectAddressesHasMore = createSelector(
  [selectAddresses],
  (addresses) => addresses.hasMore
);

export const selectIsAddressDeleted = createSelector(
  [selectAddresses],
  (addresses) => addresses.isAddressDeleted
);

export const selectCities = createSelector(
  [selectAddresses],
  (addresses) => addresses.cities
);

export const selectAreas = createSelector(
  [selectAddresses],
  (addresses) => addresses.areas
);

export const selectIsAreasLoading = createSelector(
  [selectAddresses],
  (addresses) => addresses.isAreasLoading
);

export const selectSelectedArea = createSelector(
  [selectAddresses],
  (addresses) => addresses.selectedArea
);

export const selectUserCord = createSelector(
  [selectAddresses],
  (addresses) => addresses.distance_cords
);
