import { takeLatest, all, call, put } from "redux-saga/effects";

import UserActionTypes from "../types/user.types";
import {
  signInFailure,
  signUpFailure,
  signUpSuccess,
  verifyOTPFailure,
  resendVerificationFailure,
  verifyMobileFailure,
  verifyMobileSuccesss,
  verifyMobileStart,
  signInSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  resetPasswordVerifyOTPSuccess,
  signoutFailure,
  signoutSuccess,
  sendContactUsMessageSuccess,
  sendContactUsMessageFailure,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  GetFAQSuccess,
  GetFAQFailure,
  ChangePhoneSuccess,
  ChangePhoneFailure,
  changePasswordSuccess,
  changePasswordFailure,
  GetNotificationSuccess,
  GetNotificationFailure,
  GetNotificationCountSuccess,
  GetNotificationCountFailure,
  setNotificationReadSuccess,
  setNotificationReadFailure,
  updateMobileSuccess,
  updateMobileFailure,
  verifyNewMobileSuccess,
  verifyNewMobileFailure,
  verifyNewMobileStart,
  deleteUserProfileSuccess,
  deleteUserProfileFailure,
  setFCMSuccess,
  setFCMFailure,
  resendVerificationSuccess,
  setMobileSuccess,
  setMobileFailure,
  getSystemConfigurationSuccess,
  getSystemConfigurationFailure,
} from "../actions/user.actions";

import * as URL from "services/URLs";

import getAxios from "utils/Axios";
import { StorageUtils } from "utils";
import { useNavigate } from "react-router";

const axios = getAxios();

export function* phoneSignIn({ payload }) {
  try {
    const data = {
      customer: {
        ...payload,
      },
      device: {
        uuid: "uuid",
        token: "firebase_auth_token",
      },
    };
    const res = yield call(axios.call, "post", URL.LOGIN, data);
    if (res?.status === 200) {
      yield call(
        axios.setHeaders,
        "Authorization",
        `Bearer ${res?.data?.access_token}`
      );
      yield call(StorageUtils.saveItems, localStorage, [
        {
          name: "access_token",
          data: res?.data?.access_token,
        },
      ]);
      yield put(
        signInSuccess({
          ...res?.data?.data?.data?.attributes,
          access_token: res?.data?.access_token,
          isRemembered: payload.isRemembered,
          LoginType: "normal",
        })
      );
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onPhoneSignInStart() {
  yield takeLatest(UserActionTypes.PHONE_SIGN_IN_START, phoneSignIn);
}

export function* googleSignIn({ payload }) {
  const data = {
    ...payload,
    type: "customer",
    device: {
      uuid: "uuid",
      token: "firebase_auth_token",
    },
  };

  try {
    const res = yield call(axios.call, "post", URL.GOOGLE_LOGIN, data);

    if (res?.status === 200) {
      yield call(
        axios.setHeaders,
        "Authorization",
        `Bearer ${res?.data?.access_token}`
      );
      yield call(StorageUtils.saveItems, localStorage, [
        {
          name: "access_token",
          data: res?.data?.access_token,
        },
      ]);
      yield put(
        signInSuccess({
          ...res?.data?.data?.data?.attributes,
          access_token: res?.data?.access_token,
          isRemembered: false,
          LoginType: "social",
        })
      );
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}

export function* facebookSignIn({ payload }) {
  const data = {
    ...payload,
    type: "customer",
    device: {
      uuid: "uuid",
      token: "firebase_auth_token",
    },
  };

  try {
    const res = yield call(axios.call, "post", URL.FACEBOOK_LOGIN, data);

    if (res?.status === 200) {
      yield call(
        axios.setHeaders,
        "Authorization",
        `Bearer ${res?.data?.access_token}`
      );
      yield call(StorageUtils.saveItems, localStorage, [
        {
          name: "access_token",
          data: res?.data?.access_token,
        },
      ]);
      yield put(
        signInSuccess({
          ...res?.data?.data?.data?.attributes,
          access_token: res?.data?.access_token,
          isRemembered: false,
        })
      );
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onFacebookSignInStart() {
  yield takeLatest(UserActionTypes.FACEBOOK_SIGN_IN_START, facebookSignIn);
}

export function* appleSignIn({ payload }) {
  const data = {
    ...payload,
    type: "customer",
    device: {
      uuid: "uuid",
      token: "firebase_auth_token",
    },
  };

  try {
    const res = yield call(axios.call, "post", URL.APPLE_LOGIN, data);

    if (res?.status === 200) {
      yield call(
        axios.setHeaders,
        "Authorization",
        `Bearer ${res?.data?.access_token}`
      );
      yield call(StorageUtils.saveItems, localStorage, [
        {
          name: "access_token",
          data: res?.data?.access_token,
        },
      ]);
      yield put(
        signInSuccess({
          ...res?.data?.data?.data?.attributes,
          access_token: res?.data?.access_token,
          isRemembered: false,
        })
      );
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onAppleSignInStart() {
  yield takeLatest(UserActionTypes.APPLE_SIGN_IN_START, appleSignIn);
}

export function* phoneSignUp({ payload }) {
  try {
    const data = {
      customer: {
        ...payload,
      },
      device: {
        uuid: "uuid",
        token: "firebase_auth_token",
      },
    };
    const res = yield call(axios.call, "post", URL.REGISTER, data);
    if (res?.status === 200) {
      yield call(
        axios.setHeaders,
        "Authorization",
        `Bearer ${res?.data?.access_token}`
      );
      yield call(StorageUtils.saveItems, localStorage, [
        {
          name: "access_token",
          data: res?.data?.access_token,
        },
      ]);
      yield put(
        signUpSuccess({
          ...res?.data?.data,
          access_token: res?.data?.access_token,
          isRemembered: false,
        })
      );
    }
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* onPhoneSignUpStart() {
  yield takeLatest(UserActionTypes.PHONE_SIGN_UP_START, phoneSignUp);
}

export function* verifyOTP({ payload }) {
  const data = { ...payload };
  try {
    const verifyOTPRes = yield call(
      axios.call,
      "post",
      `${URL.VERIFY_OTP}?verification_code=${data.verification_code}`,
      data
    );

    if (verifyOTPRes.status === 200) {
      if (data.code_scope === "reset_password") {
        StorageUtils.saveItems(localStorage, [
          { name: "varification_token", data: verifyOTPRes.data.data },
        ]);
        yield put(resetPasswordVerifyOTPSuccess());
      } else
        data.new_mobile_number
          ? yield put(
              verifyNewMobileStart({
                user: {
                  mobile_number: data.new_mobile_number,
                  type: data.user.type,
                },
                "verification-token": verifyOTPRes.data.data,
              })
            )
          : yield put(
              verifyMobileStart({
                ...data,
                "verification-token": verifyOTPRes.data.data,
              })
            );
    }
  } catch (error) {
    yield put(verifyOTPFailure(error));
  }
}

export function* onVerifyOTPStart() {
  yield takeLatest(UserActionTypes.VERIFY_OTP_START, verifyOTP);
}

export function* verifyMobile({ payload }) {
  const data = {
    ...payload,
    device: {
      uuid: "uuid",
      token: "firebase_auth_token",
    },
  };
  try {
    const verifyMobileRes = yield call(
      axios.call,
      "post",
      URL.VERIFY_MOBILE,
      {
        user: data.user,
        device: {
          uuid: "uuid",
          token: "firebase_auth_token",
        },
      },
      { "verification-token": data["verification-token"] }
    );
    if (verifyMobileRes?.status === 200) yield put(verifyMobileSuccesss());
  } catch (error) {
    yield put(verifyMobileFailure(error));
  }
}

export function* onVerifyMobileStart() {
  yield takeLatest(UserActionTypes.VERIFY_MOBILE_NUMBER_START, verifyMobile);
}

export function* verifyNewMobile({ payload }) {
  const data = { ...payload };
  try {
    const verifyNewMobileRes = yield call(
      axios.call,
      "post",
      URL.VERIFY_MOBILE,
      {
        user: data.user,
        device: {
          uuid: "uuid",
          token: "firebase_auth_token",
        },
      },
      { "verification-token": data["verification-token"] }
    );
    if (verifyNewMobileRes?.status === 200)
      yield put(verifyNewMobileSuccess(verifyNewMobileRes?.data?.data));
  } catch (error) {
    yield put(verifyNewMobileFailure(error));
  }
}

export function* onVerifyNewMobileStart() {
  yield takeLatest(
    UserActionTypes.VERIFY_NEW_MOBILE_NUMBER_START,
    verifyNewMobile
  );
}

export function* sendVerificationMessage({ payload }) {
  const data = { ...payload };
  try {
    const res = yield call(
      axios.call,
      "post",
      URL.SEND_VERIFICATION_MESSAGE,
      data
    );
    if (res?.status === 200) yield put(resendVerificationSuccess(res?.data));
  } catch (error) {
    yield put(resendVerificationFailure(error));
  }
}

export function* onSendVerificationMessageStart() {
  yield takeLatest(
    UserActionTypes.SEND_VERIFICATION_MESSAGE_START,
    sendVerificationMessage
  );
}

export function* resetPassword({ payload }) {
  const data = { ...payload };
  try {
    const res = yield call(axios.call, "post", URL.RESET_PASSWORD, data, {
      "verification-token": StorageUtils.getItems(localStorage, [
        "varification_token",
      ])["varification_token"],
    });
    if (res?.status === 200) {
      yield put(resetPasswordSuccess());
      StorageUtils.removeItems(localStorage, ["varification_token"]);
      StorageUtils.removeItems(sessionStorage, ["distance_cords"]);
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_START, resetPassword);
}

export function* signOut() {
  try {
    const res = yield call(axios.call, "post", URL.LOGOUT);

    if (res.status === 200) {
      yield call(StorageUtils.removeItems, localStorage, [
        "persist:root",
        "access_token",
      ]);
      yield call(StorageUtils.clear, sessionStorage);
      yield put(signoutSuccess());
    }
  } catch (error) {
    yield put(signoutFailure(error));
  }
}

export function* onSignoutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* SendContactUsMessage({ payload }) {
  const data = {
    contact_us: {
      full_name: payload.full_name,
      email: payload.email,
      message: payload.message,
      form_type: "customer",
    },
  };
  try {
    const res = yield call(axios.call, "post", `${URL.CONTACT_MESSAGE}`, data);

    if (res.status === 200) yield put(sendContactUsMessageSuccess(true));
  } catch (error) {
    yield put(sendContactUsMessageFailure(error));
  }
}

export function* onSendContactUsMessageStart() {
  yield takeLatest(
    UserActionTypes.SEND_CONTACT_US_MESSAGE_START,
    SendContactUsMessage
  );
}

export function* UpdateUserProfile({ payload }) {
  const data = {
    customer: {
      first_name: payload.first_name,
      last_name: payload.last_name,
      email: payload.email,
      gender: payload.gender,
      date_of_birth: payload.date_of_birth,
      receive_offers_discounts: payload.receive_offers_discounts,
      subscribe_news_letter: payload.subscribe_news_letter,
    },
  };
  try {
    const res = yield call(axios.call, "put", `${URL.UPDATE_PROFILE}`, data);

    if (res.status === 200)
      yield put(updateUserProfileSuccess(res?.data?.data));
  } catch (error) {
    yield put(updateUserProfileFailure(error));
  }
}

export function* onUpdateUserProfileStart() {
  yield takeLatest(
    UserActionTypes.UPDATE_USER_PROFILE_START,
    UpdateUserProfile
  );
}

export function* updateMobile({ payload }) {
  const data = {
    user: {
      mobile_number: payload.phone,
      password: payload.password,
    },
  };
  try {
    const res = yield call(axios.call, "post", URL.UPDATE_MOBILE, data);
    if (res?.status === 200) {
      yield put(updateMobileSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(updateMobileFailure(error));
  }
}

export function* onUpdateMobileStart() {
  yield takeLatest(UserActionTypes.UPDATE_MOBILE_START, updateMobile);
}

export function* changePassword({ payload }) {
  const data = {
    user: {
      current_password: payload.old_password,
      new_password: payload.password,
    },
  };
  try {
    const res = yield call(axios.call, "put", URL.CHANGE_PASSWORD, data);
    if (res?.status === 200) {
      yield put(changePasswordSuccess());
    }
  } catch (error) {
    yield put(changePasswordFailure(error));
  }
}

export function* onChangePasswordStart() {
  yield takeLatest(UserActionTypes.CHANGE_PASSWORD_START, changePassword);
}

export function* GetFAQ() {
  try {
    const res = yield call(axios.call, "get", URL.FAQ);

    if (res.status === 200) yield put(GetFAQSuccess(res?.data?.data));
  } catch (error) {
    yield put(GetFAQFailure(error));
  }
}

export function* onGetFAQStart() {
  yield takeLatest(UserActionTypes.GET_FAQ_START, GetFAQ);
}

export function* GetNotifications() {
  try {
    const res = yield call(axios.call, "get", URL.NOTIFICATION);

    if (res.status === 200) yield put(GetNotificationSuccess(res?.data?.data));
  } catch (error) {
    yield put(GetNotificationFailure(error));
  }
}

export function* onGetNotificationsStart() {
  yield takeLatest(UserActionTypes.GET_NOTIFICATION_START, GetNotifications);
}

export function* GetNotificationsCount() {
  try {
    const res = yield call(axios.call, "get", URL.NOTIFICATION_COUNT);

    if (res.status === 200)
      yield put(GetNotificationCountSuccess(res?.data?.data));
  } catch (error) {
    yield put(GetNotificationCountFailure(error));
  }
}

export function* onGetNotificationsCountStart() {
  yield takeLatest(
    UserActionTypes.GET_NOTIFICATION_COUNT_START,
    GetNotificationsCount
  );
}

export function* setNotificationsRead({ payload }) {
  const data = {
    notification: {
      id: payload.id,
    },
  };
  try {
    const res = yield call(axios.call, "put", URL.NOTIFICATION_READ, data);

    if (res.status === 200)
      yield put(setNotificationReadSuccess(res?.data?.data));
  } catch (error) {
    yield put(setNotificationReadFailure(error));
  }
}

export function* onSetNotificationsReadStart() {
  yield takeLatest(
    UserActionTypes.SET_NOTIFICATION_READ_START,
    setNotificationsRead
  );
}

export function* DeleteUserProfile() {
  try {
    const res = yield call(axios.call, "delete", URL.UPDATE_PROFILE);

    if (res.status === 200) {
      yield call(StorageUtils.removeItems, localStorage, [
        "persist:root",
        "access_token",
      ]);
      yield call(StorageUtils.clear, sessionStorage);
      yield put(deleteUserProfileSuccess(res?.data?.data));
    }
  } catch (error) {
    yield put(deleteUserProfileFailure(error));
  }
}

export function* onDeleteUserProfileStart() {
  yield takeLatest(
    UserActionTypes.DELETE_USER_PROFILE_START,
    DeleteUserProfile
  );
}

export function* SetFCMToken({ payload }) {
  const data = {
    fcm_token: {
      firebase_auth_token: payload.token,
    },
  };
  try {
    const res = yield call(axios.call, "post", URL.SET_FCM, data);

    if (res.status === 200) yield put(setFCMSuccess(res?.data?.data));
  } catch (error) {
    yield put(setFCMFailure(error));
  }
}

export function* onSetFCMTokenStart() {
  yield takeLatest(UserActionTypes.SET_FCM_TOKEN_START, SetFCMToken);
}

export function* SetMobile({ payload }) {
  console.log(payload);
  const data = {
    user: {
      mobile_number: payload?.mobileNumber,
    },
  };
  try {
    const res = yield call(axios.call, "post", URL.SET_MOBILE, data);
    if (res.status === 200) {
      yield put(setMobileSuccess(res?.data?.data));
      payload?.action && payload.action();
    }
  } catch (error) {
    yield put(setMobileFailure(error));
  }
}

export function* onSetMobileStart() {
  yield takeLatest(UserActionTypes.SET_MOBILE_NUMBER_START, SetMobile);
}

export function* GetSystemConfiguration({ payload }) {
  try {
    const res = yield call(axios.call, "get", URL.SYSTEM_CONFIGURATION);
    if (res.status === 200)
      yield put(getSystemConfigurationSuccess(res?.data?.data));
  } catch (error) {
    yield put(getSystemConfigurationFailure(error));
  }
}

export function* onGetSystemConfigurationStart() {
  yield takeLatest(
    UserActionTypes.GET_SYSTEM_CONFIGURATION_START,
    GetSystemConfiguration
  );
}

export function* userSagas() {
  yield all([
    call(onPhoneSignInStart),
    call(onPhoneSignUpStart),
    call(onVerifyOTPStart),
    call(onVerifyMobileStart),
    call(onVerifyNewMobileStart),
    call(onSendVerificationMessageStart),
    call(onResetPasswordStart),
    call(onSignoutStart),
    call(onGoogleSignInStart),
    call(onFacebookSignInStart),
    call(onAppleSignInStart),
    call(onSendContactUsMessageStart),
    call(onUpdateUserProfileStart),
    call(onUpdateMobileStart),
    call(onChangePasswordStart),
    call(onGetFAQStart),
    call(onGetNotificationsStart),
    call(onGetNotificationsCountStart),
    call(onSetNotificationsReadStart),
    call(onDeleteUserProfileStart),
    call(onSetFCMTokenStart),
    call(onSetMobileStart),
    call(onGetSystemConfigurationStart),
  ]);
}
