import * as URL from "services/URLs";

import {
  AddRateVendorFailure,
  AddRateVendorSuccess,
  CheckoutOrderFailure,
  CheckoutOrderSuccess,
  cancelOrderFailure,
  cancelOrderSuccess,
  getOrderFailure,
  getOrderSuccess,
  getPaymentFailure,
  getPaymentSuccess,
  listOrdersFailure,
  listOrdersSuccess,
  loadMoreOrdersSuccess,
  reorderFailure,
  reorderSuccess,
  updateStatusFailure,
  updateStatusSuccess,
} from "../actions/orders.actions";
import {
  DeleteCartDataSuccess,
  ListCartDataStart,
  UpdateCartDataSuccess,
} from "Redux/Cart/actions/cart.actions";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { StorageUtils } from "utils";
import getAxios from "utils/Axios";
import ordersActionsTypes from "../types/orders.types";

const axios = getAxios();

export function* listOrders({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
    ...payload,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
                ...payload,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_ORDERS}?${queryParams.join("")}`
    );

    if (res?.status === 200) {
      if (payload.page_number > 1)
        yield put(
          loadMoreOrdersSuccess({
            orders: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      else
        yield put(
          listOrdersSuccess({
            orders: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
    }
  } catch (error) {
    yield put(listOrdersFailure(error));
  }
}

export function* onListordersStart() {
  yield takeLatest(ordersActionsTypes.LIST_ORDERS_START, listOrders);
}

export function* onLoadMoreOrdersStart() {
  yield takeLatest(ordersActionsTypes.LOAD_MORE_ORDERS_START, listOrders);
}

export function* getPayment({ payload }) {
  const data = {
    delivery_service_type: payload?.delivery_service_type,
    cart: payload.cart,
  };
  const installments = payload.installments;
  try {
    const res = yield call(
      axios.call,
      "post",
      `${URL.PAYMENT}?installments_enabled=${installments}`,
      data
    );
    if (res?.status === 200) {
      yield put(getPaymentSuccess(res?.data));
    }
  } catch (error) {
    yield put(getPaymentFailure(error));
  }
}

export function* onGetPaymentStart() {
  yield takeLatest(ordersActionsTypes.GET_PAYMENT_START, getPayment);
}

export function* checkoutOrder({ payload }) {
  const deliveryTime = new Date();
  const delivery_time = `${deliveryTime.getHours()}:${deliveryTime.getMinutes()}`;
  const delivery_date = `${deliveryTime.getDate()}-${
    deliveryTime.getMonth() + 1
  }-${deliveryTime.getFullYear()}`;

  const delivery_later_time = StorageUtils.getItems(sessionStorage, [
    "delivery_time",
  ])["delivery_time"];
  const delivery_later_date = StorageUtils.getItems(sessionStorage, [
    "delivery_date",
  ])["delivery_date"];

  const data = {
    delivery_service_type: payload?.delivery_service_type,
    order: {
      delivery_time:
        StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
        "now"
          ? delivery_time
          : delivery_later_time,
      delivery_date:
        StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
        "now"
          ? delivery_date
          : delivery_later_date,
      ...payload,
    },
  };

  try {
    const res = yield call(axios.call, "post", URL.CHECKOUT, data);

    if (res?.status === 200)
      yield all([
        put(CheckoutOrderSuccess(res?.data?.data)),
        put(DeleteCartDataSuccess()),
      ]);
  } catch (error) {
    yield put(CheckoutOrderFailure(error));
  }
}

export function* onCheckoutOrderStart() {
  yield takeLatest(ordersActionsTypes.CHECKOUT_ORDER_START, checkoutOrder);
}

export function* cancelOrder({ payload }) {
  const data = {
    order: {
      subject: payload.reasonOfCancelling,
      body: payload.reasonOfCancelling,
    },
  };

  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.CANCEL_ORDER}/${payload.orderId}`,
      data
    );

    if (res.status === 200) yield put(cancelOrderSuccess());
  } catch (erorr) {
    yield put(cancelOrderFailure());
  }
}

export function* onCancelOrder() {
  yield takeLatest(ordersActionsTypes.CANCEL_ORDER_START, cancelOrder);
}

export function* getOrder({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const { orderId } = payload;

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_ORDERS}/${orderId}?${queryParams.join("")}`
    );

    if (res.status === 200) yield put(getOrderSuccess(res?.data?.data));
  } catch (error) {
    yield put(getOrderFailure());
  }
}

export function* onGetOrderStart() {
  yield takeLatest(ordersActionsTypes.GET_ORDER_START, getOrder);
}

export function* reorder({ payload }) {
  try {
    const delivery_type =
      StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

    const deliveryTime = new Date();
    const delivery_time = `${deliveryTime.getHours()}:${deliveryTime.getMinutes()}`;
    const delivery_date = `${deliveryTime.getDate()}-${
      deliveryTime.getMonth() + 1
    }-${deliveryTime.getFullYear()}`;

    const delivery_later_time = StorageUtils.getItems(sessionStorage, [
      "delivery_time",
    ])["delivery_time"];
    const delivery_later_date = StorageUtils.getItems(sessionStorage, [
      "delivery_date",
    ])["delivery_date"];

    const data = {
      order: {
        delivery_time:
          StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
          "now"
            ? delivery_time
            : delivery_later_time,
        delivery_date:
          StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
          "now"
            ? delivery_date
            : delivery_later_date,
        delivery_type: delivery_type,
      },
    };

    const res = yield call(
      axios.call,
      "post",
      `${URL.REORDER}/${payload}`,
      data
    );

    if (res?.status === 200) {
      yield put(UpdateCartDataSuccess(res?.data?.data));
      yield put(reorderSuccess());
      yield put(ListCartDataStart());
    }
  } catch (error) {
    yield put(reorderFailure(error));
  }
}

export function* onReorderStart() {
  yield takeLatest(ordersActionsTypes.REORDER_START, reorder);
}

export function* AddRateVendor({ payload }) {
  const data = {
    vendor_review: {
      rating: payload.VendorRating ? payload.VendorRating : 0,
      delivery_rating: payload.DeliveryRating ? payload.DeliveryRating : 0,
      body: payload.body,
      title: payload.title,
    },
  };
  try {
    const res = yield call(
      axios.call,
      "post",
      `${URL.GET_VENDOR_REVIEWS}/${payload.id}`,
      data
    );
    if (res?.status === 200) yield put(AddRateVendorSuccess(res?.data?.data));
  } catch (error) {
    yield put(AddRateVendorFailure(error));
  }
}

export function* onAddRateVendorStart() {
  yield takeLatest(ordersActionsTypes.ADD_RATE_VENDOR_START, AddRateVendor);
}

export function* updateStatus({ payload }) {
  try {
    const data = {
      order: {
        status: payload.status, // [pending, request_changes, accepted, rejected, canceled, completed]
      },
    };

    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_STATUS}/${payload.id}`,
      data
    );

    if (res?.status === 200) yield put(updateStatusSuccess(res?.data?.data));
  } catch (error) {
    yield put(updateStatusFailure(error));
  }
}

export function* onupdateStatusStart() {
  yield takeLatest(ordersActionsTypes.UPDATE_STATUS_START, updateStatus);
}

export function* ordersSagas() {
  yield all([
    call(onListordersStart),
    call(onLoadMoreOrdersStart),
    call(onCheckoutOrderStart),
    call(onCancelOrder),
    call(onGetOrderStart),
    call(onReorderStart),
    call(onAddRateVendorStart),
    call(onGetPaymentStart),
    call(onupdateStatusStart),
  ]);
}
