import React from "react";

import { PropTypes } from "prop-types";
import StarRatings from "react-star-ratings/build/star-ratings";
import { useTranslation } from "react-i18next";

import { CloseIcon, FullStar } from "utils/helpers/SVGsSrc";

import { WithSpinner } from "Hocs";

import { DropdownMenu, Typography } from "components/atoms";
import { Modal } from "components/molecules";

const Reviews = ({ vendorReviews }) => {
  return (
    <>
      {vendorReviews?.map((item) => {
        return (
          <>
            <div className="w-full flex justify-between items-start px-4 sm:px-6 py-4">
              <div className="flex flex-col items-start">
                <Typography
                  as="h2"
                  lineHeight="leading-none"
                  fontWeight="font-bold"
                  color="text-text_3b3b3b"
                  classes={"text-[12px] sm:text-[22px] mt-[8px]"}
                  text={item?.customer_name}
                />
                <Typography
                  as="p"
                  lineHeight="leading-none"
                  classes={"py-3 text-[12px] sm:text-[16px]"}
                  color="text-warm_grey"
                  text={item?.body}
                />
                <Typography
                  as="p"
                  lineHeight="leading-none"
                  classes={"py-3 text-[11px] sm:text-[16px]"}
                  color="text-warm_grey"
                  text={item?.date?.split("T")[0]}
                />
              </div>
              <span className="flex items-start">
                <StarRatings
                  rating={item?.average_rating ? item?.average_rating : 0}
                  numberOfStars={5}
                  name="rating"
                  starRatedColor="#e5a128"
                  starEmptyColor="white"
                  starDimension="20px"
                  isAggregateRating={true}
                  starSpacing="2px"
                  svgIconViewBox="0 0 22 21"
                  svgIconPath="M22 8.138a.956.956 0 0 1-.344.635l-4.8 4.685 1.137 6.616a1.968 1.968 0 0 1 .013.265.781.781 0 0 1-.139.47.467.467 0 0 1-.4.192 1.067 1.067 0 0 1-.529-.159L11 17.718l-5.936 3.123a1.121 1.121 0 0 1-.529.159.48.48 0 0 1-.416-.192.784.784 0 0 1-.139-.47 2.165 2.165 0 0 1 .026-.265l1.137-6.616L.331 8.773A1.008 1.008 0 0 1 0 8.138q0-.489.741-.609l6.637-.966 2.974-6.02Q10.6 0 11 0t.648.543l2.975 6.021 6.637.966q.741.119.741.609z"
                />
              </span>
            </div>
            <hr className={"w-full h-[1px] bg-text_bfbfbf"} />
          </>
        );
      })}
    </>
  );
};

const ReviewModal = ({
  Open,
  ToggleModal,
  vendorReviews,
  isReviewsLoading,
  RestaurantData,
  choices,
  ReviewSort,
  setReviewSort,
}) => {
  const { t } = useTranslation();
  const ReviewsContainer = WithSpinner(Reviews);
  return (
    <Modal isOpen={Open} ToggleModalVisibility={ToggleModal}>
      <div className="bg-white w-full max-w-[900px] pt-6">
        <div className="w-full flex justify-end px-4 sm:px-6">
          <CloseIcon
            title="close"
            onClick={ToggleModal}
            className="cursor-pointer"
          />
        </div>
        <Typography
          as="h1"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"text-[16px] sm:text-[25px]"}
          text={t("Reviews")}
        />
        <div className="w-full flex justify-between items-center px-4 sm:px-6 py-6">
          <div className="flex items-center">
            <StarRatings
              rating={RestaurantData?.rating ? RestaurantData?.rating : 0}
              numberOfStars={5}
              name="rating"
              starRatedColor="#e5a128"
              starEmptyColor="white"
              // starDimension="20px"
              isAggregateRating={true}
              starSpacing="2px"
              svgIconViewBox="0 0 22 21"
              svgIconPath="M22 8.138a.956.956 0 0 1-.344.635l-4.8 4.685 1.137 6.616a1.968 1.968 0 0 1 .013.265.781.781 0 0 1-.139.47.467.467 0 0 1-.4.192 1.067 1.067 0 0 1-.529-.159L11 17.718l-5.936 3.123a1.121 1.121 0 0 1-.529.159.48.48 0 0 1-.416-.192.784.784 0 0 1-.139-.47 2.165 2.165 0 0 1 .026-.265l1.137-6.616L.331 8.773A1.008 1.008 0 0 1 0 8.138q0-.489.741-.609l6.637-.966 2.974-6.02Q10.6 0 11 0t.648.543l2.975 6.021 6.637.966q.741.119.741.609z"
            />
            <Typography
              as="h2"
              color="text-warm_grey"
              classes="px-2 font-SFProText text-[14px] sm:text-lg lg:text-xl"
              text={RestaurantData?.rating ? RestaurantData?.rating : 0}
            />
          </div>
          <div className="hidden sm:block">
            <DropdownMenu
              options={choices}
              ReviewSort={ReviewSort}
              setReviewSort={setReviewSort}
            />
          </div>
        </div>
        <hr className={"w-full h-[1px] bg-text_bfbfbf"} />
        <div className="w-full flex-col flex max-h-[60vh] overflow-auto">
          <div className="w-full flex justify-between items-center px-4 sm:px-6 py-4">
            <Typography
              as="h2"
              lineHeight="leading-none"
              fontWeight="font-semibold"
              color="text-text_3b3b3b"
              classes={"text-[12px] sm:text-[20px]"}
              text={t("Quality of food")}
            />
            <span className="flex items-center">
              <FullStar className={"mx-[1px] w-[13px] sm:w-[20px]"} />
              <Typography
                as="h2"
                color="text-warm_grey"
                classes="px-2 font-SFProText text-[14px] sm:text-lg lg:text-xl"
                text={RestaurantData?.rating ? RestaurantData?.rating : 0}
              />
            </span>
          </div>
          <hr className={"w-full h-[1px] bg-text_bfbfbf"} />
          <div className="w-full flex justify-between items-center px-4 sm:px-6 py-4">
            <Typography
              as="h2"
              lineHeight="leading-none"
              fontWeight="font-semibold"
              color="text-text_3b3b3b"
              classes={"text-[12px] sm:text-[20px]"}
              text={t("Delivery experience")}
            />
            <span className="flex items-center">
              <FullStar className={"mx-[1px] w-[13px] sm:w-[20px]"} />
              <Typography
                as="h2"
                color="text-warm_grey"
                classes="px-2 font-SFProText text-[14px] sm:text-lg lg:text-xl"
                text={
                  RestaurantData?.delivery_rating
                    ? RestaurantData?.delivery_rating
                    : 0
                }
              />
            </span>
          </div>
          <hr className={"w-full h-[1px] bg-text_bfbfbf"} />
          <ReviewsContainer
            isLoading={isReviewsLoading}
            vendorReviews={vendorReviews}
          />
        </div>
      </div>
    </Modal>
  );
};

ReviewModal.propTypes = {
  vendorReviews: PropTypes.array,
};

export default ReviewModal;
