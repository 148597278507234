import { createSelector } from "reselect";

const selectOrders = (state) => state.orders;

export const selectordersList = createSelector(
  [selectOrders],
  (orders) => orders.orders
);

export const selectOrder = createSelector(
  [selectOrders],
  (orders) => orders.order
);

export const selectOrderError = createSelector(
  [selectOrders],
  (orders) => orders.error
);

export const selectHasMoreOrders = createSelector(
  [selectOrders],
  (orders) => orders.hasMore
);

export const selectOrdersLoading = createSelector(
  [selectOrders],
  (orders) => orders.loading
);

export const selectIsOrderCancelled = createSelector(
  [selectOrders],
  (orders) => orders.isOrderCancelled
);

export const selectPaymentInfo = createSelector(
  [selectOrders],
  (orders) => orders.paymentInfo
);

export const isReordered = createSelector(
  [selectOrders],
  (orders) => orders.reordered
);
