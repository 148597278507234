import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPayload, StorageUtils } from "utils";

import {
  phoneSignInStart,
  resendVerificationMessageStart,
} from "Redux/user/actions/user.actions";
import {
  selectMobileVerificationStatus,
  selectCurrentUser,
} from "Redux/user/selectors/user.selectors";

import { SocialAuth } from "..";
import { CustomButton, Typography, FormInput } from "components/atoms";
import { ValidationsWrapper } from "components/Containers";

import styles from "./LoginForm.module.css";

const LoginForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  let isMobileVerified = useSelector(selectMobileVerificationStatus);

  const [userCredentials, setCredentials] = useState({
    mobile_number: state?.mobile ?? "",
    password: "",
    isRemembered: true,
  });

  const [phone, setPhone] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [ForgetError, setForgetError] = useState("");

  const phoneSignInStartHandler = (userCredentials) =>
    dispatch(phoneSignInStart(userCredentials));

  const handleSubmit = async (event) => {
    event.preventDefault();

    phoneSignInStartHandler(userCredentials);
  };

  const handleCheck = async (event, phoneNumber) => {
    event.preventDefault();
    dispatch(
      resendVerificationMessageStart({
        user: {
          mobile_number: phoneNumber,
          type: "customer",
        },
        code_scope: "reset_password",
      })
    );
  };

  useEffect(() => {
    if (currentUser && !currentUser?.is_verified) {
      navigate("/Verify_mobile_number", {
        state: {
          scope: "verification",
          mobile: userCredentials?.mobile_number,
        },
      });
    } else if (currentUser) {
      // if (state?.returnUrl) navigate(state?.returnUrl);
      // else

      navigate("/");
      StorageUtils.removeItems(localStorage, [
        ("login_mobile_number", "varification_token"),
      ]);
    }
  }, [currentUser, navigate, userCredentials, state]);

  useEffect(() => {
    if (forgetPassword) {
      // if (isMobileVerified) {
      navigate("/Verify_mobile_number", {
        state: {
          scope: "reset_password",
          mobile: phone,
        },
      });
      setForgetPassword(false);
      // }
    }
  }, [forgetPassword, isMobileVerified]);

  return (
    <ValidationsWrapper
      initialValues={{
        phone: userCredentials?.mobile_number ?? "",
        password: "",
      }}
      rules={["phone", "password"]}
    >
      {({ FormikHandler }) => {
        return (
          <div className={styles.form}>
            <div>
              <Typography
                as="h1"
                size="6xl"
                color="text-text_white"
                text={t("Welcome back")}
              />

              <Typography
                as="p"
                size="lg"
                color="text-text_grey"
                fontWeight="font-medium"
                lineHeight="leading-[1.61]"
                classes="mt-2 lg:mt-3 3xl:mt-4"
                text={t("Please login to your account.")}
              />

              <div className={styles.formInputsContainer}>
                <FormInput
                  id="mobileNumber"
                  name="mobile_number"
                  type="tel"
                  width="w-full"
                  height="h-10"
                  containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                  containerClasses="defaultInput"
                  label={t("Mobile Number")}
                  labelSize="sm"
                  value={FormikHandler.values.phone}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("phone", e.target.value);
                    getPayload(e, userCredentials, setCredentials);
                    if (e.target.value) setForgetError("");
                  }}
                  error={ForgetError ? ForgetError : FormikHandler.errors.phone}
                />
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="Password"
                    name="password"
                    type="password"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Password")}
                    labelSize="sm"
                    value={FormikHandler.values.password}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue(
                        "password",
                        currentUser?.mobile_number ?? e.target.value
                      );
                      getPayload(e, userCredentials, setCredentials);
                    }}
                    error={FormikHandler.errors.password}
                  />
                </div>
              </div>

              <div className="mt-5 lg:mt-6 3xl:mt-10 flex justify-end ">
                {/* <div className="mt-5 lg:mt-6 3xl:mt-10 flex justify-between "> */}
                {/* <FormInput
                  id="rememberMe"
                  name="rememberMe"
                  type="checkbox"
                  width="w-4"
                  height="h-4"
                  bgColor="bg_transparent"
                  fullWidth={false}
                  containerClasses="selectInputs"
                  inputTypeClass="checkboxOutlined"
                  label={t("Remember me")}
                  labelSize="xs"
                  checked={userCredentials?.isRemembered}
                  onValueChange={(e) =>
                    getPayload(
                      {
                        target: {
                          name: "isRemembered",
                          value: e.target.checked,
                        },
                      },
                      userCredentials,
                      setCredentials
                    )
                  }
                /> */}

                <Typography
                  as="span"
                  size="xs"
                  color="text-text_grey"
                  text={t("Forgot your password?")}
                  classes={`w-3/4 ltr:text-right rtl:text-left cursor-pointer`}
                  action={(e) => {
                    if (
                      FormikHandler.values.phone &&
                      !FormikHandler.errors.phone
                    ) {
                      setPhone(FormikHandler.values.phone);
                      handleCheck(e, FormikHandler.values.phone);
                      setForgetPassword(true);
                    } else {
                      setForgetError("Please enter your number first");
                    }
                  }}
                />
              </div>

              <div className="mt-5 lg:mt-8 3xl:mt-10">
                <CustomButton
                  width="w-full"
                  height="h-10"
                  buttonTypeClass="primary"
                  label={t("Sign in")}
                  disabled={!FormikHandler.isValid}
                  action={handleSubmit}
                />
              </div>

              <div className="flex flex-col items-center mt-8 3xl:mt-12">
                <Typography
                  as="span"
                  size="sm"
                  color="text-text_white"
                  classes="mb-3 lg:mb-5 3xl:mb-6"
                  text={t("Or Sign in with")}
                />

                <SocialAuth />

                <div className="mt-5 3xl:mt-12 flex">
                  <Typography
                    as="p"
                    size="sm"
                    color="text-text_white"
                    text={t("Not a member?")}
                  />

                  <Link to="/SignUp">
                    <Typography
                      as="span"
                      size="sm"
                      color="text-text_white"
                      fontWeight="font-bold"
                      classes="ml-1 rtl:mr-1 cursor-pointer "
                      text={t("Sign Up")}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default LoginForm;
