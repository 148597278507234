import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  listOffersStart,
  loadMoreOffersStart,
} from "Redux/offers/actions/offers.actions";

import {
  selectHasMoreOffers,
  selectOffersList,
  selectOffersLoading,
} from "Redux/offers/selectors/offers.selectors";

import { OffersTemplate } from "templates";

const OffersPage = () => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const dispatch = useDispatch();

  const offers = useSelector(selectOffersList);
  const loading = useSelector(selectOffersLoading);
  const hasMore = useSelector(selectHasMoreOffers);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (offers) setPage((prev) => prev + 1);
  }, [offers]);

  useEffect(() => {
    if (currentLng) {
      dispatch(listOffersStart());
    }
  }, [dispatch, currentLng]);

  const loadMoreOffers = () =>
    dispatch(loadMoreOffersStart({ page_number: page, page_size: 10 }));

  return (
    <OffersTemplate
      offers={offers}
      hasMore={hasMore}
      loading={loading}
      loadMoreOffers={loadMoreOffers}
    />
  );
};

export default OffersPage;
