import { createSelector } from "reselect";

const selectOffers = (state) => state.offers;

export const selectOffersList = createSelector(
  [selectOffers],
  (offers) => offers.offers
);

export const selectHasMoreOffers = createSelector(
  [selectOffers],
  (offers) => offers.hasMore
);

export const selectOffersLoading = createSelector(
  [selectOffers],
  (offers) => offers.loading
);
