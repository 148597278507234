import ordersActionsTypes from "../types/orders.types";

import { createAction } from "utils";

export const getPaymentStart = (payload) =>
  createAction(ordersActionsTypes.GET_PAYMENT_START, payload);

export const getPaymentSuccess = (paymentInfo) =>
  createAction(ordersActionsTypes.GET_PAYMENT_SUCCESS, paymentInfo);

export const getPaymentFailure = (error) =>
  createAction(ordersActionsTypes.GET_PAYMENT_FAILURE, error);

export const listOrdersStart = (payload) =>
  createAction(ordersActionsTypes.LIST_ORDERS_START, payload);

export const listOrdersSuccess = (orders) =>
  createAction(ordersActionsTypes.LIST_ORDERS_SUCCESS, orders);

export const listOrdersFailure = (error) =>
  createAction(ordersActionsTypes.LIST_ORDERS_FAILURE, error);

export const loadMoreOrdersStart = (payload) =>
  createAction(ordersActionsTypes.LOAD_MORE_ORDERS_START, payload);

export const loadMoreOrdersSuccess = (orders) =>
  createAction(ordersActionsTypes.LOAD_MORE_ORDERS_SUCCESS, orders);

export const CheckoutOrderStart = (idAddress) =>
  createAction(ordersActionsTypes.CHECKOUT_ORDER_START, idAddress);

export const CheckoutOrderSuccess = (order) =>
  createAction(ordersActionsTypes.CHECKOUT_ORDER_SUCCESS, order);

export const CheckoutOrderFailure = (error) =>
  createAction(ordersActionsTypes.CHECKOUT_ORDER_FAILURE, error);

export const cancelOrderStart = (payload) =>
  createAction(ordersActionsTypes.CANCEL_ORDER_START, payload);

export const cancelOrderSuccess = () =>
  createAction(ordersActionsTypes.CANCEL_ORDER_SUCCESS);

export const cancelOrderFailure = (error) =>
  createAction(ordersActionsTypes.CANCEL_ORDER_FAILURE, error);

export const getOrderStart = (id) =>
  createAction(ordersActionsTypes.GET_ORDER_START, id);

export const getOrderSuccess = (order) =>
  createAction(ordersActionsTypes.GET_ORDER_SUCCESS, order);

export const getOrderFailure = (error) =>
  createAction(ordersActionsTypes.CANCEL_ORDER_FAILURE, error);

export const reorderStart = (id) =>
  createAction(ordersActionsTypes.REORDER_START, id);

export const reorderSuccess = (order) =>
  createAction(ordersActionsTypes.REORDER_SUCCESS, order);

export const reorderFailure = (error) =>
  createAction(ordersActionsTypes.REORDER_FAILURE, error);

export const AddRateVendorStart = (id) =>
  createAction(ordersActionsTypes.ADD_RATE_VENDOR_START, id);

export const AddRateVendorSuccess = (review) =>
  createAction(ordersActionsTypes.ADD_RATE_VENDOR_SUCCESS, review);

export const AddRateVendorFailure = (error) =>
  createAction(ordersActionsTypes.ADD_RATE_VENDOR_FAILURE, error);

export const updateStatusStart = (payload) =>
  createAction(ordersActionsTypes.UPDATE_STATUS_START, payload);

export const updateStatusSuccess = (order) =>
  createAction(ordersActionsTypes.UPDATE_STATUS_SUCCESS, order);

export const updateStatusFailure = (error) =>
  createAction(ordersActionsTypes.UPDATE_STATUS_FAILURE, error);
