import { CheckBoxesGroup, FiltersModal } from "components/organisms";
import {
  CollapsibleWidget,
  CustomSlider,
  Footer,
  NavBar,
  RestaurantCard,
} from "components/molecules";
import { CustomButton, FormInput, Spinner, Typography } from "components/atoms";
import { ImagesSrc, StorageUtils, chunkArray } from "utils";
import { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import { SortIcon } from "utils/helpers/SVGsSrc";
import { SplitScreen } from "components/Layouts";
import classNames from "classnames";
import { loadMoreVendorsStart } from "Redux/vendors/actions/vendors.actions";
import styles from "./Category.module.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "Hooks";
import Layout from "components/molecules/Layout";

const SortFiltersGroup = ({
  getQueryParams,
  queryParams,
  containerClasses,
}) => {
  const { t } = useTranslation();
  return (
    <div className={containerClasses}>
      <FormInput
        id="sort_a_to_z"
        name="sortBy"
        type="radio"
        fullWidth={false}
        width="w-5"
        height="h-5"
        containerClasses="selectInputs"
        label={t("A-Z")}
        value="sort_a_to_z"
        onValueChange={getQueryParams}
        checked={queryParams.sortBy === "sort_a_to_z"}
        labelSize="xl"
        textColor="text-text_777777"
      />
      <FormInput
        id="sort_by_rating"
        name="sortBy"
        type="radio"
        fullWidth={false}
        width="w-5"
        height="h-5"
        containerClasses="selectInputs"
        label={t("Rating")}
        labelSize="xl"
        textColor="text-text_777777"
        value="sort_by_rating"
        onValueChange={getQueryParams}
        checked={queryParams.sortBy === "sort_by_rating"}
      />
      {/* <FormInput
        id="Delivery time"
        name="sortBy"
        type="radio"
        fullWidth={false}
        width="w-5"
        height="h-5"
        containerClasses="selectInputs"
        label={t("Delivery time")}
        labelSize="xl"
        textColor="text-text_777777"
      /> */}
    </div>
  );
};

const FilterModalsButtonsGroup = ({
  categories,
  filters,
  queryParams,
  getQueryParams,
}) => {
  const { t } = useTranslation();

  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const ToggleCategoriesModalVisibility = () =>
    setIsCategoriesModalOpen(!isCategoriesModalOpen);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const ToggleFiltersModalVisibility = () =>
    setIsFiltersModalOpen(!isFiltersModalOpen);

  return (
    <div className="w-full h-10 border-[1px] rounded-[4px] border-text_bfbfbf lg:hidden mt-6 mb-8 flex">
      <CustomButton
        width="w-1/3 "
        height="h-full"
        textColor="text-warm_grey"
        label={t("Category")}
        action={ToggleCategoriesModalVisibility}
        buttonTypeClass="outlined"
        borderColor="border-r-text_bfbfbf "
        classes="rounded-l-[4px]"
      />
      <CustomButton
        width="w-1/3"
        height="h-full"
        textColor="text-warm_grey"
        label={t("Filter")}
        action={ToggleFiltersModalVisibility}
        buttonTypeClass="outlined"
        borderColor="border-r-text_bfbfbf "
      />
      <CustomButton
        width="w-1/3"
        height="h-full"
        textColor="text-warm_grey"
        label={t("Dishes")}
        buttonTypeClass="outlined"
        borderColor="border-transparent"
        classes="rounded-r-[4px]"
      />
      <FiltersModal
        isOpen={isCategoriesModalOpen}
        ToggleModalVisibility={ToggleCategoriesModalVisibility}
        heading={t("Categories")}
      >
        <CheckBoxesGroup
          name="filter_by_main_category_id"
          Options={categories}
          selectedItems={queryParams?.filter_by_main_category_id}
          getSelectedItems={getQueryParams}
        />
      </FiltersModal>
      <FiltersModal
        isOpen={isFiltersModalOpen}
        ToggleModalVisibility={ToggleFiltersModalVisibility}
        heading={t("Categories")}
      >
        <CheckBoxesGroup
          name="filter_options"
          valueKey="key"
          Options={filters}
          selectedItems={queryParams?.filter_options}
          getSelectedItems={getQueryParams}
        />
      </FiltersModal>
    </div>
  );
};

const SortFilterModal = ({ getQueryParams, queryParams }) => {
  const { t } = useTranslation();

  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const ToggleSortByModalVisibility = () =>
    setIsSortByModalOpen(!isSortByModalOpen);

  return (
    <>
      <CustomButton
        height="h-10"
        width="w-10"
        borderRadius="4px"
        label={<SortIcon title="sort" />}
        action={ToggleSortByModalVisibility}
      />
      <FiltersModal
        isOpen={isSortByModalOpen}
        ToggleModalVisibility={ToggleSortByModalVisibility}
        heading={t("Sort By")}
      >
        <SortFiltersGroup
          getQueryParams={getQueryParams}
          queryParams={queryParams}
          containerClasses="flex flex-col w-full"
        />
      </FiltersModal>
    </>
  );
};

const CategoryTemplate = ({
  queryParams,
  getQueryParams,
  categories,
  filters,
  loading,
  loadingLoadMore,
  page,
  hasMore,
  vendors,
  topOffersVendors,
  cartData,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  const [topOffersChunkSize, setTopOffersChunkSize] = useState();
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  useEffect(() => {
    if (width >= 1440) {
      setTopOffersChunkSize(5);
    } else if (width < 1440 && width > 1100) {
      setTopOffersChunkSize(4);
    } else if (width <= 1100 && width >= 1000) {
      setTopOffersChunkSize(3);
    } else if (width < 1000 && width >= 600) {
      setTopOffersChunkSize(2);
    } else {
      setTopOffersChunkSize(1);
    }
  }, [width]);

  const [topOffersList, setTopOffersList] = useState([]);

  useEffect(() => {
    const chunkedArray = chunkArray(topOffersChunkSize, topOffersVendors);
    setTopOffersList(chunkedArray);
  }, [topOffersChunkSize, topOffersVendors]);

  return (
    <Layout>
      <main className={styles.main}>
        <div className={styles.container}>
          {/* TOP OFFERS */}
          <section className={styles.topOffers}>
            <div
              className={`flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center `}
            >
              <Typography
                as="h2"
                size="7xl"
                color="text-text_464646"
                classes={classNames("lg:mb-6", {
                  "invisible ": topOffersList?.length === 0,
                })}
                text={t("Top offers")}
              />

              <div className={`w-full lg:w-max flex flex-col md:mt-[0] `}>
                <div className="flex w-full">
                  <FormInput
                    containerClasses="flex"
                    type="search"
                    name="search_key"
                    borderRadius="4px"
                    bgColor="bg-bg_f5f5f5"
                    textColor="text-warm_grey"
                    height="h-10 lg:h-[58px]"
                    classes="absolute "
                    width="w-11/12 xl:w-[656px] 3xl:w-[820px]"
                    placeholder={t("Find a restaurant or dish …")}
                    value={queryParams.search_key}
                    onValueChange={getQueryParams}
                  />
                  <div className="block lg:hidden">
                    <SortFilterModal
                      getQueryParams={getQueryParams}
                      queryParams={queryParams}
                    />
                  </div>
                </div>
                <div>
                  <FilterModalsButtonsGroup
                    categories={categories}
                    filters={filters}
                    queryParams={queryParams}
                    getQueryParams={getQueryParams}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* TOP OFFERS */}
        <section className="lg:mb-4 3xl:px-8">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <CustomSlider ArrowsPosition={width < 600 ? "bottom" : "baseline"}>
              {topOffersList?.map((topOffersArray, idx) => (
                <div
                  key={idx}
                  className={classNames(
                    "flex justify-around lg:justify-between w-[98%] lg:w-[18%] mx-auto py-1",
                    {
                      "lg:justify-start":
                        topOffersList?.length < topOffersChunkSize,
                    }
                  )}
                >
                  {topOffersArray?.map((topOffersVendor) => (
                    <NavLink
                      key={topOffersVendor?.id}
                      to={`/restaurant/${topOffersVendor?.id}`}
                      className="w-[100%] md:w-fit lg:w-full p-5"
                    >
                      <RestaurantCard
                        vendor={topOffersVendor}
                        imgUrl={
                          topOffersVendor.logo
                            ? topOffersVendor.logo
                            : ImagesSrc.VendorCardPlaceholder3x
                        }
                        restaurantName={topOffersVendor?.display_name}
                        rating={topOffersVendor?.rating}
                        description={topOffersVendor?.description}
                        deliveryFees={
                          topOffersVendor?.delivery_details?.delivery_fee
                        }
                        deliveryDuration={
                          topOffersVendor?.delivery_details?.delivery_time
                        }
                        cardClasses={classNames(styles.categoryCard, {
                          "lg:mx-4 2xl:mx-14 ":
                            topOffersArray?.length < topOffersChunkSize,
                        })}
                        available={topOffersVendor?.is_available}
                        cartData={cartData}
                      />
                    </NavLink>
                  ))}
                </div>
              ))}
            </CustomSlider>
          )}
        </section>

        <div className={styles.container}>
          {/* FILTERS & SORT */}
          <section>
            <SplitScreen
              rightPaneClasses="w-full  lg:w-[80%]"
              leftPaneClasses="hidden lg:block lg:w-[17%]"
            >
              <div className="lg:flex lg:flex-col">
                <div className="w-full border-t-[1px] border-b-[1px] border-text_bfbfbf py-6 3xl:py-10">
                  <CollapsibleWidget title={t("Categories")}>
                    <CheckBoxesGroup
                      name="filter_by_main_category_id"
                      Options={categories}
                      selectedItems={queryParams?.filter_by_main_category_id}
                      getSelectedItems={getQueryParams}
                    />
                  </CollapsibleWidget>
                </div>
                <div className="w-full border-t-0 border-b-[1px] border-text_bfbfbf py-6 3xl:py-10">
                  <CollapsibleWidget title={t("Filter options")}>
                    <CheckBoxesGroup
                      name="filter_options"
                      valueKey="key"
                      Options={filters}
                      selectedItems={queryParams?.filter_options}
                      getSelectedItems={getQueryParams}
                    />
                  </CollapsibleWidget>
                </div>
              </div>

              <div>
                <div className={styles.sortFiltersContainer}>
                  <div className={styles.sortFilters}>
                    <Typography
                      as="h3"
                      size="3xl"
                      fontWeight="font-bold"
                      text={t("Sort by :")}
                    />
                  </div>

                  <SortFiltersGroup
                    getQueryParams={getQueryParams}
                    queryParams={queryParams}
                    containerClasses="flex w-[40%]"
                  />
                </div>
                {loading ? (
                  <Spinner loading={loading} />
                ) : (
                  <>
                    <Typography
                      as="h2"
                      size="7xl"
                      color="text-text_464646"
                      classes="lg:hidden"
                      text={t("All Restaurants")}
                    />
                    <InfiniteScroll
                      dataLength={vendors && vendors?.length}
                      scrollThreshold={0.5}
                      next={() => {
                        if (page != 1)
                          dispatch(
                            loadMoreVendorsStart({
                              ...queryParams,
                              page_number: page,
                            })
                          );
                      }}
                      hasMore={hasMore}
                      loader={<Spinner loading={loadingLoadMore} />}
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-8 gap-x-6 mx-auto py-8">
                        {vendors?.map((vendor) => (
                          <NavLink
                            key={vendor?.id}
                            to={`/restaurant/${vendor?.id}`}
                            className="w-[99%]"
                          >
                            <RestaurantCard
                              vendor={vendor}
                              imgUrl={
                                vendor?.logo
                                  ? vendor?.logo
                                  : ImagesSrc.VendorCardPlaceholder3x
                              }
                              cardClasses={"w-full"}
                              restaurantName={vendor?.display_name}
                              rating={vendor?.rating}
                              description={vendor?.description}
                              deliveryFees={
                                vendor?.delivery_details?.delivery_fee
                              }
                              deliveryDuration={
                                vendor?.delivery_details?.delivery_time
                              }
                              available={vendor?.is_available}
                              cartData={cartData}
                            />
                          </NavLink>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </SplitScreen>
          </section>
        </div>
      </main>
    </Layout>
  );
};

CategoryTemplate.propTypes = {
  queryParams: PropTypes.instanceOf(Object),
  getQueryParams: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  filters: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  page: PropTypes.number,
  vendors: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  topOffersVendors: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default CategoryTemplate;
