import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ListFavoriteRestaurantsStart,
  loadMoreFavoriteVendorsStart,
  loadMoreFavoriteVendorsSucess,
} from "Redux/vendors/actions/vendors.actions";

import {
  selectFavoriteRestaurants,
  selectListVendorsLoading,
  selectHasMoreVendors,
  selectListVendorsLoadingLoadMore,
} from "Redux/vendors/selectors/vendors.selectors";

import { getPayload } from "utils";

import { GridListWithSearchTemplate } from "templates";
import {
  selectCartData,
  selectOrderTime,
} from "Redux/Cart/selectors/cart.selectors";

const FavouritesPage = () => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const dispatch = useDispatch();

  const favoriteVendors = useSelector(selectFavoriteRestaurants);
  const loadingLoadMore = useSelector(selectListVendorsLoadingLoadMore);
  const loading = useSelector(selectListVendorsLoading);
  const hasMore = useSelector(selectHasMoreVendors);
  const OrderTime = useSelector(selectOrderTime);
  const cartData = useSelector(selectCartData);

  const [queryParams, setQueryParams] = useState({
    search_key: "",
    page_number: 1,
    page_size: 8,
  });

  const getQueryParams = (e) => getPayload(e, queryParams, setQueryParams);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (loadMoreFavoriteVendorsSucess) setPage((prev) => prev + 1);
  }, [favoriteVendors]);

  useEffect(() => {
    setPage(1);
  }, [queryParams, OrderTime]);

  useEffect(() => {
    if (currentLng) {
      dispatch(ListFavoriteRestaurantsStart(queryParams));
    }
  }, [dispatch, currentLng, queryParams, OrderTime]);

  const loadMoreVendors = () => {
    if (page != 1)
      dispatch(
        loadMoreFavoriteVendorsStart({
          ...queryParams,
          page_number: page,
        })
      );
  };

  return (
    <GridListWithSearchTemplate
      getQueryParams={getQueryParams}
      queryParams={queryParams}
      vendors={favoriteVendors}
      hasMore={hasMore}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
      loadMoreVendors={loadMoreVendors}
      heading="Favorite"
      cartData={cartData}
    />
  );
};

export default FavouritesPage;
