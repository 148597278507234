const getTimerSeconds = (deliveredAt) => {
  const currentTime = new Date();
  const deliveyTime = new Date(deliveredAt);

  const timebetweeDeliverandCurrentTime =
    deliveyTime.getTime() - currentTime.getTime();

  const Seconds_from_deliveyTime_to_currentTime =
    timebetweeDeliverandCurrentTime / 1000;

  return Seconds_from_deliveyTime_to_currentTime;
};

export default getTimerSeconds;
