import { FullScreenBgLayout } from "components/Layouts";
import { LoginForm } from "../AuthComponents";
import React from "react";

const LoginTemplate = () => {
  return (
    <main>
      <FullScreenBgLayout
        backgroundImage="LoginBg"
        glassContainerWidth=" md:w-1/2 lg:w-2/5 xl:w-[30%] "
        glassContainerHight="md:h-max"
        glassContainerStyles="px-6 lg:px-8"
      >
        <LoginForm />
      </FullScreenBgLayout>
    </main>
  );
};

export default LoginTemplate;
