import CartActionTypes from "../types/cart.types";

import { createAction } from "utils";

export const CreateNewCartStart = (payload) =>
  createAction(CartActionTypes.CREATE_CART_START, payload);

export const CreateNewCartSuccess = (payload) =>
  createAction(CartActionTypes.CREATE_CART_SUCCESS, payload);

export const CreateNewCartFail = (error) =>
  createAction(CartActionTypes.CREATE_CART_FAIL, error);

export const ListCartDataStart = () =>
  createAction(CartActionTypes.CART_DATA_START);

export const ListCartDataSuccess = (data) =>
  createAction(CartActionTypes.CART_DATA_SUCCESS, data);

export const ListCartDataFailure = (error) =>
  createAction(CartActionTypes.CART_DATA_FAILURE, error);

export const UpdateCartDataStart = (data) =>
  createAction(CartActionTypes.UPDATE_CART_DATA_START, data);

export const UpdateCartDataSuccess = (data) =>
  createAction(CartActionTypes.UPDATE_CART_DATA_SUCCESS, data);

export const UpdateCartDataFailure = (error) =>
  createAction(CartActionTypes.UPDATE_CART_DATA_FAILURE, error);

export const DeleteCartDataStart = () =>
  createAction(CartActionTypes.DELETE_CART_DATA_START);

export const DeleteCartDataSuccess = (data) =>
  createAction(CartActionTypes.DELETE_CART_DATA_SUCCESS, data);

export const DeleteCartDataFailure = (error) =>
  createAction(CartActionTypes.DELETE_CART_DATA_FAILURE, error);

export const UpdateCartItemStart = (data) =>
  createAction(CartActionTypes.UPDATE_CART_ITEM_START, data);

export const UpdateCartItemSuccess = (data) =>
  createAction(CartActionTypes.UPDATE_CART_ITEM_SUCCESS, data);

export const UpdateCartItemFailure = (error) =>
  createAction(CartActionTypes.UPDATE_CART_ITEM_FAILURE, error);

export const DeleteCartItemStart = (data) =>
  createAction(CartActionTypes.DELETE_CART_ITEM_START, data);

export const DeleteCartItemSuccess = (data) =>
  createAction(CartActionTypes.DELETE_CART_ITEM_SUCCESS, data);

export const DeleteCartItemFailure = (error) =>
  createAction(CartActionTypes.DELETE_CART_ITEM_FAILURE, error);

export const AddCartItemStart = (data) =>
  createAction(CartActionTypes.ADD_CART_ITEM_START, data);

export const AddCartItemSuccess = (data) =>
  createAction(CartActionTypes.ADD_CART_ITEM_SUCCESS, data);

export const AddCartItemFailure = (error) =>
  createAction(CartActionTypes.ADD_CART_ITEM_FAILURE, error);

export const AddCartItemAnotherVendorStart = (data) =>
  createAction(CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_START, data);

export const AddCartItemAnotherVendorSuccess = (data) =>
  createAction(CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_SUCCESS, data);

export const AddCartItemAnotherVendorFailure = (error) =>
  createAction(CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_FAILURE, error);

// export const RemoveCartItem = (data) =>
//   createAction(CartActionTypes.REMOVE_CART_ITEM, data);

// export const ClearCart = () => createAction(CartActionTypes.CLEAR_CART);

export const ApplyPromoCodeStart = (payload) =>
  createAction(CartActionTypes.APPLY_PROMO_CODE_START, payload);

export const ApplyPromoCodeSucccess = (payload) =>
  createAction(CartActionTypes.APPLY_PROMO_CODE_SUCCESS, payload);

export const ApplyPromoCodeFail = (error) =>
  createAction(CartActionTypes.APPLY_PROMO_CODE_FAILURE, error);

export const RemovePromoCodeStart = () =>
  createAction(CartActionTypes.REMOVE_PROMO_CODE_START);

export const RemovePromoCodeSuccess = (payload) =>
  createAction(CartActionTypes.REMOVE_PROMO_CODE_SUCCESS, payload);

export const RemovePromoCodeFail = (error) =>
  createAction(CartActionTypes.REMOVE_PROMO_CODE_FAIL, error);

export const UpdateOrderTime = (payload) =>
  createAction(CartActionTypes.UPDATE_ORDER_TIME, payload);

export const DeliveryTimeRangeStart = () =>
  createAction(CartActionTypes.DELIVERY_TIME_RANGE_START);

export const DeliveryTimeRangeSuccess = (payload) =>
  createAction(CartActionTypes.DELIVERY_TIME_RANGE_SUCCESS, payload);

export const DeliveryTimeRangeFail = (error) =>
  createAction(CartActionTypes.DELIVERY_TIME_RANGE_FAIL, error);
