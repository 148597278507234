import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getPayload, StorageUtils } from "utils";

import {
  changeCord,
  listAreasByCityStart,
  SaveAddressStart,
  UpdateAddressStart,
} from "Redux/addresses/actions/addresses.actions";

import {
  CustomButton,
  Typography,
  FormInput,
  DropdownMenu,
} from "components/atoms";
import { Modal } from "components/molecules";
import { ValidationsWrapper } from "components/Containers";
import DeliveryAddressModal from "../DeliveryAddressModal";
import { CloseIcon } from "utils/helpers/SVGsSrc";
import {
  selectAreas,
  selectCities,
  selectIsAreasLoading,
  selectUserCord,
} from "Redux/addresses/selectors/addresses.selectors";

const AddDeliveryAddressModal = ({
  Open,
  ToggleModal,
  DeliveryAddress,
  edit = false,
  New = false,
  currentUser,
  AddressData,
  isSelected,
  AppearCloseIcon = true,
  Checkout = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const Cities = useSelector(selectCities);
  // const Areas = useSelector(selectAreas);
  // const isAreasLoading = useSelector(selectIsAreasLoading);

  // const [selectedCity, setSelectedCity] = useState(
  //   edit
  //     ? Cities?.find((item) => item.id == AddressData.area?.city_id)
  //     : Checkout
  //     ? JSON.parse(
  //         StorageUtils.getItems(sessionStorage, ["selectedCity"])[
  //           "selectedCity"
  //         ]
  //       ) ?? ""
  //     : ""
  // );
  // const [selectedArea, setSelectedArea] = useState(
  //   edit
  //     ? ""
  //     : Checkout
  //     ? JSON.parse(
  //         StorageUtils.getItems(sessionStorage, ["selectedArea"])[
  //           "selectedArea"
  //         ]
  //       ) ?? ""
  //     : ""
  // );

  const detectUserCoords = () => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          const distance_cords = {
            lat: latitude,
            lng: longitude,
          };
          dispatch(changeCord(distance_cords));
          StorageUtils.saveItems(sessionStorage, [
            { name: "distance_cords", data: JSON.stringify(distance_cords) },
          ]);
          // setUserCoords(distance_cords);
        }
      );
    }
  };

  useEffect(() => {
    if (
      !JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    ) {
      detectUserCoords();
    }
  }, []);

  // useEffect(() => {
  //   if (edit && Open) {
  //     dispatch(listAreasByCityStart(NewAddress?.city_id));
  //   }
  // }, [Open]);

  //init state
  const [NewAddress, setNewAddress] = useState({
    name: edit ? AddressData.name : "",
    // area_id: edit ? "" : selectedArea?.id ?? "",
    // city_id: edit ? "" : selectedCity?.id ?? "",
    // area_id: "",
    // city_id: "",
    // area: edit
    //   ? AddressData.area?.name
    //   : New
    //   ? ""
    //   : DeliveryAddress?.address_components?.at(1)?.long_name,
    // city: edit
    //   ? Cities?.find((item) => item.id == AddressData.area?.city_id)
    //   : selectedCity?.id ?? "",
    // area: edit ? AddressData?.area : "",
    street_name: edit
      ? AddressData.street_name
      : New
      ? ""
      : DeliveryAddress?.address_components?.at(0)?.long_name,
    building: edit ? AddressData.building : "",
    floor_number: edit ? AddressData.floor_number : "",
    apartment_number: edit ? AddressData.apartment_number : "",
    landmark: edit ? AddressData.landmark : "",
    mobile: currentUser?.mobile_number,
    lat: edit
      ? AddressData.lat
      : New
      ? JSON.parse(
          StorageUtils.getItems(sessionStorage, ["distance_cords"])[
            "distance_cords"
          ]
        )?.lat
      : DeliveryAddress?.lat,
    lng: edit
      ? AddressData.lng
      : New
      ? JSON.parse(
          StorageUtils.getItems(sessionStorage, ["distance_cords"])[
            "distance_cords"
          ]
        )?.lng
      : DeliveryAddress?.lng,
    is_selected: edit && !isSelected ? false : true,
  });

  // useEffect(() => {
  //   if (New && Checkout) {
  //     if (Cities?.at(0))
  //       if (!selectedCity) {
  //         setSelectedCity(Cities?.at(0));
  //         dispatch(listAreasByCityStart(Cities?.at(0)?.id));
  //       }
  //   }
  // }, [Cities]);

  // useEffect(() => {
  //   if (New && Checkout)
  //     if (Areas) {
  //       if (!selectedArea) {
  //         setSelectedArea(Areas?.at(0));
  //       }
  //     }
  // }, [Areas]);

  // useEffect(() => {
  //   if (New && Checkout) {
  //     if (NewAddress?.area_id != selectedArea?.id) {
  //       getPayload(
  //         {
  //           target: {
  //             name: "area_id",
  //             value: selectedArea?.id,
  //           },
  //         },
  //         NewAddress,
  //         setNewAddress
  //       );
  //     }
  //     if (NewAddress?.city_id != selectedCity?.id) {
  //       getPayload(
  //         {
  //           target: {
  //             name: "city_id",
  //             value: selectedCity?.id,
  //           },
  //         },
  //         NewAddress,
  //         setNewAddress
  //       );
  //     }
  //   }
  // }, [NewAddress?.city_id, NewAddress?.area_id, selectedCity, selectedArea]);

  // useEffect(() => {
  //   if (edit && Open) {
  //     if (!selectedArea)
  //       setSelectedArea(Areas?.find((item) => item.id == AddressData.area?.id));
  //   }
  //   if (selectedArea) {
  //     getPayload(
  //       {
  //         target: {
  //           name: "area_id",
  //           value: selectedArea?.id,
  //         },
  //       },
  //       NewAddress,
  //       setNewAddress
  //     );
  //     if (!Areas?.find((item) => item.id == selectedArea?.id)) {
  //       setSelectedArea(Areas?.at(0));
  //     }
  //   }
  // }, [Areas, selectedArea]);

  // useEffect(() => {
  //   if (selectedCity) {
  //     getPayload(
  //       {
  //         target: {
  //           name: "city",
  //           value: selectedCity,
  //         },
  //       },
  //       NewAddress,
  //       setNewAddress
  //     );
  //   }
  // }, [Cities, selectedCity]);

  // useEffect(() => {
  //   if (selectedCity) {
  //     getPayload(
  //       {
  //         target: {
  //           name: "city_id",
  //           value: selectedCity?.id,
  //         },
  //       },
  //       NewAddress,
  //       setNewAddress
  //     );
  //   }
  // }, [Cities, selectedCity]);

  // Add new address & Update an address & check API
  const SaveAddressHandler = () => dispatch(SaveAddressStart(NewAddress));
  const SaveAddressHandleClick = async (event) => {
    event.preventDefault();
    SaveAddressHandler();
  };

  const UpdateAddressHandler = () =>
    dispatch(
      UpdateAddressStart({
        ...NewAddress,
        id: AddressData?.id,
      })
    );
  const UpdateAddressHandleClick = async (event) => {
    event.preventDefault();
    UpdateAddressHandler();
    ToggleModal(false);
  };

  useEffect(() => {
    if (DeliveryAddress?.isUserSavedAddress) {
      ToggleModal(false);
      setNewAddress({
        name: "",
        // area_id: "",
        // city_id: "",
        // area: "",
        // city: "",
        // area: "",
        street_name: "",
        building: "",
        floor_number: "",
        apartment_number: "",
        landmark: "",
        mobile: currentUser?.mobile_number,
        lat: "",
        lng: "",
        is_selected: edit && !isSelected ? false : true,
      });
      // setSelectedArea("");
      // setSelectedCity("");
    }
  }, [DeliveryAddress]);

  // Map state
  const [IsMap, setIsMap] = useState(
    New ? true : (NewAddress?.lat && NewAddress?.lng) || edit ? false : true
  );

  const [center, setCenter] = useState(
    NewAddress?.lat && NewAddress?.lng
      ? {
          lat: parseFloat(NewAddress?.lat),
          lng: parseFloat(NewAddress?.lng),
        }
      : {
          lat: 30.04442,
          lng: 31.23576,
        }
  );

  return (
    <Modal isOpen={Open} ToggleModalVisibility={() => ToggleModal(true)}>
      <ValidationsWrapper
        className={"w-full"}
        initialValues={{
          address_name: NewAddress.name,
          // area_id: NewAddress.area_id,
          // city_id: NewAddress.city_id,
          // area: NewAddress.area,
          // city: NewAddress.city,
          // area: NewAddress.area,
          street_name: NewAddress.street_name,
          building: NewAddress.building,
          floor_number: NewAddress.floor_number,
          apartment_number: NewAddress.apartment_number,
          landmark: NewAddress.landmark,
          mobile: NewAddress.mobile,
          lat: NewAddress.lat,
          lng: NewAddress.lng,
        }}
        rules={[
          "address_name",
          "street_name",
          "building",
          "floor_number",
          "apartment_number",
          "lat",
          // "area_id",
          // "city_id",
        ]}
      >
        {({ FormikHandler }) => {
          return (
            <div className="bg-white w-full max-w-[800px] p-6 mx-auto">
              {/* {AppearCloseIcon && ( */}
              <div className="flex justify-end rtl:justify-start cursor-pointer">
                <CloseIcon
                  title="close"
                  onClick={() => {
                    ToggleModal(false);
                    if (New)
                      setNewAddress({
                        name: "",
                        // area_id: "",
                        // city_id: "",
                        // area: "",
                        street_name: "",
                        building: "",
                        floor_number: "",
                        apartment_number: "",
                        landmark: "",
                        mobile: currentUser?.mobile_number,
                        lat: "",
                        lng: "",
                        is_selected: edit && !isSelected ? false : true,
                      });
                    // setSelectedArea("");
                    // setSelectedCity("");
                  }}
                />
              </div>
              {/* )} */}
              <Typography
                as="h1"
                size="2xl"
                lineHeight="leading-none"
                fontWeight="font-bold"
                color="text-text_3b3b3b"
                classes={"pb-6"}
                text={edit ? t("Edit Address") : t("Add Address")}
              />
              <div className="flex flex-col w-full items-start">
                <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("Address Name")}
                />
                <FormInput
                  id={"name"}
                  key={"name"}
                  value={FormikHandler.values.address_name}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses="AddressInput"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("address_name", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "name",
                          value: e.target.value,
                        },
                      },
                      NewAddress,
                      setNewAddress
                    );
                  }}
                  error={FormikHandler.errors.address_name}
                />
                {/* <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("City")}
                />
                <div style={{ width: "100%", position: "relative" }}>
                  <DropdownMenu
                    options={Cities}
                    ReviewSort={selectedCity}
                    setReviewSort={(city) => {
                      setSelectedCity(city);
                      FormikHandler.setFieldValue("city_id", city?.id);
                      dispatch(listAreasByCityStart(city?.id));
                    }}
                    fullWidth
                    Address
                    placeHolder={"select your city"}
                  />
                  {FormikHandler.errors.city_id && (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <small
                        className={`text-fail text-xs mt-1 ml-1 text-start`}
                      >
                        {FormikHandler.errors.city_id}
                      </small>
                    </div>
                  )}
                </div>
                <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("Area")}
                />
                <div style={{ width: "100%", position: "relative" }}>
                  <DropdownMenu
                    options={Areas}
                    ReviewSort={selectedArea}
                    setReviewSort={(area) => {
                      setSelectedArea(area);
                      FormikHandler.setFieldValue("area_id", area?.id);
                    }}
                    loading={isAreasLoading}
                    fullWidth
                    Address
                    placeHolder={"select your area"}
                  />
                  {FormikHandler.errors.area_id && (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <small
                        className={`text-fail text-xs mt-1 ml-1 text-start`}
                      >
                        {FormikHandler.errors.area_id}
                      </small>
                    </div>
                  )}
                </div> */}
                {/* <FormInput
                  id={"area"}
                  key={"area"}
                  value={NewAddress.area}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses="AddressInput"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("area", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "area",
                          value: e.target.value,
                        },
                      },
                      NewAddress,
                      setNewAddress
                    );
                  }}
                  error={FormikHandler.errors.area}
                /> */}
                <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("Street Name")}
                />
                <FormInput
                  id={"street_name"}
                  key={"street_name"}
                  value={NewAddress.street_name}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses="AddressInput"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("street_name", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "street_name",
                          value: e.target.value,
                        },
                      },
                      NewAddress,
                      setNewAddress
                    );
                  }}
                  error={FormikHandler.errors.street_name}
                />
                <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("Building name / number")}
                />
                <FormInput
                  id={"building"}
                  key={"building"}
                  value={FormikHandler.values.building}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses="AddressInput"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("building", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "building",
                          value: e.target.value,
                        },
                      },
                      NewAddress,
                      setNewAddress
                    );
                  }}
                  error={FormikHandler.errors.building}
                />
                <div className="w-full flex justify-between">
                  <div className="w-[48%] flex flex-col items-start">
                    <Typography
                      as="h1"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4"
                      text={t("Floor Number")}
                    />
                    <FormInput
                      id={"floor_number"}
                      key={"floor_number"}
                      value={FormikHandler.values.floor_number}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "floor_number",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "floor_number",
                              value: e.target.value,
                            },
                          },
                          NewAddress,
                          setNewAddress
                        );
                      }}
                      error={FormikHandler.errors.floor_number}
                    />
                  </div>
                  <div className="w-[48%] flex flex-col items-start">
                    <Typography
                      as="h1"
                      size="lg"
                      color="text-warm_grey"
                      classes="pb-2 pt-4"
                      text={t("Apartment Number")}
                    />
                    <FormInput
                      id={"apartment_number"}
                      key={"apartment_number"}
                      value={FormikHandler.values.apartment_number}
                      type="text"
                      bgColor="bg-bg_white"
                      borderColor="border-bg_e8e8e8"
                      containerClasses="AddressInput"
                      width="w-full"
                      inputTypeClass={"AddressInputColor"}
                      height="h-[35px]"
                      onValueChange={(e) => {
                        FormikHandler.setFieldValue(
                          "apartment_number",
                          e.target.value
                        );
                        getPayload(
                          {
                            target: {
                              name: "apartment_number",
                              value: e.target.value,
                            },
                          },
                          NewAddress,
                          setNewAddress
                        );
                      }}
                      error={FormikHandler.errors.apartment_number}
                    />
                  </div>
                </div>
                <Typography
                  as="h1"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4"
                  text={t("Land mark / Additional notes")}
                />
                <FormInput
                  id={"landmark"}
                  key={"landmark"}
                  value={FormikHandler.values.landmark}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("landmark", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "landmark",
                          value: e.target.value,
                        },
                      },
                      NewAddress,
                      setNewAddress
                    );
                  }}
                />
                <div className="flex w-full justify-between">
                  <div className="w-[48%] flex flex-col">
                    <CustomButton
                      width="w-full"
                      height="h-[46px]"
                      borderRadius="8px"
                      buttonTypeClass="outlined"
                      borderColor="border-primary-900"
                      textColor="text-primary-900"
                      classes={"mt-4"}
                      action={() => {
                        setIsMap(!IsMap);
                      }}
                      label={t("Refine Location")}
                    />
                    <small className="text-fail text-xs mt-2 ml-1 w-fit">
                      {FormikHandler.errors.lat}
                    </small>
                  </div>
                  <CustomButton
                    width="w-[48%]"
                    height="h-[46px]"
                    borderRadius="8px"
                    classes={"mt-4"}
                    action={(e) => {
                      edit
                        ? UpdateAddressHandleClick(e)
                        : SaveAddressHandleClick(e);
                    }}
                    label={t("Save Address")}
                    disabled={!FormikHandler.isValid}
                  />
                </div>
              </div>
              <DeliveryAddressModal
                isOpen={IsMap}
                ToggleModalVisibility={() => setIsMap(!IsMap)}
                selectedCenter={center}
                handleSelectedAddress={(selectedAddress) => {
                  // Just for validation
                  FormikHandler.setFieldValue(
                    "lat",
                    selectedAddress?.geometry?.location?.lat() ?? center?.lat
                  );
                  FormikHandler.setFieldValue(
                    "lng",
                    selectedAddress?.geometry?.location?.lng() ?? center?.lng
                  );
                  FormikHandler.setFieldValue(
                    "area",
                    selectedAddress?.address_components?.find(
                      (item) => item.types[0] === "administrative_area_level_2"
                    )?.long_name
                  );
                  FormikHandler.setFieldValue(
                    "street_name",
                    selectedAddress?.address_components?.find(
                      (item) => item.types[0] === "route"
                    )?.long_name
                  );
                  setNewAddress({
                    ...NewAddress,
                    area: selectedAddress?.address_components?.find(
                      (item) => item.types[0] === "administrative_area_level_2"
                    )?.long_name,
                    street_name: selectedAddress?.address_components?.find(
                      (item) => item.types[0] === "route"
                    )?.long_name,
                    lat:
                      selectedAddress?.geometry?.location?.lat() ?? center?.lat,
                    lng:
                      selectedAddress?.geometry?.location?.lng() ?? center?.lng,
                  });
                }}
                zoomLevel={17}
                zIndex={"z-40"}
              />
            </div>
          );
        }}
      </ValidationsWrapper>
    </Modal>
  );
};

AddDeliveryAddressModal.propTypes = {
  Open: PropTypes.bool,
  ToggleModal: PropTypes.func,
  DeliveryAddress: PropTypes.object,
  edit: PropTypes.bool,
  New: PropTypes.bool,
  currentUser: PropTypes.object,
  AddressData: PropTypes.object,
  isSelected: PropTypes.bool,
};

export default AddDeliveryAddressModal;
