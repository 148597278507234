import ordersActionsTypes from "../types/orders.types";

const INITIAL_STATE = {
  orders: null,
  order: null,
  isOrderCancelled: false,
  error: null,
  loading: false,
  total_pages: 0,
  hasMore: true,
  paymentInfo: null,
  reordered: false,
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ordersActionsTypes.GET_PAYMENT_START:
      return {
        ...state,
        loading: true,
      };
    case ordersActionsTypes.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentInfo: action.payload,
      };
    case ordersActionsTypes.GET_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ordersActionsTypes.LIST_ORDERS_START:
    case ordersActionsTypes.CANCEL_ORDER_START:
      return {
        ...state,
        loading: true,
      };
    case ordersActionsTypes.LIST_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case ordersActionsTypes.LOAD_MORE_ORDERS_START:
      return {
        ...state,
        loading: true,
        hasMore: true,
      };
    case ordersActionsTypes.LOAD_MORE_ORDERS_SUCCESS:
      const newOrdersList = action.payload.orders;
      const { orders } = state;
      return {
        orders: [...orders, ...newOrdersList],
        total_pages: action.payload.total_pages,
        loading: false,
        hasMore:
          action.payload.currentPage < action.payload.total_pages + 1
            ? true
            : false,
      };
    case ordersActionsTypes.CHECKOUT_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders
          ? [...state.orders, action.payload]
          : [action.payload],
        order: action.payload,
      };
    case ordersActionsTypes.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        isOrderCancelled: true,
        loading: false,
      };
    case ordersActionsTypes.GET_ORDER_START:
      return {
        ...state,
        loading: true,
      };
    case ordersActionsTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        isOrderCancelled: action.payload.status[4] ? true : false,
        reordered: false,
      };
    case ordersActionsTypes.CANCEL_ORDER_FAILURE:
      return { ...state, isOrderCancelled: false };
    case ordersActionsTypes.LIST_ORDERS_FAILURE:
    case ordersActionsTypes.CHECKOUT_ORDER_FAILURE:
    case ordersActionsTypes.GET_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        reordered: false,
      };
    case ordersActionsTypes.ADD_RATE_VENDOR_SUCCESS:
      return {
        ...state,
        order: { ...state.order, order_rating: action.payload.rating },
      };
    case ordersActionsTypes.REORDER_SUCCESS:
      return {
        ...state,
        reordered: true,
      };
    case ordersActionsTypes.UPDATE_STATUS_SUCCESS:
      window.location.reload();
    default:
      return state;
  }
};

export default ordersReducer;
