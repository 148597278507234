const secondsToTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));

  const hours = Math.floor((seconds % (3600 * 24)) / 3600);

  const minutes = Math.floor((seconds % 3600) / 60);

  const timeobj = {
    days,
    hours,
    minutes,
  };

  return timeobj;
};

export default secondsToTime;
