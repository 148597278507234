import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PropTypes } from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { reorderStart } from "Redux/orders/actions/orders.actions";

import { CustomButton } from "components/atoms";
import { OrderDetails } from "components/molecules";

import styles from "./OrderCard.module.css";

const OrderCard = ({
  status,
  restaurantId,
  restaurantName,
  RestaurantLogo,
  orderDate,
  orderId,
  orderHash,
  isReviewed,
  action,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        styles.container,
        { "h-[140px]": status !== "completed" },
        {
          "h-48": status === "completed",
        }
      )}
    >
      <div
        // onClick={() => {
        //   if (status !== "in_progress") navigate(`/Order_details/${orderId}`);
        //   else {
        //     action();
        //   }
        // }}
      >
        <OrderDetails
          restaurantId={restaurantId}
          restaurantName={restaurantName}
          orderDate={orderDate}
          orderId={orderId}
          orderHash={orderHash}
          RestaurantLogo={RestaurantLogo}
          onClickDetails={() => {
            if (status !== "in_progress") navigate(`/Order_details/${orderId}`);
            else {
              action();
            }
          }}
        />
      </div>
      {status === "completed" && (
        <div className={styles.buttonsContainer}>
          <CustomButton
            width="w-[155px] 3xl:w-[12.5rem] "
            height="h-10 3xl:h-12"
            borderRadius="4px"
            boxShadow="0 6px 20px 0 rgba(232, 232, 232, 0.5)"
            backdropFilter="blur(30)"
            label={t("Re-order")}
            classes={"mr-4 rtl:ml-4"}
            action={() => dispatch(reorderStart(orderId))}
          />
          <CustomButton
            width="w-[155px] 3xl:w-[12.5rem]"
            height="h-10 3xl:h-12"
            borderRadius="4px"
            buttonTypeClass="outlined"
            borderColor="border-primary-900"
            textColor="text-primary-900"
            boxShadow="0 6px 20px 0 rgba(232, 232, 232, 0.5)"
            backdropFilter="blur(30)"
            label={t("Rate Order")}
            disabled={isReviewed}
            action={() => navigate(`/Order_rate/${orderId}`)}
          />
        </div>
      )}
    </div>
  );
};

OrderCard.propTypes = {
  status: PropTypes.string,
  restaurantName: PropTypes.string,
  orderDate: PropTypes.string,
  orderId: PropTypes.number,
  isReviewed: PropTypes.bool,
  action: PropTypes.func,
};

export default OrderCard;
