import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "utils/helpers/SVGsSrc";
import { getPayload } from "utils";

import {
  changePasswordStart,
  changePasswordInformed,
  deleteUserProfileStart,
} from "Redux/user/actions/user.actions";
import { selectIsPasswordChanged } from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography } from "components/atoms";
import { Modal } from "components/molecules";
import { useNavigate } from "react-router";

const ChangePasswordModal = ({ isOpen, ToggleModalVisibility }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const DeleteProfile = () => dispatch(deleteUserProfileStart());
  const DeleteProfileHandler = () => {
    navigate("/");
    DeleteProfile();
  };

  return (
    <Modal isOpen={isOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white w-[600px] 2xl:w-[750px] p-4 3xl:p-10 ">
        <div
          onClick={ToggleModalVisibility}
          className="flex justify-end rtl:justify-start cursor-pointer"
        >
          <CloseIcon title="close" />
        </div>
        <Typography
          as="h1"
          size="lg"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"py-6 text-left rtl:text-right"}
          text={t("Are you sure you want to delete your account ?")}
        />
        <div className="w-full flex justify-end">
          <CustomButton
            height="h-9 3xl:h-12"
            buttonTypeClass="outlined"
            borderColor="border-transparent"
            textColor="text-primary-900"
            classes="font-bold py-1 px-2"
            label={t("Yes")}
            labelContainerClassName="!justify-start"
            action={() => DeleteProfileHandler()}
          />
          <CustomButton
            height="h-9 3xl:h-12"
            buttonTypeClass="outlined"
            borderColor="border-transparent"
            textColor="text-primary-900"
            classes="font-bold py-1 px-2"
            label={t("No")}
            labelContainerClassName="!justify-start"
            action={() => ToggleModalVisibility()}
          />
        </div>
      </div>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
};

export default ChangePasswordModal;
