import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { secondsToTime } from "utils";

import { useCountDown } from "Hooks";

import { ProgressBar, Typography } from "components/atoms";

const OrderTimeTracker = ({
  orderStatus,
  restaurantName,
  timerSeconds,
  progress,
  setProgress,
}) => {
  const { t } = useTranslation();

  // const { timer, handleCountDown, setTimer } = useCountDown(timerSeconds);

  const [timerFormat, setTimerFormat] = useState({
    days: "__",
    hours: "__",
    minutes: "__",
  });
  const [timer, setTimer] = useState(timerSeconds);
  useEffect(() => {
    setTimer(timerSeconds);
  }, [timerSeconds]);

  let countdown = null;
  const handleCountDown = useCallback(() => {
    let counter = timerSeconds;
    clearInterval(countdown);

    countdown = setInterval(() => {
      setTimer(--counter);
    }, 1000);

    // setTimeout(() => {
    //   clearInterval(countdown);
    //   setTimer(0);
    // }, timerSeconds * 1000);
  }, [timerSeconds]);
  useEffect(() => {
    if (timer > 0) {
      handleCountDown();
    }
  }, [handleCountDown]);

  useEffect(() => {
    const { days, hours, minutes } = secondsToTime(timer);
    if ((days === 0 && hours === 0 && minutes === 0) || timer < 0) {
      setTimerFormat({ days: 0, hours: 0, minutes: 5 });
      setProgress(100);
    } else {
      setTimerFormat({ days, hours, minutes });
      setProgress(((timerSeconds - timer) * 100) / timerSeconds);
    }
  }, [timer, setTimerFormat, setProgress, timerSeconds]);

  return (
    <div>
      <div className="relative">
        <ProgressBar
          progress={orderStatus == "pending" ? 0 : progress}
          description={
            orderStatus == "pending"
              ? `${t("Waiting for")} ${restaurantName} ${t("approval")}`
              : ` ${restaurantName} ${t("is cooking your food now")}`
          }
        />
      </div>

      <div className="py-6 2xl:py-10 ">
        <Typography
          as="p"
          size="2xl"
          lineHeight="leading-[1.14]"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          text={
            orderStatus == "pending"
              ? ""
              : `${t("Will be handed over for delivery within")} ${
                  timerFormat.days ? `${timerFormat.days} ${t("days")} ` : ""
                } ${
                  timerFormat.hours
                    ? `  ${timerFormat.hours} ${t("hours")} `
                    : ""
                } ${
                  timerFormat.minutes
                    ? `  ${timerFormat.minutes} ${t("minutes")} `
                    : ""
                }  `
          }
        />
      </div>
    </div>
  );
};

export default OrderTimeTracker;
