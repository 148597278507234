import LoginBg from "assets/Images/Login/LoginBg.png";

import SignUpBg from "assets/Images/SignUp/SignUpBg.png";

import VerifyMobileBg from "assets/Images/VerifyMobileNumber/VerifyMobileBg.png";

import PrivacyPolicyBg from "assets/Images/PrivacyPolicy/PrivacyPolicyBg.png";

import HomeHeader from "assets/Images/HomeHeader/HomeHeader.webp";
import HomeHeader2x from "assets/Images/HomeHeader/HomeHeader@2x.webp";
import HomeHeader3x from "assets/Images/HomeHeader/HomeHeader@3x.webp";

import HomeHeaderMobile from "assets/Images/HomeHeader/background@3x.webp";

import MobileApp from "assets/Images/mobileApp/mobileApp.webp";
import MobileApp2x from "assets/Images/mobileApp/mobileApp@2x.webp";
import MobileApp3x from "assets/Images/mobileApp/mobileApp@3x.webp";

import AppStore from "assets/Images/AppStore/appStore.png";

import GooglePlay from "assets/Images/GooglePlay/googlePlay.png";

import ConfirmOrder from "assets/Images/ConfirmOrder/confirm.webp";

import FailedPayment from "assets/Images/FailedPayment/FailedPayment.webp";

import RouteBetweenMap from "assets/Images/routeBetweenMap/map_mob.png";
import RouteBetweenMap3x from "assets/Images/routeBetweenMap/map.png";

import FAQLogo from "assets/Images/FAQ/faq.webp";
import FAQLogo2x from "assets/Images/FAQ/faq@2x.webp";
import FAQLogo3x from "assets/Images/FAQ/faq@3x.webp";

import ChatLogo from "assets/Images/Chat/chat.webp";
import ChatLogo2x from "assets/Images/Chat/chat@2x.webp";
import ChatLogo3x from "assets/Images/Chat/chat@3x.webp";

/*** PLACEHOLDERS ***/

import VendorPlaceHolder from "assets/Images/VendorPlaceHolder/logo.webp";
import VendorPlaceHolder2x from "assets/Images/VendorPlaceHolder/logo@2x.webp";
import VendorPlaceHolder3x from "assets/Images/VendorPlaceHolder/logo@3x.webp";

import MenuCardPlaceHolder from "assets/Images/MenuCardPlaceHolder/logo.webp";
import MenuCardPlaceHolder2x from "assets/Images/MenuCardPlaceHolder/logo@2x.webp";
import MenuCardPlaceHolder3x from "assets/Images/MenuCardPlaceHolder/logo@3x.webp";

import ItemPlaceHolder from "assets/Images/ItemPlaceHolder/item.webp";
import ItemPlaceHolder2x from "assets/Images/ItemPlaceHolder/item@2x.webp";
import ItemPlaceHolder3x from "assets/Images/ItemPlaceHolder/item@3x.webp";

import VendorCardPlaceholder from "assets/Images/VendorCardPlaceholder/logo.webp";
import VendorCardPlaceholder2x from "assets/Images/VendorCardPlaceholder/logo@2x.webp";
import VendorCardPlaceholder3x from "assets/Images/VendorCardPlaceholder/logo@3x.webp";

import NowLater from "assets/Images/NowLater/NowLater.png";

import InovaLogo from "assets/Images/Inova/inova.png";

/*** HARD CODED DATA ***/

import Offer from "assets/HardCodded/offer@3x.png";

import Cookdoor from "assets/HardCodded/cookdoor@3x.png";

import RestaurantLogo from "assets/HardCodded/RestaurantLogo@3x.png";

import OfferExample from "assets/HardCodded/OfferExample@3x.png";

import Pizza from "assets/HardCodded/pizza@3x.png";

import Burger from "assets/HardCodded/Burger@3x.webp";

const ImagesSrc = {
  LoginBg,
  SignUpBg,
  VerifyMobileBg,
  PrivacyPolicyBg,
  HomeHeader,
  HomeHeader2x,
  HomeHeader3x,
  HomeHeaderMobile,
  MobileApp,
  MobileApp2x,
  MobileApp3x,
  AppStore,
  GooglePlay,
  ConfirmOrder,
  FailedPayment,
  RouteBetweenMap,
  RouteBetweenMap3x,
  FAQLogo,
  FAQLogo2x,
  FAQLogo3x,
  ChatLogo,
  ChatLogo2x,
  ChatLogo3x,
  NowLater,

  // PLACEHOLDERS
  VendorPlaceHolder,
  VendorPlaceHolder2x,
  VendorPlaceHolder3x,
  MenuCardPlaceHolder,
  MenuCardPlaceHolder2x,
  MenuCardPlaceHolder3x,
  ItemPlaceHolder,
  ItemPlaceHolder2x,
  ItemPlaceHolder3x,
  VendorCardPlaceholder,
  VendorCardPlaceholder2x,
  VendorCardPlaceholder3x,

  // HARD CODED DATA
  Offer,
  Cookdoor,
  RestaurantLogo,
  OfferExample,
  Pizza,
  Burger,
  InovaLogo,
};

export default ImagesSrc;
