import { Typography } from "components/atoms";
import { CustomSlider, RestaurantCard } from "components/molecules";
import React from "react";
import { ImagesSrc } from "utils";
import { useNavigate, NavLink } from "react-router-dom";
import styles from "templates/App/Home/Home.module.css";
import classnames from "classnames";
import { withTranslation } from "react-i18next";

const Favourite = ({
  favoriteRestaurantsList,
  t,
  UserCoords,
  categoriesChunckSize,
  width,
  cartData,
}) => {
  const navigate = useNavigate();

  return (
    <section className={styles.popular}>
      <div className={styles.orderAgainHeader}>
        <Typography
          as="h2"
          size="7xl"
          color="text-text_464646"
          classes="mb-6"
          text={t("Favorite")}
        />
        {/* <a to="/Favourites"> */}
        <Typography
          as="span"
          size="5xl"
          color="text-text_464646"
          classes="mb-6 cursor-pointer "
          text={t("View all")}
          action={() => navigate("/Favourites")}
        />
        {/* </NavLink> */}
      </div>

      <CustomSlider ArrowsPosition={width < 600 ? "bottom" : "baseline"}>
        {favoriteRestaurantsList?.map((favoriteRestaurantsArray, idx) => (
          <div
            key={idx}
            className={classnames(
              "flex justify-center md:justify-evenly w-11/12 2xl:w-[96%] mx-auto",
              {
                "lg:justify-start":
                  favoriteRestaurantsArray?.length < categoriesChunckSize,
              }
            )}
          >
            {favoriteRestaurantsArray?.map((vendor) => (
              <NavLink
                key={vendor?.id}
                to={`/restaurant/${vendor?.id}`}
                className="lg:w-[18%] w-[100%] m-2"
              >
                <RestaurantCard
                  imgUrl={
                    vendor?.logo
                      ? vendor?.logo
                      : ImagesSrc.VendorCardPlaceholder3x
                  }
                  cardClasses={"w-full"}
                  restaurantName={vendor?.display_name}
                  rating={vendor?.rating}
                  description={vendor?.description}
                  deliveryFees={vendor?.delivery_details?.delivery_fee}
                  deliveryDuration={vendor?.delivery_details?.delivery_time}
                  available={vendor?.is_available}
                  vendor={vendor}
                  UserCoords={UserCoords}
                  cartData={cartData}
                />
              </NavLink>
            ))}
          </div>
        ))}
      </CustomSlider>
    </section>
  );
};

export default withTranslation()(Favourite);
