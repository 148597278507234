import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import {
  AddRateVendorStart,
  getOrderStart,
} from "Redux/orders/actions/orders.actions";

import { selectCurrentUser } from "Redux/user";
import { selectOrder } from "Redux/orders/selectors/orders.selectors";

import { OrderRateTemplate } from "templates";

const OrderRatePage = () => {
  let id = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // APIs
  const order = useSelector(selectOrder);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (order?.order_rating != null && order.id == id) {
      navigate(`/`);
    }
  }, [order]);

  useEffect(() => {
    dispatch(getOrderStart({ orderId: id }));
  }, [dispatch]);

  // new review object
  const [orderReview, SetOrderReview] = useState({
    id: id,
    VendorRating: 0,
    DeliveryRating: 0,
    body: "",
    title: `${currentUser?.first_name} ${currentUser?.last_name}`,
  });

  // Add review
  const AddVendorRateHandler = () => dispatch(AddRateVendorStart(orderReview));
  const AddVendorRateHandleClick = async (event) => {
    event.preventDefault();
    AddVendorRateHandler();
  };

  return (
    <OrderRateTemplate
      OrderData={order}
      AddVendorRateHandleClick={AddVendorRateHandleClick}
      orderReview={orderReview}
      SetOrderReview={SetOrderReview}
    />
  );
};

export default OrderRatePage;
