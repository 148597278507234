import { useState } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { PropTypes } from "prop-types";

import Typography from "../Typography";

import { DropArrow } from "utils/helpers/SVGsSrc";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner";

const DropdownMenu = ({
  options,
  ReviewSort,
  setReviewSort,
  type,
  fullWidth,
  Address = false,
  loading,
  placeHolder,
  style = "border-[1px] border-text_bfbfbf  px-4 rounded",
}) => {
  const [Opened, setOpened] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLng = i18n?.language;
  return (
    <Listbox value={ReviewSort} onChange={setReviewSort}>
      {loading ? (
        <Spinner loading={loading} classes={"py-0"} />
      ) : (
        <Listbox.Button
          className={`${
            fullWidth
              ? "w-full"
              : type == "nowLater"
              ? "w-[139px] h-[40px]"
              : " max-w-[200px] py-3"
          } ${style}`}
        >
          <span
            className="flex justify-between items-center text-text_343434"
            onClick={() => setOpened(!Opened)}
          >
            <Typography
              as="h2"
              size={type == "nowLater" ? "sm" : "lg"}
              lineHeight="leading-none"
              color="text-text_343434"
              classes={
                type == "nowLater"
                  ? "w-full opacity-60 text-left rtl:text-right"
                  : "opacity-60"
              }
              text={
                Address
                  ? currentLng == "ar"
                    ? ReviewSort?.name_ar ?? placeHolder
                    : ReviewSort?.name_en ?? placeHolder
                  : type == "nowLater"
                  ? ReviewSort?.name ?? t("Delivery time nav")
                  : t(ReviewSort?.name)
              }
            />
            <DropArrow className={`${Opened ? "rotate-180" : ""} w-[14px]`} />
          </span>
        </Listbox.Button>
      )}

      <Transition
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {options && (
          <Listbox.Options
            className={`absolute w-full rounded-b shadow-[0_10px_99px_0_rgb(0,0,0,0.05)] border-[1px] border-bg_e8e8e8 z-[1] max-h-[200px] overflow-auto ${
              fullWidth
                ? "w-full"
                : type == "nowLater"
                ? "top-[100%] ltr:right-0 rtl:left-0 max-w-[139px]"
                : " max-w-[200px]"
            } `}
          >
            {options?.map((person, i) => (
              <>
                {i === 0 ? (
                  ""
                ) : (
                  <hr className="w-full h-[1px] bg-text_bfbfbf" />
                )}
                <Listbox.Option
                  key={person.id}
                  value={person}
                  className={`cursor-pointer flex py-4 px-4 hover:bg-bg_e5e5e5  ${
                    Address
                      ? "bg-white"
                      : ReviewSort?.name == person.name
                      ? "bg-bg_e5e5e5"
                      : "bg-white"
                  }`}
                >
                  <Typography
                    as="h2"
                    size="lg"
                    lineHeight="leading-none"
                    classes={"font-SFProText"}
                    color="text-text_3b3b3b"
                    text={
                      Address
                        ? currentLng == "ar"
                          ? person?.name_ar
                          : person?.name_en
                        : t(person.name)
                    }
                  />
                </Listbox.Option>
              </>
            ))}
          </Listbox.Options>
        )}
      </Transition>
    </Listbox>
  );
};

DropdownMenu.propTypes = {
  options: PropTypes.array,
  description: PropTypes.string,
};

export default DropdownMenu;
