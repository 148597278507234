import {
  LoginPage,
  SignUpPage,
  VerifyMobileNumberPage,
  PrivacyPolicyPage,
  ResetPasswordPage,
  HomePage,
  CategoryPage,
  RestaurantPage,
  PlaceOrderPage,
  OrderTrackingPage,
  SuccessOrderPage,
  OrderRatePage,
  OrdersPage,
  FavouritesPage,
  PopularNearYouPage,
  AddressPage,
  OrderDetailsPage,
  ContactUsPage,
  FAQPage,
  NotificationsPage,
  AboutPage,
  OffersPage,
  VerifyMobileForEditPage,
  TermsOfUsePage,
  FailedPaymentPage,
  SetMobileNumberPage,
  PrivacyPolicyPaymentPage,
} from "pages";
import DeleteAccountPage from "pages/Auth/deleteAccount/DeleteAccount.Page";
import { ProfileTemplate } from "templates";

const routes = [
  {
    name: "login",
    path: "/login",
    exact: true,
    element: <LoginPage />,
  },
  {
    name: "delete-account",
    path: "/delete-account",
    exact: true,
    element: <DeleteAccountPage />,
  },
  {
    name: "signup",
    path: "/SignUp",
    exact: true,
    element: <SignUpPage />,
  },
  {
    name: "mobileVerification",
    path: "/Verify_mobile_number",
    exact: true,
    element: <VerifyMobileNumberPage />,
  },
  {
    name: "SetMobileNumber",
    path: "/set_mobile_number",
    exact: true,
    element: <SetMobileNumberPage />,
  },
  {
    name: "privacy_policy",
    path: "/privacy_policy",
    exact: true,
    element: <PrivacyPolicyPage />,
  },
  {
    name: "privacy_policy",
    path: "/privacy_policy_payment",
    exact: true,
    element: <PrivacyPolicyPaymentPage />,
  },
  {
    name: "terms_of_use",
    path: "/terms_of_use",
    exact: true,
    element: <TermsOfUsePage />,
  },
  {
    name: "Reset_Password",
    path: "/Reset_Password",
    exact: true,
    element: <ResetPasswordPage />,
  },
  {
    name: "Home",
    path: "/",
    exact: true,
    element: <HomePage />,
  },
  {
    name: "Restaurant",
    path: "/restaurant/:id",
    exact: true,
    element: <RestaurantPage />,
  },
  {
    name: "Offers",
    path: "/Offers",
    exact: true,
    element: <OffersPage />,
  },
  {
    name: "AboutUs",
    path: "/About_Us",
    exact: true,
    element: <AboutPage />,
  },
  {
    name: "ContactUs",
    path: "/Contact_Us",
    exact: true,
    element: <ContactUsPage />,
  },
  {
    name: "Categories",
    path: "/Categories",
    exact: true,
    element: <CategoryPage />,
  },
  {
    name: "popularNearYou",
    path: "/Popular_near_you",
    exact: true,
    element: <PopularNearYouPage />,
  },
  {
    name: "Favourites",
    path: "/Favourites",
    exact: true,
    element: <FavouritesPage />,
  },
  {
    name: "PlaceOrder",
    path: "/place_order",
    exact: true,
    element: <PlaceOrderPage />,
  },
  {
    name: "PlaceOrderConfirmation",
    path: "/place_order/success/:order_id/:vendor_name",
    exact: true,
    element: <SuccessOrderPage />,
  },
  {
    name: "PlaceOrderConfirmation",
    path: "/place_order/failed",
    exact: true,
    element: <FailedPaymentPage />,
  },
  {
    name: "Orders",
    path: "/Orders",
    exact: true,
    element: <OrdersPage />,
  },
  {
    name: "Orders",
    path: "/Orders/inProgress/:id",
    exact: true,
    element: <OrdersPage />,
  },
  {
    name: "OrderTracking",
    path: "/Order_tracking",
    exact: true,
    element: <OrderTrackingPage />,
  },
  {
    name: "OrderRate",
    path: "/Order_rate/:id",
    exact: true,
    element: <OrderRatePage />,
  },
  {
    name: "OrderDetails",
    path: "/Order_details/:id",
    exact: true,
    element: <OrderDetailsPage />,
  },
  {
    name: "Addresses",
    path: "/Addresses",
    exact: true,
    element: <AddressPage />,
  },
  {
    name: "AccountInfo",
    path: "/Account_Info",
    exact: true,
    element: <ProfileTemplate />,
  },
  {
    name: "faq",
    path: "/faq",
    exact: true,
    element: <FAQPage />,
  },
  {
    name: "Notifications",
    path: "/Notifications",
    exact: true,
    element: <NotificationsPage />,
  },
  {
    name: "verify_mobile",
    path: "/verify_mobile",
    exact: true,
    element: <VerifyMobileForEditPage />,
  },
];

export default routes;
