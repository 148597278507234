import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import useScroll from "Hooks/useScroll";

import {
  addFavoriteVendorStart,
  getVendorReviewsStart,
  ListRestaurantDataStart,
  removeFavoriteVendorStart,
} from "Redux/vendors/actions/vendors.actions";

import {
  selectIsReviewsLoading,
  selectListVendorsLoading,
  selectRestaurantData,
  selectVendorReviews,
} from "Redux/vendors/selectors/vendors.selectors";
import {
  selectCurrentUser,
  selectUserDeliveryLocation,
} from "Redux/user/selectors/user.selectors";

import { RestaurantTemplate } from "templates";
import {
  selectCartData,
  selectOrderTime,
} from "Redux/Cart/selectors/cart.selectors";
import { UpdateCartDataStart } from "Redux/Cart/actions/cart.actions";

const RestaurantPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const currentLng = i18n?.language;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const choices = [
    { id: 1, name: "Recent" },
    { id: 2, name: "Highest" },
    { id: 3, name: "Lowest" },
  ];

  //APIs
  const currentUser = useSelector(selectCurrentUser);
  const vendorData = useSelector(selectRestaurantData);
  const vendorReviews = useSelector(selectVendorReviews);
  const isReviewsLoading = useSelector(selectIsReviewsLoading);
  const DeliveryAddress = useSelector(selectUserDeliveryLocation);
  const isVendorLoading = useSelector(selectListVendorsLoading);
  const OrderTime = useSelector(selectOrderTime);
  const cartData = useSelector(selectCartData);

  useEffect(() => {
    if (currentLng) {
      dispatch(ListRestaurantDataStart(id));
    }
  }, [dispatch, currentLng, id, currentUser, OrderTime]);

  const [ReviewSort, setReviewSort] = useState(choices[0]);
  useEffect(() => {
    dispatch(getVendorReviewsStart({ id: id, Sort: ReviewSort?.name }));
  }, [dispatch, id, ReviewSort]);

  // Favorite
  const AddFavoriteVendorHandler = () => {
    dispatch(addFavoriteVendorStart(vendorData?.id));
  };

  const RemoveFavoriteVendorHandler = () => {
    dispatch(removeFavoriteVendorStart(vendorData?.id));
  };

  const ToggleFavoriteVendorClick = async (event) => {
    event.preventDefault();
    currentUser
      ? vendorData?.is_favorite
        ? RemoveFavoriteVendorHandler()
        : AddFavoriteVendorHandler()
      : navigate("/login");
  };

  // review modal state
  const [ReviewOpen, setReviewOpen] = useState(false);
  const HandleReviewModal = () => {
    setReviewOpen(!ReviewOpen);
  };
  const [deliveryOption, setDeliveryOption] = useState("delivery");

  useEffect(() => {
    if (vendorData?.is_delivery_available) {
      setDeliveryOption("pickup");
      localStorage.setItem("deliveryOption", "pickup");
    } else {
      setDeliveryOption("delivery");
      localStorage.setItem("deliveryOption", "delivery");
    }
  }, [vendorData]);

  // Section Scrolling
  const SectionsRefs = useScroll(vendorData?.menu_categories, vendorData);
  const handelChangeDeliveryOption = (data) => {
    currentUser &&
      dispatch(
        UpdateCartDataStart({
          cart: {
            is_pickup: data == "pickup" ? true : false,
          },
        })
      );
  };
  return (
    <RestaurantTemplate
      RestaurantData={vendorData}
      SectionsRefs={SectionsRefs}
      isFavorite={vendorData?.is_favorite}
      DefaultAddress={DeliveryAddress}
      ToggleFavoriteVendorClick={ToggleFavoriteVendorClick}
      ReviewOpen={ReviewOpen}
      HandleReviewModal={HandleReviewModal}
      vendorReviews={vendorReviews}
      isReviewsLoading={isReviewsLoading}
      choices={choices}
      ReviewSort={ReviewSort}
      setReviewSort={setReviewSort}
      isVendorLoading={isVendorLoading}
      currentUser={currentUser}
      handelChangeDeliveryOption={handelChangeDeliveryOption}
      cartData={cartData}
      deliveryOption={deliveryOption}
      setDeliveryOption={setDeliveryOption}
    />
  );
};

export default RestaurantPage;
