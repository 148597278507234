const AddressesActionsTypes = {
  LIST_SAVED_ADDRESSES_START: "LIST_SAVED_ADDRESSES_START",
  LIST_SAVED_ADDRESSES_SUCCESS: "LIST_SAVED_ADDRESSES_SUCCESS",
  LIST_SAVED_ADDRESSES_FAILURE: "LIST_SAVED_ADDRESSES_FAILURE",
  LOAD_MORE_ADDRESS_START: "LOAD_MORE_ADDRESS_START",
  LOAD_MORE_ADDRESS_SUCCESS: "LOAD_MORE_ADDRESS_SUCCESS",
  SAVE_ADDRESS_START: "SAVE_ADDRESS_START",
  SAVE_ADDRESS_SUCCESS: "SAVE_ADDRESS_SUCCESS",
  SAVE_ADDRESS_FAILURE: "SAVE_ADDRESS_FAILURE",
  DELETE_ADDRESS_START: "DELETE_ADDRESS_START",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",
  DELETE_ADDRESS_INFORMED: "DELETE_ADDRESS_INFORMED",
  UPDATE_ADDRESS_START: "UPDATE_ADDRESS_START",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
  SET_SELECTED_ADDRESS_START: "SET_SELECTED_ADDRESS_START",
  SET_SELECTED_ADDRESS_SUCCESS: "SET_SELECTED_ADDRESS_SUCCESS",
  SET_SELECTED_ADDRESS_FAILURE: "SET_SELECTED_ADDRESS_FAILURE",
  GET_SELECTED_ADDRESS_START: "GET_SELECTED_ADDRESS_START",
  GET_SELECTED_ADDRESS_SUCCESS: "GET_SELECTED_ADDRESS_SUCCESS",
  GET_SELECTED_ADDRESS_FAILURE: "GET_SELECTED_ADDRESS_FAILURE",
  LIST_CITIES_START: "LIST_CITIES_START",
  LIST_CITIES_SUCCESS: "LIST_CITIES_SUCCESS",
  LIST_CITIES_FAILURE: "LIST_CITIES_FAILURE",
  LIST_AREAS_BY_CITY_START: "LIST_AREAS_BY_CITY_START",
  LIST_AREAS_BY_CITY_SUCCESS: "LIST_AREAS_BY_CITY_SUCCESS",
  LIST_AREAS_BY_CITY_FAILURE: "LIST_AREAS_BY_CITY_FAILURE",
  CHANGE_SELECTED_AREA: "CHANGE_SELECTED_AREA",
  CHANGE_CORD: "CHANGE_CORD",
};

export default AddressesActionsTypes;
