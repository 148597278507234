import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import { ImagesSrc, StorageUtils } from "utils";

import { CustomButton, Img, Typography } from "components/atoms";
import { Footer, NavBar } from "components/molecules";

import styles from "./SuccessOrder.module.css";
import Layout from "components/molecules/Layout";

const SuccessOrderTemplate = ({ order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { order_id, vendor_name } = useParams();

  useEffect(() => {
    StorageUtils.saveItems(sessionStorage, [
      {
        name: "OrderTime",
        data: "",
      },
    ]);
  }, []);

  return (
    <main className={styles.main}>
      <Layout>
        <section className={styles.OrderIntro}>
          <Img
            fileName={ImagesSrc.ConfirmOrder}
            altText="Done"
            picClasses="object-cover"
            containerClasses="w-fit h-[270px] relative"
          />
          <Typography
            as="h1"
            color="text-text_3b3b3b"
            classes="font-bold mt-12 text-[40px] text-center"
            text={t("Order placed Successfully!")}
          />
          <Typography
            as="h1"
            size="2xl"
            color="text-text_3b3b3b"
            classes="font-SFProText mt-2"
            text={
              order && Object.entries(order?.status)[0][1] == "pending"
                ? `${t("Waiting for")} ${
                    location?.state?.vendorName
                      ? location?.state?.vendorName
                      : vendor_name.replace("@", " ")
                      ? vendor_name.replace("@", " ")
                      : "test"
                  } ${t("approval")}`
                : `${
                    location?.state?.vendorName
                      ? location?.state?.vendorName
                      : vendor_name.replace("@", " ")
                      ? vendor_name.replace("@", " ")
                      : "test"
                  } ${t("is cooking your food now")}`
              // order && Object.entries(order?.status)[0][1] == "pending"
              //   ? `Waiting for ${location?.state?.vendorName} approval` :
              // `${location?.state?.vendorName} ${t("is cooking your food now")}`
            }
          />
          <CustomButton
            width="w-full max-w-[200px]"
            height="h-[46px]"
            borderRadius="8px"
            classes={"mt-8"}
            action={() =>
              navigate("/Order_tracking", {
                state: {
                  orderId: location?.state?.orderId
                    ? location?.state?.orderId
                    : order_id
                    ? order_id
                    : 0,
                },
              })
            }
            label={t("Track Order")}
          />
        </section>
      </Layout>
    </main>
  );
};

export default SuccessOrderTemplate;
