import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { reorderStart } from "Redux/orders/actions/orders.actions";

import { CustomButton, Typography } from "components/atoms";

import styles from "./ReOrderCard.module.css";
import { useEffect, useState } from "react";
import { selectCartData } from "Redux/Cart/selectors/cart.selectors";
import { isReordered } from "Redux/orders/selectors/orders.selectors";

const ReOrderCard = ({
  orderId,
  restaurantName,
  quantity,
  productName,
  cardClasses,
  isReviewed,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const CartData = useSelector(selectCartData);
  const isReorder = useSelector(isReordered);

  const [SureReorder, setSureReorder] = useState(false);
  const [ReorderClicked, setReorderClicked] = useState(false);

  const AskFirst = () => {
    setReorderClicked(true);
    if (
      CartData &&
      CartData?.cart_items &&
      CartData?.cart_items?.length !== 0 &&
      CartData?.length !== 0
    ) {
      setSureReorder(
        window.confirm(
          "Do you wish to clear your cart and start a new order here ?"
        )
      );
    } else {
      dispatch(reorderStart(orderId));
    }
  };

  useEffect(() => {
    if (ReorderClicked && isReorder) navigate("/place_order");
  }, [isReorder, ReorderClicked]);

  useEffect(() => {
    if (SureReorder) dispatch(reorderStart(orderId));
  }, [SureReorder]);

  return (
    <div className={classNames(styles.OrderAgainCard, cardClasses)}>
      <Typography
        as="h3"
        size="xl"
        lineHeight="leeading-[0.85]"
        color="text-text_3b3b3b"
        text={restaurantName}
      />
      <div className={styles.OrderAgainCard__orderDetails}>
        <Typography
          as="p"
          size="lg"
          lineHeight="leeading-[1.26]"
          color="text-warm_grey"
          text={`${quantity} x`}
        />
        <Typography
          as="p"
          size="lg"
          lineHeight="leeading-[1.26]"
          color="text-warm_grey"
          classes="w-10/12 2xl:w-[91%] ellipseText line-clamp-2"
          text={productName}
        />
      </div>
      <div className="flex w-full justify-between mt-3 3xl:mt-4">
        <CustomButton
          width="w-36 3xl:w-[12.5rem] "
          height="h-9 3xl:h-12"
          borderRadius="4px"
          boxShadow="0 6px 20px 0 rgba(232, 232, 232, 0.5)"
          backdropFilter="blur(30)"
          action={() => AskFirst()}
          label={t("Re-order")}
        />
        <CustomButton
          width="w-36 3xl:w-[12.5rem]"
          height="h-9 3xl:h-12"
          borderRadius="4px"
          buttonTypeClass="outlined"
          borderColor="border-primary-900"
          textColor="text-primary-900"
          boxShadow="0 6px 20px 0 rgba(232, 232, 232, 0.5)"
          backdropFilter="blur(30)"
          label={t("Rate Order")}
          disabled={isReviewed}
          action={() => navigate(`/Order_rate/${orderId}`)}
        />
      </div>
    </div>
  );
};

export default ReOrderCard;
