import { CustomButton, Typography } from "components/atoms";

import { ButtonArrow } from "utils/helpers/SVGsSrc";
import { PropTypes } from "prop-types";
import classNames from "classnames";

const DescriptionTile = ({
  direction = "row",
  OrderItem = false,
  mobileDirection = "col",
  classes,
  icon,
  title,
  centerComponent,
  price,
  button,
  textValue,
  hasTopBorder = false,
  buttonAction,
  buttonClasses,
  mainTitle = true,
  centerJustify = true,
  changeAddress = false,
  OrderDetails = "",
}) => {
  return (
    <>
      <div
        className={classNames(
          "w-full flex bg-primary-50",
          {
            "flex-row items-start md:items-center": direction === "row",
          },
          { "py-4 justify-start": icon },
          { "p-4 justify-between": !icon },
          { "pb-0 justify-between": OrderDetails != "" },
          { "border-t-[1px] border-bg_e8e8e8": hasTopBorder },
          classes
        )}
      >
        <div
          className={classNames(
            "flex ",
            { "flex-col items-start w-full": direction === "col" },
            {
              "flex-col md:flex-row md:items-center items-start w-full lg:w-3/4 lg:justify-between":
                direction === "row" &&
                mobileDirection === "col" &&
                centerJustify,
            },
            {
              "flex-col md:flex-row md:items-center items-start w-full":
                direction === "row" && mobileDirection === "col",
            },
            {
              "flex-row justify-between items-center w-full":
                direction === "row" && mobileDirection === "row",
            }
          )}
        >
          <div className="flex">
            {icon ? icon : ""}

            {title && (
              <Typography
                as="h1"
                fontWeight="font-bold"
                color="text-text_3b3b3b"
                lineHeight="leading-[1.15]"
                classes={classNames(
                  { "text-[16px]": mainTitle },
                  { "text-[14px] md:text-[16px]": !mainTitle },
                  {
                    "text-[14px] md:text-[16px] w-24 md:w-40 xl:w-60  break-words":
                      !mainTitle && OrderItem,
                  }
                )}
                text={title}
              />
            )}
          </div>

          {centerComponent}

          {price !== undefined && mobileDirection === "row" && (
            <Typography
              as="p"
              color="text-text_3b3b3b"
              lineHeight="leading-[1.06]"
              classes={classNames(
                { "text-[16px]": mainTitle },
                { "text-[14px] md:text-[16px]": !mainTitle },
                "font-SFProText w-[70px] md:w-[150px] text-right rtl:text-left"
              )}
              text={price !== -1 ? `${price} EGP` : ""}
            />
          )}
        </div>

        {price !== undefined && mobileDirection === "col" && (
          <Typography
            as="p"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.06]"
            classes={classNames(
              { "text-[16px]": mainTitle },
              { "text-[14px] md:text-[16px]": !mainTitle },
              "font-SFProText w-[70px] md:w-[170px] text-right rtl:text-left"
            )}
            text={price !== -1 ? `${price} EGP` : ""}
          />
        )}

        {textValue && (
          <Typography
            as="p"
            color="text-text_3b3b3b"
            lineHeight="leading-[1.06]"
            classes="text-[16px]  w-[70px] md:w-[150px] text-right rtl:text-left"
            text={textValue}
          />
        )}

        {button && (
          <CustomButton
            width=""
            height="h-9 3xl:h-12"
            buttonTypeClass="outlined"
            textColor="text-primary-900"
            classes={buttonClasses}
            label={button}
            AfterIcon={
              changeAddress ? (
                <ButtonArrow
                  width="18px"
                  className="ml-5 rtl:mr-5 rtl:origin-center rtl:rotate-180"
                />
              ) : (
                <></>
              )
            }
            action={buttonAction}
          />
        )}
      </div>
      {OrderDetails && (
        <div className={classNames("w-full bg-primary-50 px-4 pb-4")}>
          {OrderDetails}
        </div>
      )}
    </>
  );
};

DescriptionTile.propTypes = {
  direction: PropTypes.string,
  classes: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  centerComponent: PropTypes.node,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textValue: PropTypes.string,
  button: PropTypes.string,
};

export default DescriptionTile;
