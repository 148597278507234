import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getPayload } from "utils";

import { useCountDown } from "Hooks";

import {
  resendVerificationMessageStart,
  verifyOTPStart,
} from "Redux/user/actions/user.actions";
import {
  selectCurrentUser,
  selectNewMobileVerificationStatus,
  selectResetPasswordVerifyOTPStatus,
} from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography } from "components/atoms";
import { ValidationsWrapper } from "components/Containers";

import styles from "./VerifyMobileForEditForm.module.css";

const VerifyMobileForEditForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isNewMobileVerified = useSelector(selectNewMobileVerificationStatus);
  const isResetPasswordVerifyOTPSuccess = useSelector(
    selectResetPasswordVerifyOTPStatus
  );

  const { timer, handleCountDown } = useCountDown(120);

  const [verifyOTPPayload, setPayload] = useState({
    verification_code: "",
    new_mobile_number: state?.mobile,
    user: {
      mobile_number: currentUser?.mobile_number,
      type: "customer",
    },
    code_scope: "verification",
  });

  const verifyOTPStartHandler = (verifyOTPPayload) => {
    dispatch(verifyOTPStart(verifyOTPPayload));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    verifyOTPStartHandler(verifyOTPPayload);
  };

  const resendOTPHandler = () => {
    dispatch(resendVerificationMessageStart(verifyOTPPayload));
  };

  useEffect(() => {
    if (isNewMobileVerified) navigate("/Account_Info");
  }, [
    isNewMobileVerified,
    isResetPasswordVerifyOTPSuccess,
    navigate,
    state,
    verifyOTPPayload,
  ]);

  return (
    <div className={styles.main}>
      <ValidationsWrapper
        className="h-full"
        initialValues={{ OTP_code: "" }}
        rules={["OTP_code"]}
      >
        {({ FormikHandler }) => {
          return (
            <div className={styles.form}>
              <div className={styles.formcontainer}>
                <div className={styles.formHeader}>
                  <Typography
                    as="h1"
                    size="9xl"
                    color="text-text_464646"
                    text={t("Enter a verification code")}
                  />

                  <Typography
                    as="p"
                    size="xl"
                    color="text-text_464646"
                    fontWeight="font-normal"
                    lineHeight="leading-[1.23]"
                    classes="mt-2 lg:mt-4 3xl:mt-8"
                    text={t(
                      "To verify your identity, please enter the verification code that we sent to the new phone number"
                    )}
                  />

                  <Typography
                    as="p"
                    size="5xl"
                    color="text-text_464646"
                    classes="mt-2 lg:mt-8 3xl:mt-16"
                    style={{ direction: "initial" }}
                    text={`•••••••${state?.mobile?.substr(
                      state?.mobile?.length - 4
                    )}`}
                  />
                </div>

                <ReactCodeInput
                  type="number"
                  fields={5}
                  className={styles.formInputsContainer}
                  onChange={(data) => {
                    FormikHandler.setFieldValue("OTP_code", data);
                  }}
                  onComplete={(data) => {
                    FormikHandler.setFieldValue("OTP_code", data);
                    getPayload(
                      {
                        target: { name: "verification_code", value: data },
                      },
                      verifyOTPPayload,
                      setPayload
                    );
                  }}
                />
                {FormikHandler.errors.OTP_code && (
                  <small className="text-fail text-xs mt-4">
                    {FormikHandler.errors.OTP_code}
                  </small>
                )}

                <div className="mt-5 lg:mt-8 3xl:mt-10 ">
                  <CustomButton
                    width="w-56"
                    height="h-10"
                    label={t("Submit")}
                    disabled={!FormikHandler.isValid}
                    action={handleSubmit}
                  />
                </div>

                <div className="mt-5 3xl:mt-12 flex w-max flex-wrap justify-center ">
                  <Typography
                    as="p"
                    size="sm"
                    color="text-text_464646"
                    text={t(
                      "It may take a minute to receive the code ? Haven’t received it?"
                    )}
                  />

                  <button
                    onClick={() => {
                      resendOTPHandler();
                      handleCountDown();
                    }}
                    // disabled={timer !== 0}
                  >
                    <Typography
                      as="span"
                      size="sm"
                      color="text-text_464646"
                      fontWeight="font-bold"
                      classes={`ml-1 rtl:mr-1 ${""}
                        //timer !== 0 ? "cursor-not-allowed" : "cursor-pointer"
                        
                          `}
                      text={t("Re-send a new code")}
                    />
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </ValidationsWrapper>
    </div>
  );
};

export default VerifyMobileForEditForm;
