import { PropTypes } from "prop-types";

import { CloseIcon, ConfirmedIcon } from "utils/helpers/SVGsSrc";

import { Typography } from "components/atoms";
import { Modal } from "components/molecules";

const SuccessConfirmationModal = ({
  isOpen,
  ToggleModalVisibility,
  heading,
  subHeadings,
  zIndex = "z-30",
  onClosAction,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      ToggleModalVisibility={() => {
        ToggleModalVisibility();
        onClosAction();
      }}
      zIndex={zIndex}
    >
      <div className="bg-white w-[700px] 2xl:w-[880px] p-4 3xl:p-10 ">
        <div
          onClick={() => {
            ToggleModalVisibility();
            onClosAction();
          }}
          className="flex justify-end rtl:justify-start cursor-pointer"
        >
          <CloseIcon title="close" />
        </div>
        <div className="mx-auto w-24 h-24">
          <ConfirmedIcon width="100%" height="100%" title="confirmed" />
        </div>
        <div className="w-full flex flex-col items-center mt-9 3xl:mt-12 ">
          {heading && (
            <Typography
              as="h3"
              size="3xl"
              fontWeight="font-bold"
              lineHeight="leading-[1.06]"
              color="text-text_3b3b3b"
              text={heading}
            />
          )}
          <div className="mt-9">
            {subHeadings &&
              subHeadings.map((subHeading) => (
                <Typography
                  as="p"
                  size="5xl"
                  color="text-warm_grey"
                  classes="mb-2"
                  text={subHeading}
                />
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

SuccessConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  heading: PropTypes.string,
  subHeadings: PropTypes.instanceOf(Array),
};

export default SuccessConfirmationModal;
