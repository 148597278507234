import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "utils/helpers/SVGsSrc";
import { getPayload } from "utils";

import {
  changePasswordStart,
  changePasswordInformed,
} from "Redux/user/actions/user.actions";
import { selectIsPasswordChanged } from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography, FormInput } from "components/atoms";
import { Modal } from "components/molecules";
import { ValidationsWrapper } from "components/Containers";
import { SuccessConfirmationModal } from "components/organisms";

const DeleteAccountModal = ({ isOpen, ToggleModalVisibility }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [NewPassword, setNewPassword] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const IsPasswordChanged = useSelector(selectIsPasswordChanged);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const closeConfirmation = () => dispatch(changePasswordInformed());

  useEffect(() => {
    if (IsPasswordChanged) {
      setIsPasswordUpdated(true);
    }
  }, [IsPasswordChanged]);

  const SaveNewPasswordHandler = () =>
    dispatch(changePasswordStart(NewPassword));
  const SaveNewPasswordHandleClick = async (event) => {
    event.preventDefault();
    SaveNewPasswordHandler();
  };

  return (
    <Modal isOpen={isOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <SuccessConfirmationModal
        isOpen={isPasswordUpdated}
        ToggleModalVisibility={() => {
          setIsPasswordUpdated(false);
          ToggleModalVisibility();
        }}
        onClosAction={closeConfirmation}
        heading={t("لقد تم تجديد كلمة المرور")}
        zIndex={"z-40"}
      />
      <div className="bg-white w-[600px] 2xl:w-[750px] p-4 3xl:p-10 ">
        <div
          onClick={ToggleModalVisibility}
          className="flex justify-end rtl:justify-start cursor-pointer"
        >
          <CloseIcon title="close" />
        </div>
        <Typography
          as="h1"
          size="2xl"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"pb-6"}
          text={t("Change Password")}
        />
        <ValidationsWrapper
          initialValues={{
            old_password: NewPassword.old_password,
            password: NewPassword.password,
            confirm_password: NewPassword.confirm_password,
          }}
          rules={["old_password", "password", "confirm_password"]}
        >
          {({ FormikHandler }) => {
            return (
              <div>
                <Typography
                  as="h2"
                  size="lg"
                  color="text-warm_grey text-left rtl:text-right"
                  classes="pb-2 pt-4"
                  text={t("Current password")}
                />
                <FormInput
                  id="old_password"
                  name="old_password"
                  type="password"
                  width="w-full"
                  height="h-10"
                  eyeTop="top-[20%]"
                  containerHeight="h-[50px] max-h-[50px] min-h-[50px]"
                  containerClasses="passwordInput"
                  inputTypeClass={"AddressInputColor"}
                  borderColor="border-bg_e8e8e8"
                  errorMargin={"-mt-4"}
                  value={FormikHandler.values.old_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("old_password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "old_password",
                          value: e.target.value,
                        },
                      },
                      NewPassword,
                      setNewPassword
                    );
                  }}
                  error={FormikHandler.errors.old_password}
                />
                <Typography
                  as="h2"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4 text-left rtl:text-right"
                  text={t("New password")}
                />
                <FormInput
                  id="password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-10"
                  eyeTop="top-[20%]"
                  containerHeight="h-[50px] max-h-[50px] min-h-[50px]"
                  containerClasses="passwordInput"
                  inputTypeClass={"AddressInputColor"}
                  borderColor="border-bg_e8e8e8"
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "password",
                          value: e.target.value,
                        },
                      },
                      NewPassword,
                      setNewPassword
                    );
                  }}
                  error={FormikHandler.errors.password}
                />
                <Typography
                  as="h2"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4 text-left rtl:text-right"
                  text={t("Confirm new password")}
                />
                <FormInput
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  width="w-full"
                  height="h-10"
                  eyeTop="top-[20%]"
                  containerHeight="h-[50px] max-h-[50px] min-h-[50px]"
                  containerClasses="passwordInput"
                  inputTypeClass={"AddressInputColor"}
                  borderColor="border-bg_e8e8e8"
                  value={FormikHandler.values.confirm_password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue(
                      "confirm_password",
                      e.target.value
                    );
                    getPayload(
                      {
                        target: {
                          name: "confirm_password",
                          value: e.target.value,
                        },
                      },
                      NewPassword,
                      setNewPassword
                    );
                  }}
                  error={FormikHandler.errors.confirm_password}
                />
                <CustomButton
                  width="w-full max-w-[300px]"
                  height="h-[46px]"
                  borderRadius="8px"
                  classes={"mt-4"}
                  label={t("Save")}
                  disabled={!FormikHandler.isValid}
                  action={(e) => SaveNewPasswordHandleClick(e)}
                />
              </div>
            );
          }}
        </ValidationsWrapper>
      </div>
    </Modal>
  );
};

DeleteAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
};

export default DeleteAccountModal;
