import {
  ApplyPromoCodeStart,
  ListCartDataStart,
  RemovePromoCodeStart,
  UpdateCartDataStart,
  UpdateCartItemStart,
} from "Redux/Cart/actions/cart.actions";
import {
  CheckoutOrderStart,
  getPaymentStart,
} from "Redux/orders/actions/orders.actions";
import React, { useEffect, useState } from "react";
import {
  selectCartData,
  selectIsCartLoading,
  selectIsPromoError,
} from "Redux/Cart/selectors/cart.selectors";
import {
  selectOrder,
  selectPaymentInfo,
} from "Redux/orders/selectors/orders.selectors";
import { useDispatch, useSelector } from "react-redux";

import { ListRestaurantDataStart } from "Redux/vendors/actions/vendors.actions";
import { PlaceOrderTemplate } from "templates";
import { StorageUtils } from "utils";
import { selectCurrentUser } from "Redux/user";
import { selectRestaurantData } from "Redux/vendors/selectors/vendors.selectors";
import { selectUserDeliveryLocation } from "Redux/user/selectors/user.selectors";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const PlaceOrderPage = () => {
  const { i18n } = useTranslation();
  const currentLng = i18n?.language;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // APIs
  const CartData = useSelector(selectCartData);
  const IsCartLoading = useSelector(selectIsCartLoading);
  const currentUser = useSelector(selectCurrentUser);
  const DeliveryAddress = useSelector(selectUserDeliveryLocation);
  const order = useSelector(selectOrder);
  const paymentInfo = useSelector(selectPaymentInfo);
  const vendor = useSelector(selectRestaurantData);
  const PromoError = useSelector(selectIsPromoError);
  // const selectedAddress = useSelector(selectSelectedAddress);
  const [deliveryOption, setDeliveryOption] = useState(null);

  useEffect(() => {
    if (CartData) dispatch(ListRestaurantDataStart(CartData?.vendor_id));
  }, [CartData]);

  useEffect(() => {
    if (CartData) {
      if (CartData?.is_pickup) {
        setDeliveryOption("pickup");
        localStorage.setItem("deliveryOption", "pickup");
      } else {
        setDeliveryOption("delivery");
        localStorage.setItem("deliveryOption", "delivery");
      }
    }
  }, [CartData]);
  useEffect(() => {
    if (currentLng && currentUser) {
      dispatch(ListCartDataStart());
    }
  }, [dispatch, currentLng, currentUser]);

  // CartItem Handler
  const UpdateCartItemHandler = (data) => dispatch(UpdateCartItemStart(data));
  const UpdateCartItemHandleClick = async (
    event,
    Type,
    ItemData,
    newInstruction
  ) => {
    event.preventDefault();
    Type === "Instruction"
      ? UpdateCartItemHandler({
          id: ItemData?.id,
          Instruction: newInstruction,
        })
      : Type === "Add"
      ? UpdateCartItemHandler({
          id: ItemData?.id,
          newQ: ItemData?.quantity + 1,
          Instruction: newInstruction,
        })
      : UpdateCartItemHandler({
          id: ItemData?.id,
          newQ: ItemData?.quantity - 1,
          Instruction: newInstruction,
        });
  };

  const [CartInstruction, setCartInstruction] = useState(
    CartData?.special_instructions
  );

  useEffect(() => {
    setCartInstruction(CartData?.special_instructions);
  }, [CartData?.special_instructions]);

  const [promoCodeValue, setPromoCodeValue] = useState("");

  // Checkout Handler
  const date = new Date();
  const CheckoutOrderHandler = (data) => {
    if (
      StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] == "now"
    ) {
      dispatch(
        UpdateCartDataStart({
          cart: {
            delivery_type: "now",
            delivery_time: `${date.getHours()}:${date.getMinutes()}`,
            delivery_date: `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`,
          },
        })
      );
    }
    //console.log(data);
    const body = {
      delivery_service_type: deliveryOption,
      ...data,
    };

    dispatch(CheckoutOrderStart(body));
  };
  const CheckoutOrderHandleClick = async (event, data) => {
    event.preventDefault();
    CheckoutOrderHandler(data);
  };

  const applyPromoCodeHandler = (promoCode) => {
    const body = { promocode: { value: promoCode } };
    dispatch(ApplyPromoCodeStart(body));
  };

  const removePromoCodeHandler = () => {
    dispatch(RemovePromoCodeStart());
  };

  // Redirect after checkout
  useEffect(() => {
    if (CartData?.cart_items?.length == 0 || CartData?.length == 0) {
      navigate("/");
    } else if (order) {
      if (order?.cart?.id == CartData?.id) {
        navigate(
          `/place_order/success/${order?.id}/${order?.cart?.vendor_name}`,
          {
            state: {
              orderId: order?.id,
              vendorName: order?.cart?.vendor_name,
            },
          }
        );
      }
    }
  }, [order, CartData]);

  // Address
  const [isNotSavedAddress, setIsNotSavedAddress] = useState(false);
  useEffect(() => {
    if (
      (DeliveryAddress && !DeliveryAddress?.isUserSavedAddress) ||
      DeliveryAddress == null
    ) {
      setIsNotSavedAddress(true);
    }
  }, []);

  const SaveAddressHandle = (value) => {
    setIsNotSavedAddress(value);
  };

  // Order update
  const [OrderData, setOrderData] = useState({
    payment_type: "cash", // [visa, cash]

    cart_special_instructions: CartData?.special_instructions,
    cart_items: [],
  });

  const payWithCard = async (installments) => {
    // if (!payment) {
    dispatch(
      getPaymentStart({
        installments: installments,
        cart: {
          customer_address_id: DeliveryAddress?.id,
          cart_special_instructions: CartData?.special_instructions,
          cart_items: CartData?.cart_items,
        },
        delivery_service_type: deliveryOption,
      })
    );
    //   setPayment(true);
    // }
  };
  const handelChangeDeliveryOption = (data) => {
    dispatch(
      UpdateCartDataStart({
        cart: {
          is_pickup: data == "pickup" ? true : false,
        },
      })
    );
  };
  return (
    <PlaceOrderTemplate
      acceptOnlinePayment={CartData?.vendor_accepts_online_payment}
      // acceptOnlinePayment={vendor?.accept_online_payment}
      payWithCard={payWithCard}
      paymentInfo={paymentInfo}
      IsCartLoading={IsCartLoading}
      CartData={CartData}
      UpdateCartItemHandleClick={UpdateCartItemHandleClick}
      CheckoutOrderHandleClick={CheckoutOrderHandleClick}
      CartInstruction={CartInstruction}
      setCartInstruction={setCartInstruction}
      DeliveryAddress={DeliveryAddress}
      isNotSavedAddress={isNotSavedAddress}
      SaveAddressHandle={SaveAddressHandle}
      currentUser={currentUser}
      OrderData={OrderData}
      setOrderData={setOrderData}
      applyPromoCodeHandler={applyPromoCodeHandler}
      promoCodeValue={promoCodeValue}
      setPromoCodeValue={setPromoCodeValue}
      removePromoCodeHandler={removePromoCodeHandler}
      PromoError={PromoError}
      setDeliveryOption={setDeliveryOption}
      deliveryOption={deliveryOption}
      vendor={vendor}
      handelChangeDeliveryOption={handelChangeDeliveryOption}
    />
  );
};

export default PlaceOrderPage;
