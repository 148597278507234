import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { cancelOrderStart } from "Redux/orders/actions/orders.actions";

import { selectOrdersLoading } from "Redux/orders/selectors/orders.selectors";

import { CustomButton, Spinner, FormInput } from "components/atoms";
import { Modal, ModalHeader } from "components/molecules";

const reasonsOfCancelling = [
  "Order is taking too long to be delivered",
  "I placed the order by mistake",
  "I want to change some items",
  "I changed my mind",
  "Other",
];

const ReasonsOfCancellingModal = ({
  isOpen,
  orderId,
  ToggleModalVisibility,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loading = useSelector(selectOrdersLoading);

  const [reasonOfCancelling, setReasonOfCancelling] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [enteredReasonOfCancelling, setEnteredReasonOfCancelling] =
    useState("");

  useEffect(() => {
    if (!isOpen) {
      setSelectedReason("");
      setEnteredReasonOfCancelling("");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white w-80 min-w-80 lg:w-[691px] lg:min-w-[691px]  ">
        <ModalHeader
          ToggleModalVisibility={ToggleModalVisibility}
          heading={t("Please tell us the reason for cancelling the order?")}
          hasShadow={false}
          textAlignment="ltr:text-left rtl:text-right"
        />
        <div className="w-full  p-4 xl:px-8 ">
          <div className="flex flex-col w-full ltr:text-left rtl:text-right">
            <div className="flex flex-col items-end">
              {selectedReason.includes("Other") ? (
                <FormInput
                  id="reason_of_cancelling"
                  value={enteredReasonOfCancelling}
                  type="textarea"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  width="w-full mb-4"
                  Resize={true}
                  onValueChange={(e) => {
                    setEnteredReasonOfCancelling(e.target.value);
                    setReasonOfCancelling(e.target.value);
                  }}
                />
              ) : (
                reasonsOfCancelling?.map((reasonOfCancelling) => (
                  <FormInput
                    key={reasonOfCancelling}
                    id={reasonOfCancelling}
                    name="reasonsOfCancelling"
                    type="radio"
                    fullWidth={false}
                    width="w-[15px]"
                    height="h-[15px]"
                    containerClasses="selectInputs"
                    label={t(reasonOfCancelling)}
                    value={reasonOfCancelling}
                    onValueChange={(e) => {
                      setSelectedReason(e.target.value);
                      setReasonOfCancelling(e.target.value);
                    }}
                    checked={selectedReason === reasonOfCancelling}
                    labelSize="xl"
                    textColor="text-text_777777"
                  />
                ))
              )}
              <CustomButton
                height="h-9 3xl:h-12"
                buttonTypeClass="outlined"
                textColor="text-primary-900"
                label={
                  loading ? (
                    <Spinner color="#fff" loading={loading} />
                  ) : (
                    t("Submit")
                  )
                }
                action={() =>
                  dispatch(cancelOrderStart({ reasonOfCancelling, orderId }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReasonsOfCancellingModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
};

export default ReasonsOfCancellingModal;
