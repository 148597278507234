import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Add } from "utils/helpers/SVGsSrc";

import { WithSpinner } from "Hocs";

import {
  DeleteAddressInformed,
  loadMoreAddressesStart,
} from "Redux/addresses/actions/addresses.actions";

import { CustomButton, Spinner } from "components/atoms";
import { AddressCard, Footer, NavBar } from "components/molecules";
import {
  AddDeliveryAddressModal,
  SuccessConfirmationModal,
} from "components/organisms";

import styles from "./Address.module.css";
import { selectIsAddressDeleted } from "Redux/addresses/selectors/addresses.selectors";
import Layout from "components/molecules/Layout";

const AddressesListBody = ({
  savedAddresses,
  DeliveryAddress,
  loading,
  hasMore,
  Page,
  currentUser,
  // selectedArea,
  // setSelectedArea,
  // selectedCity,
  // setSelectedCity,
  // Cities,
  // Areas,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  const IsAddressDeleted = useSelector(selectIsAddressDeleted);
  const [isAddressDeleted, setIsAddressDeleted] = useState(false);

  useEffect(() => {
    if (IsAddressDeleted) {
      setIsAddressDeleted(true);
    }
  }, [IsAddressDeleted]);

  const closeConfirmation = () => dispatch(DeleteAddressInformed());

  return (
    <InfiniteScroll
      dataLength={savedAddresses && savedAddresses?.length}
      className="w-full md:px-2"
      style={{ width: "100%" }}
      scrollThreshold={0.8}
      next={() => {
        dispatch(loadMoreAddressesStart({ page_number: Page }));
      }}
      hasMore={hasMore}
      loader={<Spinner loading={loading} />}
    >
      {DeliveryAddress?.isUserSavedAddress && !loading && (
        <>
          <AddressCard
            AddressData={DeliveryAddress}
            isSelected={true}
            currentUser={currentUser}
            edit={true}
            state={state}
            // selectedArea={selectedArea}
            // setSelectedArea={setSelectedArea}
            // selectedCity={selectedCity}
            // setSelectedCity={setSelectedCity}
            // Cities={Cities}
            // Areas={Areas}
          />
          <SuccessConfirmationModal
            isOpen={isAddressDeleted}
            ToggleModalVisibility={() => {
              setIsAddressDeleted(false);
            }}
            onClosAction={closeConfirmation}
            heading={t("The Address is deleted successfully")}
          />
        </>
      )}
      {savedAddresses
        ?.filter((item) => item.id != DeliveryAddress?.id)
        ?.map((item) => {
          return (
            <AddressCard
              AddressData={item}
              currentUser={currentUser}
              state={state}
              // selectedArea={selectedArea}
              // setSelectedArea={setSelectedArea}
              // selectedCity={selectedCity}
              // setSelectedCity={setSelectedCity}
              // Cities={Cities}
              // Areas={Areas}
            />
          );
        })}
    </InfiniteScroll>
  );
};

const AddressTemplate = ({
  currentUser,
  savedAddresses,
  loading,
  hasMore,
  DeliveryAddress,
  Page,
  // selectedArea,
  // setSelectedArea,
  // selectedCity,
  // setSelectedCity,
  // Cities,
  // Areas,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isAddAddress, setIsAddAddress] = useState(false);

  const AddressesListContainer = WithSpinner(AddressesListBody);

  return (
    <Layout>
      <main className={styles.main}>
        <AddDeliveryAddressModal
          Open={isAddAddress}
          ToggleModal={() => setIsAddAddress(false)}
          currentUser={currentUser}
          DeliveryAddress={DeliveryAddress}
          New={true}
          // selectedArea={selectedArea}
          // setSelectedArea={setSelectedArea}
          // selectedCity={selectedCity}
          // setSelectedCity={setSelectedCity}
          // Cities={Cities}
          // Areas={Areas}
        />
        <div className={styles.container}>
          <div className="w-full flex justify-end items-center">
            <CustomButton
              height="h-9 3xl:h-12"
              borderColor={"border-bg_f7f7f7 md:border-bg_white"}
              buttonTypeClass="outlined"
              textColor="text-primary-900"
              classes={"font-bold mb-6 mx-4"}
              label={t("Add Addresses")}
              BeforeIcon={<Add width="24px" className="mr-2 rtl:ml-2" />}
              action={() => {
                setIsAddAddress(true);
                navigate(`/Addresses`);
              }}
            />
          </div>
          <AddressesListContainer
            isLoading={loading}
            savedAddresses={savedAddresses}
            DeliveryAddress={DeliveryAddress}
            loading={loading}
            hasMore={hasMore}
            Page={Page}
            currentUser={currentUser}
            // selectedArea={selectedArea}
            // setSelectedArea={setSelectedArea}
            // selectedCity={selectedCity}
            // setSelectedCity={setSelectedCity}
            // Cities={Cities}
            // Areas={Areas}
          />
        </div>
      </main>
      
    </Layout>
  );
};

AddressTemplate.propTypes = {
  savedAddresses: PropTypes.array,
  DeliveryAddress: PropTypes.object,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  page: PropTypes.number,
  currentUser: PropTypes.object,
};

export default AddressTemplate;
