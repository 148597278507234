import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Footer, Tabs, NavBar } from "components/molecules";
import { OrdersList } from "components/organisms";
import { useLocation, useNavigate } from "react-router";

import styles from "./Orders.module.css";
import Layout from "components/molecules/Layout";

const tabView = {
  completed: <OrdersList status="completed" />,
  cancelled: <OrdersList status="canceled" />,
  current: <OrdersList status="in_progress" />,
};

const OrdersTemplate = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const navigate = useNavigate();

  const categories = {
    [t("In progress")]: {
      status: "current",
    },
    [t("Completed")]: {
      status: "completed",
    },
    [t("Cancelled")]: {
      status: "cancelled",
    },
  };

  const [status, setStatus] = useState(
    state?.status ? state?.status : "current"
  );

  const [tabIndex] = useState(
    Object.values(categories).findIndex((value) => value?.status === status)
  );

  const getTabView = (status) => {
    setStatus(status);
  };

  return (
    <Layout>
      <main className={styles.main}>
        <section>
          <Tabs
            tabIndex={tabIndex}
            getTabView={getTabView}
            categories={categories}
            click={() => navigate("/Orders")}
          >
            {tabView[status]}
          </Tabs>
        </section>
      </main>
    </Layout>
  );
};

export default OrdersTemplate;
