import classNames from "classnames";
import { Typography } from "components/atoms";
import {
  CustomSlider,
  PopularFavCard,
  RestaurantCard,
} from "components/molecules";
import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "templates/App/Home/Home.module.css";
import { ImagesSrc, StorageUtils } from "utils";

const PopularNearYou = ({
  t,
  popularNearRestaurantsList,
  cartData,
  width,
  UserCoords,
  selectedAddress,
}) => {
  //console.log("first");
  return (
    <section className={styles.popular}>
      <div className={styles.orderAgainHeader}>
        <Typography
          as="h2"
          size="7xl"
          color="text-text_464646"
          classes="mb-6 cursor-pointer text-[18px] lg:text-[26px]"
          text={UserCoords ? t("Popular near you") : t("Popular restaurants")}
        />
        <NavLink
          to={{
            pathname: "/Popular_near_you",
            state: StorageUtils.getItems(sessionStorage, ["distance_cords"])[
              "distance_cords"
            ]
              ? {
                  lat: StorageUtils.getItems(sessionStorage, [
                    "distance_cords",
                  ])["distance_cords"].lat,
                  lng: StorageUtils.getItems(sessionStorage, [
                    "distance_cords",
                  ])["distance_cords"].lng,
                }
              : "",
          }}
        >
          <Typography
            as="span"
            size="5xl"
            color="text-text_464646"
            classes="mb-6"
            text={t("View all")}
          />
        </NavLink>
      </div>
      <div
        className={classNames(
          "flex  gap-4 flex-wrap items-center justify-center lg:px-0 px-5"
        )}
        style={{ width: "100%" }}
      >
        {popularNearRestaurantsList?.map((vendor, idx) => (
          <NavLink
            key={vendor?.id}
            to={`/restaurant/${vendor?.id}`}
            style={{ display: "flex" }}
            className="lg:w-[18%] md:w-[23%] sm:w-[33%] w-[100%] xl:h-[320px] h-[100%]"
          >
            <RestaurantCard
              imgUrl={
                vendor?.logo ? vendor?.logo : ImagesSrc.VendorCardPlaceholder3x
              }
              restaurantName={vendor?.display_name}
              rating={vendor?.rating}
              description={vendor?.description}
              deliveryFees={vendor?.delivery_details?.delivery_fee}
              deliveryDuration={vendor?.delivery_details?.delivery_time}
              available={vendor?.is_available}
              vendor={vendor}
              UserCoords={UserCoords}
              cartData={cartData}
              selectedAddress={selectedAddress}
            />
          </NavLink>
        ))}
      </div>
    </section>
  );
};

export default withTranslation()(PopularNearYou);
