import { useTranslation } from "react-i18next";

import { Typography } from "components/atoms";
import { Footer, NotificationCard, NavBar } from "components/molecules";

import styles from "./Notifications.module.css";
import Layout from "components/molecules/Layout";

const NotificationsTemplate = ({ Notifications }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <main className={styles.main}>
        <div className="px-6 lg:px-0">
          <Typography
            as="h2"
            size="7xl"
            color="text-text_464646"
            classes="hidden lg:block lg:mb-6"
            text={t("Notification")}
          />
        </div>
        {Notifications?.today?.length != 0 ? (
          <div className="mt-8 lg:bg-primary-50 px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="lg:mb-6"
              text={t("Today")}
            />
            {Notifications?.today?.map((item) => (
              <NotificationCard
                restaurantImg={item?.attributes?.logo}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={item?.attributes?.sent_at}
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
                notificationAction={item?.attributes?.need_action}
                orderStatus={item?.attributes?.order_status}
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {Notifications?.yesterday?.length != 0 ? (
          <div className="mt-8 lg:bg-primary-50 px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="lg:mb-6"
              text={t("Yesterday")}
            />
            {Notifications?.yesterday?.map((item) => (
              <NotificationCard
                restaurantImg={item?.attributes?.logo}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={item?.attributes?.sent_at}
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
                notificationAction={item?.attributes?.need_action}
                orderStatus={item?.attributes?.order_status}
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {Notifications?.later?.length != 0 ? (
          <div className="mt-8 lg:bg-primary-50 px-6 lg:px-20 lg:pt-14">
            <Typography
              as="h2"
              size="7xl"
              t
              color="text-text_464646"
              classes="lg:mb-6"
              text={t("Later notification")}
            />
            {Notifications?.later?.map((item) => (
              <NotificationCard
                restaurantImg={item?.attributes?.logo}
                notificationType={item?.attributes?.notification_type}
                dataId={item?.attributes?.data_id}
                notificationTitle={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_title
                    : item?.attributes?.title
                }
                notificationTime={item?.attributes?.sent_at}
                notificationDescription={
                  i18n.language == "ar"
                    ? item?.attributes?.ar_message
                    : item?.attributes?.message
                }
                notificationAction={item?.attributes?.need_action}
                orderStatus={item?.attributes?.order_status}
              />
            ))}
          </div>
        ) : (
          ""
        )}
      </main>
    </Layout>
  );
};

export default NotificationsTemplate;
