import React, { useState, useEffect } from "react";
import { GoogleMap, DirectionsService } from "@react-google-maps/api";
import { StorageUtils } from "utils";

function MapWithDirections({ restaurantData }) {
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [response, setResponse] = useState(null);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const directionsCallback = (response) => {
    //console.log(response, "response");
    if (response !== null && response.status === "OK") {
      setResponse(response.status);
      setDirectionsResponse(response);
    }
  };
  if (map && directionsResponse) {
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setDirections(directionsResponse);
    directionsRenderer.setMap(map);
  }
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: +sessionLocation?.lat,
          lng: +sessionLocation?.lng,
        }}
        zoom={7}
        onLoad={!map && onLoad}
        options={{ disableDefaultUI: true }}
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
      >
        <DirectionsService
          options={{
            destination: {
              lat: +sessionLocation?.lat,
              lng: +sessionLocation?.lng,
            },
            origin: {
              lat: +restaurantData?.lat,
              lng: +restaurantData?.lng,
            },
            travelMode: "DRIVING",
          }}
          callback={response != "OK" && directionsCallback}
        />
      </GoogleMap>
    </div>
  );
}

export default MapWithDirections;
