import React from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings/build/star-ratings";

import { getPayload } from "utils";

import { CustomButton, Typography, FormInput } from "components/atoms";
import { Footer, NavBar } from "components/molecules";

import styles from "./OrderRate.module.css";
import Layout from "components/molecules/Layout";

const OrderRateTemplate = ({
  OrderData,
  AddVendorRateHandleClick,
  orderReview,
  SetOrderReview,
}) => {
  const { t } = useTranslation();

  // Food Rating onChange function
  const FoodRatingChanged = (newFoodRating) => {
    getPayload(
      {
        target: {
          name: "VendorRating",
          value: newFoodRating,
        },
      },
      orderReview,
      SetOrderReview
    );
  };

  // Delivery Rating onChange function
  const DeliveryRatingChanged = (newDeliveryRating) => {
    getPayload(
      {
        target: {
          name: "DeliveryRating",
          value: newDeliveryRating,
        },
      },
      orderReview,
      SetOrderReview
    );
  };

  return (
    <Layout>
      <main className={styles.main}>
        <div className={styles.container}>
          <Typography
            as="h1"
            size="5xl"
            lineHeight="leading-none"
            fontWeight="font-bold"
            classes={"mt-10"}
            color="text-primary-900"
            text={t("Rate your order")}
          />
          <div className="flex flex-col items-center py-10">
            <Typography
              as="h2"
              size="lg"
              lineHeight="leading-none"
              fontWeight={"font-semibold"}
              classes={"mb-4"}
              color="text-text_3b3b3b"
              text={`${t("How was your food from")} ${
                OrderData?.cart?.vendor_name
              } ?`}
            />
            <StarRatings
              rating={orderReview?.VendorRating}
              changeRating={FoodRatingChanged}
              numberOfStars={5}
              name="rating"
              starRatedColor="#e5a128"
              starHoverColor="#e5a128"
              starEmptyColor="white"
              starDimension="25px"
              starSpacing="2px"
              svgIconViewBox="0 0 22 21"
              svgIconPath="M22 8.138a.956.956 0 0 1-.344.635l-4.8 4.685 1.137 6.616a1.968 1.968 0 0 1 .013.265.781.781 0 0 1-.139.47.467.467 0 0 1-.4.192 1.067 1.067 0 0 1-.529-.159L11 17.718l-5.936 3.123a1.121 1.121 0 0 1-.529.159.48.48 0 0 1-.416-.192.784.784 0 0 1-.139-.47 2.165 2.165 0 0 1 .026-.265l1.137-6.616L.331 8.773A1.008 1.008 0 0 1 0 8.138q0-.489.741-.609l6.637-.966 2.974-6.02Q10.6 0 11 0t.648.543l2.975 6.021 6.637.966q.741.119.741.609z"
            />
          </div>
          <div className="flex flex-col items-center py-10">
            <Typography
              as="h2"
              size="lg"
              lineHeight="leading-none"
              fontWeight={"font-semibold"}
              classes={"mb-4"}
              color="text-text_3b3b3b"
              text={t("How was your delivery experience?")}
            />
            <StarRatings
              rating={orderReview?.DeliveryRating}
              changeRating={DeliveryRatingChanged}
              numberOfStars={5}
              name="rating"
              starRatedColor="#e5a128"
              starHoverColor="#e5a128"
              starEmptyColor="white"
              starDimension="25px"
              starSpacing="2px"
              svgIconViewBox="0 0 22 21"
              svgIconPath="M22 8.138a.956.956 0 0 1-.344.635l-4.8 4.685 1.137 6.616a1.968 1.968 0 0 1 .013.265.781.781 0 0 1-.139.47.467.467 0 0 1-.4.192 1.067 1.067 0 0 1-.529-.159L11 17.718l-5.936 3.123a1.121 1.121 0 0 1-.529.159.48.48 0 0 1-.416-.192.784.784 0 0 1-.139-.47 2.165 2.165 0 0 1 .026-.265l1.137-6.616L.331 8.773A1.008 1.008 0 0 1 0 8.138q0-.489.741-.609l6.637-.966 2.974-6.02Q10.6 0 11 0t.648.543l2.975 6.021 6.637.966q.741.119.741.609z"
            />
          </div>
          <div className="flex flex-col items-start py-10 md:min-w-[700px]">
            <div className="flex mb-2">
              <Typography
                as="h2"
                size="lg"
                lineHeight="leading-none"
                fontWeight={"font-bold"}
                color="text-text_3b3b3b"
                text={t("Write your feedback")}
              />
              <Typography
                as="p"
                color="text-warm_grey"
                classes={"text-[10px] md:text-[12px] px-3"}
                text={`( ${t("Optional")} )`}
              />
            </div>
            <FormInput
              id={"special_instructions"}
              key={"special_instructions"}
              type="textarea"
              bgColor="bg-bg_white"
              borderColor="border-bg_e8e8e8"
              width="w-full"
              Resize={true}
              onValueChange={(e) => {
                getPayload(
                  {
                    target: {
                      name: "body",
                      value: e.target.value,
                    },
                  },
                  orderReview,
                  SetOrderReview
                );
              }}
            />
          </div>

          <CustomButton
            width="w-full max-w-[200px]"
            height="h-[46px]"
            borderRadius="8px"
            label={t("Submit")}
            action={(e) => AddVendorRateHandleClick(e)}
          />
        </div>
      </main>
    </Layout>
  );
};

OrderRateTemplate.propTypes = {
  OrderData: PropTypes.object,
  AddVendorRateHandleClick: PropTypes.func,
  orderReview: PropTypes.object,
  SetOrderReview: PropTypes.func,
};

export default OrderRateTemplate;
