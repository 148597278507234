import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import classNames from "classnames";

import { BlackBicycle, ButtonArrow, Location } from "utils/helpers/SVGsSrc";

import { CustomButton, Typography } from "components/atoms";

import styles from "./DeliveryAddressCard.module.css";
import { StorageUtils } from "utils";
import { selectUserDeliveryLocation } from "Redux/user/selectors/user.selectors";
import { useSelector } from "react-redux";

const DeliveryAddressCard = ({
  address,
  canChangeAddress = true,
  hasBicycleIcon = true,
  width = "w-full",
  height = "h-[fit-content]",
  borderRadius = "8px",
  addressDescriptionClasses,
  hasMarginBottom = true,
  cardHeading,
  currentUser,
}) => {
  const { t } = useTranslation();
  const DeliveryAddress = useSelector(selectUserDeliveryLocation);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      style={{ borderRadius }}
      className={classNames(styles.contaner, width, height, {
        "mb-8": hasMarginBottom,
      })}
    >
      <div className={styles.header}>
        {hasBicycleIcon && (
          <div className={styles.headerIcon}>
            <BlackBicycle width="100%" height="100%" title="go to addresses" />
          </div>
        )}

        <Typography
          as="h1"
          size="xl"
          color="text-text_3b3b3b"
          fontWeight="font-bold"
          text={cardHeading}
        />
      </div>
      {address?.name && (
        <div className="flex items-center">
          <div className={styles.locationIcon}>
            <Location
              width="100%"
              height="100%"
              className="ltr:mr-3 rtl:ml-3"
            />
          </div>
          <Typography
            size="sm"
            as="h2"
            fontWeight="font-semibold"
            color="text-text_3b3b3b"
            text={address?.name}
          />
        </div>
      )}
      <Typography
        size="sm"
        as="p"
        color="text-text_3b3b3b"
        lineHeight="leading-5"
        classes={addressDescriptionClasses}
        // text={
        //   address?.isUserSavedAddress
        //     ? `${address?.building}, ${address?.street_name}, ${address?.area}`
        //     : address?.structured_formatting?.main_text
        // }
        text={
          StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
            "selectedAddressType"
          ] == "notSaved" ||
          !StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
            "selectedAddressType"
          ]
            ? DeliveryAddress?.structured_formatting &&
              `${DeliveryAddress?.structured_formatting?.main_text} ,${DeliveryAddress?.structured_formatting?.secondary_text}`
            : address?.building &&
              `${address?.building}, ${address?.street_name}`
        }
      />
      {/* , ${address?.area?.name} */}

      {canChangeAddress && (
        <CustomButton
          width="w-full"
          height="h-9 3xl:h-12"
          buttonTypeClass="outlined"
          textColor="text-primary-900"
          label={t("Change Address")}
          AfterIcon={
            <ButtonArrow
              width="18px"
              className="ml-5 rtl:mr-5 rtl:origin-center rtl:rotate-180"
            />
          }
          action={() => {
            currentUser
              ? navigate(`/Addresses`, {
                  state: {
                    returnUrl: pathname,
                  },
                })
              : navigate(`/login`);
          }}
        />
      )}
    </div>
  );
};

DeliveryAddressCard.propTypes = {
  address: PropTypes.instanceOf(Object),
  canChangeAddress: PropTypes.bool,
  hasBicycleIcon: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  addressDescriptionClasses: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  cardHeading: PropTypes.string,
};

export default DeliveryAddressCard;
