import { NavLink } from "react-router-dom";

import { ImagesSrc } from "utils";

import { Img, Typography } from "components/atoms";

import styles from "./OfferCard.module.css";

const OfferCard = ({
  id,
  restaurantLogo,
  restaurantName,
  offerTitle,
  offerDescription,
}) => {
  return (
    <NavLink to={`/restaurant/${id}`}>
      <div className={styles.offerContainer}>
        <div className={styles.restaurantLogo}>
          <Img
            containerClasses="w-[103px] lg:w-[170px] h-full mx-auto p-1"
            fileName={restaurantLogo ?? ImagesSrc.MenuCardPlaceHolder}
            altText={restaurantName}
            picClasses={"!w-fit mx-auto"}
          />
        </div>

        <div className={styles.orderInfo}>
          <Typography
            as="h3"
            size="xl"
            fontWeight="font-bold"
            lineHeight="leeading-[1.44]"
            color="text-text_3b3b3b"
            text={offerTitle}
          />

          <Typography
            as="p"
            size="lg"
            fontWeight="font-light"
            lineHeight="leeading-[1.38]"
            color="text-warm_grey"
            classes="lg:mt-2 ellipseText line-clamp-3 h-10 max-h-10"
            text={offerDescription}
          />
        </div>
      </div>
    </NavLink>
  );
};

export default OfferCard;
