import { useState } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  DeleteAddressStart,
  setSelectedAddressStart,
} from "Redux/addresses/actions/addresses.actions";
// import { saveUserDeliveryLocation } from "Redux/user/actions/user.actions";

import { CustomButton, Typography } from "components/atoms";
import { AddDeliveryAddressModal } from "components/organisms";

import { Delete, Edit } from "utils/helpers/SVGsSrc";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { StorageUtils } from "utils";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";

const AddressCard = ({
  AddressData,
  isSelected = false,
  currentUser,
  state,
  // selectedArea,
  // setSelectedArea,
  // selectedCity,
  // setSelectedCity,
  // Cities,
  // Areas,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedAddress = useSelector(selectSelectedAddress);

  const [isEditAddress, setIsEditAddress] = useState(false);
  return (
    <>
      <div
        className={`bg-bg_white w-full flex flex-col md:flex-row justify-between items-center rounded-none md:rounded-[8px] md:shadow-[0_3px_6px_0_rgb(0,0,0,0.16)] p-4  border-bg_e8e8e8 mb-8 cursor-pointer  ${
          isSelected
            ? "border-primary-900 border-[2px] cursor-auto"
            : "md:border-[1px]"
        }`}
      >
        <div
          className="w-full"
          onClick={async () => {
            // to save in backend
            await dispatch(setSelectedAddressStart(AddressData.id));
            await StorageUtils.saveItems(sessionStorage, [
              {
                name: "distance_cords",
                data: JSON.stringify(selectedAddress),
              },
            ]);
            StorageUtils.saveItems(sessionStorage, [
              {
                name: "address",
                data: `${selectedAddress?.name},${selectedAddress?.street_name}`,
              },
            ]);
            await navigate(state.returnUrl);
          }}
        >
          <Typography
            as="h2"
            // size="xl"
            color="text-text_3b3b3b"
            fontWeight="font-bold"
            classes={"text-[15px] md:text-[20px]"}
            text={AddressData?.name}
          />
          <Typography
            as="p"
            lineHeight="leading-none"
            color="text-warm_grey"
            classes="pt-3 text-[14px] md:text-[16px]"
            text={`${AddressData?.apartment_number}, ${AddressData?.floor_number}, ${AddressData?.building}, ${AddressData?.street_name}`}
            // , ${AddressData?.area?.name}
          />
        </div>
        <div className="flex mt-3 md:m-0">
          <CustomButton
            height="h-9 3xl:h-12"
            buttonTypeClass="outlined"
            borderColor="border-transparent"
            textColor="text-primary-900"
            classes="font-bold"
            label={t("Edit Addresses")}
            BeforeIcon={<Edit height="16px" className="mr-2 rtl:ml-2" />}
            action={() => setIsEditAddress(true)}
          />
          <CustomButton
            height="h-9 3xl:h-12"
            width={"w-full"}
            buttonTypeClass="outlined"
            borderColor="border-transparent"
            textColor="text-primary-900"
            classes="pl-6 rtl:pr-4 font-bold"
            label={t("Delete Addresses")}
            BeforeIcon={<Delete height="16px" className="mr-2 rtl:ml-2" />}
            action={async () => {
              await dispatch(
                DeleteAddressStart({
                  id: AddressData?.id,
                  isSelected: isSelected,
                })
              );
              StorageUtils.removeItems(sessionStorage, ["distance_cords"]);
              StorageUtils.removeItems(sessionStorage, ["address"]);
            }}
          />
        </div>
      </div>
      <AddDeliveryAddressModal
        Open={isEditAddress}
        ToggleModal={() => setIsEditAddress(false)}
        currentUser={currentUser}
        edit={true}
        AddressData={AddressData}
        isSelected={isSelected}
        // selectedArea={selectedArea}
        // setSelectedArea={setSelectedArea}
        // selectedCity={selectedCity}
        // setSelectedCity={setSelectedCity}
        // Cities={Cities}
        // Areas={Areas}
      />
    </>
  );
};

AddressCard.propTypes = {
  AddressData: PropTypes.object,
  isSelected: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default AddressCard;
