import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { ImagesSrc } from "utils";

import { Spinner, Typography, FormInput } from "components/atoms";
import { Footer, RestaurantCard, NavBar } from "components/molecules";

import styles from "./GridListWithSearch.module.css";
import Layout from "components/molecules/Layout";
import classNames from "classnames";

const GridListWithSearchTemplate = ({
  getQueryParams,
  queryParams,
  vendors,
  hasMore,
  loading,
  loadingLoadMore,
  loadMoreVendors,
  heading,
  cartData,
}) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <main className={styles.main}>
        <section>
          <div className="flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center  lg:mb-4 2xl:mb-8 3xl:mb-10">
            <Typography
              as="h2"
              size="7xl"
              color="text-text_464646"
              classes="lg:mb-6"
              text={t(heading)}
            />
            <div className="w-full lg:w-max flex flex-col">
              <div className="flex w-full mb-8 lg:mb-0 ">
                <FormInput
                  containerClasses="flex"
                  type="search"
                  name="search_key"
                  borderRadius="4px"
                  bgColor="bg-bg_f5f5f5"
                  textColor="text-warm_grey"
                  height="h-10 lg:h-[58px]"
                  classes="absolute "
                  width="w-11/12 xl:w-[656px] 3xl:w-[820px]"
                  placeholder={t("Find a restaurant or dish …")}
                  value={queryParams.search_key}
                  onValueChange={getQueryParams}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <InfiniteScroll
              dataLength={vendors && vendors?.length}
              // scrollThreshold={0.8}
              next={loadMoreVendors}
              hasMore={hasMore}
              loader={<Spinner loading={loadingLoadMore} />}
              style={{ paddingBlock: "10px" }}
            >
              <div
                className={classNames(
                  "flex  gap-4 flex-wrap items-center justify-center lg:px-0 px-5"
                )}
                style={{ width: "100%" }}
              >
                {vendors?.map((vendor) => (
                  <NavLink
                    key={vendor?.id}
                    to={`/restaurant/${vendor?.id}`}
                    style={{ display: "flex" }}
                    className="lg:w-[18%] md:w-[23%] sm:w-[33%] w-[100%] xl:h-[320px] h-[100%]"
                  >
                    <RestaurantCard
                      vendor={vendor}
                      imgUrl={
                        vendor?.logo
                          ? vendor?.logo
                          : ImagesSrc.VendorCardPlaceholder3x
                      }
                      restaurantName={vendor?.display_name}
                      rating={vendor?.rating}
                      description={vendor?.description}
                      deliveryFees={vendor?.delivery_details?.delivery_fee}
                      deliveryDuration={vendor?.delivery_details?.delivery_time}
                      cardClasses={"mx-auto"}
                      available={vendor?.is_available}
                      cartData={cartData}
                    />
                  </NavLink>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </section>
      </main>
    </Layout>
  );
};

export default GridListWithSearchTemplate;
