import React from "react";

import { PropTypes } from "prop-types";
import { Carousel } from "react-responsive-carousel";

import { SliderRightArrow, SliderLeftArrow } from "utils/helpers/SVGsSrc";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./CustomSlider.module.css";

const arrowPositions = {
  bottom: {
    bottom: 0,
    leftRight: "calc(50% - 35px)",
  },
  baseline: {
    top: "calc(50% - 21px)",
    leftRight: 15,
  },
};

const CustomSlider = ({
  ArrowsPosition = "bottom",
  numItemsPerView,
  children,
}) => {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    width: 25,
    height: 21,
    cursor: "pointer",
    ...arrowPositions[ArrowsPosition],
  };

  return (
    <Carousel
      showIndicators={false}
      numItemsPerView={numItemsPerView}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev ? (
          <div>
            <div className={styles.sliderArrows}>
              <SliderRightArrow
                width="100%"
                height="100%"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  left: arrowPositions[ArrowsPosition].leftRight,
                  transform: "rotate(180deg)",
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.sliderArrows}>
              <SliderLeftArrow
                width="100%"
                height="100%"
                onClick={onClickHandler}
                title={label}
                style={{
                  ...arrowStyles,
                  left: arrowPositions[ArrowsPosition].leftRight,
                }}
              />
            </div>
          </div>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext ? (
          <div className={styles.sliderArrows}>
            <SliderRightArrow
              width="100%"
              height="100%"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
                right: arrowPositions[ArrowsPosition].leftRight,
              }}
            />
          </div>
        ) : (
          <div className={styles.sliderArrows}>
            <SliderLeftArrow
              width="100%"
              height="100%"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
                right: arrowPositions[ArrowsPosition].leftRight,
                transform: "rotate(180deg)",
              }}
            />
          </div>
        )
      }
    >
      {children}
    </Carousel>
  );
};

CustomSlider.propTypes = {
  ArrowsPosition: PropTypes.string,
  numItemsPerView: PropTypes.number,
  children: PropTypes.node,
};

export default CustomSlider;
