import { takeLatest, all, call, put } from "redux-saga/effects";

import CartActionTypes from "../types/cart.types";
import {
  AddCartItemAnotherVendorFailure,
  AddCartItemAnotherVendorSuccess,
  AddCartItemFailure,
  AddCartItemSuccess,
  ApplyPromoCodeFail,
  ApplyPromoCodeSucccess,
  CreateNewCartFail,
  CreateNewCartSuccess,
  DeleteCartDataFailure,
  DeleteCartDataSuccess,
  DeleteCartItemFailure,
  DeleteCartItemSuccess,
  DeliveryTimeRangeFail,
  DeliveryTimeRangeSuccess,
  ListCartDataFailure,
  ListCartDataStart,
  ListCartDataSuccess,
  RemovePromoCodeFail,
  RemovePromoCodeSuccess,
  UpdateCartDataFailure,
  UpdateCartDataStart,
  UpdateCartDataSuccess,
  UpdateCartItemFailure,
  UpdateCartItemStart,
  UpdateCartItemSuccess,
} from "../actions/cart.actions";

import * as URL from "services/URLs";

import getAxios from "utils/Axios";
import { StorageUtils } from "utils";
import { useDispatch } from "react-redux";

const axios = getAxios();

export function* CartData() {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_CART}?${queryParams.join("")}`
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])["selectedArea"]
      // && StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //   "selectedAddressType"
      // ] == "notSaved"
      //   ? `?area_id=${
      //       JSON.parse(
      //         StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //           "selectedArea"
      //         ]
      //       )?.id
      //     }`
      //   : ""}
    );
    if (res.status === 200) yield put(ListCartDataSuccess(res?.data?.data));
  } catch (error) {
    yield put(ListCartDataFailure(error));
  }
}

export function* onCartDataStart() {
  yield takeLatest(CartActionTypes.CART_DATA_START, CartData);
}

export function* UpdateCartData({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const data = {
      cart: {
        special_instructions: payload,
      },
    };
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_CART}?${queryParams.join("")}`,
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `?area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
      payload
    );
    if (res?.status === 200) yield put(UpdateCartDataSuccess(res?.data?.data));
  } catch (error) {
    yield put(UpdateCartDataFailure(error));
  }
}

export function* onUpdateCartDataStart() {
  yield takeLatest(CartActionTypes.UPDATE_CART_DATA_START, UpdateCartData);
}

export function* DeleteCart() {
  try {
    const res = yield call(axios.call, "delete", `${URL.DELETE_CART}`);
    if (res?.status === 200) yield put(DeleteCartDataSuccess(res?.data?.data));
  } catch (error) {
    yield put(DeleteCartDataFailure(error));
  }
}

export function* onDeleteCartStart() {
  yield takeLatest(CartActionTypes.DELETE_CART_DATA_START, DeleteCart);
}

export function* UpdateCartItem({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const data = {
      cart_item: {
        quantity: payload?.newQ,
        special_instructions: payload?.Instruction,
      },
    };
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_CART_ITEM}/${payload?.id}?${queryParams.join("")}`,
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `?area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
      data
    );
    if (res?.status === 200) {
      yield all([
        put(UpdateCartItemSuccess(res?.data?.data)),
        put(UpdateCartDataSuccess(res?.data?.cart)),
      ]);
    } else {
      yield put(UpdateCartItemFailure(true));
    }
  } catch (error) {
    yield put(UpdateCartItemFailure(error));
  }
}

export function* onUpdateCartItemStart() {
  yield takeLatest(CartActionTypes.UPDATE_CART_ITEM_START, UpdateCartItem);
}

export function* AddCartItem({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({
                lat,
                lng,
                delivery_type,
              }).length -
                1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const data = payload;
    const res = yield call(
      axios.call,
      "post",
      `${URL.ADD_CART_ITEM}?${queryParams.join("")}`,
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `?area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
      data
    );
    if (res?.status === 200) {
      yield all([
        put(AddCartItemSuccess(res?.data?.data)),
        put(UpdateCartDataSuccess(res?.data?.cart)),
      ]);
    } else {
      yield put(AddCartItemFailure(true));
    }
  } catch (error) {}
}

export function* onAddCartItemStart() {
  yield takeLatest(CartActionTypes.ADD_CART_ITEM_START, AddCartItem);
}

export function* AddCartItemAnotherVendor({ payload }) {
  try {
    const data = payload;

    yield call(axios.call, "delete", `${URL.DELETE_CART}`);
    const res = yield call(
      axios.call,
      "post",
      `${URL.ADD_CART_ITEM}`,
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `?area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
      data
    );
    if (res?.status === 200) {
      yield all([
        put(AddCartItemAnotherVendorSuccess(res?.data?.data)),
        put(UpdateCartDataSuccess(res?.data?.cart)),
      ]);
    } else {
      yield put(AddCartItemAnotherVendorFailure(true));
    }
  } catch (error) {}
}

export function* onAddCartItemAnotherVendorStart() {
  yield takeLatest(
    CartActionTypes.ADD_CART_ITEM_ANOTHER_VENDOR_START,
    AddCartItemAnotherVendor
  );
}

export function* DeleteItemCart({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "delete",
      `${URL.DELETE_CART_ITEM}/${payload.id}`
    );
    if (res?.status === 200) yield put(DeleteCartItemSuccess(res?.data));
  } catch (error) {
    yield put(DeleteCartItemFailure(error));
  }
}

export function* onDeleteItemCartStart() {
  yield takeLatest(CartActionTypes.DELETE_CART_ITEM_START, DeleteItemCart);
}

export function* DeliveryTimeRanges({ payload }) {
  try {
    const res = yield call(axios.call, "get", `${URL.DELIVERY_TIME_RANGES}`);
    if (res?.status === 200) yield put(DeliveryTimeRangeSuccess(res?.data));
  } catch (error) {
    yield put(DeliveryTimeRangeFail(error));
  }
}

export function* onDeliveryTimeRangeStart() {
  yield takeLatest(
    CartActionTypes.DELIVERY_TIME_RANGE_START,
    DeliveryTimeRanges
  );
}

export function* ApplyPromoCode({ payload }) {
  try {
    const res = yield call(axios.call, "post", URL.PROMO_CODE, payload);
    if (res?.status === 200) yield put(ApplyPromoCodeSucccess(res?.data?.data));
    else yield put(ApplyPromoCodeFail(true));
  } catch (error) {}
}

export function* onApplyPromoCode() {
  yield takeLatest(CartActionTypes.APPLY_PROMO_CODE_START, ApplyPromoCode);
}

export function* RemovePromoCode() {
  try {
    const res = yield call(axios.call, "post", URL.REMOVE_PROMO_CODE);
    if (res?.status === 200) yield put(RemovePromoCodeSuccess(res?.data?.data));
  } catch (error) {
    yield put(RemovePromoCodeFail(error));
  }
}

export function* onRemovePromoCode() {
  yield takeLatest(CartActionTypes.REMOVE_PROMO_CODE_START, RemovePromoCode);
}

export function* CreateCart({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "post",
      `${URL.CREATE_CART}`,
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `?area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
      payload
    );
    if (res?.status === 200) yield put(CreateNewCartSuccess(res?.data?.data));
  } catch (error) {
    yield put(CreateNewCartFail(error));
  }
}
export function* onCreateCart() {
  yield takeLatest(CartActionTypes.CREATE_CART_START, CreateCart);
}

export function* CartSagas() {
  yield all([
    call(onCartDataStart),
    call(onUpdateCartDataStart),
    call(onDeleteCartStart),
    call(onUpdateCartItemStart),
    call(onAddCartItemStart),
    call(onAddCartItemAnotherVendorStart),
    call(onDeleteItemCartStart),
    call(onApplyPromoCode),
    call(onRemovePromoCode),
    call(onCreateCart),
    call(onDeliveryTimeRangeStart),
  ]);
}
