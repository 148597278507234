import { useTranslation } from "react-i18next";

import { LocationGrey, Time } from "utils/helpers/SVGsSrc";

import { Typography } from "components/atoms";
import { Footer, NavBar } from "components/molecules";

import styles from "./About.module.css";
import Layout from "components/molecules/Layout";

const DetailsSection = ({ heading, description }) => {
  return (
    <section className="bg-primary-50 text-center p-6 lg:py-11 xl:py-14 3xl:py-20 lg:px-40 xl:px-[270px]  3xl:px-80">
      <Typography
        as="h2"
        size="7xl"
        color="text-primary-900"
        classes="mb-4"
        text={heading}
      />

      <Typography
        as="p"
        size="xl"
        lineHeight="leading-[1.53]"
        color="text-warm_grey"
        classes={"text-left"}
        text={description}
      />
    </section>
  );
};

const AboutTemplate = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <main className={styles.main}>
        <DetailsSection
          heading="What about Grow Business Development and Solutions?"
          description={
            <>
              <br />
              <span style={{ display: "block" }}>
                `The main goal of the company ("Grow; business development and
                solutions")is to help individuals and soho (small office, home
                office) get to their potential by providing solutions dedicated
                to each category of them. We started with home chefs and
                analyzed the main points preventing them from growing their
                business and taking it to the next level.
              </span>
              <br />
              <span style={{ display: "block" }}>
                We noticed that although home chefs might be very talented
                dedicated and passionate toward their work but still they might
                not have the needed business tools to grow their business. For
                example they do not know how to market for their work, how to
                access and analyze market data, managing Logistics and to put
                cost under control.
              </span>
              <br />
              <span style={{ display: "block" }}>
                We have the solution for all those missing tools.
              </span>
              <br />
              <span style={{ display: "block" }}>
                While consumers also have an obvious gap mainly due to the fact
                that they work with unknown providers who they do not trust. We
                also found the solution to this by ticking all the points before
                accepting any provider on our App.
              </span>
              <br />
              <span style={{ display: "block" }}>
                We have a quality assurance team who starts with our home chefs
                from the very beginning by providing them with free training
                including all the main food safety and hygiene need to know
                knowledge and also inspecting their kitchens to assure they are
                applying what they learn during the training.
              </span>
              <br />
              <span style={{ display: "block" }}>
                On the other hand it is mandatory for each chef who wants to
                join us to provide his/her medical card and sign the contract in
                which they commit on keeping the quality standards.
              </span>
              <br />
              <span style={{ display: "block" }}>
                After chefs are accepted we don't stop at this point but we
                continue following up on them by sudden audit visits to their
                work locations and also by mystery shopping from them to check
                on the final quality and shape of the food they provide
              </span>
              <br />
              <span style={{ display: "block" }}>
                Throughout the process and with the help of our customers
                reviews we eliminate the low performers and assist the high
                performers further by funding them to grow their business and
                take it the next level
              </span>
              <br />
              <span style={{ display: "block" }}>
                we are also preparing to have our supply chain, saving our home
                chefs from the hassle of checking the market for best price,
                easing the logistics, saving their time while also assuring our
                customers that we have the upper hand control over the
                ingredients their food is made
              </span>
              <br />
              <span style={{ display: "block" }}>
                Then we reach our final stage where we introduce our home chefs
                to public and assist them to start their business on ground in
                cobranded restaurants that can be partly funded by us
              </span>
              <br />
              <span style={{ display: "block" }}>
                along the way we can also assist the lower performers and not
                dropping them away, but we can still create a new more
                achievable role to them by recommending them to other chefs who
                needs a hand in their work more than 95% of our home chefs till
                the moment are strong women who want to help their families,
                introduce their talent to the world or even reach a dream of
                being a business woman one day
              </span>
            </>
          }
        />

        <div className="mt-8 lg:mt-20 2xl:mt-[120px]">
          <DetailsSection
            heading={t("Who we are ?")}
            description="We are an Egyptian joint stock company founded by Wael Ghali, with more than 20 years of experience in multinationals. Currently treasury and corporate financing director at Orange Egypt and previously Treasury Sr. Manager at Vodafone Egypt."
          />
        </div>

        <iframe
          src="https://maps.google.com/maps?q=34%20Abbas%20El-Akkad%2C%20Nasr%20City%2C%20Cairo%2C%20Egypt&t=m&z=15&output=embed&iwloc=near"
          loading="lazy"
          title="abla nazira"
          className="h-[286px] mt-8 lg:mt-20 2xl:mt-[120px] w-full"
        ></iframe>

        <section className="mx-4 mt-8 lg:mt-20 lg:mx-[130px] 2xl:[260px]">
          <div className="bg-primary-50 rounded-lg py-6 px-4  lg:px-10 xl:py-10 xl:px-20">
            <Typography
              as="h2"
              size="4xl"
              color="text-text_3b3b3b"
              classes="mb-4"
              text="Lorem ipsum"
            />
            <div className="flex justify-between items-center mb-4">
              <div className="w-4 h-5 ">
                <LocationGrey
                  width="100%"
                  height="100%"
                  title="abla nazira location"
                />
              </div>
              <Typography
                as="p"
                size="lg"
                lineHeight="leading-[1.7]"
                color="text-warm_grey"
                classes="w-[90%] lg:w-[96%] xl:w-[98%]"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
              />
            </div>

            <div className="flex justify-between items-center ">
              <div className="w-4 h-5">
                <Time width="100%" height="100%" title="abla nazira location" />
              </div>
              <Typography
                as="p"
                size="lg"
                lineHeight="leading-[1.7]"
                color="text-warm_grey"
                classes="w-[90%] lg:w-[96%] xl:w-[98%]"
                text="Working hours"
              />
            </div>
            <Typography
              as="p"
              size="lg"
              lineHeight="leading-[1.7]"
              color="text-warm_grey"
              classes="mt-2"
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
            />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default AboutTemplate;
