import { Stack } from "@mui/material";
import PasswordInput from "components/atoms/Input/PaswordInput";
import PhoneInput from "../PhoneInput/PhoneInput";

export default function PhoneTap({ formik }) {
  return (
    <Stack
      gap={2}
      sx={{
        ".MuiInputLabel-root": { fontSize: { xs: "16px", md: "18px" } },
        ".MuiInput-input": { color: "#000" },
      }}
    >
      <Stack>
        <PhoneInput
          id="mobile_number"
          name="mobile_number"
          inputValue={formik.values.mobile_number}
          setPhone={formik.handleChange}
          clickEnter={() => void 0}
          placeholder="Phone Number"
          labels="en"
          error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
          helperText={formik.touched.mobile_number && formik.errors.mobile_number}
          onChange={(e) =>
            formik.setValues({ ...formik?.values, mobile_number: e.target.value})
          }
        />
      </Stack>
      <PasswordInput
        name="password"
        value={formik?.values.password}
        onChange={formik.handleChange}
        error={formik.errors.password}
      />
    </Stack>
  );
}
