import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { ImagesSrc, StorageUtils } from "utils";

import { CustomButton, Img, Typography } from "components/atoms";
import { Footer, NavBar } from "components/molecules";

import styles from "./FailedPayment.module.css";
import Layout from "components/molecules/Layout";

const FailedPaymentTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    StorageUtils.saveItems(sessionStorage, [
      {
        name: "OrderTime",
        data: "",
      },
    ]);
  }, []);

  return (
    <main className={styles.main}>
      <Layout>
        <section className={styles.OrderIntro}>
          <Img
            fileName={ImagesSrc.FailedPayment}
            altText="Done"
            picClasses="object-cover"
            containerClasses="w-fit h-[270px] relative"
          />
          <Typography
            as="h1"
            color="text-text_3b3b3b"
            classes="font-bold mt-12 text-[40px] text-center"
            text={t("Payment Failed")}
          />
          <Typography
            as="h1"
            size="2xl"
            color="text-text_3b3b3b"
            classes="font-SFProText mt-2"
            text={t(
              "Your payment has failed due to some technical error, please try again."
            )}
          />
          <CustomButton
            width="w-full max-w-[200px]"
            height="h-[46px]"
            borderRadius="8px"
            classes={"mt-8"}
            action={() => navigate("/place_order")}
            label={t("Try Again")}
          />
        </section>
      </Layout>
    </main>
  );
};

export default FailedPaymentTemplate;
