import { toast, Slide } from "react-toastify";

export const toastTypes = {
  SUC: "success",
  ERR: "error",
  WAR: "warn",
};

export const showToast = (type, msg) => {
  toast[type](msg, { hideProgressBar: true, transition: Slide });
};
