import React from "react";

import { FullScreenBgLayout } from "components/Layouts";
import { SignUpForm } from "../AuthComponents";

const SignUpTemplate = () => {
  return (
    <main>
      <FullScreenBgLayout
        backgroundImage="SignUpBg"
        glassContainerWidth=" md:w-1/2 lg:w-[70%] "
        glassContainerHight="md:h-[90%]"
        glassContainerStyles=""
      >
        <SignUpForm />
      </FullScreenBgLayout>
    </main>
  );
};

export default SignUpTemplate;
