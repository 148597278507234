import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { CartIcon, CloseIcon } from "utils/helpers/SVGsSrc";

import { WithSpinner } from "Hocs";

import { selectCurrentUser } from "Redux/user";
import {
  ApplyPromoCodeStart,
  DeleteCartItemStart,
  ListCartDataStart,
  RemovePromoCodeStart,
  UpdateCartDataStart,
  UpdateCartItemStart,
} from "Redux/Cart/actions/cart.actions";

import {
  selectCartData,
  selectIsCartLoading,
} from "Redux/Cart/selectors/cart.selectors";

import { CustomButton, Typography, FormInput } from "components/atoms";
import { CartItem } from "components/molecules";
import { showToast, toastTypes } from "utils/helpers/Toast";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";
import { StorageUtils } from "utils";
import { toast } from "react-toastify";

const CartBody = ({
  CartData,
  UpdateCartItemHandleClick,
  CheckoutHandler,
  applyPromoCodeHandler,
  removePromoCodeHandler,
  deliveryOption,
  restaurantData,
  // setPromoCodeValue,
}) => {
  const { t } = useTranslation();
  const [promoCodeValue, setPromoCodeValue] = useState("");

  const [SpecialInstruction, setSpecialInstruction] = useState(
    CartData?.special_instructions
  );
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const selectedAddress = useSelector(selectSelectedAddress);

  return (
    <>
      {CartData &&
      CartData?.cart_items &&
      CartData?.cart_items?.length !== 0 &&
      CartData?.length !== 0 ? (
        <>
          <hr className="w-full bg-bg_e8e8e8" />
          <div className=" px-6 ">
            <Typography
              as="h2"
              size="sm"
              color="text-text_3b3b3b"
              classes="font-bold py-1"
              text={CartData?.vendor_name}
            />
          </div>
          <hr className="w-full bg-bg_e8e8e8" />
          {CartData?.cart_items?.map((item, i) => (
            <span key={i}>
              <CartItem
                ItemData={item}
                UpdateCartItemHandleClick={UpdateCartItemHandleClick}
              />
            </span>
          ))}
          <div className="px-6">
            <Typography
              as="span"
              size="xs"
              color="text-warm_grey"
              classes="py-1"
              text={t("Any thing else we need to know?")}
            />
            <FormInput
              type="textarea"
              bgColor="bg-bg_white"
              borderColor="border-bg_e8e8e8"
              width="w-full"
              value={SpecialInstruction ?? ""}
              onValueChange={(e) => {
                setSpecialInstruction(e.target.value);
              }}
              textareaRows={"2"}
              Resize={true}
            />
          </div>
          <div className=" px-6 ">
            <Typography
              as="h4"
              size="sm"
              color="text-text_3b3b3b"
              classes="font-bold pt-2 -pb-1"
              text={t("Save on your orders")}
            />
            {CartData?.promocode ? (
              <div className="w-full flex justify-between">
                <Typography
                  as="h4"
                  size="sm"
                  color="text-text_3b3b3b"
                  classes="font-bold pt-2 -pb-1"
                  text={
                    t("Applied Promocode") + ": " + CartData?.promocode?.value
                  }
                />
                <CloseIcon
                  className="mt-5"
                  onClick={() => removePromoCodeHandler()}
                />
              </div>
            ) : (
              <div className="w-full flex">
                <FormInput
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8 focus:border-bg_e8e8e8 !border-r-0 !border-l-[1px] rtl:!border-l-0 rtl:!border-r-[1px] focus:border-r-0 active:border-r-0 shadow-0"
                  width="w-full"
                  height="h-[35px]"
                  value={promoCodeValue}
                  onValueChange={(e) => {
                    setPromoCodeValue(e.target.value);
                  }}
                />
                <CustomButton
                  width="w-fit"
                  height="h-[35px]"
                  buttonTypeClass="outlined"
                  borderColor="!border-bg_e8e8e8 focus:border-bg_e8e8e8 active:border-bg_e8e8e8"
                  textColor="text-primary-900"
                  classes={
                    "border-l-0 border-r-[1px] rtl:border-l-[1px] rtl:border-r-0 text-[15px] px-2 backdrop-blur-0"
                  }
                  label={t("Cart Apply")}
                  action={() => {
                    applyPromoCodeHandler(promoCodeValue);
                  }}
                />
              </div>
            )}

            <Typography
              as="h4"
              size="sm"
              color="text-text_3b3b3b"
              classes="font-bold pt-2 -pb-1"
              text={t("Payment summary")}
            />
            <div className="w-full">
              <div className="w-full flex justify-between items-center">
                <Typography
                  as="h4"
                  size="xs"
                  color="text-text_3b3b3b"
                  classes="py-1 leading-4"
                  text={t("Subtotal")}
                />
                <Typography
                  as="h4"
                  size="xs"
                  classes="font-SFProText leading-4"
                  text={`${CartData?.sub_total} EGP`}
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <Typography
                  as="h4"
                  size="xs"
                  color="text-text_3b3b3b"
                  classes="py-1 leading-4"
                  text={t("Discount")}
                />
                <Typography
                  as="h4"
                  size="xs"
                  classes="font-SFProText leading-4"
                  text={`- ${CartData?.discount} EGP`}
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <Typography
                  as="h4"
                  size="xs"
                  color="text-text_3b3b3b"
                  classes="py-1 leading-4"
                  text={t("Discount for all items")}
                />
                <Typography
                  as="h4"
                  size="xs"
                  classes="font-SFProText leading-4"
                  text={`- ${CartData?.vendor_discount} EGP`}
                />
              </div>
              {deliveryOption == "delivery" && (
                <div className="w-full flex justify-between items-center">
                  <Typography
                    as="h4"
                    size="xs"
                    color="text-text_3b3b3b"
                    classes="py-1 leading-4"
                    text={t("Delivery fees")}
                  />
                  <Typography
                    as="h4"
                    size="xs"
                    classes="font-SFProText leading-4"
                    text={`${parseFloat(CartData?.delivery_fee).toFixed(
                      2
                    )} EGP`}
                  />
                </div>
              )}
              <div className="w-full flex justify-between items-center">
                <Typography
                  as="h4"
                  size="xs"
                  color="text-text_3b3b3b"
                  classes="py-1 leading-4"
                  text={t("Total")}
                />
                <Typography
                  as="h4"
                  size="xs"
                  classes="font-SFProText leading-4"
                  text={`${(Math.round(CartData?.total * 100) / 100).toFixed(
                    2
                  )} EGP`}
                />
              </div>
            </div>
            <CustomButton
              width="w-full"
              height="h-[46px]"
              borderRadius="8px"
              classes={"mt-4"}
              label={t("Checkout")}
              action={(e) => {
                if (!selectedAddress && !sessionLocation)
                  toast.error("please choose location");
                else CheckoutHandler(e, SpecialInstruction);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center">
            <Typography
              as="h1"
              size="md"
              color="text-warm_grey"
              classes="text-center leading-5 pb-3"
              text={t("There are no items In your cart")}
            />
            <CartIcon width="60px" height="100%" title="cart" />
          </div>
        </>
      )}
    </>
  );
};

const CartModule = ({ nav = false, deliveryOption, restaurantData }) => {
  const CartContainer = WithSpinner(CartBody);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLng = i18n?.language;
  const navigate = useNavigate();

  const CartData = useSelector(selectCartData);
  const IsCartLoading = useSelector(selectIsCartLoading);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentLng && currentUser && nav) {
      dispatch(ListCartDataStart());
    }
  }, [dispatch, currentLng, currentUser, nav]);

  // cart item handler
  const UpdateCartItemHandler = (data) => dispatch(UpdateCartItemStart(data));
  const DeleteCartItemHandler = (data) => dispatch(DeleteCartItemStart(data));
  const UpdateCartItemHandleClick = async (event, Type, ItemData) => {
    event.preventDefault();
    Type === "Add"
      ? UpdateCartItemHandler({
          id: ItemData.id,
          newQ: ItemData?.quantity + 1,
        })
      : ItemData?.quantity === 1
      ? DeleteCartItemHandler({ id: ItemData?.id })
      : UpdateCartItemHandler({
          id: ItemData.id,
          newQ: ItemData?.quantity - 1,
        });
  };

  // cart handler
  const UpdateCartHandler = (data) =>
    dispatch(UpdateCartDataStart({ cart: { special_instructions: data } }));

  const UpdateCartHandleClick = (event, NewSpecialInstruction) => {
    event.preventDefault();
    UpdateCartHandler(NewSpecialInstruction);
  };

  const CheckoutHandler = (e, SpecialInstruction) => {
    if (!CartData?.is_available) {
      showToast(toastTypes.ERR, "Vendor is currently not available");
    } else if (
      !CartData?.cart_items?.every((item) => item.available_quantity > 0)
    ) {
      showToast(
        toastTypes.ERR,
        "Product is currently sold out, please try again tomorrow"
      );
    } else if (CartData?.minimum_order <= CartData?.sub_total) {
      UpdateCartHandleClick(e, SpecialInstruction);
      navigate("/place_order");
    } else {
      showToast(toastTypes.ERR, "Minimum order amount not reached");
    }
  };

  const applyPromoCodeHandler = (promoCode) => {
    const body = { promocode: { value: promoCode } };
    dispatch(ApplyPromoCodeStart(body));
  };
  const removePromoCodeHandler = () => {
    dispatch(RemovePromoCodeStart());
  };

  return (
    <div className="w-[100%] lg:max-w-[350px] h-[fit-content] bg-bg_white py-5 rounded-0 lg:rounded-lg">
      <Typography
        as="h1"
        size="xl"
        color="text-text_3b3b3b"
        fontWeight="font-bold"
        classes="px-6 pb-4"
        text={t("Your cart")}
      />
      <CartContainer
        isLoading={IsCartLoading}
        CartData={CartData}
        UpdateCartItemHandleClick={UpdateCartItemHandleClick}
        CheckoutHandler={CheckoutHandler}
        applyPromoCodeHandler={applyPromoCodeHandler}
        removePromoCodeHandler={removePromoCodeHandler}
        deliveryOption={deliveryOption}
        restaurantData={restaurantData}
      />
    </div>
  );
};

CartModule.propTypes = {
  CartData: PropTypes.object,
  UpdateCartItemHandleClick: PropTypes.func,
  CheckoutHandler: PropTypes.func,
};

export default CartModule;
