export const LOGIN = "/customer/login";

export const LOGOUT = "/customer/logout";

export const REGISTER = "/customer/register";

export const VERIFY_OTP = "/auth/verify_otp";

export const SEND_VERIFICATION_MESSAGE = "/auth/send_verification_message";

export const RESET_PASSWORD = "/auth/reset_password";

export const FACEBOOK_LOGIN = "/auth/facebook_login";

export const GOOGLE_LOGIN = "/auth/google_login";

export const APPLE_LOGIN = "/auth/apple_login";

export const VERIFY_MOBILE = "/verify_mobile";

export const MAIN_CATEGORIES = "/product/main_categories";

export const VENDORS = "/vendor/vendors";

export const GET_OFFERS = "/vendor/get_offers";

export const VENDOR_FILTERS = "/vendor/filters";

export const ADD_fAVORITE_VENDOR = "/customer/new_favorite_vendor";

export const REMOVE_fAVORITE_VENDOR = "/customer/delete_favorite_vendor";

export const GET_NEARBY_VENDORS = "/vendor/get_nearby_vendors";

export const GET_TOP_OFFERS_VENDORS = "/vendor/get_top_offers_vendors";

export const GET_VENDOR_REVIEWS = "/vendor/vendor_reviews";

export const FAVORITE_VENDORS = "/customer/favorite_vendors";

export const GET_ORDERS = "/order/orders";

export const GET_CART = "/cart/cart";

export const UPDATE_CART = "/cart/update_cart";

export const DELETE_CART = "/cart/delete_cart";

export const CREATE_CART = "/cart/new_cart";

export const ADD_CART_ITEM = "/cart/new_cart_item";

export const DELETE_CART_ITEM = "/cart/delete_cart_item";
export const DELIVERY_TIME_RANGES = "/delivery_time_ranges";

export const UPDATE_CART_ITEM = "/cart/update_cart_item";

export const ADDRESSES = "/customer/addresses";

export const NEW_ADDRESS = "/customer/new_address";

export const DELETE_ADDRESS = "/customer/delete_address";

export const UPDATE_ADDRESS = "/customer/update_address";

export const SELECTED_ADDRESS = "/customer/selected_address";

export const CHECKOUT = "/order/checkout";

export const REORDER = "/order/reorder";

export const UPDATE_STATUS = "/order/update_status";

export const CANCEL_ORDER = "/order/cancel";

export const CONTACT_MESSAGE = "/contact_us";

export const UPDATE_PROFILE = "/customer/customer_profile";

export const UPDATE_MOBILE = "/update_mobile";

export const CHANGE_PASSWORD = "/auth/change_password";

export const FAQ = "/faq";

export const NOTIFICATION = "/notifications";

export const NOTIFICATION_COUNT = "/notifications_count";

export const NOTIFICATION_READ = "/set_read_notifications";

export const SET_FCM = "/set_fcm_token";

export const PAYMENT = "/payment/get_payment_info";

export const PROMO_CODE = "/cart/apply_promocode";

export const REMOVE_PROMO_CODE = "/cart/remove_promocode";

export const SET_MOBILE = "/set_mobile";

export const SYSTEM_CONFIGURATION = "/system_configurations";

export const CITIES = "/cities";

export const AREAS = "/areas";
