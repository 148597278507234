import { takeLatest, all, call, put } from "redux-saga/effects";

import AddressesActionsTypes from "../types/addresses.types";

import {
  DeleteAddressFailure,
  DeleteAddressSuccess,
  getSelectedAddressFailure,
  getSelectedAddressSuccess,
  listAreasByCityFailure,
  listAreasByCitySuccess,
  listCitiesFailure,
  listCitiesSuccess,
  listSavedAddressesFailure,
  listSavedAddressesSuccess,
  loadMoreAddressesSuccess,
  SaveAddressFailure,
  SaveAddressSuccess,
  setSelectedAddressFailure,
  setSelectedAddressSuccess,
  UpdateAddressFailure,
  UpdateAddressSuccess,
} from "../actions/addresses.actions";
import { saveUserDeliveryLocation } from "Redux/user/actions/user.actions";

import * as URL from "services/URLs";

import getAxios from "utils/Axios";
import { StorageUtils } from "utils";

const axios = getAxios();

export function* listSavedAddresses({ payload }) {
  const queryParams = Object.entries(payload).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx === Object.entries(payload).length - 1 ? "" : "&"
            }`
          : ""
      }`
  );
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.ADDRESSES}?${queryParams?.join("")}`
    );
    if (res?.status === 200) {
      if (payload.page_number > 1)
        yield put(
          loadMoreAddressesSuccess({
            savedAddresses: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      else
        yield put(
          listSavedAddressesSuccess({
            savedAddresses: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
    }
  } catch (error) {
    yield put(listSavedAddressesFailure(error));
  }
}

export function* onListSavedAddressesStart() {
  yield takeLatest(
    AddressesActionsTypes.LIST_SAVED_ADDRESSES_START,
    listSavedAddresses
  );
}

export function* onloadMoreAddressesStart() {
  yield takeLatest(
    AddressesActionsTypes.LOAD_MORE_ADDRESS_START,
    listSavedAddresses
  );
}

export function* SaveAddress({ payload }) {
  try {
    const data = { customer_address: payload };

    const res = yield call(axios.call, "post", URL.NEW_ADDRESS, data);

    if (res.status === 200)
      yield all([
        put(SaveAddressSuccess(res?.data?.data)),
        put(
          saveUserDeliveryLocation({
            ...res?.data?.data,
            isUserSavedAddress: true,
            lat: res?.data?.data.lat,
            lng: res?.data?.data.lng,
          })
        ),
        call(StorageUtils.saveItems, sessionStorage, [
          {
            name: "selectedAddressType",
            data: "saved",
          },
        ]),
      ]);
  } catch (error) {
    yield put(SaveAddressFailure(error));
  }
}

export function* onSaveAddressStart() {
  yield takeLatest(AddressesActionsTypes.SAVE_ADDRESS_START, SaveAddress);
}

export function* DeleteAddress({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "delete",
      `${URL.DELETE_ADDRESS}/${payload.id}`
    );
    if (res.status === 200)
      if (payload.isSelected)
        yield all([
          put(DeleteAddressSuccess(res?.data?.data)),
          put(
            saveUserDeliveryLocation({
              isUserSavedAddress: false,
            })
          ),
        ]);
      else {
        yield put(DeleteAddressSuccess(res?.data?.data));
      }
  } catch (error) {
    yield put(DeleteAddressFailure(error));
  }
}

export function* onDeleteAddressStart() {
  yield takeLatest(AddressesActionsTypes.DELETE_ADDRESS_START, DeleteAddress);
}

export function* UpdateAddress({ payload }) {
  const data = {
    customer_address: {
      name: payload.name,
      area: payload.area,
      // area_id: payload.area_id,
      // city_id: payload.city_id,
      street_name: payload.street_name,
      building: payload.building,
      floor_number: payload.floor_number,
      apartment_number: payload.apartment_number,
      mobile: payload.mobile,
      lat: payload.lat,
      lng: payload.lng,
      is_default: false,
      is_selected: payload.is_selected,
      landmark: payload.landmark,
    },
  };
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.UPDATE_ADDRESS}/${payload.id}`,
      data
    );
    if (res.status === 200)
      if (payload.is_selected)
        yield all([
          put(UpdateAddressSuccess(res?.data?.data)),
          put(
            saveUserDeliveryLocation({
              isUserSavedAddress: payload.is_selected,
              ...res?.data?.data,
            })
          ),
          call(StorageUtils.saveItems, sessionStorage, [
            {
              name: "selectedAddressType",
              data: "saved",
            },
          ]),
        ]);
      else {
        yield put(UpdateAddressSuccess(res?.data?.data));
      }
  } catch (error) {
    yield put(UpdateAddressFailure(error));
  }
}

export function* onUpdateAddressStart() {
  yield takeLatest(AddressesActionsTypes.UPDATE_ADDRESS_START, UpdateAddress);
}

export function* setSelectedAddress({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "put",
      `${URL.SELECTED_ADDRESS}/${payload}`
    );
    if (res.status === 200)
      yield all([
        put(setSelectedAddressSuccess(res?.data?.data)),
        put(
          saveUserDeliveryLocation({
            ...res?.data?.data,
            isUserSavedAddress: true,
          })
        ),
        call(StorageUtils.saveItems, sessionStorage, [
          {
            name: "selectedAddressType",
            data: "saved",
          },
        ]),
      ]);
  } catch (error) {
    yield put(setSelectedAddressFailure(error));
  }
}

export function* onSetSelectedAddressStart() {
  yield takeLatest(
    AddressesActionsTypes.SET_SELECTED_ADDRESS_START,
    setSelectedAddress
  );
}

export function* getSelectedAddress({ payload }) {
  try {
    const res = yield call(axios.call, "get", URL.SELECTED_ADDRESS);
    if (res.status === 200) {
      yield all([
        put(getSelectedAddressSuccess(res?.data?.data)),
        put(
          saveUserDeliveryLocation({
            ...res?.data?.data,
            isUserSavedAddress: res?.data?.data ? true : false,
          })
        ),
        call(StorageUtils.saveItems, sessionStorage, [
          {
            name: "selectedAddressType",
            data: res?.data?.data?.is_selected ? "saved" : "notSaved",
          },
        ]),
      ]);
    }
  } catch (error) {
    yield put(getSelectedAddressFailure(error));
  }
}

export function* onGetSelectedAddressStart() {
  yield takeLatest(
    AddressesActionsTypes.GET_SELECTED_ADDRESS_START,
    getSelectedAddress
  );
}

export function* listCities() {
  try {
    const res = yield call(axios.call, "get", URL.CITIES);
    if (res?.status === 200) {
      yield put(
        listCitiesSuccess({
          cities: res?.data?.data,
        })
      );
    }
  } catch (error) {
    yield put(listCitiesFailure(error));
  }
}

export function* onListCitiesStart() {
  yield takeLatest(AddressesActionsTypes.LIST_CITIES_START, listCities);
}

export function* listAreas({ payload }) {
  try {
    const res = yield call(axios.call, "get", `${URL.CITIES}/${payload}`);
    if (res?.status === 200) {
      yield put(
        listAreasByCitySuccess({
          areas: res?.data?.data,
        })
      );
    }
  } catch (error) {
    yield put(listAreasByCityFailure(error));
  }
}

export function* onListAreasStart() {
  yield takeLatest(AddressesActionsTypes.LIST_AREAS_BY_CITY_START, listAreas);
}

export function* addressesSagas() {
  yield all([
    call(onListSavedAddressesStart),
    call(onloadMoreAddressesStart),
    call(onSaveAddressStart),
    call(onDeleteAddressStart),
    call(onUpdateAddressStart),
    call(onSetSelectedAddressStart),
    call(onGetSelectedAddressStart),
    call(onListCitiesStart),
    call(onListAreasStart),
  ]);
}
