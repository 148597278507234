import CategoriesActionTypes from "../types/categories.types";

import { createAction } from "utils";

export const listCategoriesStart = () =>
  createAction(CategoriesActionTypes.LIST_CATEGORIES_START);

export const listCategoriesSuccess = (categories) =>
  createAction(CategoriesActionTypes.LIST_CATEGORIES_SUCCESS, categories);

export const listCategoriesFailure = (error) =>
  createAction(CategoriesActionTypes.LIST_CATEGORIES_FAILURE, error);
