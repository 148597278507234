import {
  AddCartItemAnotherVendorStart,
  AddCartItemStart,
  UpdateCartDataStart,
} from "Redux/Cart/actions/cart.actions";
import {
  ArrowDownGrey,
  ArrowUpGrey,
  ClockIcon,
  MinusSign,
  PlusSign,
} from "utils/helpers/SVGsSrc";
import { CustomButton, FormInput, Typography } from "components/atoms";
import { StorageUtils, getPayload } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { ValidationsWrapper } from "components/Containers";
import { selectCartData } from "Redux/Cart/selectors/cart.selectors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AddItemForm = ({ ItemData, ToggleModal, deliveryOption }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const CartData = useSelector(selectCartData);

  //init state
  const [CartItemDetails, setCartItemDetails] = useState({
    product_id: ItemData?.id,
    quantity: 1, //default
    product_size_id: ItemData?.sizes?.at(0)?.id, //default
    special_instructions: "",
    option_value_ids: [],
  });
  const [workingTimesOpen, setWorkingTimesOpen] = useState(false);

  //check if it is the same vendor or not
  const [diffVendor, setDiffVendor] = useState(false);

  useEffect(() => {
    if (diffVendor) {
      AddNewVendorCart(CartItemDetails);
      setDiffVendor(true);
    }
  }, [diffVendor]);

  const date = new Date();
  const AddCartHandler = (CartItemDetails) => {
    if (
      StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] == "now"
    ) {
      dispatch(
        UpdateCartDataStart({
          cart: {
            delivery_type: "now",
            delivery_time: `${date.getHours()}:${date.getMinutes()}`,
            delivery_date: `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`,
          },
        })
      );
    }
    dispatch(
      AddCartItemStart({
        delivery_service_type: deliveryOption,
        cart_item: { ...CartItemDetails },
      })
    );
  };
  const AddNewVendorCart = (CartItemDetails) =>
    dispatch(
      AddCartItemAnotherVendorStart({
        delivery_service_type: deliveryOption,
        cart_item: { ...CartItemDetails },
      })
    );

  const AddCartHandleClick = async (event) => {
    event.preventDefault();
    if (
      (CartData?.vendor_id === undefined || CartData?.vendor_id == null) &&
      CartData != []
    ) {
      AddCartHandler(CartItemDetails);
    } else if (ItemData?.vendor_id == CartData?.vendor_id) {
      AddCartHandler(CartItemDetails);
    } else {
      setDiffVendor(
        window.confirm(
          "Your cart contains items from another vendor. Do you wish to clear your cart and start a new order here ?"
        )
      );
    }
  };

  //Total price calculations
  const [Total, setTotal] = useState(0);
  const [BasePrice, setBasePrice] = useState(ItemData?.sizes?.at(0)?.price);
  const [ExtrasPrice, setExtrasPrice] = useState(0);

  const [isCheckboxValid, setIsCheckboxValid] = useState(false);

  const [productOptions, setProductOptions] = useState([]);

  // useEffect(() => {
  //   setCheckboxValues(
  //     ItemData?.product_options.map((item) => {
  //       return { name: item.id, min: item.min, max: item.max, ids: [] };
  //     })
  //   );
  // }, []);
  useEffect(() => {
    const newProductOptions = ItemData?.product_options.map((option) => {
      return { max: option.max, min: option.min, selectedPrices: [] };
    });
    setProductOptions(newProductOptions);
  }, []);
  useEffect(() => {
    setIsCheckboxValid(
      productOptions
        .map(
          (item) =>
            item.selectedPrices.length >= item.min &&
            item.selectedPrices?.length <= item.max
        )
        .every((each) => each == true)
    );
    // getPayload(
    //   {
    //     target: {
    //       name: "option_value_ids",
    //       value: [].concat.apply(
    //         [],
    //         CheckboxValues.map((item) => item.ids)
    //       ),
    //     },
    //   },
    //   CartItemDetails,
    //   setCartItemDetails
    // );
  }, [productOptions]);

  useEffect(() => {
    ItemData?.discount
      ? Object.keys(ItemData?.discount?.type) == 0
        ? setTotal(
            Math.round(
              ((BasePrice + ExtrasPrice) * CartItemDetails.quantity -
                ItemData?.discount?.value * CartItemDetails.quantity) *
                2
            ) / 2
          )
        : Object.keys(ItemData?.discount?.type) == 1
        ? setTotal(
            Math.round(
              (BasePrice + ExtrasPrice) *
                CartItemDetails.quantity *
                ((100 - ItemData?.discount?.value) / 100) *
                2
            ) / 2
          )
        : setTotal((BasePrice + ExtrasPrice) * CartItemDetails.quantity)
      : setTotal((BasePrice + ExtrasPrice) * CartItemDetails.quantity);
  }, [BasePrice, CartItemDetails.quantity, ExtrasPrice]);

  // useEffect(() => {
  //   if (Extras.length > 1) {
  //     setExtrasPrice(
  //       Extras.reduce(
  //         (previousValue, currentValue) =>
  //           previousValue.price + currentValue.price
  //       )
  //     );
  //   } else {
  //     if (Extras.length > 0) setExtrasPrice(Extras[0].price);
  //     else setExtrasPrice(0);
  //   }
  // }, [Extras]);

  useEffect(() => {
    let totalExtrasPrice = 0;
    productOptions.forEach((option) => {
      option.selectedPrices.forEach((price) => {
        totalExtrasPrice += price.price;
      });
    });
    setExtrasPrice(totalExtrasPrice);
    const selectedOptionValueIds = [];
    productOptions.forEach((option) => {
      option.selectedPrices.forEach((price) => {
        selectedOptionValueIds.push(price.id);
      });
    });
    setCartItemDetails({
      ...CartItemDetails,
      option_value_ids: selectedOptionValueIds,
    });
  }, [productOptions]);

  return (
    <ValidationsWrapper
      initialValues={{
        product_id: CartItemDetails.product_id,
        quantity: CartItemDetails.quantity,
        product_size_id: CartItemDetails.product_size_id,
        special_instructions: CartItemDetails.special_instructions,
        option_value_ids: [],
      }}
      rules={["product_id", "quantity", "product_size_id"]}
    >
      {({ FormikHandler }) => {
        return (
          <>
            <div className="w-full flex flex-col items-start py-2 md:py-4 px-2 md:px-6">
              <Typography
                as="h1"
                color="text-text_3b3b3b font-semibold text-[14px] md:text-xl"
                classes={"pb-1 sm:pb-4"}
                text={ItemData?.name}
              />
              <Typography
                as="p"
                color="text-warm_grey"
                classes={"text-[12px] md:text-[16px] text-left rtl:text-right"}
                text={ItemData?.description}
              />
              <div className="flex w-full">
                <div className="flex-col justify-start w-full">
                  {/* <Typography as="h4" size="xl" text={"Time icon"} /> */}
                  <div className="flex justify-start">
                    <Typography
                      as="h4"
                      size="lg"
                      color="text-warm_grey"
                      fontWeight={"font-semibold"}
                      text={t("Working Days")}
                    />
                  </div>

                  {workingTimesOpen ? (
                    <div>
                      <div className="flex justify-start flex-wrap">
                        {ItemData?.week_days?.length == 1 ? (
                          <Typography
                            as="h4"
                            size="lg"
                            color="text-warm_grey"
                            text={ItemData?.week_days?.at(0)?.day}
                          />
                        ) : (
                          <>
                            {ItemData?.week_days?.map((item, i) => (
                              <Typography
                                as="h4"
                                size="lg"
                                color="text-warm_grey"
                                text={`${item?.day} ${
                                  ItemData?.week_days?.length == i + 1
                                    ? ""
                                    : " /"
                                }`}
                              />
                            ))}
                            {/* <Typography
                              as="h4"
                              size="lg"
                              color="text-warm_grey"
                              text={ItemData?.week_days?.at(0)?.day + " :"}
                            />
                            <Typography
                              as="h4"
                              size="lg"
                              color="text-warm_grey"
                              text={ItemData?.week_days?.at(-1)?.day}
                              classes={"mx-2"}
                            /> */}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  className="flex justify-end cursor-pointer"
                  onClick={() => setWorkingTimesOpen(!workingTimesOpen)}
                >
                  {workingTimesOpen ? (
                    <ArrowUpGrey className="mt-4 " />
                  ) : (
                    <ArrowDownGrey className="mt-4 " />
                  )}

                  {/* <Typography
                      classes={"cursor-pointer"}
                      as="h4"
                      size="xl"
                      text={"Arrow"}
                      action={() => setWorkingTimesOpen(!workingTimesOpen)}
                    /> */}
                </div>
              </div>
              <div className="flex justify-start w-full">
                <Typography
                  as="h4"
                  size="lg"
                  color="text-warm_grey"
                  text={t("Stock") + ": "}
                />
                <Typography
                  as="h4"
                  size="lg"
                  color={
                    ItemData?.available_quantity > 0
                      ? "text-[#48B02C]"
                      : "text-[#FE0000]"
                  }
                  text={
                    ItemData?.available_quantity > 0
                      ? t("In Stock")
                      : t("Out of Stock")
                  }
                  classes={"mx-2"}
                />
              </div>
              <div className="flex justify-start w-full">
                <Typography
                  as="h4"
                  size="lg"
                  color="text-warm_grey"
                  text={t("Available Quantity") + ": "}
                />
                <Typography
                  as="h4"
                  size="lg"
                  color={
                    ItemData?.available_quantity > 0
                      ? "text-[#48B02C]"
                      : "text-[#FE0000]"
                  }
                  text={ItemData?.available_quantity}
                  classes={"mx-2"}
                />
              </div>
              <div className="w-full flex items-end justify-between">
                <Typography
                  as="p"
                  color="text-primary-900"
                  classes={"text-[12px] md:text-[16px] font-semibold"}
                  text={t("Price on selection")}
                />
                <span className="flex items-center bg-bg_e4e4e4/[0.2] px-2 py-1">
                  <MinusSign
                    id={"minus"}
                    key={"minus"}
                    name="quantity"
                    width={"15px"}
                    height={"100%"}
                    className={
                      FormikHandler.values.quantity === 1
                        ? " opacity-25"
                        : "cursor-pointer"
                    }
                    value={FormikHandler.values.quantity}
                    onClick={() => {
                      if (FormikHandler.values.quantity > 1) {
                        FormikHandler.setFieldValue(
                          "quantity",
                          FormikHandler.values.quantity - 1
                        );
                        getPayload(
                          {
                            target: {
                              name: "quantity",
                              value: FormikHandler.values.quantity - 1,
                            },
                          },
                          CartItemDetails,
                          setCartItemDetails
                        );
                      }
                    }}
                  />

                  <Typography
                    as="span"
                    color="text-text_3b3b3b"
                    lineHeight="leading-none"
                    classes="px-4 font-SFProText  text-[24px]"
                    text={FormikHandler.values.quantity}
                  />

                  <PlusSign
                    id={"add"}
                    key={"add"}
                    name="quantity"
                    width={"16px"}
                    height={"16px"}
                    className="cursor-pointer"
                    value={FormikHandler.values.quantity}
                    onClick={() => {
                      if (
                        ItemData?.available_quantity > 0 &&
                        FormikHandler.values.quantity <
                          ItemData?.available_quantity
                      ) {
                        FormikHandler.setFieldValue(
                          "quantity",
                          FormikHandler.values.quantity + 1
                        );
                        getPayload(
                          {
                            target: {
                              name: "quantity",
                              value: FormikHandler.values.quantity + 1,
                            },
                          },
                          CartItemDetails,
                          setCartItemDetails
                        );
                      } else {
                        toast.error(
                          `There is ${ItemData?.available_quantity} ${ItemData?.name} left in stock`
                        );
                      }
                    }}
                  />
                </span>
              </div>
            </div>
            <hr className={"w-full h-[1px]  bg-text_bfbfbf"} />
            <div className="w-full flex flex-col items-start py-2 md:py-4 px-2 md:px-6">
              <Typography
                as="h1"
                size="xl"
                color="text-text_3b3b3b font-semibold text-[14px] md:text-lg mb-1"
                text={t("Select size")}
              />
              <Typography
                as="p"
                color="text-warm_grey"
                classes={"text-[10px] md:text-[12px]"}
                text={t("Choose one")}
              />

              <div className="sm:flex justify-between w-full mt-2 flex-wrap">
                {ItemData?.sizes.map((item) => {
                  return (
                    <div className="mx-2">
                      <FormInput
                        checked={
                          CartItemDetails.product_size_id == item.id
                            ? true
                            : false
                        }
                        id={item?.id}
                        key={item?.id}
                        name="product_size_id"
                        type="radio"
                        fullWidth={false}
                        width="w-5"
                        height="h-5"
                        value={item?.id}
                        required
                        onValueChange={(e) => {
                          FormikHandler.setFieldValue(
                            "product_size_id",
                            e.target.value ? e.target.value : item.id
                          );
                          getPayload(
                            {
                              target: {
                                name: "product_size_id",
                                value: parseInt(e.target.value),
                              },
                            },
                            CartItemDetails,
                            setCartItemDetails
                          );
                          setBasePrice(item.price);
                        }}
                        containerClasses="selectInputs"
                        label={`${item?.size_name} ( ${item?.price} EGP )`}
                        labelSize={"md"}
                        textColor="text-text_777777"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {ItemData?.product_options.map((item, i) => (
              <>
                {item?.is_hidden || item?.option_values.length == 0 ? (
                  ""
                ) : (
                  <>
                    <hr className={"w-full h-[1px]  bg-text_bfbfbf"} />
                    <div className="w-full flex flex-col items-start py-2 md:py-4 px-2 md:px-6">
                      <div className="flex items-center">
                        <Typography
                          as="h1"
                          size="xl"
                          color="text-text_3b3b3b font-semibold text-[14px] md:text-lg mb-1"
                          text={item?.name}
                        />

                        {item?.min == 0 ? (
                          <Typography
                            as="p"
                            color="text-warm_grey"
                            classes={"text-[10px] md:text-[12px] px-3"}
                            text={`( ${t("Optional")} )`}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <Typography
                        as="p"
                        color="text-warm_grey"
                        classes={"text-[10px] md:text-[12px]"}
                        text={t("Choose items from the list")}
                      /> */}
                      <Typography
                        as="p"
                        color="text-warm_grey"
                        classes={"text-[10px] md:text-[12px]"}
                        text={item?.description}
                      />

                      <div className="w-full sm:flex justify-between flex-wrap mt-2">
                        {item?.option_values?.map((data, index) => {
                          return (
                            <span className={"w-[50%]"} key={data?.id}>
                              <FormInput
                                checked={
                                  productOptions[i]?.selectedPrices.find(
                                    (optionValue) =>
                                      optionValue?.id === data?.id
                                  )
                                    ? true
                                    : false

                                  // .find(
                                  //   (go) => go.id == data?.product_option_id
                                  // )
                                  // ?.ids.find((red) => red == data.id)
                                  // ? true
                                  // : false
                                }
                                id={data?.id}
                                name={data?.product_option_id}
                                fullWidth={false}
                                type="checkbox"
                                width="w-5"
                                height="h-5"
                                containerClasses="selectInputs"
                                inputTypeClass="solidCheckbox"
                                label={
                                  data?.price_addition != 0
                                    ? `${data?.name} ( ${data?.price_addition} EGP )`
                                    : `${data?.name}`
                                }
                                // : `${data?.name} ( Free )`
                                value={data?.id}
                                onValueChange={(e) => {
                                  //If the option_value is already selected then remove it
                                  if (
                                    productOptions[i]?.selectedPrices.find(
                                      (optionValue) =>
                                        optionValue.id === data?.id
                                    )
                                  ) {
                                    const filteredSelectedPrices =
                                      productOptions[i].selectedPrices.filter(
                                        (selected) => selected.id !== data?.id
                                      );
                                    const updatedProductOptions = [
                                      ...productOptions,
                                    ];
                                    updatedProductOptions[i].selectedPrices =
                                      filteredSelectedPrices;

                                    setProductOptions(updatedProductOptions);
                                  }
                                  //If the option_value is not selected add it to the selectedPrices list (inside productOptions)
                                  else {
                                    //if the selected prices list has not reached it's defined maximum
                                    if (
                                      productOptions[i]?.selectedPrices.length <
                                      productOptions[i].max
                                    ) {
                                      const productOptionsCopy = [
                                        ...productOptions,
                                      ];
                                      productOptionsCopy[i].selectedPrices.push(
                                        {
                                          id: data.id,
                                          price: data.price_addition,
                                        }
                                      );
                                      setProductOptions(productOptionsCopy);
                                    }
                                    //if it has reached it's maximum
                                    //Pop the last selected option and its price from the list and add the newly selected one
                                    else {
                                      const prodOptionsCopy = [
                                        ...productOptions,
                                      ];
                                      prodOptionsCopy[i].selectedPrices.pop();
                                      prodOptionsCopy[i].selectedPrices.push({
                                        id: data.id,
                                        price: data.price_addition,
                                      });
                                      setProductOptions(prodOptionsCopy);
                                    }
                                  }

                                  // min & max check
                                  // if (
                                  //   CheckboxValues.find(
                                  //     (item) => item.name == e.target.name
                                  //   )
                                  // ) {
                                  //   setCheckboxValues([
                                  //     ...CheckboxValues.filter(
                                  //       (item) => item.name != e.target.name
                                  //     ),
                                  //     {
                                  //       name: e.target.name,
                                  //       min: item.min,
                                  //       max: item.max,
                                  //       //finding the id of the option value in the checkboxes (product options representation)
                                  //       ids: CheckboxValues.find(
                                  //         (item) => item.name == e.target.name
                                  //       ).ids.find(
                                  //         (data) => data == e.target.value
                                  //       )
                                  //         ? //if the id already exists in the list (selected) then remove it (return the list without it)
                                  //           CheckboxValues.find(
                                  //             (item) =>
                                  //               item.name == e.target.name
                                  //           ).ids.filter(
                                  //             (data) => data != e.target.value
                                  //           )
                                  //         : CheckboxValues.find(
                                  //             (item) =>
                                  //               item.name == e.target.name
                                  //           ).ids?.length >= item.max
                                  //         ? // if ids selected exceeds max remove last selected id and add the new one
                                  //           [
                                  //             ...CheckboxValues.find(
                                  //               (item) =>
                                  //                 item.name == e.target.name
                                  //             ).ids.slice(-1),
                                  //             e.target.value,
                                  //           ]
                                  //         : //if it doesn't exceed max then just add the selected id
                                  //           [
                                  //             ...CheckboxValues.find(
                                  //               (item) =>
                                  //                 item.name == e.target.name
                                  //             ).ids,
                                  //             e.target.value,
                                  //           ],
                                  //     },
                                  //   ]);
                                  // } else {
                                  //   setCheckboxValues([
                                  //     ...CheckboxValues,
                                  //     {
                                  //       name: e.target.name,
                                  //       min: item.min,
                                  //       max: item.max,
                                  //       ids: [e.target.value],
                                  //     },
                                  //   ]);
                                  // }
                                  // // Calculating total price
                                  // if (
                                  //   Extras[index].find(
                                  //     (id) => id.id === e.target.value
                                  //   )
                                  // ) {
                                  //   setExtras(
                                  //     Extras.filter(
                                  //       (price) => price.id !== e.target.value
                                  //     )
                                  //   );
                                  // } else {
                                  //   if (Extras.length >= item.max) {
                                  //     setExtras([
                                  //       ...Extras.slice(-1),
                                  //       {
                                  //         id: e.target.value,
                                  //         price: item?.option_values.find(
                                  //           (id) => id.id == e.target.value
                                  //         ).price_addition,
                                  //       },
                                  //     ]);
                                  //   } else {
                                  //     setExtras([
                                  //       ...Extras,
                                  //       [
                                  //         ...Extras[index],
                                  //         {
                                  //           id: e.target.value,
                                  //           price: item?.option_values.find(
                                  //             (id) => id.id == e.target.value
                                  //           ).price_addition,
                                  //         },
                                  //       ],
                                  //     ]);
                                  //   }
                                  // }
                                }}
                                labelSize="xl"
                                textColor="text-text_777777"
                              />
                            </span>
                          );
                        })}
                      </div>
                      {/* || item.min == 0 */}
                      {isCheckboxValid ? (
                        ""
                      ) : (
                        <small className="text-fail text-xs mt-1 ml-1">
                          {item.min == 0
                            ? `${t("Optional but not more than")} ${item.max}`
                            : item?.min == item?.max
                            ? `${t("Please choose only")} ${item.min}`
                            : `${t("Please choose at least")} ${item.min} ${t(
                                "& not more than"
                              )} ${item.max}`}
                        </small>
                      )}
                    </div>
                  </>
                )}
              </>
            ))}
            <hr className={"w-full h-[1px]  bg-text_bfbfbf"} />
            <div className="w-full flex flex-col items-start py-2 md:py-4 px-2 md:px-6">
              <Typography
                as="h1"
                size="xl"
                color="text-text_3b3b3b font-semibold text-[14px] md:text-lg mb-1"
                text={t("Special instructions")}
              />
              <Typography
                as="p"
                color="text-warm_grey"
                classes={"text-[10px] md:text-[12px] mb-4"}
                text={t("Let us know if you have any requests with this order")}
              />
              <FormInput
                id={"special_instructions"}
                key={"special_instructions"}
                value={FormikHandler.values.special_instructions}
                placeholder={"No instructions"}
                type="textarea"
                bgColor="bg-bg_white"
                borderColor="border-bg_e8e8e8"
                width="w-full"
                Resize={true}
                onValueChange={(e) => {
                  FormikHandler.setFieldValue(
                    "special_instructions",
                    e.target.value
                  );
                  getPayload(
                    {
                      target: {
                        name: "special_instructions",
                        value: e.target.value,
                      },
                    },
                    CartItemDetails,
                    setCartItemDetails
                  );
                }}
              />
              <CustomButton
                width="w-full"
                height="h-[46px]"
                borderRadius="8px"
                classes={"mt-4"}
                action={(e) => {
                  AddCartHandleClick(e);
                  ToggleModal();
                }}
                disabled={!isCheckboxValid}
                label={
                  <div className="w-full flex justify-between px-5">
                    <Typography
                      as="h1"
                      size="xl"
                      color="text-text_white font-semibold text-[14px] md:text-[20px]"
                      classes={"uppercase"}
                      text={t("Add to cart")}
                    />
                    <Typography
                      as="h1"
                      size="xl"
                      color="text-text_white font-SFProText font-semibold text-[14px] md:text-[20px]"
                      text={`${Total} EGP`}
                    />
                  </div>
                }
              />
            </div>
          </>
        );
      }}
    </ValidationsWrapper>
  );
};

export default AddItemForm;

// All Selected values
// let newSelectedItems = [
//   ...FormikHandler.values.option_value_ids,
//   e.target.value,
// ];
// if (
//   FormikHandler.values.option_value_ids.includes(
//     e.target.value
//   )
// ) {
//   newSelectedItems = newSelectedItems.filter(
//     (id) => id !== e.target.value
//   );
// }
// // Limit selection
// var checks = document.querySelectorAll(
//   `[name='${data?.product_option_id}']`
// );
// for (var i = 0; i < checks.length; i++)
//   checks[i].onclick = selectiveCheck;
// function selectiveCheck(event) {
//   var checkedChecks =
//     document.querySelectorAll(
//       `[name='${data?.product_option_id}']:checked`
//     );
//   if (checkedChecks.length >= item?.max)
//     return false;
// }

// Updating
// FormikHandler.setFieldValue(
//   "option_value_ids",
//   newSelectedItems
// );
// getPayload(
//   {
//     target: {
//       name: "option_value_ids",
//       value: newSelectedItems,
//     },
//   },
//   CartItemDetails,
//   setCartItemDetails
// );
