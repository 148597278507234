import { createSelector } from "reselect";

const selectCart = (state) => state.cart;

export const selectCartData = createSelector([selectCart], (cart) => cart.cart);
export const deliveryTimeRanges = createSelector(
  [selectCart],
  (cart) => cart.deliveryTimeRanges
);

export const selectIsCartLoading = createSelector(
  [selectCart],
  (cart) => cart.loading
);

export const selectIsPromoError = createSelector(
  [selectCart],
  (cart) => cart.PromoError
);

export const selectOrderTime = createSelector(
  [selectCart],
  (cart) => cart.OrderTime
);
