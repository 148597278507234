import { all, call } from "redux-saga/effects";

import { userSagas } from "./user/sagas/user.sagas";
import { categoriesSagas } from "./categories/sagas/categories.sagas";
import { RestaurantsSagas } from "./vendors/sagas/vendors.sagas";
import { offersSagas } from "./offers/sagas/offers.sagas";
import { ordersSagas } from "./orders/sagas/orders.sagas";
import { CartSagas } from "./Cart/sagas/cart.sagas";
import { addressesSagas } from "./addresses/sagas/addresses.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(categoriesSagas),
    call(offersSagas),
    call(ordersSagas),
    call(RestaurantsSagas),
    call(CartSagas),
    call(addressesSagas),
  ]);
}
