import { useTranslation } from "react-i18next";

import { FullScreenBgLayout } from "components/Layouts";
import { /* CustomButton, */ Typography } from "components/atoms";

const TermsOfUseTemplate = () => {
  const { t } = useTranslation();

  return (
    <FullScreenBgLayout
      backgroundImage="PrivacyPolicyBg"
      glassContainerWidth="md:w-4/5 xl:w-7/12"
      glassContainerHight="md:h-max"
    >
      <main className="h-full">
        <div className="h-full flex flex-col justify-between">
          <Typography
            as="h1"
            size="9xl"
            color="text-text_white"
            classes="text-center"
            text={t("Terms of use")}
          />
          <div className="h-96 w-full whiteScroll overflow-y-scroll my-4 lg:my-8 3xl:my-16">
            <div className=" px-5 md:px-8 lg:px-14 3xl:px-20 ">
              <Typography
                as="p"
                X
                size="xl"
                color="text-text_grey"
                text={`المقدمّة :
منصة ابلة نظيرة المملوكة شركة - Grow business development and solutions جرو بيزنس ديفلوبمينت اند سليوشنز للتسويق،  مقرها مكتب E08 الكائن بالطابق الرابع من العقار رقم 100 لشارع الميرغني و يمثلها قانوناً السيد / وائل مجدى فؤاد غالى بصفته  رئيس مجلس الادارة بموجب السجل التجاري رقم 180645 الصادر من مكتب استثمار القاهرة ترحّب بكم، و تبلغكم بأنكم سوف تجدون أدناه الشروط والأحكام المُنظّمة لاستخدامكم لمنصة ابلة نظيرة وكافة الآثار القانونية التي تنتج عن استخدامك لخدمات منصة ابلة نظيرة في الشبكة الانترنت.
نظرًا لاستخدام أي شخص يستخدم منصة ابلة نظيرة، سواء كان مستهلكًا أو تاجرًا أو غير ذلك، فإن هذا يعتبر موافقة وقبولًا منهم، وهم مؤهلون تمامًا قانونًا  لجميع المواد والشروط، وأحكام هذه الاتفاقية وهي تأكيد لالتزامكم بلوائحها وما ورد فيها.
`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`المادة 1 :- أهلية التاجر القانونية:`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`اولا: أهلية التاجر القانونية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يقر التاجر بأنه ذا أهلية قانونية، وأن عمره لا يقل عن ثمانية عشرة عاماً، كما هو معتبر في القانون المصري، وأنه ذا دراية كافية لإنشاء وتأسيس و إدارة متجره الإلكتروني عبر منصة ابلة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يجوز للتاجر أن يمارس التجارة إذا كان يقل عن سن الرشد المعتبر القانون المصري (ثمانية عشرة عاماً)، حيث أنه يلتزم بأن يقدّم ما يثبت موافقة وليه على تأسيسه للمتجر الإلكتروني.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	يقر التاجر بأنه ذا أهلية سليمة وأنه غير مصاب بأي عارض من عوارض الأهلية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	في حال قيام التاجر بالتسجيل كمؤسسة أو شركة، فإن هذه المؤسسة أو الشركة عن طريقه يجب أن تتوافر فيه الأهلية القانونية اللازمة للقيام بأعمال التجارة عبر منصة ابلة نظيرة.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	يوافق التاجر بأنه في حال مخالفته لهذه المادة ” أهلية التاجر القانونية ” فإنه يتحمّل تبعات هذه المخالفة أمام المستهلكين أو المستخدمين أو الغير، حيث أن منصة ابلة نظيرة  لا علاقة لها بتعاملات التاجر مع المستهلك أو الغير من الناحية، كما أنه يحق لمنصة ابلة نظيرة في هذه الحالة مسألة التاجر بموجب أحكام المسؤولية العقدية، ومطالبته بالتعويض عن الأضرار التي قد تنشأ نتيجة هذه المخالفة، و من قبيل هذه الأضرار ما يصيب منصة ابلة نظيرة في سمعتها أمام التجار الآخرين أو المستهلكين أو المستخدمين أو أمام الغير.`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`ثانيا: أهلية المستخدم القانونية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يقر المستخدم بأنه ذا أهلية قانونية، وأن عمره لا يقل عن ثمانية عشرة عاماً، كما هو معتبر في القانون المصري، وأنه ذا دراية كافية لإدارة حسابة الإلكتروني عبر منصة ابلة نظيرة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يقر المستخدم بأنه ذا أهلية سليمة وأنه غير مصاب بأي عارض من عوارض الأهلية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	يوافق المستخدم بأنه في حال مخالفته لهذه المادة ” أهلية التاجر القانونية ” فإنه يتحمّل تبعات هذه المخالفة أمام التجار أو المستخدمين أو الغير، `}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes={"font-bold my-4"}
                text={`المادة 2 :- نطاق الخدمات`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                text={`تستخدم  منصتنا لتقديم تسويق و ترويج المنتجات و الخدمات من خلال تكنولوجيا المعلومات داخل جمهوريه مصر العربية.
يتعين على المستخدمين إدخال معلومات شخصية. يجب أن تكون المعلومات صحيحة أو صحيحة بشكل عشوائي. يتعين عليك توافق على:
`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                classes="my-2"
                text={`1)	شروط الاستخدام المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                classes="my-2"
                text={`2)	سياسة الخصوصية المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="2xl"
                color="text-text_grey"
                classes="font-bold my-4"
                text={`المادة 3 – الحسابات والتزامات التسجيل:`}
              />
              <Typography
                as="p"
                size="xl"
                color="text-text_grey"
                text={`فور التقدم بطلب الانضمام إلى عضوية منصة ابلة نظيرة تكون مطالباً بالإفصاح عن معلومات محددة واختيار اسم مستخدم وكلمة مرور سرية لاستعمالها عند الوصول لخدمات منصة ابلة نظيرة وبعد تنشيط حسابك سوف تصبح مستخدمًا لخدمات منصة ابلة نظيرة، وبذلك تكون قد وافقت على:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	أن تكون مسؤولاً عن المحافظة على سرية معلومات حسابك وسرية كلمة المرور، و تكون بذلك موافقاً على إعلام منصة ابلة نظيرة حالاً بأي استخدام غير مفوض به لمعلومات حسابك لدى منصة ابلة نظيرة أو أي اختراق آخر لمعلوماتك السرية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	لن تكون منصة ابلة نظيرة بأي حال من الأحوال مسؤولًة عن أي خسارة قد تلحق بك بشكل مباشر أو غير مباشر معنوية أو مادية نتيجة كشف معلومات اسم المستخدم أو كلمة الدخول أو في حال إساءة استخدام المتجر.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	أنت تلتزم باستخدام حسابك لدى منصة ابلة نظيرة بنفسك، حيث أنك مسؤولاً عنه مسؤولية كاملة، وفي حال استخدام غيرك له فهذه يعني أنك قد فوّضته باستخدام باسمك ولحسابك مالم تقوم بإبلاغ إدارة منصة ابلة نظيرة بعكس ذلك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	أنت تلتزم عند استخدام منصة ابلة نظيرة أن تستخدمها بكل جدية ومصداقية، وأن تلتزم بقواعد وأحكام اتفاقية الاستخدام وأن تلتزم بالضوابط النظامية و القانونية المعمول بها في جمهورية مصر العربية، وتعتبر ملزماً بتعويض منصة ابلة نظيرة عن أي خسائر مباشرة أو غير مباشرة قد تلحق بمنصة ابلة نظيرة نتيجة أي استخدام غير شرعي أو غير حقيقي أو غير مفوض لحسابك من طرفك أو من طرف أي شخص آخر حصل على مفاتيح الوصول إلى حسابك بالمنصة سواء كان لإنجاز الخدمات باستعمال اسم المستخدم وكلمة المرور أو نتيجة لإهمالك المحافظة على سرية اسم المستخدم وكلمة المرور، وسواء بتفويض منك أو بلا تفويض.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	أنت تلتزم بالإفصاح عن معلومات حقيقية وصحيحة ومحدثة و كاملة وقانونية عن نفسك حسبما هو مطلوب أثناء التسجيل لدى منصة ابلة نظيرة وتلتزم بتحديث بياناتك في حال تغييرها في الواقع أو في حال الحاجة إلى ذلك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`6)	ألا تدرج ضمن اسم المستخدم أي من تفاصيل الاتصال بك كعناوين البريد الإلكتروني أو أرقام هواتفك أو أي تفاصيل شخصية، أو أي عبارة تُشير إلى علاقة شخصية أو تجارية بينك وبين منصة ابلة نظيرة أو مُلّاكها.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`7)	ألا تضع ما يشير في متجرك إلى أي علاقة مباشرة أو غير مباشرة بين المتجر و منصة ابلة نظيرة أو إدارتها أو مُلّاكها، حيث أن منصة ابلة نظيرة لا علاقة لها بما تقوم به في متجرك وهي غير مسؤولة عن نشاط متجرك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`8)	إن منصة ابلة نظيرة تلتزم بمعالجة معلوماتك الشخصية وعناوين الاتصال بك بطريقة سريّة وفقاً لأحكام سياسة الخصوصية و شروط الاستخدام المعمول بها لدى منصة ابلة نظيرة`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`9)	سوف تكون ملزمًا بالحفاظ على بيانات التسجيل و تحديثها دائمًا للإبقاء عليها حقيقية و صحيحة و راهنة و كاملة و قانونية، و إذا أفصحت عن معلومات غير حقيقية أو غير صحيحة أو غير راهنة أو غير كاملة أو غير قانونية او مخالفة لما جاء في اتفاقية الاستخدام، فإن منصة ابلة نظيرة تمتلك الحق في وقف أو تجميد أو إلغاء عضويتك أو متجرك و حسابك في المنصة، وذلك دون إلحاق الأضرار بحقوق منصة ابلة نظيرة الأخرى و وسائله المشروعة في استرداد حقوقها وحماية باقي المستخدمين.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`10)	لمنصة ابلة نظيرة الحق في أي وقت في أن تجري أي تحقيقات تراها ضرورية و يطالبك بالإفصاح عن أي معلومات إضافية أو وثائق لإثبات هويتك أو ملكيتك لأموالك أو للحساب الخاص بك.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`11)	في حالة عدم الالتزام بأي مما ورد أعلاه فإن لإدارة منصة ابلة نظيرة الحق في إيقاف أو إلغاء متجرك أو عضويتك أو حجبك من الولوج لخدمات منصة ابلة نظيرة مرة أخرى. و تحتفظ المنصة كذلك بالحق في إلغاء أي حسابات غير مؤكدة أو عمليات أو حسابات مر عليها مدة طويلة دون نشاط.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 4 – الحقوق:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	كافة المحتويات المتواجدة على منصة ابلة نظيرة هي ملك لمنصة ابلة نظيرة و حقوقها محفوظة لمنصة ابلة نظيرة، سواء كانت مكتوبة أو غير مكتوبة، على سبيل المثال لا الحصر: النصوص المكتوبة وغير المكتوبة – التصاميم الجرافيكية – الأفكار التقنية – الشعارات – العروض – أيقونات الأزرار – الرموز – المقاطع الصوتية – البيانات المجمّعة والبرامج، ولا يحق لكائنًا من كان أن يستخدمها بأي شكل من الأشكال سواء كان استخدام مباشر أو غير مباشر أو عن طريق طرف ثالث.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	تُنوه إدارة منصة ابلة نظيرة على أنها سوف تتخذ اللازم حيال التجاوز والتعدي على أي من حقوق منصة ابلة نظيرة أو التعدي على حقوق ملكيتها الفكرية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	لا تتحمّل منصة ابلة نظيرة المسؤولية في حال التعدي على حقوق الملكية الفكرية المملوكة للتُجّار الأعضاء في المنصة أو المملوكة للمتاجر المسجّلة لديها.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 5 – الملكية الفكرية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	تحترم إدارة منصة ابلة نظيرة حقوق الملكية الفكرية الخاصة بالتجار والتي كوّنوها عبر متاجرهم، سواءً كانت مملوكة لهم قبل تأسيس المتجر أم بعد تأسيسه.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يحترم التاجر حقوق الملكية الفكرية الخاصة بمنصة ابلة نظيرة، والتي من ضمنها منصة ابلة نظيرة نفسها، والكلمات والشعارات والرموز الأخرى الخاصة بمنصة ابلة نظيرة أو المعروضة على منصة ابلة نظيرة، حيث أن منصة ابلة نظيرة، وكل حق يتبعها، هي حقوق محمية بموجب حقوق الملكية الفكرية وقوانين العلامات التجارية وتعد ملكية خالصة لمنصة ابلة نظيرة و لا يحق بأي حال من الأحوال التعدي عليها أو استخدامها دون تفويض من إدارة منصة مخازن.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 6 – سرية المعلومات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	تحيطكم منصة ابلة نظيرة علمًا بأن الشبكة العنكبوتية (فضاء الإنترنت) ليس وسيلة آمنة، وسرية المعلومات الشخصية لا يمكن أن تكون مضمونة 100% في فضاء الإنترنت`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	تتخذ منصة ابلة نظيرة معايير ذات جودة عالية لحماية المستخدمين والمستهلكين ومنع وصول شخص غير مفوض إلى معلومات المستخدمين الشخصية، وحفظها.`}
              />

              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	منصة ابلة نظيرة ليس لها سيطرة على أفعال أي طرف ثالث، أو الغير، مثل صفحات الانترنت الأخرى الموصولة عن طريق روابط إلى المنصة أو أطراف ثالثة تدعي أنها تمثلك وتمثل آخرين.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	أنت تعلم وتوافق على أن منصة ابلة نظيرة قد تستخدم معلوماتك التي زودتها بها، بهدف تقديم الخدمات لك في منصة ابلة نظير، ولإرسال رسائل تسويقية لك، وان سياسة الخصوصية في منصة ابلة نظيرة تضبط عمليات الجمع والمعالجة والاستخدام والتحويل لمعلومات هويتك الشخصية.  `}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 7 – إلغاء العضوية أو المتجر:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`إن منصة ابلة نظيرة -بحسب اتفاقية الاستخدام وبحسب القوانين بجمهورية مصر العربية- قد تلجأ إلى وقف مؤقت أو دائم للتاجر أو سحب وإلغاء عضوية التاجر أو تحديد قدرات التجار على الولوج إلى خدمات منصة ابلة نظيرة، في حالة:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	انتهاك قواعد وأحكام اتفاقية الاستخدام`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	إذا لم يكن بإمكان منصة ابلة نظيرة توثيق أي من معلومات المستخدم المقدمة إليها.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	إذا قررت منصة ابلة نظيرة أن نشاطات المستخدم مخالفة للقانون أو أن نشاطات المستخدم قد تتسبب لمستخدمين آخرين أو لمنصة ابلة نظيرة بمتاعب أو مخالفات قانونية.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`4)	قد تقوم  منصة ابلة نظيرة “بحسب تقييمها” إلى إعادة نشاط المستخدمين التى اوقفتها سابقاً، حيث أن المستخدم الذي أوقف نشاطه نهائياً أو أُلغيت عضويته، قد لا يكون بإمكانه التسجيل أو استعادة حسابه في منصة ابلة نظيرة أو استخدام المنصة بأي طريقة كانت مهما كانت الظروف، لحين السماح له بإعادة نشاطه في منصة ابلة نظيرة من قبل إدارة منصة ابلة نظيرة و مع ذلك فإن المستخدم في حال انتهاكه لشروط الاستخدام  هذه فإن منصة ابلة نظيرة تحتفظ بحقها في استعادة أو المطالبة بأي مبالغ مستحقة لمنصة ابلة نظيرة على المستخدم وأي خسائر أو أضرار تسبب بها التاجر لمنصة ابلة نظيرة كما أن لمنصة ابلة نظيرة الحق باتخاذ الإجراءات القانونية في جمهورية مصر العربية ضد المستخدم.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`5)	إن منصة ابلة نظيرة لا تتنازل عن حقها في اتخاذ الإجراءات المناسبة حيال أي انتهاك يحدث لقواعد وأحكام شروط الاستخدام ولغيرها من أفعال الانتهاك المشابهة، وكذلك إن منصة ابلة نظيرة لا تلتزم في القيام باتخاذ أي اجراءات ضد أي انتهاك يحدث لشروط الاستخدام وإنما يخضع هذا الأمر لتقدير إدارة منصة ابلة نظيرة و إدارتها القانونية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 8 – قانون مكافحة جرائم الانترنت و قانون حماية البيانات الشخصية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`يلتزم التجار بعدم مخالفة أي من أحكام القوانين المصرية و بالأخص قانون مكافحة جرائم الانترنت و قانون حماية البيانات الشخصية و لائحتهما و ما يرد عليهما من تعديلات، وفي حال مخالفتهم، يتحمل التاجر المخالفة المسئولية  دون أدنى مسئوليه على منصة ابلة نظيرة من جراء مخالفة المتجر للقوانين السارية المفعول، حيث أن التاجر هو المسئول عن متجره وعن تعامله مع المستهلكين مسؤولية كاملة، ولمنصة ابلة نظيرة دائماً الحق في اتخاذ ما تراه مناسباً عن أي متجر أو تاجر يخالف أحكام للقوانين السارية المفعول، سواءً عن طريق ابلاغ السلطات الرسمية أو الاكتفاء بإغلاق المتجر الإلكتروني أو إلغاء عضوية التاجر لدى المنصة.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 9 – الاتصالات ووسائل التواصل الرسمية:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يوافق التاجر في منصة ابلة نظيرة على أن تقوم المنصة بالتواصل معه عبر البريد الإلكتروني، أو من خلال قيام إدارة منصة ابلة نظيرة ببث رسائل عامة لرد إلى كافة المستخدمين أو إلى مستخدمين محددين كما يوافق التاجر ايضاً في منصة ابلة نظيرة على أن جميع الاتفاقيات والإعلانات والبيانات والاتصالات الأخرى التي تزود بها الكترونياً تقوم مقام مثيلاتها المكتوبة و لها نفس الحجيه القانونية، وهي حجة في تلبية الاحتياجات القانونية`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	سوف تقوم منصة ابلة نظيرة خلال فترة عضويتك لدى المنصة بإرسال رسائل إلكترونية ترويجية لتعريفك بأي تغيرات أو إجراءات أو نشاطات دعائية جديدة قد تضاف إلى منصة مخازن.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 10 – الضمان:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	لا تضمن منصة ابلة نظيرة إصلاح الأعطال ولا تضمن خلو المنتجات التي يقدّمها التجار من أي عيوب أخرى وإنما يضمنها المتجر أو التاجر في حالة ذكر الضمان ومدته وذلك بوصف المنتج، وعلى التاجر أن يلتزم بحسن الجودة في متجره الإلكتروني.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	يلتزم المتجر بوضع سياسة الاستبدال والاسترجاع التي تخص متجره وحده، كما يجب عليه أن يلتزم بأحكام نظام التجارة وغير ذلك من الأنظمة المرعية التي تتعلّق بالضمان أو بالتجارة.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 11 – العلاقة والإشعارات بين منصة ابلة نظيرة والتجار:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	لا تتضمن أي من قواعد وأحكام اتفاقية الاستخدام هذه إشارة إلى وجود شراكة بين أي تاجر ومنصة ابلة نظيرة، ولا تسمح منصة ابلة نظيرة لأي تاجر في أي حال من الأحوال بالإشارة المباشرة أو الغير مباشرة إلى وجود علاقة أي كان نوعها سواء كانت مباشرة أو غير مباشرة بينه كتاجر وبين منصة ابلة نظيرة أو إدارتها. `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	وأنت كتاجر توافق على أن أي إشعارات ترسل إليك من منصة ابلة نظيرة سوف تُسلّم إليك بواسطة البريد الإلكتروني أو رسائل الواتساب او الاتصال الذي قمت بتزويد منصة ابلة نظيرة به خلال عملية التسجيل.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 12 – نقل الحقوق والالتزامات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`أنت كتاجر لا تملك الحق في الاعتراض على تصرفات إدارة منصة ابلة نظيرة اياً كانت هذه التصرفات على سبيل المثال لا الحصر: تمس كيان منصة ابلة نظيرة أو التزاماتها أو ملكيتها أو مسئولياتها أو تصرفات تقنية أو تصرفات إدارية تتعلّق بمنصة ابلة نظيرة، وليست مُلزَمة منصة ابلة نظيرة بإبلاغك، و لمنصة ابلة نظيرة إن رأت أهمية إبلاغك عن أي من هذه التصرفات أن تقوم بإبلاغك وفقاً لإرادتها المحضة، وذلك بحسب قواعد وأحكام اتفاقية الاستخدام.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 13 -القابلية للفصل `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`ما لم يُذكر خلاف ذلك في هذه الشروط، في حال تم اعتبار أي حكم من هذه الشروط غير صالح أو غير قابل للتطبيق لأي سبب أو إلى أي مدى، لن تتأثر الأحكام المتبقية من هذه الشروط، وسيتم تطبيق هذا الحكم إلى الحد الذي يسمح به القانون.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 14 - التنازل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يحق لمنصة ابلة نظيرة التنازل عن أي من هذه الشروط أو جميعها، كما يحق لها التنازل أو التفويض - كليًا أو جزئيًا - فيما يتعلق بأي حق من حقوقها أو التزاماتها بموجب هذه الشروط.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	ولا يحق لك التنازل عن هذه الشروط -كليًا أو جزئيًا- ولا تحويل حقوقك أو ترخيصها من الباطن بموجب هذه الشروط، إلى أي جهة خارجية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 15-التغييرات`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`1)	يحق لنا إجراء تغييرات على هذه الشروط (بما في ذلك أي شروط وأحكام إضافية من منصة ابلة نظيرة) من وقت إلى آخر من خلال إخطارك بهذه التغييرات بأي وسيلة معقولة (قبل سريانها)، بما في ذلك عن طريق نشر اتفاقية منقحة على خدمة منصة ابلة نظيرة المعمول بها  `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`2)	لن تنطبق أي تغييرات من هذا القبيل على أي نزاع بينك وبيننا ينشأ قبل التاريخ الذي نشرنا فيه الشروط والأحكام المنقحة، أو شروط وأحكام منصة ابلة نظيرة الأخرى، التي تتضمن هذه التغييرات، أو قبل إخطارك بوسيلة أخرى بهذه التغييرات. `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`3)	إن استخدامك منصة ابلة نظيرة بعد أي تغييرات في هذه الشروط سيعبر عن قبولك هذه التغييرات. إذا كنت لا ترغب في متابعة استخدام خدمة منصة ابلة نظيرة بموجب الشروط المحدثة، فيمكنك إنهاء حسابك عن طريق الاتصال بنا. يشير تاريخ النفاذ المحدد في أعلى هذا المستند إلى تاريخ آخر تغيير لهذه الشروط.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 16-الاتفاق الكامل`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`تشكّل هذه الشروط كل الشروط والأحكام المتفق عليها بينك وبين منصة ابلة نظيرة وتحل محلّ أي اتفاقيات سابقة متعلقة بموضوع هذه الشروط، سواء أكانت خطية أم شفهية. وكما هو مذكور أعلاه، فقد أُدرجت الشروط والأحكام الأخرى التي تحكم استخدام خدمة منصة ابلة نظيرة في هذه الشروط بموجب الإشارة، متضمنة الشروط و سياسة الخصوصية المتبعة لدينا.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 17 – القانون والتشريع المُطبّق `}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`اتفاقية الاستخدام هذه محكومة ومصاغة بحسب القوانين المعمول بها والسارية في جمهورية مصر العربية.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`المادة 18 – حل الخلافات:`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`في حال النزاع يتم حل الخلاف عن طريق التوفيق والمفاوضات أو التسوية الودية أو التحكيم و ذلك في مركز للتحكيم و  وفقاً لقواعد المركز.`}
              />
            </div>
          </div>
        </div>
      </main>
    </FullScreenBgLayout>
  );
};

export default TermsOfUseTemplate;
