import { PropTypes } from "prop-types";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import { Typography } from "components/atoms";

const CollapsibleWidget = ({ title, children }) => {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between">
            <Typography
              as="span"
              size="3xl"
              fontWeight="font-bold"
              color="text-text_3b3b3b"
              text={title}
            />

            <ChevronUpIcon
              className={`${
                open ? "transform rotate-180" : ""
              } w-6 h-6 text-text_3b3b3b`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="w-full pt-4 pb-2">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

CollapsibleWidget.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CollapsibleWidget;
