import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "utils/helpers/SVGsSrc";
import { getPayload } from "utils";

import { updateMobileStart } from "Redux/user/actions/user.actions";
import { selectUnverifiedMobile } from "Redux/user/selectors/user.selectors";

import { CustomButton, Typography, FormInput } from "components/atoms";
import { Modal } from "components/molecules";
import { ValidationsWrapper } from "components/Containers";

const ChangeMobileModal = ({ isOpen, ToggleModalVisibility }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unVerifiedMobile = useSelector(selectUnverifiedMobile);

  const [NewMobile, setNewMobile] = useState({
    phone: "",
    password: "",
  });

  useEffect(() => {
    if (unVerifiedMobile) {
      navigate("/verify_mobile", {
        state: {
          scope: "verification",
          mobile: unVerifiedMobile,
        },
      });
    }
  }, [unVerifiedMobile]);

  const PhoneUpdateHandler = (UpdatePhone) => {
    dispatch(updateMobileStart(UpdatePhone));
  };

  return (
    <Modal isOpen={isOpen} ToggleModalVisibility={ToggleModalVisibility}>
      <div className="bg-white w-[600px] 2xl:w-[750px] p-4 3xl:p-10 ">
        <div
          onClick={ToggleModalVisibility}
          className="flex justify-end rtl:justify-start cursor-pointer"
        >
          <CloseIcon title="close" />
        </div>
        <Typography
          as="h1"
          size="2xl"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"pb-6"}
          text={t("Change mobile number")}
        />
        <ValidationsWrapper
          initialValues={{
            phone: NewMobile.phone,
            password: NewMobile.password,
          }}
          rules={["phone", "password"]}
        >
          {({ FormikHandler }) => {
            return (
              <div>
                <Typography
                  as="h2"
                  size="lg"
                  color="text-warm_grey text-left rtl:text-right"
                  classes="pb-2 pt-4"
                  text={t("New mobile number")}
                />
                <FormInput
                  id={"phone"}
                  key={"phone"}
                  value={FormikHandler.values.phone}
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses="AddressInput"
                  width="w-full"
                  inputTypeClass={"AddressInputColor"}
                  height="h-[35px]"
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("phone", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "phone",
                          value: e.target.value,
                        },
                      },
                      NewMobile,
                      setNewMobile
                    );
                  }}
                  error={FormikHandler.errors.phone}
                />
                <Typography
                  as="h2"
                  size="lg"
                  color="text-warm_grey"
                  classes="pb-2 pt-4 text-left rtl:text-right"
                  text={t("Password")}
                />
                <FormInput
                  id="password"
                  name="password"
                  type="password"
                  width="w-full"
                  height="h-[40px]"
                  eyeTop="top-[20%]"
                  containerHeight="h-[50px] max-h-[50px] min-h-[50px]"
                  containerClasses="passwordInput"
                  inputTypeClass={"AddressInputColor"}
                  borderColor="border-bg_e8e8e8"
                  errorMargin={"-mt-4"}
                  value={FormikHandler.values.password}
                  onValueChange={(e) => {
                    FormikHandler.setFieldValue("password", e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "password",
                          value: e.target.value,
                        },
                      },
                      NewMobile,
                      setNewMobile
                    );
                  }}
                  error={FormikHandler.errors.password}
                />
                <CustomButton
                  width="w-full max-w-[300px]"
                  height="h-[46px]"
                  borderRadius="8px"
                  classes={"mt-4"}
                  label={t("Save")}
                  disabled={!FormikHandler.isValid}
                  action={() => PhoneUpdateHandler(NewMobile)}
                />
              </div>
            );
          }}
        </ValidationsWrapper>
      </div>
    </Modal>
  );
};

ChangeMobileModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
};

export default ChangeMobileModal;
