import {
  ArrowDownGrey,
  ArrowUpGrey,
  BlackBicycle,
  ClockIcon,
  Favourite,
  FullFavourite,
  FullStar,
  Location,
  Share,
  Time,
} from "../../../utils/helpers/SVGsSrc";
import { CartModule, ReviewModal } from "components/organisms";
import { CustomButton, FormInput, Img, Typography } from "components/atoms";
import { DeliveryAddressCard, RestaurantMenuCard } from "components/molecules";

import { ImagesSrc, StorageUtils } from "utils";
import { SplitScreen } from "components/Layouts";
import StarRatings from "react-star-ratings/build/star-ratings";
import { getTimeAMPM24 } from "utils/helpers/getTimeAMPM24";
import styles from "../../../templates/App/Restaurant/Restaurant.module.css";
import style from "../../molecules/Cards/DeliveryAddressCard/DeliveryAddressCard.module.css";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShareModel from "./Models/ShareModel";
import PickupAddressCard from "components/molecules/Cards/PickupAddressCard/PickupAddressCard";
import SelectDileveryOption from "./SelectDileveryOption";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";
import { useSelector } from "react-redux";
const RestaurantBody = ({
  RestaurantData,
  SectionsRefs,
  isFavorite = false,
  DefaultAddress,
  ToggleFavoriteVendorClick,
  ReviewOpen,
  HandleReviewModal,
  vendorReviews,
  isReviewsLoading,
  choices,
  ReviewSort,
  setReviewSort,
  currentUser,
  handelChangeDeliveryOption,
  cartData,
  deliveryOption,
  setDeliveryOption,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [Search, setSearch] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [workingTimesOpen, setWorkingTimesOpen] = useState(true);
  const [locationState, setLocationState] = useState();
  const address = StorageUtils.getItems(sessionStorage, ["address"])["address"];

  const [sessionLocation, setSessionLocation] = useState(
    JSON.parse(
      StorageUtils.getItems(sessionStorage, ["distance_cords"])[
        "distance_cords"
      ]
    )
  );
  useEffect(() => {
    setSessionLocation(
      JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    );
  }, StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]);

  const ToggleModal = (val) => setOpenModal(val);
  useEffect(() => {
    if (cartData) {
      if (cartData?.is_pickup) {
        setDeliveryOption("pickup");
        localStorage.setItem("deliveryOption", "pickup");
      } else {
        setDeliveryOption("delivery");
        localStorage.setItem("deliveryOption", "delivery");
      }
    }
  }, [cartData]);

  const pickupDistnation =
    parseFloat(RestaurantData?.travel_distance).toFixed(2) > 1
      ? parseFloat(RestaurantData?.travel_distance).toFixed(2)
      : parseFloat(RestaurantData?.travel_distance).toFixed(2) * 1000;
  const pickupDistnationUnit =
    Math.ceil(parseFloat(RestaurantData?.travel_distance).toFixed(2)) > 1
      ? `${t("km")}`
      : `${t("m")}`;

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.permissions?.query({ name: "geolocation" }).then((result) => {
        //console.log(result.state);
        if (result.state === "denied") {
          setLocationState(() => "denied");
        } else {
          setLocationState(() => "allow");
          setSessionLocation(
            JSON.parse(
              StorageUtils.getItems(sessionStorage, ["distance_cords"])[
                "distance_cords"
              ]
            )
          );
        }
      });
    } else {
      alert("Geolocation is not supported in your browser.");
    }
  }, []);
  return (
    <>
      <ShareModel ToggleModal={ToggleModal} openModal={openModal} />
      <section className={styles.RestaurantIntro}>
        <SplitScreen
          containerClasses="w-full items-center"
          leftPaneClasses="w-full flex justify-center lg:justify-start  md:max-w-[55%]"
          rightPaneClasses="w-full flex flex-col items-end lg:w-[38%]"
        >
          <div className="w-full gap-2">
            <SplitScreen
              containerClasses="w-full max-w-[400px] md:max-w-none justify-between items-start flex-row mx-auto gap-3 md:gap-1"
              leftPaneClasses="w-fit lg:w-[max-content] "
              middlePaneClasses="w-full md:w-[58%] lg:w-[fit-content] lg:max-w-[75%] 2xl:max-w-[80%] SpecialPaddingRestaurantData "
            >
              <div style={{ position: "relative" }} className="ga">
                {!RestaurantData?.is_available && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      bottom: "0",
                      right: "0",
                      left: "0",
                      backgroundColor: "rgba(18, 18, 18, 0.38)",
                      backdropFilter: "blur(5.3px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography
                      as="h3"
                      size="lg"
                      color="text-white"
                      lineHeight="leading-[1.45]"
                      clsasses={"text-center"}
                      text={"Unavailable"}
                    />
                  </div>
                )}

                <Img
                  containerClasses="mx-auto lg:mb-8 lg:my-0 w-[160px] h-[160px] lg:w-[200px] lg:h-[200px]"
                  // containerClasses="max-w-[74px] md:max-w-[100px] lg:max-w-[200px] max-h-[200px] min-w-[70px] mx-auto lg:mb-8 lg:my-0 w-[160px] h-[160px]"
                  picClasses="object-cover"
                  Style={{
                    borderRadius: "50%",
                  }}
                  fileName={
                    RestaurantData?.logo
                      ? RestaurantData?.logo
                      : ImagesSrc.VendorPlaceHolder
                  }
                  altText={RestaurantData?.display_name}
                />
              </div>

              <div className={styles.RestaurantData}>
                <Typography
                  as="h1"
                  size="7xl"
                  color="text-text_3b3b3b"
                  text={RestaurantData?.display_name}
                />
                <div
                  className="flex items-center text-warm_grey text-xl cursor-pointer"
                  onClick={HandleReviewModal}
                >
                  {RestaurantData && (
                    <>
                      <span
                        className="hidden lg:flex cursor-pointer"
                        onClick={HandleReviewModal}
                      >
                        <StarRatings
                          rating={
                            RestaurantData?.rating ? RestaurantData?.rating : 0
                          }
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="#e5a128"
                          starEmptyColor="white"
                          starDimension="20px"
                          isAggregateRating={true}
                          starSpacing="2px"
                          svgIconViewBox="0 0 22 21"
                          svgIconPath="M22 8.138a.956.956 0 0 1-.344.635l-4.8 4.685 1.137 6.616a1.968 1.968 0 0 1 .013.265.781.781 0 0 1-.139.47.467.467 0 0 1-.4.192 1.067 1.067 0 0 1-.529-.159L11 17.718l-5.936 3.123a1.121 1.121 0 0 1-.529.159.48.48 0 0 1-.416-.192.784.784 0 0 1-.139-.47 2.165 2.165 0 0 1 .026-.265l1.137-6.616L.331 8.773A1.008 1.008 0 0 1 0 8.138q0-.489.741-.609l6.637-.966 2.974-6.02Q10.6 0 11 0t.648.543l2.975 6.021 6.637.966q.741.119.741.609z"
                        />
                      </span>
                      <FullStar className="lg:hidden w-[12px]" />
                    </>
                  )}

                  <Typography
                    as="h2"
                    color="text-warm_grey"
                    classes="px-2 font-SFProText text-[16px] sm:text-lg lg:text-xl"
                    text={RestaurantData?.rating ? RestaurantData?.rating : 0}
                  />
                  {RestaurantData?.number_of_reviews ? (
                    <Typography
                      as="h2"
                      color="text-warm_grey"
                      classes="font-SFProText text-[13px] sm:text-lg lg:text-xl"
                      text={`(${RestaurantData?.number_of_reviews} ${t(
                        "Res Reviews"
                      )})`}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <ReviewModal
                  Open={ReviewOpen}
                  ToggleModal={HandleReviewModal}
                  vendorReviews={vendorReviews}
                  isReviewsLoading={isReviewsLoading}
                  RestaurantData={RestaurantData}
                  choices={choices}
                  ReviewSort={ReviewSort}
                  setReviewSort={setReviewSort}
                />
                <Typography
                  as="p"
                  size="xl"
                  color="text-warm_grey"
                  classes="sm:py-2 sm:pt-4 max-w-[100%]"
                  style={{ overflowWrap: "break-word" }}
                  text={RestaurantData?.description}
                />
                <div className="hidden lg:flex items-center ">
                  <Location width="16px" height="100%" title="location" />
                  <Typography
                    as="h4"
                    size="xl"
                    color="text-warm_grey"
                    classes="px-4"
                    text={RestaurantData?.address}
                  />
                </div>
                {/* delivery data */}
                {RestaurantData?.is_delivery_available &&
                  (sessionLocation ? (
                    <div className="hidden lg:flex items-center justify-center py-2 text-xl text-warm_grey ">
                      <div className={styles.DeliverDetails}>
                        <Typography
                          as="h4"
                          size="xl"
                          fontWeight="font-bold"
                          classes="px-1"
                          text={`${t("Res Delivery fee")} :`}
                        />
                        <Typography
                          as="h4"
                          size="xl"
                          classes="px-1 font-SFProText"
                          text={
                            typeof RestaurantData?.delivery_details
                              ?.delivery_fee == "string"
                              ? RestaurantData?.delivery_details?.delivery_fee
                              : `${parseFloat(
                                  RestaurantData?.delivery_details?.delivery_fee
                                )?.toFixed(2)}
                              EGP`
                          }
                        />
                      </div>
                      {/* <span className="hidden md:block">|</span>
                    <div className={styles.DeliverDetails}>
                      <Typography
                        as="h4"
                        size="xl"
                        fontWeight="font-bold"
                        classes="px-1"
                        text={`${t("Res Delivery time")} :`}
                      />
                      <Typography
                        as="h4"
                        size="xl"
                        classes="px-1 font-SFProText"
                        text={`${
                          RestaurantData?.delivery_details?.delivery_time
                        } ${t("min")}`}
                      />
                    </div> */}
                      {typeof RestaurantData?.delivery_details?.delivery_fee !=
                        "string" && (
                        <>
                          <span className="hidden md:block">|</span>
                          <div className={styles.DeliverDetails}>
                            <Typography
                              as="h4"
                              size="xl"
                              fontWeight="font-bold"
                              classes="px-1"
                              text={`${t("Res Min Order")} :`}
                            />
                            <Typography
                              as="h4"
                              size="xl"
                              classes="px-1 font-SFProText"
                              text={
                                RestaurantData?.minimum_order
                                  ? `${RestaurantData?.minimum_order} EGP`
                                  : "0"
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="hidden lg:flex items-center justify-center py-2 text-xl text-warm_grey ">
                      <div className={style.headerIcon}>
                        <BlackBicycle
                          width="100%"
                          height="100%"
                          title="go to addresses"
                        />
                      </div>
                      <Typography
                        as="span"
                        size="sm"
                        color="text-warm_grey"
                        lineHeight="leading-[1.19]"
                        text={t(`delivery`)}
                      />
                    </div>
                  ))}
                {/* pichup data */}
                {RestaurantData?.pickup_details?.is_pickup_available && (
                  <div className="hidden lg:flex items-center justify-center py-2 text-xl text-warm_grey ">
                    <div className={styles.DeliverDetails}>
                      <Typography
                        as="h4"
                        size="xl"
                        fontWeight="font-bold"
                        classes="px-1"
                        text={`${t("pickup")} `}
                      />
                      {sessionLocation && (
                        <Typography
                          as="h4"
                          size="xl"
                          classes="px-1 font-SFProText"
                          text={`${
                            currentLang == "ar" ? t(`awayar`) : ""
                          } ${pickupDistnation} ${pickupDistnationUnit}  ${
                            currentLang == "en" ? t(`away`) : ""
                          }`}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!RestaurantData?.is_delivery_available &&
                  !RestaurantData?.pickup_details?.is_pickup_available && (
                    <div className="hidden lg:flex items-center justify-center py-2 text-xl text-warm_grey ">
                      <Typography
                        as="span"
                        size="sm"
                        color="text-warm_grey"
                        lineHeight="leading-[1.19]"
                        text={`${t("out of range")}`}
                      />
                    </div>
                  )}
                <div className="flex w-full">
                  <div className="flex justify-start w-full">
                    {/* <Typography as="h4" size="xl" text={"Time icon"} /> */}
                    <ClockIcon className="rtl:ml-4 ltr:mr-4 mt-1" />

                    <div>
                      <Typography
                        as="h4"
                        size="xl"
                        color="text-warm_grey"
                        fontWeight={"font-semibold"}
                        text={t("Available on")}
                        classes={"mt-1"}
                      />

                      {workingTimesOpen ? (
                        <>
                          {RestaurantData?.working_times
                            ?.filter((item) => item?.is_working_day)
                            ?.map((workingTime) => {
                              return (
                                <div className="flex justify-between">
                                  <Typography
                                    as="h4"
                                    size="xl"
                                    color="text-warm_grey"
                                    text={workingTime.day}
                                    classes="mr-28"
                                  />
                                  {workingTime.from == 0 &&
                                  workingTime.to == 24 ? (
                                    <div className="flex justify-between ">
                                      <Typography
                                        as="h4"
                                        size="xl"
                                        color="text-warm_grey"
                                        text={"All day"}
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex justify-between ">
                                      <Typography
                                        as="h4"
                                        size="xl"
                                        color="text-warm_grey"
                                        text={
                                          getTimeAMPM24(workingTime.from) + "-"
                                        }
                                      />
                                      <Typography
                                        as="h4"
                                        size="xl"
                                        color="text-warm_grey"
                                        text={
                                          workingTime.to == 24
                                            ? "11:59 PM"
                                            : getTimeAMPM24(workingTime.to)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div
                    className="flex justify-end cursor-pointer"
                    onClick={() => setWorkingTimesOpen(!workingTimesOpen)}
                  >
                    {workingTimesOpen ? (
                      <ArrowUpGrey className="mt-3 " />
                    ) : (
                      <ArrowDownGrey className="mt-3 " />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden">
                {isFavorite ? (
                  <FullFavourite
                    width="18px"
                    onClick={(e) => ToggleFavoriteVendorClick(e)}
                  />
                ) : (
                  <Favourite
                    width="18px"
                    onClick={(e) => ToggleFavoriteVendorClick(e)}
                  />
                )}
                <Share width="18px" className="mx-1" />
              </div>
            </SplitScreen>
            <div className="lg:hidden w-full flex items-start sm:items-center flex-col justify-center py-2 text-xl text-warm_grey">
              <div className="flex items-center justify-center">
                <Location width="16px" height="100%" title="location" />
                <Typography
                  as="h4"
                  size="xl"
                  color="text-warm_grey"
                  classes="px-4"
                  text={RestaurantData?.address}
                />
              </div>
              {RestaurantData?.is_delivery_available &&
                (sessionLocation ? (
                  <div className="flex w-full max-w-[400px] items-center  py-2">
                    <div className={styles.DeliverDetails}>
                      <BlackBicycle width="16px" />
                      <Typography
                        as="h4"
                        size="xl"
                        classes="px-1 font-SFProText "
                        text={
                          typeof RestaurantData?.delivery_details
                            ?.delivery_fee == "string"
                            ? RestaurantData?.delivery_details?.delivery_fee
                            : `${parseFloat(
                                RestaurantData?.delivery_details?.delivery_fee
                              )?.toFixed(2)}
                          EGP`
                        }
                      />
                    </div>
                    {/* <div className={styles.DeliverDetails}>
                    <Time width="12px" />
                    <Typography
                      as="h4"
                      size="xl"
                      classes="px-1 font-SFProText leading-2"
                      text={`${
                        RestaurantData?.delivery_details?.delivery_time
                      } ${t("min")}`}
                    />
                  </div> */}
                    {typeof RestaurantData?.delivery_details?.delivery_fee !=
                      "string" && (
                      <div className={styles.DeliverDetails}>
                        <Typography
                          as="h4"
                          classes="text-[14px]"
                          text={`${t("Res Min Order")} :`}
                        />
                        <Typography
                          as="h4"
                          size="xl"
                          classes="px-1 font-SFProText"
                          text={
                            RestaurantData?.minimum_order
                              ? `${RestaurantData?.minimum_order} EGP`
                              : "0"
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex w-full  items-center py-2">
                    <div className={style.headerIcon}>
                      <BlackBicycle
                        width="100%"
                        height="100%"
                        title="go to addresses"
                      />
                    </div>
                    <Typography
                      as="span"
                      size="sm"
                      color="text-warm_grey"
                      lineHeight="leading-[1.19]"
                      text={t(`delivery`)}
                    />
                  </div>
                ))}

              {RestaurantData?.pickup_details?.is_pickup_available && (
                <div className="lg:hidden w-full py-2 text-xl text-warm_grey ">
                  <div
                    className={styles.DeliverDetails}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <Typography
                      as="h4"
                      size="xl"
                      fontWeight="font-bold"
                      classes="px-1"
                      text={`${t("pickup")} `}
                    />
                    {sessionLocation && (
                      <Typography
                        as="h4"
                        size="xl"
                        classes="px-1 font-SFProText"
                        text={`${
                          currentLang == "ar" ? t(`awayar`) : ""
                        } ${pickupDistnation} ${pickupDistnationUnit}  ${
                          currentLang == "en" ? t(`away`) : ""
                        }`}
                      />
                    )}
                  </div>
                </div>
              )}
              {!RestaurantData?.is_delivery_available &&
                !RestaurantData?.pickup_details?.is_pickup_available && (
                  <div className="lg:hidden w-full py-2 text-xl text-warm_grey ">
                    <Typography
                      as="span"
                      size="sm"
                      color="text-warm_grey"
                      lineHeight="leading-[1.19]"
                      text={`${t("out of range")}`}
                    />
                  </div>
                )}
            </div>

            {/* <div className={styles.DeliverData}>
              <Typography
                as="h3"
                size=""
                color="text-warm_grey"
                fontWeight="md:font-bold"
                text={t("Res Delivered by us, with life tracking")}
              />
            </div> */}
          </div>

          {/* Discount Part */}
          <div className="w-full hidden md:flex flex-col items-center lg:items-end justify-center mt-6 lg:mt-0 ">
            {RestaurantData?.discount_on_all_items &&
            RestaurantData?.discount_on_all_items?.length !== 0 &&
            RestaurantData?.discount_on_all_items?.amount !== 0 ? (
              <div className={styles.Discounts}>
                <h3 className="text-lg lg:text-3xl text-text_3b3b3b font-bold">
                  <span className="font-SFProText">
                    {RestaurantData?.discount_on_all_items?.amount}{" "}
                    {RestaurantData?.discount_on_all_items?.discount_type ==
                    "flat"
                      ? "EGP"
                      : "%"}
                  </span>{" "}
                  {t("Res Discount on all orders")}
                </h3>
                <h3 className="text-lg lg:text-3xl text-text_3b3b3b font-bold">
                  {t("Res Expires on")}{" "}
                  <span className="font-SFProText">
                    {RestaurantData?.discount_on_all_items?.exp_date}
                  </span>
                </h3>
              </div>
            ) : (
              ""
            )}
            <CustomButton
              classes="mt-[28px] shadow-redButton"
              width="w-full max-w-[566px]"
              height="h-9 3xl:h-12"
              borderRadius="4px"
              buttonTypeClass="outlined"
              borderColor="border-primary-900"
              textColor="text-primary-900"
              label={t("Res Add Favorite")}
              action={(e) => ToggleFavoriteVendorClick(e)}
              BeforeIcon={
                isFavorite ? (
                  <FullFavourite width="18px" className="mr-5 rtl:ml-5" />
                ) : (
                  <Favourite width="18px" className="mr-5 rtl:ml-5" />
                )
              }
            />
            <CustomButton
              classes="mt-[28px] shadow-redButton"
              width="w-full max-w-[566px]"
              height="h-9 3xl:h-12"
              borderRadius="4px"
              buttonTypeClass="outlined"
              borderColor="border-primary-900"
              textColor="text-primary-900"
              label={t("Res Share")}
              BeforeIcon={<Share width="18px" className="mr-5 rtl:ml-5" />}
              action={() => {
                setOpenModal(true);
              }}
            />
          </div>
        </SplitScreen>
      </section>
      {/* Offers Section */}
      <section className={styles.OffersSection}>
        {RestaurantData?.discount_on_all_items &&
        RestaurantData?.discount_on_all_items?.length !== 0 &&
        RestaurantData?.discount_on_all_items?.amount !== 0 ? (
          <div className={`${styles.Discounts} md:hidden mb-2`}>
            <h3 className="text-lg lg:text-3xl text-text_3b3b3b font-bold">
              <span className="font-SFProText">
                {RestaurantData?.discount_on_all_items?.amount}{" "}
                {RestaurantData?.discount_on_all_items?.discount_type == "flat"
                  ? "EGP"
                  : "%"}
              </span>
              {t("Res Discount on all orders")}
            </h3>
            <h3 className="text-lg lg:text-3xl text-text_3b3b3b font-bold">
              {t("Res Expires on")}
              <span className="font-SFProText">
                {RestaurantData?.discount_on_all_items?.exp_date}
              </span>
            </h3>
          </div>
        ) : (
          ""
        )}
        <SplitScreen
          containerClasses="w-full"
          leftPaneClasses={`w-full lg:max-w-[20%] sticky ${
            !sessionLocation
              ? "top-[200px] "
              : address
              ? "top-[170px]"
              : "top-[108px]"
          } md:top-[60px] z-10 lg:z-auto lg:relative lg:top-0`}
          middlePaneClasses="w-full text-center lg:max-w-[60%]"
          rightPaneClasses="w-full lg:w-[20%] "
        >
          {/* Categories */}
          <div id="Bar" className="w-full flex justify-start">
            <div className="w-full flex flex-row lg:flex-col lg:justify-start lg:max-w-[280px] bg-bg_f7f7f7 md:bg-bg_white px-4 py-2 lg:px-6 lg:py-8 rounded-0 lg:rounded-lg whitespace-nowrap lg:whitespace-normal overflow-auto">
              <Typography
                as="h1"
                color="text-text_3b3b3b"
                classes="hidden lg:flex flex-row md:flex-col font-bold text-[20px] py-2 px-3 lg:p-0 lg:pb-6 lg:pr-2"
                text={t("Res Categories")}
              />
              {RestaurantData?.menu_categories?.map((item, i) => {
                if (item?.products?.length != 0)
                  return (
                    <span key={i}>
                      <Typography
                        size="xl"
                        as="span"
                        color="hover:text-text_white lg:hover:text-text_777777"
                        lineHeight="leading-[2.05]"
                        fontWeight="font-semibold"
                        classes="cursor-pointer px-2 lg:py-1 lg:p-0 inline-block rounded-lg bg-bg_e4e4e4 mx-1 lg:rounded-0 lg:bg-bg_white hover:bg-primary-900 lg:hover:bg-bg_white"
                        text={item?.name}
                        action={() =>
                          SectionsRefs[i]?.current?.scrollIntoView()
                        }
                      />
                    </span>
                  );
              })}
            </div>
          </div>

          {/* Menu */}
          <div className="w-full mt-6 lg:mt-0">
            <div className="w-[100%] flex flex-col items-start h-[fit-content] mb-8 px-0 md:px-6 py-3 sm:mx-auto">
              <div className="w-full flex flex-col sm:flex-row items-end md:items-center justify-between px-5 md:px-0">
                <FormInput
                  containerClasses="flex"
                  type="search"
                  bgColor="bg-bg_f7f7f7"
                  borderRadius="8px"
                  height="h-8 md:h-[46px]"
                  width="w-[100%]"
                  placeholder="Search"
                  onValueChange={(e) => {
                    if (e.target.value == "") {
                      setSearch();
                    } else {
                      setSearch(
                        [].concat
                          .apply(
                            [],
                            RestaurantData?.menu_categories.map(
                              (item) => item.products
                            )
                          )
                          .filter(
                            (item) =>
                              item.name
                                .toLowerCase()
                                .includes(e.target.value) ||
                              item.name.toUpperCase().includes(e.target.value)
                          )
                      );
                    }
                  }}
                />
                {/* 
                <CustomButton
                  classes="bg-transparent px-5 sm:px-0 md:mt-1 sm:mt-0 text-[13px] "
                  width="w-fit md:w-full md:max-w-[220px]"
                  height="h-9 3xl:h-12"
                  buttonTypeClass="outlined"
                  borderColor="border-transparent"
                  textColor="text-primary-900 underline underline-offset-2"
                  label="View scanned menu"
                /> 
              */}
              </div>
              {Search
                ? Search?.map((item) => (
                    <RestaurantMenuCard
                      ProductData={item}
                      deliveryOption={deliveryOption}
                    />
                  ))
                : RestaurantData?.menu_categories?.map((item, i) => {
                    if (item?.products?.length != 0)
                      return (
                        <>
                          <Typography
                            Ref={SectionsRefs?.at(i)}
                            as="span"
                            color="text-text_3b3b3b"
                            classes="px-5 md:px-0 scroll-mt-[140px] md:scroll-mt-[90px] lg:scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[80px] 3xl:scroll-mt-[100px] font-bold text-3xl md:pb-6 pt-[32px]"
                            text={item?.name}
                          />
                          {item?.products?.map((item) => (
                            <RestaurantMenuCard
                              ProductData={item}
                              deliveryOption={deliveryOption}
                            />
                          ))}
                        </>
                      );
                  })}
              {/* {RestaurantData?.menu_categories?.map((item, i) => {
                return (
                  <>
                    <Typography
                      Ref={SectionsRefs?.at(i)}
                      as="span"
                      color="text-text_3b3b3b"
                      classes="px-5 md:px-0 scroll-mt-[140px] md:scroll-mt-[90px] lg:scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[80px] 3xl:scroll-mt-[100px] font-bold text-3xl md:pb-6 pt-[32px]"
                      text={item?.name}
                    />
                    {item?.products?.map((item) => (
                      <RestaurantMenuCard ProductData={item} />
                    ))}
                  </>
                );
              })} */}
            </div>
          </div>

          {/* Delivery and Cart */}
          <div className="w-full flex flex-col items-end">
            <SelectDileveryOption
              restaurantData={RestaurantData}
              setDeliveryOption={setDeliveryOption}
              deliveryOption={deliveryOption}
              handelChangeDeliveryOption={handelChangeDeliveryOption}
            />

            {deliveryOption == "delivery" && (
              <DeliveryAddressCard
                address={DefaultAddress}
                cardHeading={t("Delivers to")}
                currentUser={currentUser}
              />
            )}
            {deliveryOption == "pickup" &&
              RestaurantData?.pickup_details?.is_pickup_available && (
                <PickupAddressCard
                  restaurantData={RestaurantData}
                  cardHeading={t("pickupFrom")}
                  currentUser={currentUser}
                />
              )}
            <CartModule
              deliveryOption={deliveryOption}
              restaurantData={RestaurantData}
            />
          </div>
        </SplitScreen>
      </section>
    </>
  );
};

export default RestaurantBody;
