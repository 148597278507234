import { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { WithSpinner } from "Hocs";

import { ImagesSrc, getTimerSeconds } from "utils";

import { getOrderStart } from "Redux/orders/actions/orders.actions";

import {
  selectIsOrderCancelled,
  selectOrder,
  selectOrdersLoading,
} from "Redux/orders/selectors/orders.selectors";

import { CustomButton, Img } from "components/atoms";
import { RiderInfo } from "components/molecules";
import {
  OrderSummary,
  OrderTimeTracker,
  ReasonsOfCancellingModal,
  SuccessConfirmationModal,
} from "components/organisms";

import styles from "./InProgress.module.css";
import { t } from "i18next";
import { useNavigate } from "react-router";

const InProgressTemplateBody = ({
  restaurantCoords,
  order,
  userCoords,
  riderInfo,
  restaurantName,
  cartItems,
  invoiceDetails,
  paymentType,
  deliveryDateTime,
  id,
}) => {
  const [timerSeconds] = useState(getTimerSeconds(deliveryDateTime));
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [isSuccesModalOpen, setIsSuccesModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ToggleModalVisibility = () => setIsOpen(!isOpen);
  const isOrderCancelled = useSelector(selectIsOrderCancelled);
  useEffect(() => {
    if (isOrderCancelled) {
      setIsOpen(false);
      setIsSuccesModalOpen(true);
    }
  }, [isOrderCancelled]);

  return (
    <>
      <section className="my-7">
        <Img
          fileName={ImagesSrc.RouteBetweenMap}
          fileName2x={ImagesSrc.RouteBetweenMap3x}
          fileName3x={ImagesSrc.RouteBetweenMap3x}
          picClasses="object-cover"
          containerClasses="cursor-pointer h-[138px] lg:h-[278px] 2xl:h-[370px]"
          altText="route between restaurant and customer"
          action={() =>
            window.open(
              `https://www.google.com/maps/place/${restaurantCoords.lat},${restaurantCoords.lng}/@${userCoords.lat},${userCoords.lng},12`
            )
          }
        />
      </section>
      <section className={` relative ${styles.container}`}>
        <OrderTimeTracker
          orderStatus={order && Object.entries(order?.status)[0][1]}
          restaurantName={restaurantName}
          timerSeconds={timerSeconds}
          progress={progress}
          setProgress={setProgress}
        />
      </section>

      {riderInfo && (
        <section className={styles.container}>
          <RiderInfo riderInfo={riderInfo} />
        </section>
      )}

      <OrderSummary
        restaurantName={restaurantName}
        cartItems={cartItems}
        invoiceDetails={invoiceDetails}
        specialInstruction={order?.cart?.special_instructions}
        paymentType={paymentType}
      />
      <section className="flex justify-center">
        <CustomButton
          width="w-full lg:w-80"
          height="h-10 lg:h-12 2xl:h-14"
          borderRadius="4px"
          buttonTypeClass="outlined"
          borderColor="border-primary-900"
          textColor="text-primary-900"
          boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
          classes="font-bold"
          label={t("Cancel Order")}
          action={ToggleModalVisibility}
        />
        <ReasonsOfCancellingModal
          isOpen={isOpen}
          orderId={id}
          ToggleModalVisibility={ToggleModalVisibility}
        />
        <SuccessConfirmationModal
          isOpen={isSuccesModalOpen}
          ToggleModalVisibility={() => {
            setIsSuccesModalOpen(!isSuccesModalOpen);
            navigate("/Orders", {
              state: {
                status: "cancelled",
              },
            });
          }}
          heading={t("Order canceled Successfully")}
        />
      </section>
    </>
  );
};

const InProgressTemplate = ({ id }) => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const dispatch = useDispatch();

  const order = useSelector(selectOrder);
  const loading = useSelector(selectOrdersLoading);

  useEffect(() => {
    if (currentLng) {
      dispatch(getOrderStart({ orderId: id }));
    }
  }, [dispatch, currentLng, id]);

  const InProgressTemplateContainer = WithSpinner(InProgressTemplateBody);

  return (
    <InProgressTemplateContainer
      id={id}
      isLoading={loading}
      restaurantCoords={order && { lat: +order?.lat, lng: +order?.lng }}
      order={order}
      userCoords={
        order && {
          lat: order?.customer_address?.lat,
          lng: order?.customer_address?.lng,
        }
      }
      riderInfo={order && order?.rider}
      restaurantName={order && order?.cart?.vendor_name}
      cartItems={order && order?.cart?.cart_items}
      invoiceDetails={order && order?.invoice_details}
      paymentType={order && Object.entries(order?.payment_type)[0][1]}
      deliveryDateTime={order && order?.delivery_date_time}
    />
  );
};

InProgressTemplateBody.propTypes = {
  restaurantCoords: PropTypes.instanceOf(Object),
  userCoords: PropTypes.instanceOf(Object),
  riderInfo: PropTypes.instanceOf(Object),
  restaurantName: PropTypes.string,
  cartItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  invoiceDetails: PropTypes.instanceOf(Object),
  paymentType: PropTypes.string,
};

InProgressTemplate.propTypes = {
  status: PropTypes.string,
};

export default InProgressTemplate;
