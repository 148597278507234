import { PropTypes } from "prop-types";

import Typography from "../Typography";

import styles from "./ProgressBar.module.css";

const ProgressBar = ({ progress, description }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.progressBar}
        style={{ width: `${progress}%` }}></div>
      <div className={styles.descriptionContainer}>
        <Typography
          as="p"
          size="2xl"
          classes="relative"
          color="text-warm_grey"
          fontWeight="font-semibold"
          lineHeight="leading-[1.14]"
          text={description}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  description: PropTypes.string,
};

export default ProgressBar;
