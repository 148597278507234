import { StorageUtils } from "utils";

export const getGeoLocation = async ({
  selectedAddress,
  distance_cords,
  dispatch,
  saveLocation,
  geoCoder,
  saveUserDeliveryLocation,
  setQuery,
}) => {
  await geoCoder.geocode(
    {
      placeId: selectedAddress.place_id,
    },
    async (responses, status) => {
      if (status === "OK") {
        distance_cords.lat = +responses[0].geometry.location.lat();
        distance_cords.lng = +responses[0].geometry.location.lng();
        await saveLocation(distance_cords);
        await setQuery(
          `${selectedAddress?.structured_formatting?.main_text} ,${selectedAddress?.structured_formatting?.secondary_text}`
        );
        StorageUtils.saveItems(sessionStorage, [
          {
            name: "address",
            data: `${selectedAddress?.structured_formatting?.main_text} ,${selectedAddress?.structured_formatting?.secondary_text}`,
          },
        ]);
        await dispatch(
          saveUserDeliveryLocation({
            ...selectedAddress,
            isUserSavedAddress: false,
            lat: +responses[0].geometry.location.lat(),
            lng: +responses[0].geometry.location.lng(),
          })
        );
      }
    }
  );
};

export const generateAddress = async (
  location,
  geoCoder,
  handleSelectedAddress
) => {
  await geoCoder?.geocode({ location }, async (results, status) => {
    if (status === "OK") {
      if (results[0]) {
        await handleSelectedAddress({
          ...results[0],
          structured_formatting: {
            main_text: results[0].formatted_address,
            secondary_text: "",
          },
        });
      }
    }
  });
};
