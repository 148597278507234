import { PropTypes } from "prop-types";
import { BackArrow } from "utils/helpers/SVGsSrc";

import {
  CustomButton,
  DropdownMenu,
  FormInput,
  Img,
  Typography,
} from "components/atoms";
import { Modal } from "components/molecules";
import { ImagesSrc, StorageUtils } from "utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeliveryTimeRangeStart,
  UpdateCartDataStart,
  UpdateOrderTime,
} from "Redux/Cart/actions/cart.actions";
import { deliveryTimeRanges } from "Redux/Cart/selectors/cart.selectors";

const LaterOrderModal = ({
  isOpen,
  ToggleModalVisibility,
  ToggleNowLater,
  zIndex = "z-30",
}) => {
  const { t, i18n } = useTranslation();
  const deliveryTimeRangesData = useSelector(deliveryTimeRanges);
  const Hours = deliveryTimeRangesData?.delivery_time_ranges?.map((data) => {
    return {
      name_ar: data?.viewed_as,
      name_en: data?.viewed_as,
      value: data?.from,
    };
  });

  const [selectedHour, setSelectedHour] = useState();
  const [selectedDay, setSelectedDay] = useState();

  const dispatch = useDispatch();

  const [dateState, setDateState] = useState(null);
  const [AMPM, setAMPM] = useState("AM");
  const [errorMsg, setErrorMsg] = useState("");

  var dateObject = new Date(selectedDay);
  useEffect(() => {
    //console.log(isInThePast(dateObject));
    if (isInThePast(dateObject)) {
      setErrorMsg(t("Date cannot be in the past"));
    } else {
      setErrorMsg("");
    }
  }, [selectedDay]);

  var currentDate = new Date();
  const today = new Date(currentDate.getTime() + 60000);

  const isInThePast = (date) => {
    const today = new Date();
    if (
      date?.getDate() == today?.getDate() &&
      date?.getMonth() == today?.getMonth()
    )
      return deliveryTimeRangesData?.can_order_for_later_today ? false : true;
    else return date < today;
  };

  useEffect(() => {
    if (dateState?.getHours() >= 12) {
      setAMPM("PM");
    } else {
      setAMPM("AM");
    }
    if (isInThePast(dateState)) {
      setErrorMsg(t("Date cannot be in the past"));
    } else {
      setErrorMsg("");
    }
  }, [dateState]);
  useEffect(() => {
    setDateState(today);
  }, []);

  useEffect(() => {
    dispatch(DeliveryTimeRangeStart());
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      ToggleModalVisibility={() => {
        ToggleModalVisibility(true);
      }}
      zIndex={zIndex}
    >
      <div className="bg-white w-[600px] 2xl:w-[700px] 3xl:py-10 py-6">
        <div
          className={"w-full flex px-4 border-b-[1px] pb-6 border-bg_e8e8e8"}
        >
          <BackArrow
            className="cursor-pointer rtl:rotate-180"
            onClick={() => ToggleModalVisibility(false)}
          />
          <Typography
            as="h1"
            size="2xl"
            lineHeight="leading-none"
            fontWeight="font-bold"
            color="text-text_3b3b3b"
            classes={"text-center w-full mr-5 rtl:ml-5"}
            text={t("Choose delivery time")}
          />
        </div>
        <div className="w-full flex flex-col items-center mt-9 3xl:mt-12 ">
          <div className="w-full px-8">
            <div className="flex justify-start w-full mb-4 ">
              <Typography
                as="span"
                text={t("Date")}
                color="text-warm_grey"
                size="lg"
              />
            </div>

            <FormInput
              type="date"
              borderColor="border-text_bfbfbf"
              height="h-[40px]"
              width="w-full"
              placeholder={t("Select date")}
              value={selectedDay}
              onValueChange={(e) => setSelectedDay(e.target.value)}
            />
            {errorMsg && <small className="text-red-600">{errorMsg}</small>}
          </div>
          <div className="w-full px-8">
            <div className="flex justify-start w-full mb-4 mt-8">
              {/* <CalendarIcon className="mr-4" /> */}
              <Typography
                as="span"
                text={t("Time")}
                color="text-warm_grey"
                size="lg"
              />
            </div>
            <div style={{ width: "100%", position: "relative" }}>
              <DropdownMenu
                options={Hours}
                ReviewSort={selectedHour}
                setReviewSort={(hour) => {
                  setSelectedHour(hour);
                }}
                fullWidth
                Address
                placeHolder={"Select time"}
                type="nowLater"
                style="border-[1px] border-text_bfbfbf  p-1 px-2 "
              />
            </div>

            {/* <small className="text-red-600">{errorMsg}</small> */}
          </div>
          <CustomButton
            width="w-full max-w-[450px]"
            height="h-10 3xl:h-12"
            borderRadius="8px"
            boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
            backdropFilter="blur(30)"
            label={t("Confirm")}
            classes={"mt-8"}
            disabled={errorMsg || !selectedDay || !selectedHour}
            action={() => {
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "OrderTime",
                  data: "later",
                },
              ]);
              dispatch(UpdateOrderTime("later"));
              // save datetime
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_datetime",
                  data: `${selectedDay} - ${selectedHour?.value}`,
                },
              ]);
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_date",
                  data: `${selectedDay}`,
                },
              ]);
              StorageUtils.saveItems(sessionStorage, [
                {
                  name: "delivery_time",
                  data: `${selectedHour?.value}`,
                },
              ]);
              dispatch(
                UpdateCartDataStart({
                  cart: {
                    delivery_time: selectedHour?.value,
                    delivery_date: selectedDay,
                    delivery_type: "later",
                  },
                })
              );

              ToggleModalVisibility(false);
              ToggleNowLater();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

LaterOrderModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  heading: PropTypes.string,
};

export default LaterOrderModal;
