import { PropTypes } from "prop-types";

import { CartIcon } from "utils/helpers/SVGsSrc";

import { PopOver } from "components/molecules";
import CartModule from "components/organisms/CartModule";

const CartPopover = ({ CartData }) => {
  const deliveryOption = localStorage.getItem('deliveryOption')
  return (
    <PopOver
      panelClasses="-translate-x-full md:-translate-x-full rtl:translate-x-0 md:rtl:translate-x-0 left-1/2"
      label={
        <>
          {CartData?.cart_items?.length ? (
            <span className="absolute right-[-6px] top-[-6px] bg-primary-900 rounded-full h-[18px] w-[18px] text-text_white font-SFProText flex justify-center items-center">
              {CartData?.cart_items?.length}
            </span>
          ) : (
            ""
          )}
          <CartIcon width="100%" height="100%" title="cart" />
        </>
      }
    >
      <div
        id="cart"
        className={`min-w-[300px] max-h-[85vh] h-full overflow-auto`}
      >
        <CartModule nav deliveryOption={deliveryOption} />
      </div>
    </PopOver>
  );
};

CartPopover.propTypes = {
  // CartData: PropTypes.object,
};

export default CartPopover;
