import { takeLatest, all, call, put, takeEvery } from "redux-saga/effects";

import VendorsActionTypes from "../types/vendors.types";
import {
  addFavoriteVendorFailure,
  addFavoriteVendorSuccess,
  AddRateVendorFailure,
  AddRateVendorSuccess,
  getAllVendorsFailure,
  getAllVendorsSuccess,
  getTopOffersVendorsFailure,
  getTopOffersVendorsSuccess,
  getVendorFiltersFailure,
  getVendorFiltersSuccess,
  getVendorReviewsFailure,
  getVendorReviewsSuccess,
  ListFavoriteRestaurantsFailure,
  ListFavoriteRestaurantsSuccess,
  ListPopularNearRestaurantsFailure,
  ListPopularNearRestaurantsSuccess,
  ListRestaurantDataFailure,
  ListRestaurantDataSuccess,
  loadMoreFavoriteVendorsSucess,
  loadMorePopularNearVendorsSucess,
  loadMoreVendorsSucess,
  removeFavoriteVendorFailure,
  removeFavoriteVendorSuccess,
} from "../actions/vendors.actions";

import * as URL from "services/URLs";

import { StorageUtils } from "utils";
import getAxios from "utils/Axios";


const axios = getAxios();

export function* RestaurantData({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({ lat, lng, delivery_type }).map(
    (item, idx) => {
      return `${
        item[1]
          ? `${item[0]}=${
              Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
            }${
              idx === Object.entries({ lat, lng, delivery_type }).length - 1
                ? ""
                : "&"
            }`
          : ""
      }`;
    }
  );

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.VENDORS}/${payload}?product_details=true&${queryParams.join("")}`
    );
    if (res.status === 200)
      yield put(ListRestaurantDataSuccess(res?.data?.data));
  } catch (error) {
    yield put(ListRestaurantDataFailure(error));
  }
}

export function* onRestaurantDataStart() {
  yield takeLatest(VendorsActionTypes.RESTAURANT_DATA_START, RestaurantData);
}

export function* listFavoriteRestaurant({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  // const Payload =
  // StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
  //   "selectedAddressType"
  // ] == "notSaved"
  //   ? {
  //       ...payload,
  //       area_id: JSON.parse(
  //         StorageUtils.getItems(sessionStorage, ["selectedArea"])[
  //           "selectedArea"
  //         ]
  //       )?.id,
  //     }
  //   : payload;
  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
    ...payload,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({ lat, lng, delivery_type, ...payload }).length - 1
                ? ""
                : "&"
            }`
          : ""
      }`
  );
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.FAVORITE_VENDORS}?${queryParams.join("")}`
    );

    if (res?.status) {
      if (payload.page_number > 1) {
        yield put(
          loadMoreFavoriteVendorsSucess({
            favoriteVendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      } else {
        yield put(
          ListFavoriteRestaurantsSuccess({
            favoriteVendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      }
    }
  } catch (error) {
    yield put(ListFavoriteRestaurantsFailure(error));
  }
}

export function* onListFavoriteRestaurantsStart() {
  yield takeLatest(
    VendorsActionTypes.LIST_FAVORITE_RESTAURANTS_START,
    listFavoriteRestaurant
  );
}

export function* onLoadMoreFavoriteRestaurantsStart() {
  yield takeLatest(
    VendorsActionTypes.LOAD_MORE_FAVORITE_RESTAURANTS_START,
    listFavoriteRestaurant
  );
}

export function* listPopularNearRestaurants({ payload }) {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
    ...payload,
  }).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx ===
              Object.entries({ lat, lng, delivery_type, ...payload }).length - 1
                ? ""
                : "&"
            }`
          : ""
      }`
  );

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_NEARBY_VENDORS}?${queryParams.join("")}`
    );

    if (res?.status) {
      if (payload.page_number > 1) {
        yield put(
          loadMorePopularNearVendorsSucess({
            popularNearVendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      } else {
        yield put(
          ListPopularNearRestaurantsSuccess({
            popularNearVendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      }
    }
  } catch (error) {
    yield put(ListPopularNearRestaurantsFailure(error));
  }
}

export function* onListPopularNearRestaurantsStart() {
  yield takeLatest(
    VendorsActionTypes.LIST_POPULAR_NEAR_RESTAURANTS_START,
    listPopularNearRestaurants
  );
}

export function* onListMorePopularNearRestaurantsStart() {
  yield takeLatest(
    VendorsActionTypes.LOAD_MORE_POPULAR_NEAR_RESTAURANTS_START,
    listPopularNearRestaurants
  );
}

export function* listVendorFilters() {
  try {
    const res = yield call(axios.call, "get", URL.VENDOR_FILTERS);
    if (res.status === 200) yield put(getVendorFiltersSuccess(res?.data?.data));
  } catch (error) {
    yield put(getVendorFiltersFailure(error));
  }
}

export function* onListVendorFiltersStart() {
  yield takeLatest(
    VendorsActionTypes.GET_VENDOR_FILTERS_START,
    listVendorFilters
  );
}

export function* getAllVendors({ payload }) {
  const filterOptions = Object.fromEntries(
    payload.filter_options.map((filter_option) => [filter_option, true])
  );

  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParamsObj = {
    ...payload,
    sortBy: null,
    filter_options: null,
    sort_a_to_z: payload.sortBy === "sort_a_to_z",
    sort_by_rating: payload.sortBy === "sort_by_rating",
    filter_by_main_category_id: payload.filter_by_main_category_id.length
      ? payload.filter_by_main_category_id
      : null,
    ...filterOptions,
    // area_id:
    //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
    //     "selectedAddressType"
    //   ] == "notSaved"
    //     ? JSON.parse(
    //         StorageUtils.getItems(sessionStorage, ["selectedArea"])[
    //           "selectedArea"
    //         ]
    //       )?.id
    //     : "",
  };

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
    ...queryParamsObj,
  }).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${
            idx ===
            Object.entries({ lat, lng, delivery_type, ...queryParamsObj })
              .length -
              1
              ? ""
              : "&"
          }`
        : ""
    }`;
  });

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.VENDORS}?${queryParams.join("")}`
    );

    if (res?.status === 200) {
      if (payload.page_number > 1)
        yield put(
          loadMoreVendorsSucess({
            vendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      else
        yield put(
          getAllVendorsSuccess({
            vendors: res?.data?.data,
            total_pages: res?.data?.total_pages,
          })
        );
    }
  } catch (error) {
    yield put(getAllVendorsFailure(error));
  }
}

export function* onGetAllVendorsStart() {
  yield takeLatest(VendorsActionTypes.GET_ALL_VENDORS_START, getAllVendors);
}

export function* onLoadMoreVendorsStart() {
  yield takeEvery(VendorsActionTypes.LOAD_MORE_VENDORS_START, getAllVendors);
}

export function* AddFavoriteVendor({ payload }) {
  const data = { customer_favorite_vendor: { vendor_id: payload } };
  try {
    const res = yield call(
      axios.call,
      "post",
      `${URL.ADD_fAVORITE_VENDOR}`,
      data
    );
    if (res?.status === 200)
      yield put(addFavoriteVendorSuccess(res?.data?.data));
  } catch (error) {
    yield put(addFavoriteVendorFailure(error));
  }
}

export function* onAddFavoriteVendorStart() {
  yield takeLatest(
    VendorsActionTypes.ADD_FAVORITE_VENDOR_START,
    AddFavoriteVendor
  );
}

export function* RemoveFavoriteVendor({ payload }) {
  const data = { data: { customer_favorite_vendor: { vendor_id: payload } } };
  try {
    const res = yield call(
      axios.call,
      "delete",
      `${URL.REMOVE_fAVORITE_VENDOR}`,
      data
    );
    if (res?.status === 200)
      yield put(removeFavoriteVendorSuccess(res?.data?.data));
  } catch (error) {
    yield put(removeFavoriteVendorFailure(error));
  }
}

export function* onRemoveFavoriteVendorStart() {
  yield takeLatest(
    VendorsActionTypes.REMOVE_FAVORITE_VENDOR_START,
    RemoveFavoriteVendor
  );
}

export function* GetReviewsVendor({ payload }) {
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_VENDOR_REVIEWS}/${payload.id}?sort_by=${payload.Sort}`
    );
    if (res?.status === 200)
      yield put(getVendorReviewsSuccess(res?.data?.data));
  } catch (error) {
    yield put(getVendorReviewsFailure(error));
  }
}

export function* onGetReviewsVendorStart() {
  yield takeLatest(
    VendorsActionTypes.GET_VENDOR_REVIEWS_START,
    GetReviewsVendor
  );
}

export function* getTopOffersVendors() {
  const { lat, lng } = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"]
    ? JSON.parse(
        StorageUtils.getItems(sessionStorage, ["distance_cords"])[
          "distance_cords"
        ]
      )
    : "";

  const delivery_type =
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ?? "";

  const queryParams = Object.entries({
    lat,
    lng,
    delivery_type,
  }).map((item, idx) => {
    return `${
      item[1]
        ? `${item[0]}=${
            Array.isArray(item[1]) ? `[${item[1].join(",")}]` : item[1]
          }${
            idx === Object.entries({ lat, lng, delivery_type }).length - 1
              ? ""
              : "&"
          }`
        : ""
    }`;
  });

  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_TOP_OFFERS_VENDORS}?${queryParams.join("")}`
      // ${
      //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //     "selectedArea"
      //   ] &&
      //   StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      //     "selectedAddressType"
      //   ] == "notSaved"
      //     ? `&area_id=${
      //         JSON.parse(
      //           StorageUtils.getItems(sessionStorage, ["selectedArea"])[
      //             "selectedArea"
      //           ]
      //         )?.id
      //       }`
      //     : ""
      // }
    );
    if (res?.status === 200)
      yield put(getTopOffersVendorsSuccess(res?.data?.data));
  } catch (error) {
    yield put(getTopOffersVendorsFailure(error));
  }
}

export function* onGetTopOffersVendors() {
  yield takeLatest(
    VendorsActionTypes.GET_TOP_OFFERS_VENDORS_START,
    getTopOffersVendors
  );
}

export function* RestaurantsSagas() {
  yield all([
    call(onRestaurantDataStart),
    call(onListFavoriteRestaurantsStart),
    call(onLoadMoreFavoriteRestaurantsStart),
    call(onListPopularNearRestaurantsStart),
    call(onListMorePopularNearRestaurantsStart),
    call(onGetAllVendorsStart),
    call(onAddFavoriteVendorStart),
    call(onRemoveFavoriteVendorStart),
    call(onListVendorFiltersStart),
    call(onLoadMoreVendorsStart),
    call(onGetReviewsVendorStart),
    call(onGetTopOffersVendors),
  ]);
}
