import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";

import { WithSpinner } from "Hocs";

import { CustomButton, Typography, FormInput } from "components/atoms";
import { Footer, NavBar } from "components/molecules";

import styles from "./FAQ.module.css";
import Layout from "components/molecules/Layout";
const FAQBody = ({ FAQs }) => {
  return (
    <>
      {FAQs?.length > 0 ? (
        FAQs?.map((item) => (
          <div className="w-full bg-bg_white p-4 rounded-[8px] shadow-[0_3px_6px_0_rgb(0,0,0,0.16)] mb-6">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center">
                    <ChevronRightIcon
                      color="#bf0022"
                      className={` ${
                        open
                          ? "transform rotate-90 h-[20px]"
                          : "w-[20px] rtl:rotate-180"
                      }`}
                    />
                    <Typography
                      as="h2"
                      lineHeight="leading-none"
                      fontWeight="font-bold"
                      color="text-text_3b3b3b"
                      classes={"text-[16px]"}
                      text={item?.question}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-4">
                    <Typography
                      as="p"
                      lineHeight="leading-none"
                      color="text-text_FQA"
                      classes={"text-[14px]"}
                      text={item?.answer}
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))
      ) : (
        <Typography
          as="h2"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"text-[16px]"}
          text={"There aren't any frequently asked questions here !!"}
        />
      )}
    </>
  );
};

const FAQTemplate = ({ FAQs, isFAQsLoading }) => {
  const { t } = useTranslation();

  const FAQContainer = WithSpinner(FAQBody);

  const [SearchedFAQ, setSearchedFAQ] = useState();
  const [FAQInput, setFAQInput] = useState("");

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setSearchedFAQ(
        FAQs.filter(
          (item) =>
            item.question.toLowerCase().includes(FAQInput) ||
            item.question.toUpperCase().includes(FAQInput)
        )
      );
    }
  };

  useEffect(() => {
    setSearchedFAQ(FAQs);
  }, [FAQs]);

  return (
    <Layout>
      <div className={styles.main}>
        <Typography
          as="h1"
          lineHeight="leading-none"
          fontWeight="font-bold"
          color="text-text_3b3b3b"
          classes={"text-center text-[17px] md:text-[30px] mb-6"}
          text={t("Frequently Asked Questions")}
        />
        <div className="w-full flex justify-center">
          <FormInput
            id={"search"}
            containerClasses="FAQSearch"
            value={FAQInput}
            type="search"
            bgColor="bg-bg_white"
            textColor="text-warm_grey"
            height="h-[38px] md:h-[50px]"
            width="w-full max-w-[800px] md:w-[800px] ltr:border-r-0 rtl:border-l-0"
            onValueChange={(e) => setFAQInput(e.target.value)}
            onKeyDown={(e) => handleEnter(e)}
          />
          <CustomButton
            width="w-fit"
            height="h-[38px] md:h-[50px]"
            classes={"px-6"}
            label={t("Search")}
            action={() =>
              setSearchedFAQ(
                FAQs.filter(
                  (item) =>
                    item.question.toLowerCase().includes(FAQInput) ||
                    item.question.toUpperCase().includes(FAQInput)
                )
              )
            }
          />
        </div>
      </div>

      <div className={styles.container}>
        <FAQContainer isLoading={isFAQsLoading} FAQs={SearchedFAQ} />
      </div>
    </Layout>
  );
};

export default FAQTemplate;
