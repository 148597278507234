import React, { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import { CustomButton, Typography, FormInput } from "components/atoms";
import DescriptionTile from "../DescriptionTile";
import Counter from "../Counter";

import { getPayload } from "utils";

const OrderItem = ({
  ItemData,
  UpdateCartItemHandleClick,
  ItemInstruction,
  setItemInstruction,
}) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(ItemData.special_instructions != "");
  useEffect(() => {
    if (
      !ItemInstruction?.cart_items?.find(
        (item) => item.cart_item_id == ItemData.id
      )?.special_instructions
    ) {
      getPayload(
        {
          target: {
            name: "cart_items",
            value: [
              ...ItemInstruction?.cart_items.filter(
                (id) => id.cart_item_id != ItemData.id
              ),
              {
                cart_item_id: ItemData?.id,
                special_instructions: ItemData.special_instructions,
              },
            ],
          },
        },
        ItemInstruction,
        setItemInstruction
      );
    }
  }, []);

  return (
    <>
      <DescriptionTile
        title={ItemData?.product_name}
        mobileDirection={"row"}
        OrderItem
        mainTitle={false}
        centerComponent={
          <>
            <span className="flex items-center">
              <Counter
                ItemData={ItemData}
                UpdateCartItemHandleClick={UpdateCartItemHandleClick}
                ItemInstruction={
                  ItemInstruction?.cart_items?.find(
                    (item) => item.cart_item_id == ItemData.id
                  )?.special_instructions
                }
              />
            </span>
            <span className="w-full max-w-[300px] hidden sm:block">
              {isEmpty ? (
                <FormInput
                  id={ItemData?.id}
                  key={ItemData?.id}
                  placeholder={"Add Special Instruction"}
                  name="cart_items"
                  type="text"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  width="w-full"
                  height="h-[40px]"
                  value={
                    ItemInstruction?.cart_items?.find(
                      (item) => item.cart_item_id == ItemData.id
                    )?.special_instructions ?? ItemData?.special_instructions
                  }
                  onValueChange={(e) => {
                    getPayload(
                      {
                        target: {
                          name: "cart_items",
                          value: [
                            ...ItemInstruction?.cart_items.filter(
                              (id) => id.cart_item_id != ItemData.id
                            ),
                            {
                              cart_item_id: ItemData?.id,
                              special_instructions: e.target.value,
                            },
                          ],
                        },
                      },
                      ItemInstruction,
                      setItemInstruction
                    );
                  }}
                />
              ) : (
                <CustomButton
                  width=""
                  height="h-9 3xl:h-12"
                  buttonTypeClass="outlined"
                  textColor="text-primary-900"
                  label={t("Please add any special instructions")}
                  action={() => {
                    setIsEmpty(true);
                  }}
                />
              )}
            </span>
          </>
        }
        price={ItemData?.total_price}
        OrderDetails={
          <>
            <Typography
              as="p"
              size="sm"
              lineHeight="leading-[1.46]"
              classes="ellipseText line-clamp-2 "
              text={ItemData?.size?.size_name}
            />
            {ItemData?.options?.map((item) => (
              <Typography
                as="p"
                size="sm"
                lineHeight="leading-[1.46]"
                classes="ellipseText line-clamp-2 "
                text={item?.option_value?.sub_option_name}
              />
            ))}
          </>
        }
      />
      <span className="w-full sm:hidden pb-4 px-4">
        <Typography
          as="span"
          color="text-warm_grey"
          lineHeight="leading-none"
          classes="text-[12px]"
          text={t("Please add any special instructions")}
        />
        <FormInput
          id={ItemData?.id}
          key={ItemData?.id}
          placeholder={"Add Special Instruction"}
          name="cart_items"
          type="text"
          bgColor="bg-bg_white"
          borderColor="border-bg_e8e8e8"
          width="w-full"
          height="h-[40px]"
          value={
            ItemInstruction?.cart_items?.find(
              (item) => item.cart_item_id == ItemData.id
            )?.special_instructions ?? ItemData?.special_instructions
          }
          onValueChange={(e) => {
            getPayload(
              {
                target: {
                  name: "cart_items",
                  value: [
                    ...ItemInstruction?.cart_items.filter(
                      (id) => id.cart_item_id != ItemData.id
                    ),
                    {
                      cart_item_id: ItemData?.id,
                      special_instructions: e.target.value,
                    },
                  ],
                },
              },
              ItemInstruction,
              setItemInstruction
            );
          }}
        />
      </span>
    </>
  );
};

// OrderItem.PropTypes = {};

export default OrderItem;
