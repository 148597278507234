import { Footer, NavBar } from "components/molecules";

import { PropTypes } from "prop-types";
import { WithSpinner } from "Hocs";
import styles from "./Restaurant.module.css";
import RestaurantBody from "components/organisms/Resturant/RestaurantBody";
import Layout from "components/molecules/Layout";

const RestaurantTemplate = ({
  RestaurantData,
  SectionsRefs,
  isFavorite = false,
  DefaultAddress,
  ToggleFavoriteVendorClick,
  ReviewOpen,
  HandleReviewModal,
  vendorReviews,
  isReviewsLoading,
  choices,
  ReviewSort,
  setReviewSort,
  isVendorLoading,
  currentUser,
  handelChangeDeliveryOption,
  cartData,
  deliveryOption,
  setDeliveryOption,
}) => {
  const RestaurantContainer = WithSpinner(RestaurantBody);
  return (
    <main className={styles.main}>
      <Layout>
        <RestaurantContainer
          isLoading={isVendorLoading}
          page={true}
          RestaurantData={RestaurantData}
          SectionsRefs={SectionsRefs}
          isFavorite={isFavorite}
          DefaultAddress={DefaultAddress}
          ToggleFavoriteVendorClick={ToggleFavoriteVendorClick}
          ReviewOpen={ReviewOpen}
          HandleReviewModal={HandleReviewModal}
          vendorReviews={vendorReviews}
          isReviewsLoading={isReviewsLoading}
          choices={choices}
          ReviewSort={ReviewSort}
          setReviewSort={setReviewSort}
          currentUser={currentUser}
          handelChangeDeliveryOption={handelChangeDeliveryOption}
          cartData={cartData}
          deliveryOption={deliveryOption}
          setDeliveryOption={setDeliveryOption}
        />
      </Layout>
    </main>
  );
};

RestaurantTemplate.propTypes = {
  RestaurantData: PropTypes.object,
  isFavorite: PropTypes.bool,
  SectionsRefs: PropTypes.array,
  DefaultAddress: PropTypes.object,
  ToggleFavoriteVendorClick: PropTypes.func,
  ReviewOpen: PropTypes.bool,
  HandleReviewModal: PropTypes.func,
  vendorReviews: PropTypes.array,
  isReviewsLoading: PropTypes.bool,
  choices: PropTypes.array,
  ReviewSort: PropTypes.string,
  setReviewSort: PropTypes.func,
};

export default RestaurantTemplate;
