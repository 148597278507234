import { Typography } from "components/atoms";
import { CustomSlider, RestaurantCard } from "components/molecules";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "templates/App/Home/Home.module.css";
import classnames from "classnames";
import { withTranslation } from "react-i18next";

const Categories = ({ t, categoriesChunckSize, categoriesList }) => {
  return (
    <section className={styles.Categories}>
      <Typography
        as="h2"
        size="7xl"
        color="text-text_464646"
        classes="mb-6"
        text={t("Categories")}
      />
      <CustomSlider ArrowsPosition="baseline">
        {categoriesList?.map((categoriesArray, idx) => {
          return (
            <div
              key={idx}
              className={classnames(
                "flex justify-center md:justify-evenly w-11/12 2xl:w-[96%] mx-auto",
                {
                  "lg:justify-start":
                    categoriesArray?.length < categoriesChunckSize,
                }
              )}
            >
              {categoriesArray?.map((category) => (
                <NavLink
                  key={category?.id}
                  to="/Categories"
                  state={{ categoryId: category?.id }}
                >
                  <div
                    className={classnames(styles.categoryCard, {
                      "lg:mx-4 2xl:mx-14":
                        categoriesArray?.length < categoriesChunckSize,
                    })}
                  >
                    <Typography
                      as="p"
                      size="4xl"
                      color="text-text_white"
                      text={category?.name}
                    />
                  </div>
                </NavLink>
              ))}
            </div>
          );
        })}
      </CustomSlider>
    </section>
  );
};

export default withTranslation()(Categories);
