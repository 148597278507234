import { useState } from "react";

import { PropTypes } from "prop-types";
import { Tab } from "@headlessui/react";

const Tabs = ({ children, categories, getTabView, tabIndex, click }) => {
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const [selectedIndex, setSelectedIndex] = useState(tabIndex);

  return (
    <div>
      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(
            Object.values(categories).findIndex((value, idx) => idx === index)
          );
          getTabView(Object.entries(categories)[index][1].status);
        }}
      >
        <Tab.List className="flex px-6 md:px-0 ">
          {Object.keys(categories).map((category) => (
            <Tab
              onClick={click}
              key={category}
              className={({ selected }) =>
                classNames(
                  "px-4 py-2 h-10 text-[12px] font-bold leading-tight  outline-none focus:shadow-outline w-full border  border-primary-900",
                  selected
                    ? "bg-primary-900 text-bg_f7f7f7  hover:bg-primary-900 hover:text-bg_f7f7f7 border  border-primary-900"
                    : "text-primary-900 hover:bg-primary-900 hover:text-bg_f7f7f7"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel key={idx} className="">
              {children}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  categories: PropTypes.instanceOf(Object),
  getTabView: PropTypes.func,
};

export default Tabs;
