import UserActionTypes from "../types/user.types";

const INITIAL_STATE = {
  currentUser: null,
  error: null,
  mobile_verified: false,
  reset_password_verifyOTPSuccess: false,
  password_reset: false,
  currentDeliveryLocation: null,
  isContactUsMessageSend: false,
  isPasswordChanged: false,
  FAQs: null,
  isFAQLoading: false,
  notifications: null,
  notificationsCount: null,
  isNotificationLoading: false,
  un_verified_mobile: null,
  new_mobile_verified: false,
  FCMToken: null,
  isProfileUpdated: false,
  isProfileLoading: false,
  systemConfiguration: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SAVE_USER_DELIVERY_LOCATION:
      return {
        ...state,
        currentDeliveryLocation: action.payload,
      };
    case UserActionTypes.CLEAR_USER_DELIVERY_LOCATION:
      return {
        ...state,
        currentDeliveryLocation: null,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return { ...state, error: action.payload };
    case UserActionTypes.VERIFY_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        mobile_verified: true,
        currentUser: { ...state.currentUser, is_verified: true },
      };
    case UserActionTypes.SEND_VERIFICATION_MESSAGE_START:
      return {
        ...state,
        mobile_verified: false,
        reset_password_verifyOTPSuccess: false,
      };
    case UserActionTypes.SEND_VERIFICATION_MESSAGE_SUCCESS:
      return {
        ...state,
        mobile_verified: false,
      };
    case UserActionTypes.SEND_VERIFICATION_MESSAGE_FAILURE:
      return {
        ...state,
        mobile_verified: false,
      };
    case UserActionTypes.RESET_PASSWORD_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        reset_password_verifyOTPSuccess: true,
      };
    case UserActionTypes.VERIFY_OTP_START:
      return {
        ...state,
        password_reset: false,
      };
    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password_reset: true,
      };
    case UserActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        password_reset: false,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS: {
      window.location.reload();
      return {
        ...state,
        currentUser: null,
        error: null,
        mobile_verified: false,
        reset_password_verifyOTPSuccess: false,
        password_reset: false,
      };
    }
    case UserActionTypes.DELETE_USER_PROFILE_SUCCESS: {
      window.location.reload();
      return {
        ...state,
        currentUser: null,
        error: null,
        mobile_verified: false,
        reset_password_verifyOTPSuccess: false,
        password_reset: false,
      };
    }
    case UserActionTypes.SEND_CONTACT_US_MESSAGE_START: {
      return {
        ...state,
        isContactUsMessageSend: false,
      };
    }
    case UserActionTypes.SEND_CONTACT_US_MESSAGE_SUCCESS: {
      return {
        ...state,
        isContactUsMessageSend: action.payload,
      };
    }
    case UserActionTypes.SEND_CONTACT_US_MESSAGE_FAILURE: {
      return {
        ...state,
        isContactUsMessageSend: false,
      };
    }
    case UserActionTypes.UPDATE_USER_PROFILE_START: {
      return {
        ...state,
        isProfileUpdated: false,
        isProfileLoading: true,
      };
    }
    case UserActionTypes.UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isProfileUpdated: true,
        currentUser: {
          ...state.currentUser,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          gender: action.payload.gender,
          date_of_birth: action.payload.date_of_birth,
          receive_offers_discounts: action.payload.receive_offers_discounts,
          subscribe_news_letter: action.payload.subscribe_news_letter,
        },
        isProfileLoading: false,
      };
    }
    case UserActionTypes.VERIFY_NEW_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          mobile_number: action.payload.user.mobile_number,
        },
        new_mobile_verified: true,
        un_verified_mobile: null,
      };
    case UserActionTypes.UPDATE_MOBILE_SUCCESS:
      return {
        ...state,
        un_verified_mobile: action.payload.user.unverified_mobile_number,
        new_mobile_verified: false,
      };
    case UserActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordChanged: true,
      };
    case UserActionTypes.CHANGE_PASSWORD_INFORMED:
      return {
        ...state,
        isPasswordChanged: false,
      };
    case UserActionTypes.GET_FAQ_START: {
      return {
        ...state,
        isFAQLoading: true,
      };
    }
    case UserActionTypes.GET_FAQ_SUCCESS: {
      return {
        ...state,
        isFAQLoading: false,
        FAQs: action.payload,
      };
    }
    case UserActionTypes.GET_FAQ_FAILURE: {
      return {
        ...state,
        isFAQLoading: false,
      };
    }
    case UserActionTypes.GET_NOTIFICATION_START: {
      return {
        ...state,
        isNotificationLoading: true,
      };
    }
    case UserActionTypes.GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isNotificationLoading: false,
        notifications: action.payload,
      };
    }
    case UserActionTypes.GET_NOTIFICATION_FAILURE: {
      return {
        ...state,
        isNotificationLoading: false,
      };
    }
    case UserActionTypes.GET_NOTIFICATION_COUNT_SUCCESS: {
      return {
        ...state,
        notificationsCount: action.payload,
      };
    }
    case UserActionTypes.GET_NOTIFICATION_COUNT_FAILURE: {
      return {
        ...state,
      };
    }
    case UserActionTypes.SET_NOTIFICATION_READ_SUCCESS: {
      return {
        ...state,
        notificationsCount: action.payload,
      };
    }
    case UserActionTypes.SET_NOTIFICATION_READ_FAILURE: {
      return {
        ...state,
      };
    }
    case UserActionTypes.SET_FCM_TOKEN_SUCCESS: {
      return {
        ...state,
        FCMToken: action.payload.device.firebase_auth_token,
      };
    }
    case UserActionTypes.SET_MOBILE_NUMBER_START: {
      return {
        ...state,
      };
    }
    case UserActionTypes.SET_MOBILE_NUMBER_SUCCESS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          mobile_number: action.payload.user.mobile_number,
        },
        un_verified_mobile: action.payload.user.mobile_number,
      };
    }
    case UserActionTypes.GET_SYSTEM_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        systemConfiguration: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
