import "react-toastify/dist/ReactToastify.css";

import {
  GetNotificationCountStart,
  GetNotificationStart,
  setFCMStart,
} from "Redux/user/actions/user.actions";
import { GetToken, onMessageListener } from "utils/firebase/firebase.utils";
import { showToast, toastTypes } from "utils/helpers/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router";

import Routes from "Routes";
import { StorageUtils } from "utils";
import { ToastContainer } from "react-toastify";
import { selectCurrentUser } from "Redux/user";
import { selectFCM } from "Redux/user/selectors/user.selectors";
import firebase from "firebase/compat/app";

/* import { useDispatch, useSelector } from "react-redux";

import { signoutStart } from "Redux/user/actions/user.actions";

import { selectCurrentUser } from "Redux/user";
 */

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const FCMToken = useSelector(selectFCM);

  const [isToken, setToken] = useState(null);
  useEffect(() => {
    if (firebase?.messaging?.isSupported()) GetToken(setToken);
    if (isToken && currentUser && isToken != FCMToken) {
      dispatch(setFCMStart({ token: isToken }));
    }
  }, [isToken, currentUser]);

  if (firebase?.messaging?.isSupported())
    onMessageListener()
      .then((payload) => {
        showToast(toastTypes.SUC, payload.notification.title);
        dispatch(GetNotificationCountStart());
        dispatch(GetNotificationStart());
      })
      .catch((err) => {});

  /*  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    // check user session
    const onUnload = (e) => {
      e.preventDefault();
      if (currentUser && !currentUser?.isRemembered) {
        dispatch(signoutStart());
        StorageUtils(localStorage.clear(localStorage));
      }
      return null;
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, [dispatch]); */

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  useEffect(() => {
    const currentLang = StorageUtils.getItems(localStorage, ["i18nextLng"])[
      "i18nextLng"
    ];
    document.body.dir = currentLang === "ar" ? "rtl" : "ltr";
    document.body.lang = currentLang;
  }, []);

  const appRoutes = useRoutes(Routes);

  return (
    <div className="font-Raleway">
      <ToastContainer />
      {appRoutes}
    </div>
  );
}

export default App;
