import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPayload, StorageUtils } from "utils";

import { listCategoriesStart } from "Redux/categories/actions/categories.actions";
import {
  getTopOffersVendorsStart,
  getVendorFiltersStart,
} from "Redux/vendors/actions/vendors.actions";
import { getAllVendorsStart } from "Redux/vendors/actions/vendors.actions";

import { selectCategoriesList } from "Redux/categories/selectors/categories.selectors";

import {
  selectHasMoreVendors,
  selectListVendorsLoading,
  selectListVendorsLoadingLoadMore,
  selectTopOffersVendors,
  selectVendorFilters,
  selectVendorsList,
} from "Redux/vendors/selectors/vendors.selectors";

import { CategoryTemplate } from "templates";
import {
  selectCartData,
  selectOrderTime,
} from "Redux/Cart/selectors/cart.selectors";

const CategoryPage = () => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const { state } = useLocation();

  const dispatch = useDispatch();

  const categories = useSelector(selectCategoriesList);
  const loading = useSelector(selectListVendorsLoading);
  const loadingLoadMore = useSelector(selectListVendorsLoadingLoadMore);
  const hasMore = useSelector(selectHasMoreVendors);
  const vendors = useSelector(selectVendorsList);
  const filters = useSelector(selectVendorFilters);
  const topOffersVendors = useSelector(selectTopOffersVendors);
  const OrderTime = useSelector(selectOrderTime);
  const cartData = useSelector(selectCartData);

  const [queryParams, setQueryParams] = useState({
    sortBy: "sort_a_to_z",
    search_key: "",
    filter_by_main_category_id: state ? [state?.categoryId] : [],
    filter_options: [],
    page_number: 1,
    page_size: 10,
  });

  const getQueryParams = (e) => getPayload(e, queryParams, setQueryParams);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (vendors) setPage((prev) => prev + 1);
  }, [vendors]);

  useEffect(() => {
    setPage(1);
  }, [queryParams, OrderTime]);

  useEffect(() => {
    if (currentLng) {
      dispatch(getAllVendorsStart(queryParams));
    }
  }, [dispatch, currentLng, queryParams, OrderTime]);

  useEffect(() => {
    if (currentLng) {
      dispatch(getTopOffersVendorsStart());
    }
  }, [dispatch, currentLng, OrderTime]);

  useEffect(() => {
    if (currentLng) {
      dispatch(listCategoriesStart());
      dispatch(getVendorFiltersStart());
    }
  }, [dispatch, currentLng]);

  return (
    <CategoryTemplate
      queryParams={queryParams}
      getQueryParams={getQueryParams}
      categories={categories}
      filters={filters && filters.filter((item) => item.key)}
      loading={loading}
      loadingLoadMore={loadingLoadMore}
      page={page}
      hasMore={hasMore}
      vendors={vendors}
      topOffersVendors={topOffersVendors}
      cartData={cartData}
    />
  );
};

export default CategoryPage;
