import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userReducer } from "./user";
import vendorsReducer from "./vendors/reducer/vendors.reducer";
import categoriesReducer from "./categories/reducer/categories.reducer";
import offersReducer from "./offers/reducer/offers.reducer";
import ordersReducer from "./orders/reducer/orders.reducer";
import CartReducer from "./Cart/reducer/cart.reducer";
import addressesReducer from "./addresses/reducer/addresses.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cart"],
};

const rootReducer = combineReducers({
  user: userReducer,
  vendors: vendorsReducer,
  categories: categoriesReducer,
  offers: offersReducer,
  orders: ordersReducer,
  cart: CartReducer,
  addresses: addressesReducer,
});

export default persistReducer(persistConfig, rootReducer);
