import React from "react";

// import { PropTypes } from "prop-types";

import { Typography } from "components/atoms";
import Counter from "../Counter";

const CartItem = ({ ItemData, UpdateCartItemHandleClick }) => {
  return (
    <>
      <div className="px-6 py-1">
        <div className="w-full flex justify-between items-center">
          <Typography
            as="h3"
            color="text-text_3b3b3b"
            classes="font-bold text-[13px] py-1 leading-[1.06] max-w-[95px] w-full break-all"
            text={ItemData?.product_name}
          />

          <Counter
            UpdateCartItemHandleClick={UpdateCartItemHandleClick}
            ItemData={ItemData}
          />

          <Typography
            as="h4"
            size="xs"
            classes="font-SFProText min-w-[52px] text-right rtl:text-left"
            text={`${ItemData?.total_price} EGP`}
          />
        </div>
      </div>
      <hr className="w-full bg-bg_e8e8e8" />
    </>
  );
};

// CartItem.PropTypes = {
// name: PropTypes.string,
// items: PropTypes.string,
// };

export default CartItem;

/* 
  <span className="flex items-center">
    <MinusSign
      width="10px"
      className="cursor-pointer" //ItemData?.quantity === 1 ? " opacity-25" : "cursor-pointer"
      onClick={(e) => {
        // if (ItemData?.quantity > 1)
        UpdateCartItemHandleClick(e, "Subtract", ItemData);
      }}
    />
    <span className="px-2">{ItemData?.quantity}</span>
    <PlusSign
      width={"12px"}
      className="cursor-pointer"
      onClick={(e) => UpdateCartItemHandleClick(e, "Add", ItemData)}
    />
  </span>
*/
