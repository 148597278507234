import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import { StorageUtils } from "utils";
import { MapMarker } from "components/atoms";
import { Modal, ModalHeader } from "components/molecules";
import MapWithDirections from "./test";

const DirectionModal = ({
  isOpen,
  ToggleModalVisibility,
  zoomLevel = 17,
  zIndex = "z-30",
  restaurantData,
}) => {
  const { t } = useTranslation();
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );

  return (
    <Modal
      isOpen={isOpen}
      ToggleModalVisibility={ToggleModalVisibility}
      zIndex={zIndex}
    >
      <div className="bg-white w-[100%] lg:w-[60%]">
        <ModalHeader
          ToggleModalVisibility={ToggleModalVisibility}
          heading={t("Pickup Address")}
        />

        <div style={{ height: "auto", width: "100%" }}>
          <MapWithDirections restaurantData={restaurantData} />
        </div>
      </div>
    </Modal>
  );
};

DirectionModal.propTypes = {
  isOpen: PropTypes.bool,
  ToggleModalVisibility: PropTypes.func,
  children: PropTypes.node,
};

export default DirectionModal;
