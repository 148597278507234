import OffersActionTypes from "../types/offers.types";

import { createAction } from "utils";

export const listOffersStart = () =>
  createAction(OffersActionTypes.LIST_OFFERS_START, {
    page_number: 1,
    page_size: 10,
  });

export const listOffersSuccess = (offers) =>
  createAction(OffersActionTypes.LIST_OFFERS_SUCCESS, offers);

export const listOffersFailure = (error) =>
  createAction(OffersActionTypes.LIST_OFFERS_FAILURE, error);

export const loadMoreOffersStart = (payload) =>
  createAction(OffersActionTypes.LOAD_MORE_OFFERS_START, payload);

export const loadMoreOffersSuccess = (offers) =>
  createAction(OffersActionTypes.LOAD_MORE_OFFERS_SUCCESS, offers);

export const loadMoreOffersFailure = (error) =>
  createAction(OffersActionTypes.LOAD_MORE_OFFERS_FAILURE, error);
