import { useTranslation } from "react-i18next";

import { FullScreenBgLayout } from "components/Layouts";
import { /* CustomButton, */ Typography } from "components/atoms";

const PrivacyPolicyPaymentTemplate = () => {
  const { t } = useTranslation();

  return (
    <FullScreenBgLayout
      backgroundImage="PrivacyPolicyBg"
      glassContainerWidth="md:w-4/5 xl:w-7/12"
      glassContainerHight="md:h-max"
    >
      <main className="h-full">
        <div className="h-full flex flex-col justify-between">
          <Typography
            as="h1"
            size="9xl"
            color="text-text_white"
            classes="text-center"
            text={t("Payfort Policies")}
          />
          <div className="h-96 w-full whiteScroll overflow-y-scroll my-4 lg:my-8 3xl:my-16">
            <div className=" px-5 md:px-8 lg:px-14 3xl:px-20 ">
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Refund Policy`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`In the case of problems with your goods and services order, please contact us and we will assist you. We might issue full or partial refunds depending on your case; if you did not receive your order due to any reason from our side, you may be issued a full refund; if part of your order is missing, we may issue a partial refund. Relevant factors to each case will be considered. You will receive your refund to the original source of payment within a period of 10 to 45 dayscdepending on the method of payment and issuing bank of your card in case of card payment or any other mean we might agree on`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Price and Payment`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`Order acceptance represents an agreement between you and the Merchant. The Merchant is solely responsibility for this order. Prices quoted on the Platform are in Egyptian Pounds and subject to applicable tax (if any). Prices and offers on the Platform may vary from the prices and offers offered anywhere else by our Merchants.
The way we diplay prices may vary from Merchant to another. Prices and additional charges (including but not limited to Delivery) indicated on the Platform are as at the time of each Order and may be subject to change later. We reserve the right to charge fees for our services as we deem necessary for our business. The breakdown of the prices and additional charges are displayed before checkout or on the item details. By placing an order you agree to all amounts, additional charges displayed to you.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Method of Payment`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`We reserve the right to add, remove or amend any payment method at our sole discretion. If you choose to pay using an online payment method, the payment shall be processed by our third party payment service provider(s). We shall not store, sell, share, rent or lease your credit/debit card data. However by accepting this terms and conditions you consent, your credit card / payment information will be stored with our third party payment service provider(s) for future Orders.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Order Cancelation`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`You can cancel an order for a full refund until the order has been accepted by the Merchant. In the event that you need to cancel the order after it has been accepted by the Merchant we will handle this on case by case basis.
We and the merchant reserve the right to cancel any order, before or after acceptance, and will notify you as early as possible. If the your order was rejected or cancelled by the merchant, we shall do a full refund.`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Ordering`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`You understand that the available goods offered may vary from delivery area to delivery area and from time to time. We shall do our best to clearly identify the avaialble merchants based on your location and the time of your order`}
              />
              <Typography
                as="p"
                size="2xl"
                classes="font-bold my-4"
                color="text-text_grey"
                text={`Delivery`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`We shall do our best to deliver your Order to the delivery address provided by you on timely manner according to the estimated delivery time that is provided to you. However, You acknowledge that the delivery time we provide is only for reference and Orders may arrive earlier or later.`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`In case we were unable to deliver your order for a reason caused by you, for example; no one is present or available, inaccurate data provided, misleading information, being unreachable or we were unable to contact you after reasonable attempts, it is unsafe to make the delivery. in such cases we reserve the right take one or more action of the below;`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`*Cancel your order with or without refund`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`*Apply penalty by applying negative balance to your account`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`*Suspend your account`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`*Exclude your account from some offers or promotion`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`*Ristrict the methods of payments you can use`}
              />
              <Typography
                as="p"
                size="xl"
                classes="my-2"
                color="text-text_grey"
                text={`If it was made available for our customers to select contactless We shall not be liable for theft, damage, contamination and/or other deterioration of the goods that occur after delivery has taken place.`}
              />
            </div>
          </div>
          {/* 
          <div className=" mx-auto w-full lg:w-9/12 flex justify-evenly">
            <CustomButton
              label={t("Decline")}
              buttonTypeClass="outlined"
              width="w-40 lg:w-[215px]"
              height="h-12"
            />

            <CustomButton
              label={t("Accept")}
              width="w-40 lg:w-[215px]"
              height="h-12"
            />
          </div> */}
        </div>
      </main>
    </FullScreenBgLayout>
  );
};

export default PrivacyPolicyPaymentTemplate;
