import { Fragment } from "react";

import { PropTypes } from "prop-types";
import { Popover, Transition } from "@headlessui/react";

const PopOver = ({ children, label, panelClasses = "", onClick }) => {
  return (
    <Popover className="relative">
      <Popover.Button onClick={onClick}>{label}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          static
          className={`absolute z-10 rounded-lg bg-primary-50 transform ${panelClasses} `}
        >
          {({ close }) => (
            <div
              style={{
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
              }}
              className="overflow-hidden rounded-lg  ring-1 ring-black ring-opacity-5"
              onClick={() => {
                if (children?.props?.id !== "cart") close();
              }}
            >
              {children}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

Popover.propTypes = {
  label: PropTypes.any,
  children: PropTypes.node,
  panelClasses: PropTypes.string,
};

export default PopOver;
