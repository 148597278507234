import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getTimerSeconds } from "utils";

import { selectIsOrderCancelled } from "Redux/orders/selectors/orders.selectors";

import { CustomButton } from "components/atoms";
import {
  OrderSummary,
  OrderTimeTracker,
  ReasonsOfCancellingModal,
  SuccessConfirmationModal,
} from "components/organisms";

import styles from "./OrderTracking.module.css";

const OrderTrackingTemplate = ({ order }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const ToggleModalVisibility = () => setIsOpen(!isOpen);

  const [timerSeconds, setTimerSeconds] = useState(
    getTimerSeconds(order?.delivery_date_time)
  );
  const [progress, setProgress] = useState(0);

  const [isSuccesModalOpen, setIsSuccesModalOpen] = useState(false);

  const isOrderCancelled = useSelector(selectIsOrderCancelled);

  useEffect(() => {
    if (isOrderCancelled) {
      setIsOpen(false);
      setIsSuccesModalOpen(true);
    }
  }, [isOrderCancelled]);

  useEffect(() => {
    setTimerSeconds(getTimerSeconds(order?.delivery_date_time));
  }, [order]);

  return (
    <>
      <main className={styles.main}>
        <div className={styles.container}>
          <OrderTimeTracker
            orderStatus={order && Object.entries(order?.status)[0][1]}
            restaurantName={order?.cart?.vendor_name}
            timerSeconds={timerSeconds}
            progress={progress}
            setProgress={setProgress}
          />
        </div>
        
        <OrderSummary
          restaurantName={order?.cart?.vendor_name}
          cartItems={order?.cart?.cart_items}
          invoiceDetails={order?.invoice_details}
          specialInstruction={order?.cart?.special_instructions}
          paymentType={order && Object.entries(order?.payment_type)[0][1]}
        />

        <div className={styles.container}>
          <section className="flex justify-center">
            <CustomButton
              width="w-full lg:w-80"
              height="h-10 lg:h-12 2xl:h-14"
              borderRadius="4px"
              buttonTypeClass="outlined"
              borderColor="border-primary-900"
              textColor="text-primary-900"
              boxShadow="0 3px 6px 0 rgba(0, 0, 0, 0.16)"
              classes="font-bold"
              label={t("Cancel Order")}
              action={ToggleModalVisibility}
            />
            <ReasonsOfCancellingModal
              isOpen={isOpen}
              orderId={order?.id}
              ToggleModalVisibility={ToggleModalVisibility}
            />
            <SuccessConfirmationModal
              isOpen={isSuccesModalOpen}
              ToggleModalVisibility={() =>
                navigate("/Orders", {
                  state: {
                    status: "cancelled",
                  },
                })
              }
              heading={t("Order canceled Successfully")}
            />
          </section>
        </div>
      </main>
    </>
  );
};

OrderTrackingTemplate.propTypes = {
  order: PropTypes.instanceOf(Object),
};

export default OrderTrackingTemplate;
