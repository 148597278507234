import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 330, md: 600 },
  bgcolor: "background.paper",
  borderRadius: "6px",
  px: { xs: 1, md: 3 },
  py: { xs: 3, md: 8 },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default function AlertModal({ open, message,icon, onClose }) {

  const handleClose = () => {
    onClose(); 
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box component="img" src={icon} sx={{ width: { xs: "20%", md: "13%" } }} />
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              textAlign: "center",
              fontSize: { xs: "16px", md: "28px" },
            }}
          >
            {message}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}