import { takeLatest, all, call, put } from "redux-saga/effects";

import CategoriesActionTypes from "../types/categories.types";
import {
  listCategoriesFailure,
  listCategoriesSuccess,
} from "../actions/categories.actions";

import * as URL from "services/URLs";

import getAxios from "utils/Axios";

const axios = getAxios();

export function* listCategories() {
  try {
    const res = yield call(axios.call, "get", URL.MAIN_CATEGORIES);
    if (res?.status === 200) yield put(listCategoriesSuccess(res?.data?.data));
  } catch (error) {
    yield put(listCategoriesFailure(error));
  }
}

export function* onListCategoriesStart() {
  yield takeLatest(CategoriesActionTypes.LIST_CATEGORIES_START, listCategories);
}

export function* categoriesSagas() {
  yield all([call(onListCategoriesStart)]);
}
