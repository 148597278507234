const ordersActionsTypes = {
  GET_PAYMENT_START: "GET_PAYMENT_START",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAILURE: "GET_PAYMENT_FAILURE",
  LIST_ORDERS_START: "LIST_ORDERS_START",
  LIST_ORDERS_SUCCESS: "LIST_ORDERS_SUCCESS",
  LIST_ORDERS_FAILURE: "LIST_ORDERS_FAILURE",
  CHECKOUT_ORDER_START: "CHECKOUT_ORDER_START",
  CHECKOUT_ORDER_SUCCESS: "CHECKOUT_ORDER_SUCCESS",
  CHECKOUT_ORDER_FAILURE: "CHECKOUT_ORDER_FAILURE",
  CANCEL_ORDER_START: "CANCEL_ORDER_START",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
  GET_ORDER_START: "GET_ORDER_START",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",
  LOAD_MORE_ORDERS_START: "LOAD_MORE_ORDERS_START",
  LOAD_MORE_ORDERS_SUCCESS: "LOAD_MORE_ORDERS_SUCCESS",
  LOAD_MORE_ORDERS_FAILURE: "LOAD_MORE_ORDERS_FAILURE",
  REORDER_START: "REORDER_START",
  REORDER_SUCCESS: "REORDER_SUCCESS",
  REORDER_FAILURE: "REORDER_FAILURE",
  ADD_RATE_VENDOR_START: "ADD_RATE_VENDOR_START",
  ADD_RATE_VENDOR_SUCCESS: "ADD_RATE_VENDOR_SUCCESS",
  ADD_RATE_VENDOR_FAILURE: "ADD_RATE_VENDOR_FAILURE",
  UPDATE_STATUS_START: "UPDATE_STATUS_START",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",
};

export default ordersActionsTypes;
