import { PropTypes } from "prop-types";

import { Typography } from "components/atoms";

import styles from "./AutoCompleteListOption.module.css";

const AutoCompleteListOption = ({
  icon,
  location,
  locationDescription,
  action,
}) => {
  return (
    <li onClick={action} className={styles.option}>
      {icon && <div className={styles.optionIcon}>{icon}</div>}

      <div>
        {location && (
          <Typography
            as="h3"
            size="lg"
            lineHeight="leading-[0.94]"
            fontWeight="font-semibold"
            color="text-text_464646"
            text={location}
          />
        )}
        {locationDescription && (
          <Typography
            as="span"
            size="sm"
            lineHeight="leading-none"
            color="text-warm_grey"
            text={locationDescription}
          />
        )}
      </div>
    </li>
  );
};

AutoCompleteListOption.propTypes = {
  icon: PropTypes.node,
  location: PropTypes.string,
  locationDescription: PropTypes.string,
  action: PropTypes.func,
};

export default AutoCompleteListOption;
