import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { WithSpinner } from "Hocs";

import {
  listOrdersStart,
  loadMoreOrdersStart,
} from "Redux/orders/actions/orders.actions";

import {
  selectHasMoreOrders,
  selectordersList,
  selectOrdersLoading,
} from "Redux/orders/selectors/orders.selectors";

import { Spinner } from "components/atoms";
import { OrderCard } from "components/molecules";
import InProgressTemplate from "templates/App/Orders/InProgress";
import { useNavigate, useParams } from "react-router";

const OrdersListBody = ({
  status,
  orders,
  loading,
  hasMore,
  page,
  setSelectedOrderId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <>
      {orders?.length ? (
        <div className="my-8 bg-primary-50 lg:px-20 lg:py-14">
          <InfiniteScroll
            dataLength={orders && orders?.length}
            scrollThreshold={0.5}
            next={() =>
              dispatch(loadMoreOrdersStart({ status, page_number: page }))
            }
            hasMore={hasMore}
            loader={<Spinner loading={loading} />}
          >
            {orders?.map((order) => (
              <OrderCard
                key={order?.id}
                status={status}
                restaurantId={order?.cart?.vendor_id}
                restaurantName={order?.cart?.vendor_name}
                RestaurantLogo={order?.vendor?.logo}
                orderDate={order?.created_at}
                orderId={order?.id}
                orderHash={order?.hash_id}
                isReviewed={order?.is_reviewed}
                action={() => {
                  navigate(`/Orders/inProgress/${order?.id}`);
                  // setSelectedOrderId(order?.id);
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const OrdersList = ({ status }) => {
  const { i18n } = useTranslation();

  const currentLng = i18n?.language;

  const dispatch = useDispatch();
  const params = useParams();

  const orders = useSelector(selectordersList);
  const loading = useSelector(selectOrdersLoading);
  const hasMore = useSelector(selectHasMoreOrders);

  useEffect(() => {
    if (currentLng) {
      dispatch(listOrdersStart({ status }));
    }
  }, [dispatch, currentLng, status]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (orders) setPage((prev) => prev + 1);
  }, [orders]);

  const OrdersListContainer = WithSpinner(OrdersListBody);

  const [selectedOrdeId, setSelectedOrderId] = useState();
  const [isDetailedViewOpen, setIsDetailedViewOpen] = useState(false);

  useEffect(() => {
    if (selectedOrdeId) setIsDetailedViewOpen(true);
  }, [selectedOrdeId]);

  return params?.id ? (
    // return isDetailedViewOpen ? (
    <InProgressTemplate id={params?.id} />
  ) : (
    <OrdersListContainer
      setIsDetailedViewOpen={setIsDetailedViewOpen}
      setSelectedOrderId={setSelectedOrderId}
      isLoading={loading}
      loading={loading}
      hasMore={hasMore}
      status={status}
      page={page}
      orders={orders}
    />
  );
};

OrdersListBody.propTypes = {
  status: PropTypes.string,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  page: PropTypes.number,
  orders: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

OrdersList.propTypes = {
  status: PropTypes.string,
};

export default OrdersList;
