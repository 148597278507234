const CartActionTypes = {
  DELIVERY_TIME_RANGE_START: "DELIVERY_TIME_RANGE_START",
  DELIVERY_TIME_RANGE_SUCCESS: "DELIVERY_TIME_RANGE_SUCCESS",
  DELIVERY_TIME_RANGE_FAIL: "DELIVERY_TIME_RANGE_FAIL",
  CREATE_CART_START: "CREATE_CART_START",
  CREATE_CART_SUCCESS: "CREATE_CART_SUCCESS",
  CREATE_CART_FAIL: "CREATE_CART_FAIL",
  CART_DATA_START: "CART_DATA_START",
  CART_DATA_SUCCESS: "CART_DATA_SUCCESS",
  CART_DATA_FAILURE: "CART_DATA_FAILURE",
  UPDATE_CART_DATA_START: "UPDATE_CART_DATA_START",
  UPDATE_CART_DATA_SUCCESS: "UPDATE_CART_DATA_SUCCESS",
  UPDATE_CART_DATA_FAILURE: "UPDATE_CART_DATA_FAILURE",
  DELETE_CART_ITEM_START: "DELETE_CART_ITEM_START",
  DELETE_CART_ITEM_SUCCESS: "DELETE_CART_ITEM_SUCCESS",
  DELETE_CART_ITEM_FAILURE: "DELETE_CART_ITEM_FAILURE",
  DELETE_CART_DATA_START: "DELETE_CART_DATA_START",
  DELETE_CART_DATA_SUCCESS: "DELETE_CART_DATA_SUCCESS",
  DELETE_CART_DATA_FAILURE: "DELETE_CART_DATA_FAILURE",
  UPDATE_CART_ITEM_START: "UPDATE_CART_ITEM_START",
  UPDATE_CART_ITEM_SUCCESS: "UPDATE_CART_ITEM_SUCCESS",
  UPDATE_CART_ITEM_FAILURE: "UPDATE_CART_ITEM_FAILURE",
  ADD_CART_ITEM_START: "ADD_CART_ITEM_START",
  ADD_CART_ITEM_SUCCESS: "ADD_CART_ITEM_SUCCESS",
  ADD_CART_ITEM_FAILURE: "ADD_CART_ITEM_FAILURE",
  ADD_CART_ITEM_ANOTHER_VENDOR_START: "ADD_CART_ITEM_ANOTHER_VENDOR_START",
  ADD_CART_ITEM_ANOTHER_VENDOR_SUCCESS: "ADD_CART_ITEM_ANOTHER_VENDOR_SUCCESS",
  ADD_CART_ITEM_ANOTHER_VENDOR_FAILURE: "ADD_CART_ITEM_ANOTHER_VENDOR_FAILURE",
  APPLY_PROMO_CODE_START: "APPLY_PROMO_CODE_START",
  APPLY_PROMO_CODE_SUCCESS: "APPLY_PROMO_CODE_SUCCESS",
  APPLY_PROMO_CODE_FAILURE: "APPLY_PROMO_CODE_FAILURE",
  REMOVE_PROMO_CODE_START: "REMOVE_PROMO_CODE_START",
  REMOVE_PROMO_CODE_SUCCESS: "REMOVE_PROMO_CODE_SUCCESS",
  REMOVE_PROMO_CODE_FAIL: "REMOVE_PROMO_CODE_FAIL",
  UPDATE_ORDER_TIME: "UPDATE_ORDER_TIME",
};

export default CartActionTypes;
