import InfiniteScroll from "react-infinite-scroll-component";
import { PropTypes } from "prop-types";

import { Spinner } from "components/atoms";
import { Footer, OfferCard, NavBar } from "components/molecules";

import styles from "./Offers.module.css";
import Layout from "components/molecules/Layout";

const OffersTemplate = ({ offers, hasMore, loading, loadMoreOffers }) => {
  return (
    <Layout>
      <main className={styles.main}>
        {offers?.length ? (
          <div className="my-8 bg-transparent lg:bg-primary-50 lg:px-20 lg:py-14">
            <InfiniteScroll
              dataLength={offers && offers?.length}
              scrollThreshold={0.5}
              next={loadMoreOffers}
              hasMore={hasMore}
              loader={<Spinner loading={loading} />}
            >
              {offers?.map((offer) => (
                <OfferCard
                  key={offer?.vendor_id}
                  id={offer?.vendor_id}
                  restaurantLogo={offer?.logo}
                  offerDescription={offer?.description}
                  offerTitle={offer?.title}
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          ""
        )}
      </main>
    </Layout>
  );
};

OffersTemplate.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  loadMoreOffers: PropTypes.func,
};

export default OffersTemplate;
