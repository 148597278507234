import { createRef, useEffect, useState } from "react";

const useScroll = (arrayOfData, dep) => {
  const [SectionsRefs, setSectionsRefs] = useState([]);

  useEffect(() => {
    if (dep) {
      setSectionsRefs((SectionsRefs) =>
        arrayOfData?.map((_, i) => SectionsRefs[i] || createRef())
      );
    }
  }, [dep]);
  return SectionsRefs;
};

export default useScroll;
