import { useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { GPSBlack, MapMarkerBlackPin, Location } from "utils/helpers/SVGsSrc";
import { isEmptyString, loadScript, StorageUtils } from "utils";

import { listSavedAddressesStart } from "Redux/addresses/actions/addresses.actions";
import { saveUserDeliveryLocation } from "Redux/user/actions/user.actions";
import { setSelectedAddressStart } from "Redux/addresses/actions/addresses.actions";

import { selectCurrentUser } from "Redux/user";
import { selectSavedAddresses } from "Redux/addresses/selectors/addresses.selectors";

import { FormInput } from "components/atoms";
import { AutoCompleteListOption } from "components/molecules";
import DeliveryAddressModal from "../Modals/DeliveryAddressModal";

import styles from "./Autocomplete.module.css";
import { generateAddress, getGeoLocation } from "./fun";

let autoComplete;
let geoCoder;

const getPredictions = (query, setSuggestions) => {
  autoComplete.getPlacePredictions(
    {
      input: query,
      types: ["establishment"],
      componentRestrictions: { country: "eg" },
      strictBounds: true,
    },
    (predictions, status) => {
      if (status === "OK") {
        setSuggestions(predictions);
      }
    }
  );
};

const handleScriptLoad = (query, setSuggestions) => {
  autoComplete = new window.google.maps.places.AutocompleteService();
  geoCoder = new window.google.maps.Geocoder();

  getPredictions(query, setSuggestions);
};

const MapWithASearchBox = ({
  selectedAddress,
  detectUserCoords,
  source,
  UserCoords,
  setUserCoords,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const savedAddresses = useSelector(selectSavedAddresses);

  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [focus, setFocus] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const ToggleModalVisibility = () => setIsOpen(!isOpen);

  const [center, setCenter] = useState(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
      ? JSON.parse(
          StorageUtils.getItems(sessionStorage, ["distance_cords"])[
            "distance_cords"
          ]
        )
      : {
          lat: 26.8206,
          lng: 30.8025,
        }
  );

  const saveLocation = (distance_cords) => {
    StorageUtils.saveItems(sessionStorage, [
      {
        name: "distance_cords",
        data: JSON.stringify(distance_cords),
      },
    ]);
    setUserCoords(distance_cords);
    setCenter({
      lat: distance_cords.lat,
      lng: distance_cords.lng,
    });
  };

  const handleSelectedAddress = async (selectedAddress) => {
    await setIsSelected(true);
    const distance_cords = {
      lat: "",
      lng: "",
    };

    if (selectedAddress.place_id) {
      await StorageUtils.saveItems(sessionStorage, [
        {
          name: "selectedAddressType",
          data: "notSaved",
        },
      ]);
      await getGeoLocation({
        selectedAddress,
        distance_cords,
        dispatch,
        saveLocation,
        geoCoder,
        saveUserDeliveryLocation,
        setQuery,
      });
    } else {
      StorageUtils.saveItems(sessionStorage, [
        {
          name: "selectedAddressType",
          data: "saved",
        },
      ]);
      distance_cords.lat = +selectedAddress.lat;
      distance_cords.lng = +selectedAddress.lng;
      await saveLocation(distance_cords);
      await setQuery(
        `(${selectedAddress?.name}),${selectedAddress?.street_name}`
      );
      await dispatch(setSelectedAddressStart(selectedAddress.id));
      StorageUtils.saveItems(sessionStorage, [
        {
          name: "address",
          data: `${selectedAddress?.structured_formatting?.main_text} ,${selectedAddress?.structured_formatting?.secondary_text}`,
        },
      ]);
    }
    setTimeout(() => {
      setIsSelected(false);
    }, 300);
  };

  useEffect(() => {
    if (currentUser) dispatch(listSavedAddressesStart({ page_number: 1 }));
  }, [currentUser]);

  useEffect(() => {
    const savedAdressesSuggestions = savedAddresses?.map((savedAddress) => ({
      ...savedAddress,
      structured_formatting: {
        main_text: `(${savedAddress?.name})`,
        secondary_text: savedAddress?.street_name,
      },
    }));

    if (isEmptyString(query) && focus && savedAddresses)
      setSuggestions(savedAdressesSuggestions);
  }, [focus, query, savedAddresses]);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`,
      () => setScriptLoaded(true)
    );
  }, []);

  useEffect(() => {
    if (scriptLoaded) handleScriptLoad(query, setSuggestions);
  }, [scriptLoaded, query]);

  useEffect(() => {
    if (!isSelected) setIsMenuVisible(focus);
  }, [focus, isSelected]);
  //console.log(query, "query");
  return (
    <div className="w-[350px] md:w-[656px] 3xl:w-[820px]">
      <div className="h-[42px] md:h-[58px]">
        <FormInput
          containerClasses="flex"
          type="search"
          borderRadius="8px"
          bgColor="bg-bg_f5f5f5"
          textColor="text-warm_grey"
          height="h-[42px] md:h-[58px]"
          classes="absolute "
          width=" w-full md:w-[656px] 3xl:w-[820px]"
          placeholder={t("Search for city , area , street nam…")}
          value={query}
          onValueChange={(e) => setQuery(e.target.value)}
          onFocus={(e) => {
            setFocus(!focus);
          }}
          onBlur={() => {
            setTimeout(() => {
              if (focus) setFocus(!focus);
            }, 300);
          }}
        />
        <div
          onClick={() => {
            detectUserCoords();
            generateAddress(
              JSON.parse(localStorage.getItem("distance_cords")),

              geoCoder,
              handleSelectedAddress
            );
            // setIsAreaConfirmationOpen(true);
          }}
          className={styles.icon}
        >
          <GPSBlack width="100%" height="100%" title="Detect location" />
        </div>
      </div>
      <div className={styles.wrapper}>
        {suggestions?.length !== 0 && isMenuVisible && (
          <ul className={styles.suggestionsList}>
            {savedAddresses && isEmptyString(query) && (
              <AutoCompleteListOption location={t("Saved Addresses")} />
            )}
            {suggestions?.map((suggestion, idx) => {
              return (
                <AutoCompleteListOption
                  key={idx}
                  icon={
                    <Location
                      width="100%"
                      height="100%"
                      title="Detect current location"
                    />
                  }
                  location={suggestion?.structured_formatting?.main_text}
                  locationDescription={
                    suggestion?.structured_formatting?.secondary_text
                  }
                  action={() => {
                    handleSelectedAddress(suggestion);
                  }}
                />
              );
            })}

            <>
              <AutoCompleteListOption
                icon={
                  <GPSBlack
                    width="100%"
                    height="100%"
                    title="Detect current location"
                  />
                }
                location={t("Deliver to current location")}
                action={() => {
                  detectUserCoords();
                  generateAddress(
                    JSON.parse(localStorage.getItem("distance_cords")),

                    geoCoder,
                    handleSelectedAddress
                  );
                }}
              />
              {source === "home" && (
                <AutoCompleteListOption
                  icon={
                    <MapMarkerBlackPin
                      width="100%"
                      height="100%"
                      title="Detect different location"
                    />
                  }
                  location={t("Deliver to different location")}
                  action={() => {
                    setIsSelected(true);
                    ToggleModalVisibility();
                  }}
                />
              )}

              <DeliveryAddressModal
                isOpen={isOpen}
                ToggleModalVisibility={ToggleModalVisibility}
                selectedCenter={center}
                saveLocation={saveLocation}
                handleSelectedAddress={handleSelectedAddress}
                detectUserCoords={detectUserCoords}
                zoomLevel={17}
                home={true}
                UserCoords={UserCoords}
                setUserCoords={setUserCoords}
              />
            </>
          </ul>
        )}
        {/* <ConfirmAreaModal
          isOpen={isAreaConfirmationOpen}
          ToggleModalVisibility={() => setIsAreaConfirmationOpen(false)}
        /> */}
      </div>
    </div>
  );
};

MapWithASearchBox.propTypes = {
  GPSIconColor: PropTypes.string,
};

export default MapWithASearchBox;
