import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import classNames from "classnames";
import { ButtonArrow, Location, Pickup } from "utils/helpers/SVGsSrc";
import { CustomButton, Typography } from "components/atoms";
import styles from "./PickupAddressCard.module.css";
import { selectUserDeliveryLocation } from "Redux/user/selectors/user.selectors";
import { useSelector } from "react-redux";
import DirectionModal from "components/organisms/Modals/DirectionModal/DirectionModal";
import { useState } from "react";
import MapWithDirections from "components/organisms/Modals/DirectionModal/test";
import { StorageUtils } from "utils";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";
import { toast } from "react-toastify";

const PickupAddressCard = ({
  restaurantData,
  canChangeAddress = true,
  hasBicycleIcon = true,
  width = "w-full ",
  height = "h-[fit-content]",
  borderRadius = "8px",
  hasMarginBottom = true,
  cardHeading,
  currentUser,
  style,
  locationIcon = true,
  CartData,
}) => {
  const { t } = useTranslation();
  const DeliveryAddress = useSelector(selectUserDeliveryLocation);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const ToggleModalVisibility = () => setOpen(!isOpen);
  const selectedAddress = useSelector(selectSelectedAddress);

  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );

  return (
    <div
      style={{ borderRadius, ...style }}
      className={classNames(styles.contaner, width, height, {
        "mb-8": hasMarginBottom,
      })}
    >
      <div
        className={
          !locationIcon
            ? `w-[450px] ${styles.header}`
            : `w-full ${styles.header}`
        }
      >
        {hasBicycleIcon && (
          <div className={styles.headerIcon}>
            <Pickup width="100%" height="100%" title="go to addresses" />
          </div>
        )}

        <Typography
          as="h1"
          size="xl"
          color="text-text_3b3b3b"
          fontWeight="font-bold"
          text={cardHeading}
        />
      </div>

      <div className="flex md:w-[30%]  w-full items-center">
        {locationIcon ? (
          <div className={styles.locationIcon}>
            <Location
              width="100%"
              height="100%"
              className="ltr:mr-3 rtl:ml-3"
            />
          </div>
        ) : (
          <p
            style={{
              fontWeight: "700",
              fontSize: "17px",
              color: "#3B3B3B",
              marginInline: "5px",
            }}
          >
            {t("Address")} :
          </p>
        )}

        <Typography
          size="sm"
          as="h2"
          fontWeight="font-semibold"
          color="text-text_3b3b3b"
          text={CartData?.vendor_details?.pickup_address}
        />
      </div>

      {canChangeAddress && (
        <CustomButton
          width={!locationIcon ? "w-[300px]" : "w-full"}
          height="h-9 3xl:h-12"
          buttonTypeClass="outlined"
          textColor="text-primary-900"
          label={t("getDirections")}
          AfterIcon={
            <ButtonArrow
              width="18px"
              className="ml-5 rtl:mr-5 rtl:origin-center rtl:rotate-180"
            />
          }
          action={() => {
            // setOpen(true)
            if (sessionLocation?.lat)
              currentUser
                ? window.open(
                    `https://www.google.com/maps/dir/?api=1&destination=${+restaurantData?.lat},${+restaurantData?.lng}&origin=${+sessionLocation?.lat},${+sessionLocation?.lng}&travelmode=driving`
                  )
                : navigate(`/login`);
            else {
              toast.error("please choose location");
            }
          }}
        />
      )}
      <DirectionModal
        isOpen={isOpen}
        ToggleModalVisibility={ToggleModalVisibility}
        restaurantData={restaurantData}
      />
    </div>
  );
};

PickupAddressCard.propTypes = {
  restaurantData: PropTypes.instanceOf(Object),
  canChangeAddress: PropTypes.bool,
  hasBicycleIcon: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  addressDescriptionClasses: PropTypes.string,
  hasMarginBottom: PropTypes.bool,
  cardHeading: PropTypes.string,
};

export default PickupAddressCard;
