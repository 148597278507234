import { takeLatest, all, call, put } from "redux-saga/effects";

import OffersActionTypes from "../types/offers.types";

import * as URL from "services/URLs";
import {
  listOffersFailure,
  listOffersSuccess,
  loadMoreOffersSuccess,
} from "../actions/offers.actions";

import getAxios from "utils/Axios";
import { StorageUtils } from "utils";

const axios = getAxios();

export function* listOffers({ payload }) {
  const Payload =
    StorageUtils.getItems(sessionStorage, ["selectedAddressType"])[
      "selectedAddressType"
    ] == "notSaved"
      ? {
          ...payload,
          // area_id: JSON.parse(
          //   StorageUtils.getItems(sessionStorage, ["selectedArea"])[
          //     "selectedArea"
          //   ]
          // )?.id,
        }
      : payload;
  const queryParams = Object.entries(Payload).map(
    (queryParam, idx) =>
      `${
        queryParam[1]
          ? `${queryParam[0]}=${queryParam[1]}${
              idx === Object.entries(Payload).length - 1 ? "" : "&"
            }`
          : ""
      }`
  );
  try {
    const res = yield call(
      axios.call,
      "get",
      `${URL.GET_OFFERS}?${queryParams.join("")}`
    );
    if (res?.status === 200) {
      if (payload.page_number > 1)
        yield put(
          loadMoreOffersSuccess({
            offers: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
      else
        yield put(
          listOffersSuccess({
            offers: res?.data?.data,
            total_pages: res?.data?.total_pages,
            currentPage: payload.page_number,
          })
        );
    }
  } catch (error) {
    yield put(listOffersFailure(error));
  }
}

export function* onListOffersStart() {
  yield takeLatest(OffersActionTypes.LIST_OFFERS_START, listOffers);
}

export function* onLoadMoreOffersStart() {
  yield takeLatest(OffersActionTypes.LOAD_MORE_OFFERS_START, listOffers);
}

export function* offersSagas() {
  yield all([call(onListOffersStart), call(onLoadMoreOffersStart)]);
}
