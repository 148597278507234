import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  getSelectedAddressStart,
  listSavedAddressesStart,
} from "Redux/addresses/actions/addresses.actions";

import { selectCurrentUser } from "Redux/user";
import {
  selectAddressesHasMore,
  selectAddressesLoading,
  selectSavedAddresses,
} from "Redux/addresses/selectors/addresses.selectors";
import { selectUserDeliveryLocation } from "Redux/user/selectors/user.selectors";

import { AddressTemplate } from "templates";

const AddressPage = () => {
  const { i18n } = useTranslation();
  const currentLng = i18n?.language;
  const dispatch = useDispatch();

  // APIs
  const currentUser = useSelector(selectCurrentUser);
  const loading = useSelector(selectAddressesLoading);
  const hasMore = useSelector(selectAddressesHasMore);
  const savedAddresses = useSelector(selectSavedAddresses);
  const DeliveryAddress = useSelector(selectUserDeliveryLocation);

  useEffect(() => {
    if (currentLng) {
      dispatch(listSavedAddressesStart({ page_number: 1 }));
    }
  }, [dispatch, currentLng]);

  useEffect(() => {
    if (currentLng) {
      dispatch(getSelectedAddressStart({ save: true }));
    }
  }, [savedAddresses]);

  // Scrolling states
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (savedAddresses) setPage(page + 1);
  }, [savedAddresses]);

  return (
    <AddressTemplate
      currentUser={currentUser}
      savedAddresses={savedAddresses}
      loading={loading}
      hasMore={hasMore}
      DeliveryAddress={DeliveryAddress}
      Page={page}
      // selectedArea={selectedArea}
      // setSelectedArea={setSelectedArea}
      // selectedCity={selectedCity}
      // setSelectedCity={setSelectedCity}
      // Cities={Cities}
      // Areas={Areas}
    />
  );
};

export default AddressPage;
