import { BlackBicycle, ButtonArrow, CheckSign } from "utils/helpers/SVGsSrc";
import {
  CustomButton,
  FormInput,
  Spinner,
  SwitchToggle,
  Typography,
} from "components/atoms";
import {
  DeliveryAddressCard,
  DescriptionTile,
  Footer,
  NavBar,
  OrderItem,
} from "components/molecules";
import React, { useState } from "react";
import { getPayload, getTimerSeconds, secondsToTime } from "utils";
import { useLocation, useNavigate } from "react-router";

import { AddDeliveryAddressModal } from "components/organisms";
import { PropTypes } from "prop-types";
import { WithSpinner } from "Hocs";
import styles from "./PlaceOrder.module.css";
import { useTranslation } from "react-i18next";
import SelectDileveryOption from "components/organisms/Resturant/SelectDileveryOption";
import PickupAddressCard from "components/molecules/Cards/PickupAddressCard/PickupAddressCard";
import Layout from "components/molecules/Layout";

const PaymentSummary = ({ CartData, deliveryOption, vendor }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <DescriptionTile
        title={t("Subtotal")}
        price={CartData?.sub_total}
        mainTitle={false}
        hasTopBorder
      />

      <DescriptionTile
        title={t("Discount")}
        price={`- ${CartData?.discount}`}
        mainTitle={false}
        hasTopBorder
      />

      <DescriptionTile
        title={t("Discount for all items")}
        price={`- ${CartData?.vendor_discount}`}
        mainTitle={false}
        hasTopBorder
      />

      {deliveryOption == "delivery" && (
        <DescriptionTile
          title={t("Delivery fees")}
          price={parseFloat(CartData?.delivery_fee)?.toFixed(2)}
          mainTitle={false}
          hasTopBorder
        />
      )}

      <DescriptionTile
        title={t("Total")}
        price={(Math.round(CartData?.total * 100) / 100).toFixed(2)}
        mainTitle={false}
        hasTopBorder
      />
    </div>
  );
};

const PlaceOrderTemplate = ({
  IsCartLoading,
  CartData,
  UpdateCartItemHandleClick,
  CheckoutOrderHandleClick,
  DeliveryAddress,
  isNotSavedAddress,
  SaveAddressHandle,
  currentUser,
  OrderData,
  setOrderData,
  paymentInfo,
  payWithCard,
  applyPromoCodeHandler,
  promoCodeValue,
  setPromoCodeValue,
  removePromoCodeHandler,
  acceptOnlinePayment,
  PromoError,
  CartInstruction,
  setCartInstruction,
  setDeliveryOption,
  deliveryOption,
  vendor,
  handelChangeDeliveryOption,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [usingCard, setUsingCard] = useState(false);
  const [PromoApplied, setPromoApplied] = useState(false);

  const [SwitchOn, setSwitchOn] = useState(false);

  const PAYFORT_URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_PAYFORT
      : window.location.origin === "https://webstaging.ablanazira.com"
      ? process.env.REACT_APP_DEV_PAYFORT
      : process.env.REACT_APP_PRO_PAYFORT;

  const { days, hours, minutes } = secondsToTime(
    (new Date(CartData?.delivery_date_time).getTime() - new Date().getTime()) /
      1000
  );

  // components
  const PaymentSummaryContainer = WithSpinner(PaymentSummary);

  return (
    <main className={styles.main}>
      <Layout>
        <AddDeliveryAddressModal
          Open={isNotSavedAddress}
          ToggleModal={SaveAddressHandle}
          DeliveryAddress={DeliveryAddress}
          currentUser={currentUser}
          New={DeliveryAddress?.lat && DeliveryAddress?.lng ? false : true}
          AppearCloseIcon={false}
          Checkout={true}
        />
        <section className={styles.OrderIntro}>
          {/* <DescriptionTile
          icon={<BlackBicycle width="60px" />}
          title={`${t("Within")} ${days ? `${days} ${t("days")} ` : ""} ${
            hours ? `  ${hours} ${t("hours")} ` : ""
          } ${minutes ? `  ${minutes} ${t("minutes")} ` : ""}  `}
          classes="mb-10"
        /> */}
          <SelectDileveryOption
            setDeliveryOption={setDeliveryOption}
            restaurantData={vendor}
            deliveryOption={deliveryOption}
            isDetails={true}
            handelChangeDeliveryOption={handelChangeDeliveryOption}
            CartData={CartData}
          />

          {deliveryOption == "delivery" && vendor?.is_delivery_available && (
            <DescriptionTile
              classes="mb-10 flex-col md:flex-row justify-between"
              title={t("Delivery address")}
              centerJustify={false}
              centerComponent={
                <div className="md:ml-8 lg:ml-16 mt-4 md:mt-0">
                  {DeliveryAddress?.name && (
                    <div className="flex items-start md:items-center h-full min-h-[30px]">
                      <Typography
                        as="h3"
                        color="text-text_3b3b3b"
                        classes="font-bold text-[14px] md:text-[16px] leading-[1.06] pr-5 rtl:pl-5 min-w-[140px]"
                        text={`${t("Address name")} :`}
                      />
                      <Typography
                        as="h3"
                        color="text-text_3b3b3b"
                        classes="text-[14px] md:text-[16px]  leading-[1.06] w-full lg:w-max-[500px]"
                        text={DeliveryAddress?.name}
                      />
                    </div>
                  )}
                  {DeliveryAddress?.apartment_number && (
                    <div className="flex items-start md:items-center h-full min-h-[30px]">
                      <Typography
                        as="h3"
                        color="text-text_3b3b3b"
                        classes="font-bold text-[14px] md:text-[16px]  leading-[1.06] pr-5 rtl:pl-5 min-w-[100px]"
                        text={`${t("Address")} :`}
                      />
                      <Typography
                        as="h3"
                        color="text-text_3b3b3b"
                        classes="text-[14px] md:text-[16px]  leading-[1.06] w-full lg:w-max-[500px]"
                        text={`${DeliveryAddress?.apartment_number}, ${DeliveryAddress?.floor_number}, ${DeliveryAddress?.building}, ${DeliveryAddress?.street_name}`}
                      />
                      {/* , ${DeliveryAddress?.area?.name} */}
                    </div>
                  )}
                </div>
              }
              button={t("Change Address")}
              changeAddress={true}
              buttonClasses={
                "text-[14px] md:text-[16px] w-full md:w-[300px] flex justify-end"
              }
              buttonAction={() => {
                navigate(`/Addresses`, {
                  state: {
                    returnUrl: pathname,
                  },
                });
              }}
            />
          )}

          {deliveryOption == "pickup" &&
            vendor?.pickup_details?.is_pickup_available && (
              <PickupAddressCard
                cardHeading={t("Restaurant address")}
                restaurantData={vendor}
                currentUser={currentUser}
                style={{ display: "flex" }}
                locationIcon={false}
                CartData={CartData}
              />
            )}
          <div className="w-full flex flex-col bg-bg_white items-center mb-10">
            <DescriptionTile title={t("Order summary")} />

            <DescriptionTile title={CartData?.vendor_name} hasTopBorder />
            {IsCartLoading ? (
              <Spinner loading={IsCartLoading} />
            ) : (
              <>
                {CartData?.cart_items?.map((item) => (
                  <>
                    <hr className="w-full h-[1px]  bg-text_bfbfbf" />
                    <OrderItem
                      ItemData={item}
                      UpdateCartItemHandleClick={UpdateCartItemHandleClick}
                      ItemInstruction={OrderData}
                      setItemInstruction={setOrderData}
                    />
                  </>
                ))}
              </>
            )}
          </div>
          <div className="w-full flex flex-col bg-bg_white items-center mb-10">
            <DescriptionTile title={t("Payment summary")} />
            <PaymentSummaryContainer
              isLoading={IsCartLoading}
              CartData={CartData}
              deliveryOption={deliveryOption}
              vendor={vendor}
            />
          </div>

          <DescriptionTile
            classes="mb-10"
            direction="col"
            title={t("Any thing else we need to know?")}
            mainTitle={false}
            centerComponent={
              IsCartLoading ? (
                <Spinner loading={IsCartLoading} />
              ) : (
                <FormInput
                  id={"cart_special_instructions"}
                  key={"cart_special_instructions"}
                  value={CartInstruction}
                  name={"cart_special_instructions"}
                  type="textarea"
                  bgColor="bg-bg_white"
                  borderColor="border-bg_e8e8e8"
                  containerClasses=""
                  width="w-full max-w-[900px] mt-4"
                  Resize={true}
                  onValueChange={(e) => {
                    setCartInstruction(e.target.value);
                    getPayload(
                      {
                        target: {
                          name: "cart_special_instructions",
                          value: e.target.value,
                        },
                      },
                      OrderData,
                      setOrderData
                    );
                  }}
                />
              )
            }
          />
          {CartData?.promocode ? (
            <div className="w-full">
              <DescriptionTile
                classes="mb-10 flex-col md:flex-row w-full"
                title={t("Save on your orders")}
                mainTitle={false}
                centerJustify={false}
                centerComponent={
                  <>
                    <div className="w-full flex md:hidden mt-4">
                      <FormInput
                        id={CartData?.id}
                        key={CartData?.id}
                        fullHeight
                        type="text"
                        bgColor="bg-bg_white"
                        borderColor="border-bg_e8e8e8 focus:border-bg_e8e8e8 !border-r-0 !border-l-[1px] rtl:!border-l-0 rtl:!border-r-[1px] focus:border-r-0 active:border-r-0 shadow-0"
                        width="w-full"
                        height="h-[40px]"
                        value={CartData?.promocode?.value}
                      />
                      <CustomButton
                        width="w-fit"
                        height="h-[40px]"
                        buttonTypeClass="outlined"
                        borderColor="!border-bg_e8e8e8 focus:border-bg_e8e8e8 active:border-bg_e8e8e8"
                        textColor="text-primary-900"
                        classes={
                          "border-l-0 border-r-[1px] rtl:border-l-[1px] rtl:border-r-0 text-[15px] px-2 backdrop-blur-0"
                        }
                        label={"Delete"}
                        action={() => {
                          setPromoApplied(false);
                          removePromoCodeHandler();
                        }}
                      />
                    </div>
                    <div className="w-full max-w-[500px] hidden md:block ml-8 lg:ml-16">
                      <FormInput
                        id={CartData?.id}
                        key={CartData?.id}
                        type="text"
                        bgColor="bg-bg_white"
                        borderColor="border-bg_e8e8e8"
                        width="w-full"
                        height="h-[40px]"
                        Resize={true}
                        value={CartData?.promocode?.value}
                      />
                    </div>
                  </>
                }
                button={"Delete"}
                //REMOVE PROMOCODE
                buttonAction={() => {
                  setPromoApplied(false);
                  removePromoCodeHandler();
                }}
                buttonClasses={"hidden md:block text-[14px] md:text-[16px]"}
              />
              <div className="flex">
                <CheckSign className="mt-1" />
                <Typography
                  as="span"
                  text="Promocode Applied"
                  color={"text-[#529036]"}
                  size={"2xl"}
                  classes={"ml-8 mb-10"}
                />
              </div>
            </div>
          ) : (
            <div className="w-full">
              <DescriptionTile
                classes="mb-10 flex-col md:flex-row w-full"
                title={t("Save on your orders")}
                mainTitle={false}
                centerJustify={false}
                centerComponent={
                  <>
                    <div className="w-full flex md:hidden mt-4">
                      <FormInput
                        id={CartData?.id}
                        key={CartData?.id}
                        fullHeight
                        type="text"
                        bgColor="bg-bg_white"
                        borderColor="border-bg_e8e8e8 focus:border-bg_e8e8e8 !border-r-0 !border-l-[1px] rtl:!border-l-0 rtl:!border-r-[1px] focus:border-r-0 active:border-r-0 shadow-0"
                        width="w-full"
                        height="h-[40px]"
                        value={promoCodeValue}
                        onValueChange={(e) => {
                          setPromoCodeValue(e.target.value);
                        }}
                      />
                      <CustomButton
                        width="w-fit"
                        height="h-[40px]"
                        buttonTypeClass="outlined"
                        borderColor="!border-bg_e8e8e8 focus:border-bg_e8e8e8 active:border-bg_e8e8e8"
                        textColor="text-primary-900"
                        classes={
                          "border-l-0 border-r-[1px] rtl:border-l-[1px] rtl:border-r-0 text-[15px] px-2 backdrop-blur-0"
                        }
                        label={t("Cart Apply")}
                        action={() => {
                          setPromoApplied(true);
                          applyPromoCodeHandler(promoCodeValue);
                        }}
                      />
                    </div>
                    <div className="w-full max-w-[500px] hidden md:block ml-8 lg:ml-16">
                      <FormInput
                        id={CartData?.id}
                        key={CartData?.id}
                        type="text"
                        bgColor="bg-bg_white"
                        borderColor="border-bg_e8e8e8"
                        width="w-full"
                        height="h-[40px]"
                        Resize={true}
                        value={promoCodeValue}
                        onValueChange={(e) => {
                          setPromoCodeValue(e.target.value);
                        }}
                      />
                    </div>
                  </>
                }
                button={t("Cart Apply")}
                buttonAction={() => {
                  setPromoApplied(true);
                  applyPromoCodeHandler(promoCodeValue);
                }}
                buttonClasses={"hidden md:block text-[14px] md:text-[16px]"}
              />
              {PromoError && PromoApplied ? (
                <div className="flex">
                  <Typography
                    as="span"
                    text=" Invalid promo code, please try again"
                    color={"text-[#ff0000]"}
                    size={"xl"}
                    classes={"ml-8 mb-10"}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="w-full flex flex-col bg-bg_white items-center mb-2">
            <DescriptionTile title={t("Payment method")} />
            <hr className={"w-full h-[1px]  bg-text_bfbfbf"} />
            <div className="w-full flex flex-col p-4">
              {/* <div className="w-full flex justify-between">
              <Typography
                as="h3"
                color="text-text_3b3b3b"
                classes="font-bold text-[14px] md:text-[16px] leading-[1.06] w-[150px]"
                text={t("Use my balance")}
              />
              <SwitchToggle enabled={SwitchOn} setEnabled={setSwitchOn} />
            </div>
            <Typography
              as="h3"
              color="text-text_3b3b3b"
              classes="font-SFProText text-[14px] md:text-[16px] leading-[1.06] py-4"
              text={"50 EGP"}
            /> */}
              <div className="py-1">
                <FormInput
                  id={"Cash"}
                  key={"Cash"}
                  name="payment_type"
                  type="radio"
                  fullWidth={false}
                  width="w-5"
                  height="h-5"
                  value={"Cash"}
                  required
                  containerClasses="selectInputs"
                  label={t("Cash")}
                  labelSize={"md"}
                  textColor="black"
                  defaultChecked={true}
                  onValueChange={() => {
                    setUsingCard(false);
                    getPayload(
                      {
                        target: {
                          name: "payment_type",
                          value: "cash",
                        },
                      },
                      OrderData,
                      setOrderData
                    );
                  }}
                />
              </div>
              {acceptOnlinePayment ? (
                <div>
                  <div className="py-1 flex w-full justify-between">
                    <FormInput
                      id={"Card"}
                      key={"Card"}
                      name="payment_type"
                      type="radio"
                      fullWidth={false}
                      width="w-5"
                      height="h-5"
                      value={"Card"}
                      required
                      containerClasses="selectInputs"
                      label={t("Card")}
                      labelSize={"md"}
                      textColor="black"
                      onValueChange={() => {
                        setUsingCard(true);
                        payWithCard(false);
                        getPayload(
                          {
                            target: {
                              name: "payment_type",
                              value: "visa",
                            },
                          },
                          OrderData,
                          setOrderData
                        );
                      }}
                    />
                  </div>
                  <div className="py-1 flex w-full justify-between">
                    <FormInput
                      disabled={
                        CartData?.installements_tier >= 1000 ? false : true
                      }
                      id={"Installments1"}
                      key={"Installments1"}
                      name="payment_type"
                      type="radio"
                      fullWidth={false}
                      width="w-5"
                      height="h-5"
                      value={"Card"}
                      required
                      containerClasses="selectInputs"
                      label={t("Installments1")}
                      labelSize={"md"}
                      textColor={
                        CartData?.installements_tier >= 1000
                          ? "black"
                          : "text-text_777777"
                      }
                      onValueChange={() => {
                        setUsingCard(true);
                        payWithCard(true);
                        getPayload(
                          {
                            target: {
                              name: "payment_type",
                              value: "visa",
                            },
                          },
                          OrderData,
                          setOrderData
                        );
                      }}
                    />
                  </div>
                  <div className="py-1 flex w-full justify-between">
                    <FormInput
                      id={"Installments2"}
                      key={"Installments2"}
                      name="payment_type"
                      disabled={
                        CartData?.installements_tier >= 500 ? false : true
                      }
                      type="radio"
                      fullWidth={false}
                      width="w-5"
                      height="h-5"
                      value={"Card"}
                      required
                      containerClasses="selectInputs"
                      label={t("Installments2")}
                      labelSize={"md"}
                      textColor={
                        CartData?.installements_tier >= 500
                          ? "black"
                          : "text-text_777777"
                      }
                      onValueChange={() => {
                        setUsingCard(true);
                        payWithCard(true);
                        getPayload(
                          {
                            target: {
                              name: "payment_type",
                              value: "visa",
                            },
                          },
                          OrderData,
                          setOrderData
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="w-full flex justify-start px-4">
            <Typography
              as="span"
              color="text-text_3b3b3b"
              classes="text-[15px] leading-[1.06]"
              text={
                <>
                  {t(
                    "By placing this order you agree to the credit card payment"
                  )}
                  <Typography
                    as="span"
                    action={() => navigate("/privacy_policy_payment")}
                    color="text-text_3b3b3b"
                    classes="font-bold text-[15px] leading-[1.06] px-2 underline underline-offset-2 cursor-pointer"
                    text={t("Terms & Conditions")}
                  />
                </>
              }
            />
          </div>

          <form
            method="post"
            action={PAYFORT_URL}
            //action="https://sbcheckout.payfort.com/FortAPI/paymentPage"
            // action="https://checkout.payfort.com/FortAPI/paymentPage"
            id="form1"
            name="form1"

            // onSubmit={(e) =>
            //   CheckoutOrderHandleClick(e, {
            //     customer_address_id: DeliveryAddress?.id,
            //     ...OrderData,
            //   })
            // }
          >
            <input type="hidden" name="command" value="PURCHASE"></input>
            <input
              type="hidden"
              name="access_code"
              value={paymentInfo?.access_code}
            ></input>
            <input
              type="hidden"
              name="merchant_identifier"
              value={paymentInfo?.merchant_identifier}
            ></input>
            <input
              type="hidden"
              name="merchant_reference"
              value={paymentInfo?.merchant_reference}
            ></input>
            <input
              type="hidden"
              name="signature"
              value={paymentInfo?.signature}
            ></input>
            <input
              type="hidden"
              name="language"
              value={paymentInfo?.language}
            ></input>
            <input
              type="hidden"
              name="amount"
              value={paymentInfo?.amount}
            ></input>
            <input
              type="hidden"
              name="customer_email"
              value={paymentInfo?.customer_email}
            ></input>
            <input
              type="hidden"
              name="currency"
              value={paymentInfo?.currency}
            ></input>
            <input
              type="hidden"
              name="merchant_extra"
              value={paymentInfo?.merchant_extra}
            ></input>
            <input
              type="hidden"
              name="merchant_extra1"
              value={paymentInfo?.merchant_extra1}
            ></input>
            <input
              type="hidden"
              name="installments"
              value={paymentInfo?.installments}
            ></input>
          </form>
          <CustomButton
            form={"form1"}
            type={"submit"}
            width="w-[90%] md:w-full md:max-w-[200px]"
            height="h-[46px]"
            borderRadius="8px"
            classes={"mt-8"}
            label={t("Place Order")}
            action={(e) => {
              if (usingCard) {
                document.getElementById("form1").submit();
              } else {
                CheckoutOrderHandleClick(e, {
                  customer_address_id: DeliveryAddress?.id,
                  ...OrderData,
                });
              }
            }}
          />
        </section>
      </Layout>
    </main>
  );
};

PlaceOrderTemplate.propTypes = {
  IsCartLoading: PropTypes.bool,
  CartData: PropTypes.object,
  UpdateCartItemHandleClick: PropTypes.func,
  CheckoutOrderHandleClick: PropTypes.func,
  DeliveryAddress: PropTypes.object,
  isNotSavedAddress: PropTypes.bool,
  SaveAddressHandle: PropTypes.func,
  currentUser: PropTypes.object,
  OrderData: PropTypes.object,
  setOrderData: PropTypes.func,
};

export default PlaceOrderTemplate;
