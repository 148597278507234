import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { getPayload } from "utils";

import { phoneSignUpStart } from "Redux/user/actions/user.actions";
import { selectCurrentUser } from "Redux/user";

import { SocialAuth } from "..";
import { CustomButton, Typography, FormInput } from "components/atoms";
import { SplitScreen } from "components/Layouts";
import { ValidationsWrapper } from "components/Containers";

import styles from "./SignUpForm.module.css";

const SignUpForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [userData, setUserData] = useState({
    first_name: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
    last_name: "",
    email: "",
  });

  const phoneSignUpStartHandler = (userData) => {
    dispatch(phoneSignUpStart(userData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    phoneSignUpStartHandler(userData);
  };

  useEffect(() => {
    if (currentUser && !currentUser?.is_verified) {
      navigate("/Verify_mobile_number", {
        state: {
          scope: "signup-verification",
        },
      });
    } else if (currentUser && currentUser?.is_verified) navigate("/");
  }, [currentUser, navigate]);

  return (
    <ValidationsWrapper
      className={classNames(
        styles.form,
        "whiteScroll overflow-y-scroll px-6 lg:px-8"
      )}
      rules={[
        "first_name",
        "phone",
        "password",
        "confirm_password",
        "last_name",
        "email",
        "termsOfUse",
      ]}
      initialValues={{
        password: "",
        confirm_password: "",
        email: "",
        phone: "",
        last_name: "",
        first_name: "",
        termsOfUse: false,
      }}
    >
      {({ FormikHandler }) => {
        return (
          <div className="relative">
            <Typography
              as="h1"
              size="6xl"
              color="text-text_white"
              text={t("Create New Account")}
            />

            <Typography
              as="p"
              size="lg"
              color="text-text_grey"
              fontWeight="font-medium"
              lineHeight="leading-[1.61]"
              classes="mt-2 lg:mt-3 3xl:mt-4"
              text={t("Please enter your data to create an account")}
            />

            <SplitScreen
              containerClasses={styles.formInputsContainer}
              leftPaneClasses="w-full lg:w-[48%]"
              rightPaneClasses="w-full lg:w-[48%]"
            >
              <div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="firstName"
                    name="first_name"
                    type="text"
                    width="w-full order-1"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("First Name")}
                    labelSize="sm"
                    value={FormikHandler.values.first_name}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("first_name", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.first_name}
                    TabIndex={"1"}
                  />
                </div>

                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="mobileNumber"
                    name="mobile_number"
                    type="tel"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Mobile Number")}
                    labelSize="sm"
                    value={FormikHandler.values.phone}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("phone", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.phone}
                    TabIndex={"3"}
                  />
                </div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="Password"
                    name="password"
                    type="password"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Password")}
                    labelSize="sm"
                    value={FormikHandler.values.password}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("password", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.password}
                    TabIndex={"5"}
                  />
                </div>
              </div>
              <div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="lastName"
                    name="last_name"
                    type="text"
                    width="w-full order-2"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Last Name")}
                    labelSize="sm"
                    value={FormikHandler.values.last_name}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("last_name", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.last_name}
                    TabIndex={"2"}
                  />
                </div>

                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="email"
                    name="email"
                    type="email"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Email (Optional)")}
                    labelSize="sm"
                    value={FormikHandler.values.email}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue("email", e.target.value);
                      getPayload(e, userData, setUserData);
                    }}
                    error={FormikHandler.errors.email}
                    TabIndex={"4"}
                  />
                </div>
                <div className="mt-5 lg:mt-6 3xl:mt-10">
                  <FormInput
                    id="confirmPassword"
                    name="confirm_password"
                    type="password"
                    width="w-full"
                    height="h-10"
                    containerHeight="h-[85px] max-h-[85px] min-h-[85px]"
                    containerClasses="defaultInput"
                    label={t("Confirm password")}
                    labelSize="sm"
                    value={FormikHandler.values.confirm_password}
                    onValueChange={(e) => {
                      FormikHandler.setFieldValue(
                        "confirm_password",
                        e.target.value
                      );
                    }}
                    error={FormikHandler.errors.confirm_password}
                    TabIndex={"6"}
                  />
                </div>
              </div>
            </SplitScreen>

            <div className="mt-5 lg:mt-6 3xl:mt-10">
              <FormInput
                id="agree"
                name="agree"
                type="checkbox"
                fullWidth={false}
                width="w-4"
                height="h-4"
                bgColor="bg_transparent"
                containerClasses="selectInputs"
                inputTypeClass="checkboxOutlined"
                label={
                  <>
                    {t("I agree to the")}{" "}
                    <Typography
                      as="span"
                      // action={() => navigate('/privacy_policy')}
                      classes=""
                      text={
                        <>
                          <Link to="/terms_of_use" target="_blank">
                            <Typography
                              as="span"
                              // action={() => navigate("/terms_of_use")}
                              classes="underline underline-offset-2 cursor-pointer"
                              text={t("terms of use")}
                            />
                          </Link>
                          <Typography as="span" text={t("and")} />
                          <Link to="/privacy_policy" target="_blank">
                            <Typography
                              as="span"
                              // action={() => navigate("/privacy_policy")}
                              classes="underline underline-offset-2 cursor-pointer"
                              text={t("privacy policy")}
                            />
                          </Link>
                        </>
                      }
                    />
                  </>
                }
                labelSize="xs"
                value={FormikHandler.values.termsOfUse}
                onValueChange={(e) => {
                  FormikHandler.setFieldValue("termsOfUse", e.target.checked);
                }}
                error={FormikHandler.errors.termsOfUse}
              />
            </div>

            <div className="mt-5 lg:mt-8 3xl:mt-10 mr-1 md:mr-2 rtl:ml-1 md:rtl:ml-2">
              <CustomButton
                width="w-full"
                height="h-10"
                label={t("Sign Up")}
                disabled={!FormikHandler.isValid}
                action={handleSubmit}
              />
            </div>

            <div className="lg:w-1/2 mx-auto">
              <div className="flex flex-col items-center mt-8 3xl:mt-12">
                {/* <Typography
                  as="span"
                  size="sm"
                  color="text-text_white"
                  classes="mb-3 lg:mb-5 3xl:mb-6"
                  text={t("Or Sign Up with")}
                />
                <SocialAuth /> */}

                <div className="mt-5 3xl:mt-12 flex">
                  <Typography
                    as="p"
                    size="sm"
                    color="text-text_white"
                    text={t("Already have an account ?")}
                  />

                  <Link to="/Login">
                    <Typography
                      as="span"
                      size="sm"
                      color="text-text_white"
                      fontWeight="font-bold"
                      classes="ml-1 rtl:mr-1 cursor-pointer "
                      text={t("Sign in")}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ValidationsWrapper>
  );
};

export default SignUpForm;
