import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import classNames from "classnames";

import { ImagesSrc } from "utils";

import { Img, Typography } from "components/atoms";

import styles from "./OrderDetails.module.css";
import { useNavigate } from "react-router";

const dateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const OrderDetails = ({
  restaurantId,
  restaurantName,
  orderDate,
  orderHash,
  RestaurantLogo,
  clickable = true,
  onClickDetails,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        styles.orderInfoContainer,
        {
          "ltr:lg:pl-6 rtl:lg:pr-6":
            window.location.pathname.includes("Order_details"),
        },
        { "cursor-pointer": clickable }
      )}
    >
      <div
        className={classNames(styles.restaurantLogo, {
          "lg:w-[191px] lg:h-[125px] 2xl:w-64 2xl:h-[166px] flex items-center my-auto cursor-pointer":
            window.location.pathname.includes("Order_details"),
        })}
        onClick={() => {
          navigate(`/restaurant/${restaurantId}`);
        }}
      >
        <Img
          containerClasses="w-20 h-[70px] lg:w-56 lg:h-full"
          picClasses={"!w-fit mx-auto"}
          fileName={
            RestaurantLogo ? RestaurantLogo : ImagesSrc.VendorCardPlaceholder
          }
          altText={restaurantName}
        />
      </div>

      <div className={styles.orderInfo} onClick={onClickDetails}>
        <Typography
          as="h3"
          size="xl"
          fontWeight="font-bold"
          lineHeight="leeading-[1.44]"
          color="text-text_3b3b3b"
          text={restaurantName}
        />

        <Typography
          as="p"
          size="lg"
          fontWeight="font-light"
          lineHeight="leeading-[1.38]"
          color="text-warm_grey"
          classes={"font-SFProText"}
          text={new Date(orderDate)?.toLocaleDateString("de-DE", dateOptions)}
        />
        <Typography
          as="p"
          size="lg"
          fontWeight="font-light"
          lineHeight="leeading-[1.38]"
          color="text-warm_grey"
          classes={"font-SFProText"}
          text={`${t("Order ID")} : ${orderHash}`}
        />
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  restaurantName: PropTypes.string,
  orderDate: PropTypes.string,
  orderId: PropTypes.number,
};

export default OrderDetails;
