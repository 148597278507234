import { PropTypes } from "prop-types";

import { FormInput } from "components/atoms";

const CheckBoxesGroup = ({
  containerClasses,
  Options,
  name,
  valueKey = "id",
  selectedItems,
  getSelectedItems,
}) => {
  return (
    <>
      {Options?.map((filter) => (
        <span key={filter?.id} className={containerClasses}>
          <FormInput
            id={`${filter?.id}-${name}`}
            fullWidth={false}
            name={name}
            type="checkbox"
            width="w-5"
            height="h-5"
            containerClasses="selectInputs"
            inputTypeClass="solidCheckbox"
            label={filter?.name}
            value={filter[valueKey]}
            checked={selectedItems.includes(filter[valueKey])}
            onValueChange={(e) => {
              let newSelectedItems = [
                ...selectedItems,
                valueKey === "id" ? +e.target.value : e.target.value,
              ];
              if (
                selectedItems.includes(
                  valueKey === "id" ? +e.target.value : e.target.value
                )
              ) {
                newSelectedItems = newSelectedItems.filter(
                  (id) =>
                    id !==
                    (valueKey === "id" ? +e.target.value : e.target.value)
                );
              }
              getSelectedItems({
                target: { name, value: newSelectedItems },
              });
            }}
            labelSize="xl"
            textColor="text-text_777777"
          />
        </span>
      ))}
    </>
  );
};

CheckBoxesGroup.propTypes = {
  title: PropTypes.string,
  Options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  name: PropTypes.string,
  valueKey: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  getSelectedItems: PropTypes.func,
};

export default CheckBoxesGroup;
