import React from "react";

import { PropTypes } from "prop-types";

import { ImagesSrc } from "utils";

import { Img } from "components/atoms";
import { Modal } from "components/molecules";
import AddItemForm from "components/organisms/AddItemForm";
import { ItemClose } from "utils/helpers/SVGsSrc";

const NewItemModal = ({ Open, ToggleModal, ItemData, deliveryOption }) => {
  return (
    <Modal isOpen={Open} ToggleModalVisibility={ToggleModal}>
      <div className="bg-white w-fit max-w-[600px] relative">
        <ItemClose
          className="absolute cursor-pointer m-4 ltr:right-0 rtl:left-0"
          onClick={ToggleModal}
        />
        {ItemData?.image ? (
          <Img
            containerClasses="w-full max-h-[273px] md:min-w-[590px]"
            picClasses={"max-h-[273px] object-cover"}
            fileName={ItemData?.image}
            altText={"ItemData"}
          />
        ) : (
          <Img
            containerClasses="w-full max-h-[273px]"
            picClasses={"max-h-[273px]"}
            fileName={ImagesSrc.ItemPlaceHolder}
            fileName2x={ImagesSrc.ItemPlaceHolder2x}
            fileName3x={ImagesSrc.ItemPlaceHolder3x}
            altText={"ItemData"}
          />
        )}

        <div className="w-full">
          <AddItemForm
            ItemData={ItemData}
            ToggleModal={ToggleModal}
            deliveryOption={deliveryOption}
          />
        </div>
      </div>
    </Modal>
  );
};

NewItemModal.propTypes = {
  Open: PropTypes.bool,
  ToggleModal: PropTypes.func,
  ItemData: PropTypes.object,
};

export default NewItemModal;
