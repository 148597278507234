import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { WithSpinner } from "Hocs";

import { getOrderStart } from "Redux/orders/actions/orders.actions";

import {
  selectOrder,
  selectOrdersLoading,
} from "Redux/orders/selectors/orders.selectors";

import { OrderTrackingTemplate } from "templates";
import Layout from "components/molecules/Layout";

const OrderTrackingPage = () => {
  const { state } = useLocation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentLng = i18n?.language;

  const order = useSelector(selectOrder);
  const loading = useSelector(selectOrdersLoading);

  const OrderTrackingTemplateContainer = WithSpinner(OrderTrackingTemplate);

  useEffect(() => {
    if (currentLng && state?.orderId)
      dispatch(getOrderStart({ orderId: state?.orderId }));
  }, [state, currentLng, dispatch]);

  return (
    <Layout>
      <OrderTrackingTemplateContainer order={order} isLoading={loading} />
    </Layout>
  );
};

export default OrderTrackingPage;
