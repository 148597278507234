const OffersActionTypes = {
  LIST_OFFERS_START: "LIST_OFFERS_START",
  LIST_OFFERS_SUCCESS: "LIST_OFFERS_SUCCESS",
  LIST_OFFERS_FAILURE: "LIST_OFFERS_FAILURE",
  LOAD_MORE_OFFERS_START: "LOAD_MORE_OFFERS_START",
  LOAD_MORE_OFFERS_SUCCESS: "LOAD_MORE_OFFERS_SUCCESS",
  LOAD_MORE_OFFERS_FAILURE: "LOAD_MORE_OFFERS_FAILURE",
};

export default OffersActionTypes;
