import { DescriptionTile } from "components/molecules";
import { Typography } from "components/atoms";
import { useTranslation } from "react-i18next";

const OrderSummary = ({
  restaurantName,
  cartItems,
  invoiceDetails,
  specialInstruction,
  paymentType,
}) => {
  const { t } = useTranslation();

  return (
    <section className="bg-primary-50 w-full mb-6 ">
      <DescriptionTile title={t("Order summary")} />
      <DescriptionTile title={restaurantName} hasTopBorder />

      {cartItems?.map((cartItem) => (
        <DescriptionTile
          key={cartItem?.id}
          title={`${cartItem?.product_name} x ${cartItem?.quantity}`}
          centerComponent={
            <Typography
              as="p"
              size="xs"
              lineHeight="leading-[1.46]"
              classes="ellipseText line-clamp-2 max-h-12 w-[277px] max-w-[277px] 2xl:w-[369px] 2xl:max-w-[369px] "
              text={cartItem?.special_instructions}
            />
          }
          price={cartItem?.price}
          hasTopBorder
          OrderDetails={
            <>
              <Typography
                as="p"
                size="sm"
                lineHeight="leading-[1.46]"
                classes="ellipseText line-clamp-2 mt-2"
                text={cartItem?.size?.size_name}
              />
              {cartItem?.options?.map((item) => (
                <div className="flex justify-between">
                  <Typography
                    as="p"
                    size="sm"
                    lineHeight="leading-[1.46]"
                    classes="ellipseText line-clamp-2 "
                    text={item?.option_value?.sub_option_name}
                  />
                  <Typography
                    as="p"
                    size="sm"
                    lineHeight="leading-[1.46]"
                    classes="ellipseText line-clamp-2 "
                    text={
                      item?.option_value?.price === -1
                        ? ""
                        : item?.option_value?.price
                    }
                  />
                </div>
              ))}
            </>
          }
        />
      ))}
      <DescriptionTile
        key={"instruction"}
        title={t("Order Instructions")}
        centerComponent={
          <Typography
            as="p"
            size="xs"
            lineHeight="leading-[1.46]"
            classes="ellipseText line-clamp-2 max-h-12 w-[277px] max-w-[277px] 2xl:w-[369px] 2xl:max-w-[369px] "
            text={specialInstruction ?? "No Instructions"}
          />
        }
        hasTopBorder
      />

      <DescriptionTile
        title={t("Subtotal")}
        price={invoiceDetails?.sub_total}
        hasTopBorder
      />
      <DescriptionTile
        title={t("Discount")}
        price={invoiceDetails?.discount}
        hasTopBorder
      />
      <DescriptionTile
        title={t("Delivery fees")}
        price={`${parseFloat(invoiceDetails?.delivery_fee).toFixed(2)}`}
        hasTopBorder
      />
      <DescriptionTile
        title={t("Total")}
        price={invoiceDetails?.total}
        hasTopBorder
      />
      <DescriptionTile
        title={t("Payment method")}
        textValue={paymentType}
        hasTopBorder
      />
    </section>
  );
};

export default OrderSummary;
