import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import classNames from "classnames";
import axios, { Axios } from "axios";

import { getAxios, getCurrentLng, StorageUtils } from "utils";
import {
  AblaNaziraLogo,
  LanguageIcon,
  Location,
  Menu,
} from "utils/helpers/SVGsSrc";

import {
  selectCurrentUser,
  selectNotifications,
  selectNotificationsCount,
} from "Redux/user/selectors/user.selectors";
import {
  GetNotificationCountStart,
  GetNotificationStart,
  getSystemConfigurationStart,
  setNotificationReadStart,
} from "Redux/user/actions/user.actions";
import {
  ListCartDataStart,
  UpdateCartDataStart,
  UpdateOrderTime,
} from "Redux/Cart/actions/cart.actions";
import { selectCartData } from "Redux/Cart/selectors/cart.selectors";

import { DropdownMenu, Typography } from "components/atoms";
import {
  AccountPopOver,
  CartPopover,
  LaterOrderModal,
} from "components/organisms";

import styles from "./NavBar.module.css";
import NotificationsPopOver from "../../../organisms/PopOvers/NotificationsPopOver";
import SideBar from "../SideBar";
import {
  selectSelectedAddress,
  selectSelectedArea,
} from "Redux/addresses/selectors/addresses.selectors";
import { changeCord } from "Redux/addresses/actions/addresses.actions";

const NavBar = ({ setUserCoords, setLocationState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const date = new Date();

  const [SideOpen, setOpen] = useState(false);
  const [temp, setTemp] = useState(false);
  const [currentLocationState, setCurrentLocationState] = useState("denied");
  const [isLaterOrder, setIsLaterOrder] = useState(false);
  const [nowLater, setNowLater] = useState(
    StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] == ""
      ? t("Delivery time nav")
      : StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
        "now"
      ? { id: 1, name: t("Now") }
      : { id: 2, name: t("Later") }
  );

  const currentUser = useSelector(selectCurrentUser);
  const CartData = useSelector(selectCartData);
  const NotificationsCount = useSelector(selectNotificationsCount);
  const Notifications = useSelector(selectNotifications);
  const SelectedArea = useSelector(selectSelectedArea);
  const selectedAddress = useSelector(selectSelectedAddress);
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const nowLaterChoices = [
    { id: 1, name: t("Now") },
    { id: 2, name: t("Later") },
  ];

  const ToggleModalVisibility = () => setIsLaterOrder(!isLaterOrder);

  useEffect(() => {
    if (currentUser) {
      if (
        nowLater.id == 2 &&
        StorageUtils.getItems(sessionStorage, ["OrderTime"])["OrderTime"] ==
          "now"
      ) {
        setIsLaterOrder(true);
      } else if (nowLater.id == 1) {
        StorageUtils.saveItems(sessionStorage, [
          {
            name: "OrderTime",
            data: "now",
          },
        ]);
        dispatch(UpdateOrderTime("now"));
        // save datetime
        StorageUtils.saveItems(sessionStorage, [
          {
            name: "delivery_datetime",
            data: `${date.getHours()}:${date.getMinutes()} - ${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`,
          },
        ]);
        StorageUtils.saveItems(sessionStorage, [
          {
            name: "delivery_date",
            data: `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`,
          },
        ]);
        StorageUtils.saveItems(sessionStorage, [
          {
            name: "delivery_time",
            data: `${date.getHours()}:${date.getMinutes()}`,
          },
        ]);
        dispatch(
          UpdateCartDataStart({
            cart: {
              delivery_type: "now",
              delivery_time: `${date.getHours()}:${date.getMinutes()}`,
              delivery_date: `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}`,
            },
          })
        );
      }
    }
  }, [nowLater]);

  const i18nDir = i18n.dir();
  //console.log(i18n.language);
  useEffect(() => {
    document.body.dir = i18nDir;
    document.body.lang = i18n.language;
  }, [i18nDir, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(GetNotificationCountStart());
      dispatch(GetNotificationStart());
      dispatch(getSystemConfigurationStart());
    }
    // dispatch(listCitiesStart());
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) dispatch(ListCartDataStart());
  }, [SelectedArea, selectedAddress, currentUser]);

  const setNotificationsRead = (ID) => {
    dispatch(setNotificationReadStart({ id: ID }));
  };

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.permissions?.query({ name: "geolocation" }).then((result) => {
        //console.log(result.state);
        if (result.state === "denied") {
          setCurrentLocationState(() => "denied");
          setLocationState(() => "denied");
          setUserCoords && setUserCoords(null);
        } else {
          setCurrentLocationState("allow");
          setLocationState(() => "allow");
        }
      });
    } else {
      alert("Geolocation is not supported in your browser.");
    }
  }, []);

  return (
    <div className={`md:mb-[50px]`}>
      <nav className={styles.nav}>
        <div className={styles.Download}>
          <a
            href={
              /iPhone|iPad|iPod/i.test(navigator.userAgent)
                ? "https://apps.apple.com/in/app/ablanazira/id1614639645?uo=2"
                : "https://play.google.com/store/apps/details?id=com.inova.ablanazira"
            }
            target="_blank"
          >
            <Typography
              as="span"
              color="text-warm_grey"
              classes="text-[13px]"
              lineHeight="leading-[1.15]"
              text={
                <>
                  {t("Order faster with Abla Nazira free app .")}{" "}
                  <Typography
                    as="span"
                    color="text-primary-900"
                    fontWeight="font-bold"
                    lineHeight="leading-[1.15]"
                    classes="cursor-pointer text-[13px]"
                    text={t("Download Now")}
                  />
                </>
              }
            />
          </a>
        </div>
        <div className={styles.container}>
          <div className={styles.navTopPartContainer}>
            <NavLink to="/">
              <div className={styles.logo}>
                <AblaNaziraLogo
                  width="100%"
                  height="100%"
                  title="Abla Nazira"
                />
              </div>
            </NavLink>

            <div>
              {!currentUser && (
                <NavLink to="/login">
                  <Typography
                    as="span"
                    size="3xl"
                    color="text-primary-900"
                    fontWeight="font-bold"
                    lineHeight="leading-[1.21]"
                    classes="cursor-pointer"
                    text={t("Login")}
                  />
                </NavLink>
              )}
            </div>
          </div>

          <div
            className={classNames(styles.iconsContainer, {
              [styles.iconsContainerUser]: currentUser,
            })}
          >
            <div className={styles.icon}>
              <CartPopover CartData={CartData} />
            </div>

            <div className={styles.icon}>
              <NotificationsPopOver
                currentUser={currentUser}
                NotificationsCount={NotificationsCount}
                Notifications={Notifications}
                setNotificationsRead={setNotificationsRead}
                onClick={() => {
                  if (localStorage.getItem("access_token")) {
                    dispatch(GetNotificationCountStart());
                    dispatch(GetNotificationStart());
                  }
                }}
              />
            </div>
            <div className={styles.icon}>
              <LanguageIcon
                width="100%"
                height="100%"
                title="language"
                onClick={() =>
                  changeLanguage(getCurrentLng() === "ar" ? "en" : "ar")
                }
              />
            </div>
            {currentUser && (
              <div
                className={classNames(
                  styles.icon,
                  styles.iconRtl,
                  styles.iconLTR
                )}
              >
                <AccountPopOver />
              </div>
            )}

            {currentUser && (
              <>
                <DropdownMenu
                  options={nowLaterChoices}
                  ReviewSort={
                    StorageUtils.getItems(sessionStorage, ["OrderTime"])[
                      "OrderTime"
                    ] == "now"
                      ? { id: 1, name: t("Now") }
                      : { id: 2, name: t("Later") }
                  }
                  setReviewSort={setNowLater}
                  type="nowLater"
                />
                <LaterOrderModal
                  isOpen={isLaterOrder}
                  ToggleModalVisibility={setIsLaterOrder}
                  ToggleNowLater={() => {
                    ToggleModalVisibility(false);
                  }}
                  zIndex={"z-40"}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.MobileContainer}>
          <div className={styles.MenuIcon}>
            <Menu
              width="100%"
              height="100%"
              title="menu"
              className="m-auto"
              onClick={() => setOpen(!SideOpen)}
            />
            <SideBar SideOpen={SideOpen} setOpen={setOpen} />
          </div>

          <NavLink to="/">
            <div className={styles.logo}>
              <AblaNaziraLogo width="100%" height="100%" title="Abla Nazira" />
            </div>
          </NavLink>

          {!currentUser ? (
            <div>
              <NavLink to="/login">
                <Typography
                  as="span"
                  size="3xl"
                  color="text-primary-900"
                  fontWeight="font-bold"
                  lineHeight="leading-[1.21]"
                  classes="cursor-pointer"
                  text={t("Login")}
                />
              </NavLink>
            </div>
          ) : (
            <div className={styles.icon}>
              <CartPopover CartData={CartData} />
            </div>
          )}
        </div>
        <div>
          <hr />
          {!sessionLocation ? (
            <div
              style={{
                background: "white",
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#707070",
                fontWeight: "700",
                padding: "10px",
              }}
              className="md:text-[26px]"
            >
              {t(
                "Find the best nearby kitchens , We will need location access or address to show nearby kitchens"
              )}
            </div>
          ) : (
            StorageUtils.getItems(sessionStorage, ["address"])["address"] && (
              <div
                style={{
                  background: "white",
                  zIndex: "1",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#707070",
                  fontWeight: "700",
                  padding: "10px",
                }}
                className="md:text-[16px] text-[12px]"
              >
                <span
                  style={{
                    fontWeight: "500",
                    marginInline: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <Location
                    width="18px"
                    height="18px"
                    title="Abla Nazira"
                    style={{
                      color: "#707070",
                    }}
                  />
                  {t("Deliver to")}
                </span>
                <span style={{ color: "#BF0022" }}>
                  {t(
                    StorageUtils.getItems(sessionStorage, ["address"])[
                      "address"
                    ]
                  )}
                </span>
              </div>
            )
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
