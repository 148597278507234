import { useEffect, useState } from "react";

import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { StorageUtils } from "utils";

import { listCategoriesStart } from "Redux/categories/actions/categories.actions";
import { listOffersStart } from "Redux/offers/actions/offers.actions";
import { listOrdersStart } from "Redux/orders/actions/orders.actions";
import {
  ListFavoriteRestaurantsStart,
  ListPopularNearRestaurantsStart,
  getAllVendorsStart,
} from "Redux/vendors/actions/vendors.actions";
import {
  changeCord,
  getSelectedAddressStart,
} from "Redux/addresses/actions/addresses.actions";

import { selectCurrentUser } from "Redux/user";
import { selectCategoriesList } from "Redux/categories/selectors/categories.selectors";
import { selectOffersList } from "Redux/offers/selectors/offers.selectors";
import { selectordersList } from "Redux/orders/selectors/orders.selectors";
import {
  selectFavoriteRestaurants,
  selectListVendorsLoading,
  selectPopularNearRestaurants,
  selectVendorsList,
} from "Redux/vendors/selectors/vendors.selectors";
import {
  selectSelectedAddress,
  selectSelectedArea,
} from "Redux/addresses/selectors/addresses.selectors";

import { HomeTemplate } from "templates";
import { signInSuccess } from "Redux/user/actions/user.actions";
import { UpdateCartDataSuccess } from "Redux/Cart/actions/cart.actions";
import {
  deliveryTimeRanges,
  selectCartData,
  selectOrderTime,
} from "Redux/Cart/selectors/cart.selectors";

const HomePage = () => {
  const { i18n, t } = useTranslation();
  const [queryParams, setQueryParams] = useState({
    sortBy: "sort_a_to_z",
    search_key: "",
    sortBy: "sort_by_rating",
    filter_by_main_category_id: [],
    filter_options: [],
    page_number: 1,
    page_size: 10,
  });
  const currentLng = i18n?.language;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      dispatch(signInSuccess(null));
      dispatch(UpdateCartDataSuccess(null));
    }
  }, []);

  // After detecting location logic

  const [UserCoords, setUserCoords] = useState(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const sessionLocation = StorageUtils.getItems(sessionStorage, [
    "distance_cords",
  ])["distance_cords"];

  const currentUser = useSelector(selectCurrentUser);
  const topRatedVendors = useSelector(selectVendorsList);
  const categories = useSelector(selectCategoriesList);
  const offers = useSelector(selectOffersList);
  const popularNearVendors = useSelector(selectPopularNearRestaurants);
  const selectedAddress = useSelector(selectSelectedAddress);
  const OrderTime = useSelector(selectOrderTime);
  const loading = useSelector(selectListVendorsLoading);
  const CartData = useSelector(selectCartData);
  const deliveryTimeRangesData = useSelector(deliveryTimeRanges);

  // after login
  const orders = useSelector(selectordersList);
  const favoriteVendors = useSelector(selectFavoriteRestaurants);

  useEffect(() => {
    dispatch(listCategoriesStart());
    dispatch(listOffersStart());
  }, []);

  useEffect(() => {
    if (currentLng && sessionLocation) {
      dispatch(
        ListPopularNearRestaurantsStart({
          page_number: 1,
          page_size: 10,
        })
      );
      if (currentUser)
        dispatch(
          ListFavoriteRestaurantsStart({ page_number: 1, page_size: 10 })
        );
    } else {
      dispatch(getAllVendorsStart({ ...queryParams, page_size: 15 }));
    }
  }, [currentLng, sessionLocation, OrderTime, selectedAddress]);

  useEffect(() => {
    if (currentLng && currentUser) {
      dispatch(listOrdersStart({ status: "completed" }));
      dispatch(getSelectedAddressStart({}));
    }
  }, [currentLng, currentUser]);

  useEffect(() => {
    if (sessionStorage?.distance_cords) {
      return;
    } else if (selectedAddress && !sessionStorage?.distance_cords) {
      StorageUtils.saveItems(sessionStorage, [
        {
          name: "distance_cords",
          data: JSON.stringify(selectedAddress),
        },
      ]);
      StorageUtils.saveItems(sessionStorage, [
        {
          name: "selectedAddressType",
          data: "saved",
        },
      ]);
      StorageUtils.saveItems(sessionStorage, [
        {
          name: "address",
          data: `${selectedAddress?.name},${selectedAddress?.street_name}`,
        },
      ]);
    } else if (!selectedAddress && !sessionStorage?.distance_cords) {
      detectUserCoords();
    }
  }, [selectedAddress, sessionStorage]);

  const detectUserCoords = async () => {
    await StorageUtils.saveItems(sessionStorage, [
      {
        name: "selectedAddressType",
        data: "notSaved",
      },
    ]);
    if (navigator?.geolocation) {
      await navigator?.geolocation?.getCurrentPosition(
        async ({ coords: { latitude, longitude } }) => {
          const distance_cords = {
            lat: latitude,
            lng: longitude,
          };
          await StorageUtils.saveItems(sessionStorage, [
            { name: "distance_cords", data: JSON.stringify(distance_cords) },
          ]);
          localStorage.setItem(
            "distance_cords",
            JSON.stringify(distance_cords)
          );
          await dispatch(changeCord(distance_cords));

          await setUserCoords(distance_cords);
          StorageUtils.saveItems(sessionStorage, [
            {
              name: "address",
              data: "Your current location",
            },
          ]);
        }
      );
    }
  };

  return (
    <>
      <HomeTemplate
        categories={categories}
        offers={offers}
        orders={orders}
        favoriteRestaurants={favoriteVendors}
        popularNearRestaurants={popularNearVendors}
        detectUserCoords={detectUserCoords}
        selectedAddress={selectedAddress}
        UserCoords={UserCoords}
        setUserCoords={setUserCoords}
        topRatedVendors={topRatedVendors}
        currentLng={currentLng}
        CartData={CartData}
        deliveryTimeRangesData={deliveryTimeRangesData}
        loading={loading}
      />
    </>
  );
};

export default HomePage;
