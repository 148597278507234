import React from "react";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Modal } from "components/molecules";

const ShareModel = ({ ToggleModal, openModal }) => {
  const url = window.location.href;

  return (
    <Modal isOpen={openModal} ToggleModalVisibility={() => ToggleModal(false)}>
      <FacebookShareButton url={url} className="mr-4">
        <FacebookIcon size={60} round />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={url} className="mr-4">
        <FacebookMessengerIcon size={60} round />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={url} className="mr-4">
        <TwitterIcon size={60} round />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={60} round />
      </WhatsappShareButton>
    </Modal>
  );
};

export default ShareModel;
