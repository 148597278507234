import { icons } from "assets/AssetHelper";
import axios from "axios";
import AlertModal from "components/atoms/Modal/AlertModal";
import { useFormik } from "formik";
import { useState } from "react";
import { BaseURL } from "services/modules/utils/constant";
import * as Yup from "yup";
import Loader from "../../../components/atoms/Loader/Loader";
import DeleteAccountTemplate from "../../../templates/Auth/deleteAccount/DeleteAccount.Template";

const mobile_numberValidation = Yup.object().shape({
  mobile_number: Yup.string().required("Mobile number is required"),
  password: Yup.string().required("Password is required").label("Password"),
});

const errorConfig = [
  {
    message:
      "Oops! There seems to be a mismatch.Check your phone number and password again.",
    icon: icons?.Exclamationmark,
  },
  {
    message:
      "Oops! There seems to be a mismatch.Check your phone number and password again.",
    icon: icons?.NotFound,
  },
  {
    message:
      "That account seems to be missing! Double-check the phone number and try again.",
    icon: icons?.NotFound,
  },
];

export default function DeleteAccountPage() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertIcon, setAlertIcon] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = async (values) => {
    let { mobile_number, password } = values;

    const body = {
      customer: {
        mobile_number,
        password,
      },
    };

    const config = {
      data: body,
    };
    setLoading(true);

    try {
      const response = await axios.delete(
        `${BaseURL}/v1/customer/customer_profile`,
        config
      );

      if (response.status === 200) {
        setAlertMessage("Your account has been successfully deleted.");
        setAlertOpen(true);
        formik?.resetForm();
      }
    } catch (error) {
      const errorType = error?.response?.data?.message;
      const config =
        errorConfig.find((config) => config.message === errorType) || {};

      if (config.message) {
        setAlertMessage(config.message);
        setAlertIcon(config.icon);
        setAlertOpen(true);
      } else {
        setAlertMessage("An unknown error occurred. Please try again.");
        setAlertOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { mobile_number: "", password: "" },
    validationSchema: mobile_numberValidation,
    onSubmit: async (values) => {
      handleDeleteAccount(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={loading} />
      <AlertModal
        open={alertOpen}
        message={alertMessage}
        icon={alertIcon}
        onClose={() => setAlertOpen(false)}
      />
      <DeleteAccountTemplate formik={formik} />
    </>
  );
}
