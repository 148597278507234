import { PropTypes } from "prop-types";
import classNames from "classnames";

import { Img, Typography } from "components/atoms";

import { BlackBicycle, FullStar, Pickup } from "utils/helpers/SVGsSrc";

import styles from "./RestaurantCard.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import { StorageUtils } from "utils";
import { selectSelectedAddress } from "Redux/addresses/selectors/addresses.selectors";
import { useSelector } from "react-redux";

const RestaurantCard = ({
  t,
  imgUrl,
  restaurantName,
  rating,
  description,
  deliveryFees,
  deliveryDuration,
  cardClasses,
  available,
  vendor,
  cartData,
}) => {
  const sessionLocation = JSON.parse(
    StorageUtils.getItems(sessionStorage, ["distance_cords"])["distance_cords"]
  );
  const selectedAddress = useSelector(selectSelectedAddress);

  const { i18n } = useTranslation();
  const pickupDistnation =
    parseFloat(vendor?.travel_distance).toFixed(2) > 1
      ? parseFloat(vendor?.travel_distance).toFixed(2)
      : parseFloat(vendor?.travel_distance).toFixed(2) * 1000;
  const pickupDistnationUnit =
    Math.ceil(parseFloat(vendor?.travel_distance).toFixed(2)) > 1
      ? `${t("km")}`
      : `${t("m")}`;
  const currentLang = i18n.language;
  return (
    <div className={classNames(styles.container, cardClasses)}>
      <div style={{ position: "relative" }}>
        {!available && (
          <div
            style={{
              position: "absolute",
              top: "0",
              bottom: "0",
              right: "0",
              left: "0",
              backgroundColor: "rgba(18, 18, 18, 0.38)",
              backdropFilter: "blur(5.3px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px 10px 0 0 ",
            }}
          >
            <Typography
              as="h3"
              size="lg"
              color="text-white"
              lineHeight="leading-[1.45]"
              clsasses={"text-center"}
              text={"Unavailable"}
            />
          </div>
        )}

        <Img
          containerClasses="cursor-pointer h-[104px] lg:h-[104px] 3xl:h-[130px]"
          picClasses=" object-cover"
          fileName={imgUrl}
          altText={restaurantName}
        />
      </div>

      <div className={styles.restaurantInfoContainer}>
        <div className={styles.restaurantInfoHeader}>
          <Typography
            as="h3"
            size="2xl"
            color="text-text_3b3b3b"
            fontWeight="font-bold"
            lineHeight="leading-[1.45]"
            classes="ellipseText h-8 max-h-8 w-3/4 max-w-3/4 line-clamp-1"
            text={restaurantName}
          />
          <div className={styles.restaurantInfoHeader__rating}>
            <div className={styles.ratingIcon}>
              <FullStar width="100%" height="100%" title="review" />
            </div>
            <Typography
              as="span"
              size="sm"
              color="text-warm_grey"
              lineHeight="leading-[1.19]"
              text={rating ? rating : 0}
            />
          </div>
        </div>

        <div className="py-3 3xl:py-4 ">
          <Typography
            as="p"
            size="lg"
            lineHeight="leading-[1]"
            classes="truncate line-clamp-1 h-[21px] max-h-[21px] "
            text={description}
          />
        </div>
        {/* delivery */}

        {vendor?.is_delivery_available &&
          (sessionLocation ? (
            <div className="flex">
              <div className="flex items-center gap-1">
                <div className={styles.deliveryIcon}>
                  <BlackBicycle width="100%" height="100%" title="delivery" />
                </div>
                <div>
                  <Typography
                    as="span"
                    size="sm"
                    color="text-warm_grey"
                    lineHeight="leading-[1.19]"
                    text={
                      typeof deliveryFees == "string"
                        ? deliveryFees
                        : `${parseFloat(deliveryFees).toFixed(2)} EGP`
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <div className={styles.deliveryIcon}>
                <BlackBicycle width="100%" height="100%" title="delivery" />
              </div>
              <Typography
                as="span"
                size="sm"
                color="text-warm_grey"
                lineHeight="leading-[1.19]"
                text={t(`delivery`)}
              />
            </div>
          ))}

        {/* Pickup */}
        {vendor?.pickup_details?.is_pickup_available && (
          <div className="flex items-center gap-1">
            <div className={styles.deliveryIcon}>
              <Pickup width="100%" height="100%" title="Pickup" />
            </div>

            {sessionLocation ? (
              <div className="flex">
                <div>
                  <Typography
                    as="span"
                    size="sm"
                    color="text-warm_grey"
                    lineHeight="leading-[1.19]"
                    text={`${
                      currentLang?.includes("ar")
                        ? `${t(
                            `awayar`
                          )} ${pickupDistnation} ${pickupDistnationUnit}  ${t(
                            "approx"
                          )}`
                        : ""
                    }   ${
                      currentLang?.includes("en")
                        ? `${t(
                            "approx"
                          )} ${pickupDistnation} ${pickupDistnationUnit}  ${t(
                            `away`
                          )} `
                        : ""
                    }`}
                  />
                </div>
              </div>
            ) : (
              <Typography
                as="span"
                size="sm"
                color="text-warm_grey"
                lineHeight="leading-[1.19]"
                text={t(`pickup`)}
              />
            )}
          </div>
        )}
        {!vendor?.is_delivery_available &&
          !vendor?.pickup_details?.is_pickup_available && (
            <Typography
              as="span"
              size="sm"
              color="text-warm_grey"
              lineHeight="leading-[1.19]"
              text={`${t("out of range")}`}
            />
          )}
      </div>
    </div>
  );
};

RestaurantCard.propTypes = {
  imgUrl: PropTypes.string,
  restaurantName: PropTypes.string,
  rating: PropTypes.number,
  description: PropTypes.string,
  deliveryFees: PropTypes.number,
  deliveryDuration: PropTypes.number,
};

export default withTranslation()(RestaurantCard);
