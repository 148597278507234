import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOrderStart } from "Redux/orders/actions/orders.actions";
import { selectOrder } from "Redux/orders/selectors/orders.selectors";
import { SuccessOrderTemplate } from "templates";

const SuccessOrderPage = () => {
  const dispatch = useDispatch();
  const { order_id } = useParams();

  const order = useSelector(selectOrder);

  useEffect(() => {
    dispatch(getOrderStart({ orderId: order_id }));
  }, []);

  return <SuccessOrderTemplate order={order} />;
};

export default SuccessOrderPage;
