const VendorsActionTypes = {
  RESTAURANT_DATA_START: "RESTAURANT_DATA_START",
  RESTAURANT_DATA_SUCCESS: "RESTAURANT_DATA_SUCCESS",
  RESTAURANT_DATA_FAILURE: "RESTAURANT_DATA_FAILURE",
  LIST_FAVORITE_RESTAURANTS_START: "LIST_FAVORITE_RESTAURANTS_START",
  LIST_FAVORITE_RESTAURANTS_SUCCESS: "LIST_FAVORITE_RESTAURANTS_SUCCESS",
  LIST_FAVORITE_RESTAURANTS_FAILURE: "LIST_FAVORITE_RESTAURANTS_FAILURE",
  LOAD_MORE_FAVORITE_RESTAURANTS_START: "LOAD_MORE_FAVORITE_RESTAURANTS_START",
  LOAD_MORE_FAVORITE_RESTAURANTS_SUCCESS:
    "LOAD_MORE_FAVORITE_RESTAURANTS_SUCCESS",
  LOAD_MORE_FAVORITE_RESTAURANTS_FAILURE:
    "LOAD_MORE_FAVORITE_RESTAURANTS_FAILURE",
  LIST_POPULAR_NEAR_RESTAURANTS_START: "LIST_POPULAR_NEAR_RESTAURANTS_START",
  LIST_POPULAR_NEAR_RESTAURANTS_SUCCESS:
    "LIST_POPULAR_NEAR_RESTAURANTS_SUCCESS",
  LIST_POPULAR_NEAR_RESTAURANTS_FAILURE:
    "LIST_POPULAR_NEAR_RESTAURANTS_FAILURE",
  LOAD_MORE_POPULAR_NEAR_RESTAURANTS_START:
    "LOAD_MORE_POPULAR_NEAR_RESTAURANTS_START",
  LOAD_MORE_POPULAR_NEAR_RESTAURANTS_SUCCESS:
    "LOAD_MORE_POPULAR_NEAR_RESTAURANTS_SUCCESS",
  LOAD_MORE_POPULAR_NEAR_RESTAURANTS_FAILURE:
    "LOAD_MORE_POPULAR_NEAR_RESTAURANTS_FAILURE",
  GET_ALL_VENDORS_START: "GET_ALL_VENDORS_START",
  GET_ALL_VENDORS_SUCCESS: "GET_ALL_VENDORS_SUCCESS",
  GET_ALL_VENDORS_FAILURE: "GET_ALL_VENDORS_FAILURE",
  LOAD_MORE_VENDORS_START: "LOAD_MORE_VENDORS_START",
  LOAD_MORE_VENDORS_SUCCESS: "LOAD_MORE_VENDORS_SUCCESS",
  LOAD_MORE_VENDORS_FAILURE: "LOAD_MORE_VENDORS_FAILURE",
  ADD_FAVORITE_VENDOR_START: "ADD_FAVORITE_VENDOR_START",
  ADD_FAVORITE_VENDOR_SUCCESS: "ADD_FAVORITE_VENDOR_SUCCESS",
  ADD_FAVORITE_VENDOR_FAILURE: "ADD_FAVORITE_VENDOR_FAILURE",
  REMOVE_FAVORITE_VENDOR_START: "REMOVE_FAVORITE_VENDOR_START",
  REMOVE_FAVORITE_VENDOR_SUCCESS: "REMOVE_FAVORITE_VENDOR_SUCCESS",
  REMOVE_FAVORITE_VENDOR_FAILURE: "REMOVE_FAVORITE_VENDOR_FAILURE",
  GET_VENDOR_FILTERS_START: "GET_VENDOR_FILTERS_START",
  GET_VENDOR_FILTERS_SUCCESS: "GET_VENDOR_FILTERS_SUCCESS",
  GET_VENDOR_FILTERS_FAILURE: "GET_VENDOR_FILTERS_FAILURE",
  GET_VENDOR_REVIEWS_START: "GET_VENDOR_REVIEWS_START",
  GET_VENDOR_REVIEWS_SUCCESS: "GET_VENDOR_REVIEWS_SUCCESS",
  GET_VENDOR_REVIEWS_FAILURE: "GET_VENDOR_REVIEWS_FAILURE",
  GET_TOP_OFFERS_VENDORS_START: "GET_TOP_OFFERS_VENDORS_START",
  GET_TOP_OFFERS_VENDORS_SUCCESS: "GET_TOP_OFFERS_VENDORS_SUCCESS",
  GET_TOP_OFFERS_VENDORS_FAILURE: "GET_TOP_OFFERS_VENDORS_FAILURE",
};

export default VendorsActionTypes;
